<template>
  <div class="flex">
    <div
      class="mr-4 w-1/3 hidden md:flex justify-center items-center"
    >
      <img
        :src="image"
        alt=""
        class="block"
      />
    </div>

    <div class="w-full">
      <h2 class="text-xl mb-6">
        {{ baseProductDetails.name }}

        <span class="text-sm text-gray-50 block">
          {{ baseProductDetails.brand.data.name }}
        </span>
      </h2>

      <div class="flex justify-center my-2 md:hidden">
        <img
          :src="image"
          alt=""
        />
      </div>

      <h3 class="font-bold">
        {{ $t('global.description') }}
      </h3>
      <p
        class="my-2 font-light product-info-description"
        v-html="baseProductDetails.description"
      />
      <br />

      <h3 class="my-2 font-bold">
        {{ $t('global.size_chart') }}
      </h3>

      <div class="flex text-xs xs:text-base">
        <div class="w-full font-bold text-center">
          <div class="bg-gray-00 border-b border-gray-10 p-1 xs:p-2 text-gray-50">
            cm
          </div>

          <div class="p-1 xs:p-2 border-b border-gray-10">
            {{ $t('views.product_details.size_chart.width') }}
          </div>

          <div class="p-1 xs:p-2 border-b border-gray-10">
            {{ $t('views.product_details.size_chart.height') }}
          </div>

          <div
            v-if="showSleeveLength"
            class="p-1 xs:p-2 border-b border-gray-10"
          >
            {{ $t('views.product_details.size_chart.sleeve_length') }}
          </div>
        </div>

        <div
          v-for="(size, index) in baseProductDetails.sizings.data"
          :key="index"
          class="w-full text-center"
        >
          <div class="bg-gray-00 border-b border-gray-10 p-1 xs:p-2 font-bold">
            {{ size.size }}
          </div>

          <div class="p-1 xs:p-2 border-b border-gray-10">
            {{ size.sizing_width_mm / 10 }}
          </div>

          <div class="p-1 xs:p-2 border-b border-gray-10">
            {{ size.sizing_height_mm / 10 }}
          </div>

          <div
            v-if="showSleeveLength"
            class="p-1 xs:p-2 border-b border-gray-10">
            {{ size.sizing_sleeve_mm / 10 }}
          </div>
        </div>
      </div>
      <br>
      <h3 class="my-2 font-bold">
        Herstellerangaben gemäß GPSR
      </h3>
      <div class="flex flex-col gap-3">
        <div>
          {{ $t('views.product_details.gpsr.manufacturer') }} {{ baseProductDetails.brand.data.company_name }},
          {{ baseProductDetails.brand.data.street }}, {{ baseProductDetails.brand.data.zip }}
          {{ baseProductDetails.brand.data.city }}, {{ baseProductDetails.brand.data.country }},
          <a
            :href="`mailto:${baseProductDetails.brand.data.email}`"
            class="text-blue-50"
          >
            {{ baseProductDetails.brand.data.email }}
          </a>
        </div>

        <div v-if="baseProductDetails.brand.data.representative_company_name">
          {{ $t('views.product_details.gpsr.responsible_person') }}
          {{ baseProductDetails.brand.data.representative_company_name }},
          {{ baseProductDetails.brand.data.representative_street }},
          {{ baseProductDetails.brand.data.representative_zip }}
          {{ baseProductDetails.brand.data.representative_city }},
          {{ baseProductDetails.brand.data.representative_country }},
          <a
            :href="`mailto:${baseProductDetails.brand.data.representative_email}`"
            class="text-blue-50"
          >
            {{ baseProductDetails.brand.data.representative_email }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

    props: {
      baseProductDetails: {
        type: Object,
        required: true
      }
    },

    computed: {
      image() {
        const image = this.baseProductDetails.default_image
        return image.large || image.standard
      },
      showSleeveLength() {
        return this.baseProductDetails.sizings.data.find(s => s.sizing_sleeve_mm !== null && s.sizing_sleeve_mm !== 0)
      }
    }

  }
</script>

<style scoped lang="scss">
  .product-info-description ul {
    list-style: initial;
    padding-left: 20px;
  }
</style>

<template>
  <div class="bg-white border rounded h-full px-4 py-6">
    <div class="text-blue-80 font-bold">
      {{ name }}
      <span
        v-if="!readonly"
        class="icon-edit-outline cursor-pointer text-blue-50"
        title="Bearbeiten"
        @click="$router.push({ name: 'checkout', params: {reference: cart.reference } })"></span>
    </div>
    <div class="text-gray-50 text-sm">
      <span v-if="address.company_name">{{ address.company_name }}<br /></span>
      {{ address.firstname }} {{ address.lastname }}<br />
      {{ address.street }} {{ address.streetnumber }}<br />
      {{ address.zip }} {{ address.city }}<br />
      {{ address.country }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {

    props: {
      name: String,
      address: Object,
      readonly: Boolean
    },

    computed: {
      ...mapGetters(['cart']),
    },

  }
</script>

<style scoped>

</style>

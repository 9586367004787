<template>
  <footer :class="className">
    <div class="flex-1">
      <a
        href="/terms"
        class="mr-3 hover:text-gray-80"
      >
        {{ $t('navigation.footer.terms_and_conditions') }}
      </a>
      <a
        href="/imprint"
        class="mr-3 hover:text-gray-80"
      >
        {{ $t('navigation.footer.imprint') }}
      </a>
      <a
        href="/privacy"
        class="mr-3 hover:text-gray-80"
      >
        {{ $t('navigation.footer.privacy') }}
      </a>
      <a
        href="/withdrawal"
        class="mr-3 hover:text-gray-80"
      >
        {{ $t('navigation.footer.withdrawal') }}
      </a>
    </div>

    <div v-if="showPriceInfo">
      {{ $t('navigation.footer.price_info') }}
    </div>
  </footer>
</template>

<script>
  export default {
    props: {
      className: {
        type: String,
        default: 'flex items-center flex-col text-gray-50 h-footer text-sm pt-6 px-6 md:flex-row mb-2',
      },
      showPriceInfo: {
        type: Boolean,
        default: true,
      }
    }
  }
</script>

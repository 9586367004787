<script>
  import Chip from './Chip.vue'

  export default {

    extends: Chip,

    data() {
      return {
        outline: true,
      }
    },

  }
</script>

<template>
  <div
    v-if="isVisible"
    :class="['floating-alert', `alert-${type}`, { 'alert-dismissable': dismissable }]"
    :style="`opacity: ${alertOpacity}`">
    <span class="type-icon">
      <icon
        v-if="type === 'info'"
        class="fa-fw"
        fa="lightbulb" />
      <icon
        v-if="type === 'success'"
        class="fa-fw"
        fa="check-circle" />
      <icon
        v-if="type === 'warning'"
        class="fa-fw"
        fa="info-circle" />
      <icon
        v-if="type === 'danger'"
        class="fa-fw"
        fa="exclamation-triangle" />
    </span>

    <div class="content">
      <slot></slot>
    </div>

    <a
      v-if="dismissable"
      class="close"
      title="Schließen"
      href="#"
      @click.prevent="remove">
      <icon fa="times" />
    </a>
  </div>
</template>

<script>
  import Icon from 'sb/common/components/Icon.vue'

  // time in milliseconds
  const DURATION_NORMAL = 4000
  const DURATION_LONG = 8000
  const ANIMATION_TIME = 400

  export default {

    props: {
      type: {
        type: String,
        default: 'info',
      },
      dismissable: {
        default: true,
        type: Boolean,
      },
      index: {
        type: Number,
        default: 0,
      },
      duration: [
        String,
        Number,
      ],
    },

    data() {
      return {
        isVisible: true,
        alertOpacity: 1,
      }
    },

    created() {
      if (this.duration === 'normal') {
        setTimeout(() => this.remove(), DURATION_NORMAL)
      } else if (this.duration === 'long') {
        setTimeout(() => this.remove(), DURATION_LONG)
      } else if (typeof this.duration === 'number') {
        setTimeout(() => this.remove(), parseInt(this.duration))
      }
    },

    methods: {
      remove() {
        if (!this.dismissable) {
          return false
        }

        this.alertOpacity = 0

        setTimeout(() => {
          this.isVisible = false

          if (this.index) {
            this.$emit('alert:remove', this.index)
          }
        }, ANIMATION_TIME)

        return true
      },
    },

    components: {
      Icon,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .floating-alert {
    margin-bottom: 0;
    font-size: 1rem;
    padding: 1.25rem;
    border: 1px solid transparent;
    opacity: 1;
    transition: .2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.75;
    border-radius: $border-radius-alert;
    box-shadow: $floating-alert-shadow;
    color: $white;
    font-weight: 600;

    .type-icon {
      display: block;
      font-size: 1.25rem;
      margin-right: .75rem;
      margin-left: -.25rem;
      line-height: 1;
    }

    .close {
      margin-left: auto;
      padding: 0;
      text-decoration: none;

      &:focus {
        text-decoration: underline;
      }
    }

    p {
      color: inherit;
      margin: 0 0 .5em;

      &:last-child {
        margin: 0;
      }
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: inherit;
        text-decoration: none;
      }
    }

    &,
    &.alert-info {
      background-color: $blue-50;
      border-color: $blue-50;
    }

    &.alert-success {
      background-color: $green-50;
      border-color: $green-50;
    }

    &.alert-warning {
      background-color: $yellow-50;
      border-color: $yellow-50;
    }

    &.alert-danger {
      background-color: $red-50;
      border-color: $red-50;
    }
  }
</style>

import * as components from './src'
import * as filters from './src/common/filters'

export default {
  install(Vue, options = {}) {
    if (options.useRouter) {
      Vue.prototype.$useRouter = true
    }

    for (const key in components) {
      const component = components[key]
      if (component) {
        Vue.component(key, component)
      }
    }

    for (const key in filters) {
      const filter = filters[key]
      if (filter) {
        Vue.filter(key, filter)
      }
    }
  }
}

<template>
  <div
    v-if="selectedProcessingItem && selectedProcessingItem.type === 'text'"
    class="text-gray-500"
  >
    <label>Dein Text</label>

    <sg-textarea
      ref="textarea"
      class="text-gray-80"
      placeholder="Dein Text"
      :value="textContent"
      @input="onInput"
    />

    <div class="flex mt-4 gap-4">
      <div class="flex-1">
        <label>Schriftart</label>

        <font-selection
          :active-font-family-name="activeFontFamilyName"
          :designer-fonts="designerFonts"
          @selectFont="selectFont"
        />
      </div>

      <div class="flex-1">
        <label>Farbe</label>

        <label
          for="textColorSelect"
          class="relative block border-gray-20 border rounded-md pt-2 pl-3 cursor-pointer color-wrapper"
          style="height: 50px;"
        >
          <color-swatch-designer
            :color="colorObject"
            :current-color="{id: null}"
          />

          <input
            id="textColorSelect"
            :value="selectedProcessingItem.text_color"
            class="opacity-0 absolute top-0 left-0 w-0 h-0"
            type="color"
            @change="updateTextColor"
          />

          <span class="block mt-1 text-gray-80">
            {{ selectedProcessingItem.text_color }}
          </span>
        </label>
      </div>

      <div
        v-if="fontObject.bold_enabled || fontObject.italic_enabled"
      >
        <label>Stil</label>

        <div class="flex gap-2">
          <div
            v-if="fontObject.bold_enabled"
            :class="selectedProcessingItem.font_bold ? 'bg-blue-10 text-blue-500 border-blue-200' : 'text-gray-80 border-gray-20'"
            class="cursor-pointer text-center pt-2 text-2xl border rounded-md"
            style="width: 50px; height: 50px"
            @click.prevent="toggleBold"
          >
            <strong>B</strong>
          </div>

          <div
            v-if="false && fontObject.italic_enabled"
            :class="selectedProcessingItem.font_italic ? 'bg-blue-10 text-blue-500 border-blue-200' : 'text-gray-80 border-gray-20'"
            class="cursor-pointer text-center pt-2 font-extrabold text-2xl border rounded-md pr-1"
            style="width: 50px; height: 50px"
            @click.prevent="toggleItalic"
          >
            <i>I</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ColorSwatchDesigner from 'sb/elements/Designer/Controls/ColorSwatchDesigner'
  import SgTextarea from 'sb/elements/Form/SgTextarea'
  import { PLACEHOLDER } from 'sb/elements/Designer/ProcessingItemTypes/ProcessingItemTypeTextPath'
  import FontSelection from 'sb/elements/Designer/Controls/FontSelection.vue'

  export default {

    props: {
      selectedProcessingItem: {
        required: false,
        type: Object
      },
      designerFonts: {
        type: Array,
        required: true
      },
      processingItems: {
        required: true,
        type: Array
      }
    },

    data() {
      return {
        showFontSelect: false,
        textContent: '',
        isTypingTimeout: null,
        isTyping: false,
      }
    },

    mounted() {
      this.updateProcessingItemBindings()
    },

    methods: {
      toggleItalic() {
        if (this.selectedProcessingItem.type !== 'text') return

        this.updateProcessingItem({
          ...this.selectedProcessingItem,
          font_italic: !this.selectedProcessingItem.font_italic
        })
      },
      toggleBold() {
        if (this.selectedProcessingItem.type !== 'text') return

        this.updateProcessingItem({
          ...this.selectedProcessingItem,
          font_bold: !this.selectedProcessingItem.font_bold
        })
      },
      onInput(value) {
        this.isTyping = true

        this.textContent = value

        this.updateProcessingItem({
          ...this.selectedProcessingItem,
          content: this.textContent.trimStart().replaceAll(/ +/g, ' ')
        })

        this.isTypingTimeout = setTimeout(() => {
          this.isTyping = false
        }, 500)
      },
      updateTextColor(event) {
        if (this.selectedProcessingItem.type !== 'text') return

        this.updateProcessingItem({
          ...this.selectedProcessingItem,
          text_color: event.target.value
        })
      },
      selectFont(font) {
        if (this.selectedProcessingItem.type !== 'text') {
          return
        }

        let font_italic = this.selectedProcessingItem.font_italic
        if (!font.italic_enabled) {
          font_italic = false
        }

        let font_bold = this.selectedProcessingItem.font_bold
        if (!font.bold_enabled) {
          font_bold = false
        }

        this.showFontSelect = false

        this.updateProcessingItem({
          ...this.selectedProcessingItem,
          font_family: font,
          font_italic,
          font_bold,
        })
      },
      updateProcessingItem(updatedProcessingItem) {
        this.$emit('processing-updated', updatedProcessingItem)
      },
      updateProcessingItemBindings() {
        if (!this.selectedProcessingItem && !this.isTyping) {
          return
        }

        if (this.selectedProcessingItem.content === PLACEHOLDER) {
          this.textContent = ''
        } else if (this.selectedProcessingItem.content !== this.textContent) {
          this.textContent = this.selectedProcessingItem.content
        }

        if (this.$refs.textarea && this.$refs.textarea.$el) {
          this.$refs.textarea.$el.focus()
        }
      },
    },

    computed: {
      activeFontFamilyName() {
        if (!this.selectedProcessingItem.font_family) {
          return ''
        }

        return this.selectedProcessingItem.font_family.name
      },
      fontObject() {
        if (!this.selectedProcessingItem.font_family) {
          return {}
        }

        return this.designerFonts.find(f => f.name === this.selectedProcessingItem.font_family.name)
      },
      colorObject() {
        if (!this.selectedProcessingItem || !this.selectedProcessingItem.text_color) {
          return {}
        }
        return {
          id: this.selectedProcessingItem.text_color,
          hexcode: this.selectedProcessingItem.text_color.replace('#', '')
        }
      },
      shortContent() {
        if (this.selectedProcessingItem.content.length < 20) {
          return this.selectedProcessingItem.content
        } else {
          return this.selectedProcessingItem.content.substr(0, 20) + '...'
        }
      }
    },

    watch: {
      selectedProcessingItem: {
        deep: true,
        handler() {
          this.updateProcessingItemBindings()
        }
      },
      colorObject() {
        this.updateProcessingItem()
      }
    },

    components: {
      SgTextarea,
      ColorSwatchDesigner,
      FontSelection,
    }

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .color-wrapper::after {
    @include fa;

    content: "\f0dc";
    color: $grey-70;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: .75rem;
    bottom: 0;
    pointer-events: none;
  }
</style>

<template>
  <button
    class="border text-sm py-1 px-2 mr-2 mb-2 rounded"
    :class="active ? 'bg-blue-00 text-blue-50 border-light-blue': 'border-gray-20 text-gray-70'"
    @click.prevent="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean,
        default: false
      },
    },
  }
</script>

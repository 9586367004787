<template>
  <section class="product-info__data-sheet">
    <sg-table>
      <tbody>
        <tr>
          <th>Produktname</th>
          <td>{{ baseProduct.name }}</td>
        </tr>
        <tr>
          <th>Hersteller</th>
          <td>{{ baseProduct.brand.name }}</td>
        </tr>
        <tr>
          <th>Artikelnummer</th>
          <td>{{ baseProduct.brand_product_name }}</td>
        </tr>
        <tr>
          <th>Produktkategorie</th>
          <td>{{ baseProduct.categories.product_type }}</td>
        </tr>
        <tr>
          <th>Geschlecht</th>
          <td>{{ baseProduct.categories.gender }}</td>
        </tr>
        <tr>
          <th>Produktart</th>
          <td>{{ baseProduct.categories.style }}</td>
        </tr>
        <tr>
          <th>Farben</th>
          <td>{{ baseProduct.colors.length }}</td>
        </tr>
        <tr>
          <th>Größen</th>
          <td>
            <span
              v-for="(size, i) in baseProduct.sizes"
              :key="size.reference">
              {{ size.size }}{{ i !== baseProduct.sizes.length - 1 ? ',' : '' }}
            </span>
          </td>
        </tr>
        <tr>
          <th>Preis</th>
          <td>ab {{ baseProduct.baseprice_with_processing_default | currency }}</td>
        </tr>
      </tbody>
    </sg-table>
  </section>
</template>

<script>
  import SgTable from 'sb/elements/Layout/SgTable.vue'

  export default {

    props: {
      baseProduct: {
        type: Object,
        required: true,
      },
    },

    components: {
      SgTable,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .product-info__data-sheet {
    width: 100%;
  }
</style>

<template>
  <div class="w-full md:w-1/3 p-3">
    <div
      v-if="productImage"
      class="p-3 cursor-pointer border border-white hover:border-grey-10 rounded transition duration-500"
      :class="{'border-blue-30': currentProduct.id === product.id}"
      @click="changeCurrentProduct"
    >
      <img
        :src="productImage"
        class="w-full block"
        alt=""
      >

      <div class="w-full text-center">
        <div class="tracking-3 leading-ah-relaxed">
          {{ product.name }}
        </div>
        <div class="text-blue-50 text-sm tracking-4 mt-1 leading-snug">
          {{ $t('global.price_from') }} {{ baseProductPrice | currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Cart from '@/mixins/Cart'

  export default {

    mixins: [ Cart ],

    props: {
      product: {
        type: Object,
        required: true
      },
      currentProduct: {
        type: Object,
        required: true
      },
      availableColors: {
        type: Array
      },
    },

    methods: {
      async changeCurrentProduct() {
        if (this.cart && this.allCartProductsForCurrentGroup.some(({ base_product_id }) => base_product_id === this.product.id)) {
          // Product already added, do nothing
          this.$emit('close')
          return
        }

        this.$emit('select-product', this.product)
      },
      /**
       *
       * @param product
       * @param type
       * @return {*}
       */
      findPrintareaByType(product, type) {
        return product.printareas.data.find(printarea => printarea.key === type)
      },
      ...mapActions([
        'setIsMpc',
      ])
    },

    computed: {
      baseProductPrice() {
        return parseInt(this.product.baseprice_processing_default.data.amount)
      },
      productImage() {
        let image = this.product.default_image || this.defaultProductVariant.images.data.front.find(i => i.image_type === 'catalog-image')
        return image.small || image.standard
      },
      productFit() {
        return this.product.attributes.data.find(p => p.type_key === 'product-fit')
      },
      availableSizes() {
        let productVariant = this.product.variants.data.find(p => p.id === this.currentBaseProductColor.id)
        if (!productVariant) {
          productVariant = this.product.variants.data[0]
        }
        let sizes = productVariant.size_variants.data.map(s => s.size)
        return sizes[0] + '-' + sizes[sizes.length - 1]
      },
      defaultProductVariant() {
        let sku = this.product.default_variant_sku.split('.')
        let productVariant = this.product.variants.data.find(p => p.id === parseInt(sku[1]))
        if (!productVariant) {
          productVariant = this.product.variants.data[0]
        }
        return productVariant
      },
      ...mapGetters([
        'currentBaseProductColor',
        'currentManufacturingJob',
        'cart',
        'allCartProductsForCurrentGroup',
        'currentBaseProduct'
      ])
    },

  }
</script>

<template>
  <i
    :class="`fa fa-${fa}`"
    aria-hidden="true">
  </i>
</template>

<script>
  export default {

    props: {
      fa: {
        type: String,
        required: true,
      },
    },

  }
</script>

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import Locales from './vue-i18n.generated.js'
import ShirtigoLocales from 'vendor/shirtigo/shirtigo-frontend-components/src/locales.js'

if (typeof Locales === 'undefined') {
  console.error('Locale file not found.')
}
Locales.de.shirtigo = ShirtigoLocales.de.shirtigo
Locales.en.shirtigo = ShirtigoLocales.en.shirtigo

Vue.use(VueI18n)

Vue.config.lang = 'de'

export const i18n = new VueI18n({
  locale: Vue.config.lang,
  fallbackLocale: 'de',
  messages: Locales,
})

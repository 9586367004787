<template>
  <div class="app catalog-detail">
    <catalog-header></catalog-header>

    <article class="product-preview">
      <container class="product-preview__container">
        <catalog-detail-thumbnails
          :images="images"
          :current-image="currentImage"
          @set:image="$emit('set:image', $event)" />
        <catalog-detail-preview
          :images="images"
          :current-image="currentImage"
          @set:image="$emit('set:image', $event)" />
        <catalog-detail-context
          :brand-name="baseProduct.brand.name"
          :product-name="baseProduct.name"
          :colors="colors"
          :current-color="currentColor"
          :price="price"
          @set:color="$emit('set:color', $event)"
          @open:pod-designer="$emit('open:pod-designer')"
          @open:configurator="$emit('open:configurator')"
          @open:sizes-table="openSizesTable" />
      </container>
    </article>

    <catalog-product-info
      ref="productInfo"
      :base-product="baseProduct" />
  </div>
</template>

<script>
  import SweetScroll from 'sweet-scroll'

  import CatalogHeader from 'sb/modules/Catalog/CatalogHeader.vue'
  import CatalogProductInfo from 'sb/modules/Catalog/ProductInfo/CatalogProductInfo.vue'

  export default {

    props: {
      baseProduct: Object,
      colors: Array,
      images: Array,
      currentColor: Object,
      currentImage: Object,
      price: Number,
    },

    methods: {
      openSizesTable() {
        this.$refs.productInfo.section = 'sizes-table'
        setTimeout(() => {
          const scroll = new SweetScroll({ duration: 500, updateUrl: false })
          scroll.toElement(this.$refs.productInfo.$el)
        }, 400)
      },
    },

    components: {
      CatalogHeader,
      CatalogProductInfo,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .catalog-detail {
    .product-preview {
      background: $grey-10;
      padding: 2em 0;

      @include breakpoint(lg) {
        padding: 4em 0;
      }

      &__container {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        @include breakpoint(lg) {
          flex-wrap: nowrap;
        }
      }

      .catalog-detail-preview {
        @include breakpoint(sm) {
          flex: 1;
          width: auto;
        }
      }

      .catalog-detail-thumbnails {
        flex-direction: row;
        width: 100%;
        order: 1;
        padding: 1em 0 2em;

        @include breakpoint(sm) {
          flex-direction: column;
          width: auto;
          order: 0;
          padding: 0;
        }

        &__item {
          margin-bottom: 0;

          &:not(:last-child) {
            margin-right: .5em;
          }

          @include breakpoint(sm) {
            margin-right: 0 !important;
            margin-bottom: .5em;
          }
        }
      }

      .catalog-detail-context {
        order: 2;

        @include breakpoint(lg) {
          min-width: 400px;
          max-width: 400px;
        }
      }
    }
  }
</style>

import { render, staticRenderFns } from "./SgCheckbox.vue?vue&type=template&id=0cc9825c&scoped=true"
import script from "./SgCheckbox.vue?vue&type=script&lang=js"
export * from "./SgCheckbox.vue?vue&type=script&lang=js"
import style0 from "./SgCheckbox.vue?vue&type=style&index=0&id=0cc9825c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc9825c",
  null
  
)

export default component.exports
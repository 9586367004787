<template>
  <form-group :has-danger="hasDanger">
    <label class="form-group__label">
      <slot></slot>
    </label>
    <sg-textarea
      :id="id"
      :name="name"
      :class="[className, { 'has-danger': hasDanger, 'input-lg': size === 'lg' }]"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      :required="required"
      :tabindex="tabindex"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
      @keydown.enter="$emit('keydown.enter', $event)"
      @input="$emit('input', $event)" />
  </form-group>
</template>

<script>
  import FormGroup from './FormGroup.vue'
  import SgTextarea from './SgTextarea.vue'

  export default {

    props: {
      id: String,
      name: String,
      className: String,
      hasDanger: Boolean,
      placeholder: String,
      value: {
        type: String,
        required: true
      },
      disabled: Boolean,
      required: Boolean,
      tabindex: Number,
      autofocus: Boolean,
      autocomplete: String,
      size: String,
    },

    components: {
      FormGroup,
      SgTextarea,
    },

  }
</script>

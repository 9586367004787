<template>
  <div
    class="relative"
    :title="colorName"
    :class="[styling, { active, dark }]"
    :style="`background-color: #${color}`"
    @click.prevent="$emit('click', $event)"
  >
    <!-- TODO: Don't use <div> or <a> tags instead of <button> -->
    <img
      v-if="heather"
      src="/images/heather_texture.png"
      alt="heather_texture"
      class="color-box-heathered"
    />
    <div
      v-if="discontinued"
      class="absolute -mt-2 -mr-2 top-0 right-0 z-50 rounded-full bg-white border border-white"
    >
      <img
        class="h-4 w-4"
        src="/images/exclamation-solid.svg"
        :alt="$t('views.products.discontinued_color')"
      />
    </div>
  </div>
</template>

<script>
  export default {

    props: {
      title: String,
      color: String,
      active: Boolean,
      dark: Boolean,
      small: Boolean,
      heather: Boolean,
      discontinued: Boolean,
    },

    data() {
      return {
        styling: 'color-swatch',
        colorName: null
      }
    },

    created() {
      if (this.small) {
        this.styling = 'color-swatch'
      }

      this.colorName = this.title

      if (this.discontinued) {
        this.colorName += ' (' + this.$t('views.products.discontinued_color') + ')'
      }
    }

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .color-swatch {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 32px;
    height: 32px;
    float: left;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3125rem;
    background-color: #fafafa;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 12px;
    line-height: 3px;
    cursor: pointer;
    margin: 0;

    &:hover {
      box-shadow: 0 0 0 2px #fff, 0 0 0 3px theme('colors.blue.50');
    }

    &:focus {
      box-shadow: 0 0 0 2px white, 0 0 15px 0 theme('colors.blue.50'), 0 0 0 4px #039be5;
      color: #fff;
    }

    &.active {
      box-shadow: 0 0 0 2px white, 0 0 15px 0 theme('colors.blue.50'), 0 0 0 4px #039be5;
      color: #fff;
    }
  }
</style>

// eslint-disable-next-line no-unused-vars
import { PropOptions } from 'vue'

require('../typedefs')

export default {

  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    /** @type {PropOptions<BaseProduct>} */
    baseProduct: {
      type: Object,
      required: true
    },
    /** @type {PropOptions<ApiFormattedProduct>} */
    product: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    /**
     * @param {BaseProductSizeVariant} size
     * @param {BaseProductSizeVariant[]} rowSizes
     */
    sizeIsActiveForRow(size, rowSizes) {
      return rowSizes.some((rowSize) => size.size === rowSize.size)
    },
    /**
     * @param {string} baseProductVariantId
     * @return {number}
     */
    getSizeAmountForVariantId(baseProductVariantId) {
      const variant = this.product.variants.find((variant) => variant.baseproduct_variant_id === baseProductVariantId)
      if (variant) {
        return variant.amount
      }

      return 0
    },
    removeColor(colorId) {
      this.$emit('removeColor', colorId)
    },
    /**
     * @param {string} baseProductVariantId
     * @param {string} amount
     */
    updateSizeAmountForVariantId(baseProductVariantId, amount) {
      this.$emit(
        'updateAmount',
        amount,
        baseProductVariantId,
      )
    },
    /**
     * @param {string} baseProductVariantId
     * @return {{sizeId: number, colorId: number}}
     */
    extractVariantPartsFromId(baseProductVariantId) {
      const [ , colorId, sizeId ] = baseProductVariantId.split('.')
      return { colorId: parseInt(colorId), sizeId: parseInt(sizeId) }
    },
    /**
     * @param {BaseProductSizeVariant} size
     * @param {BaseProductSizeVariant[]} rowSizes
     */
    getSizeForRow(size, rowSizes) {
      return rowSizes.find((rowSize) => size.size === rowSize.size)
    },
  },

  computed: {
    /**
     * @return {{
     *   [colorId: string]: {
     *     color: BaseProductColorVariant,
     *     sizes: BaseProductSizeVariant[],
     *   }
     * }}
     */
    colorMatrix() {
      const colorRows = {}

      this.product.variants.forEach(({ baseproduct_variant_id }) => {
        const { colorId } = this.extractVariantPartsFromId(baseproduct_variant_id)

        if (!{}.hasOwnProperty.call(colorRows, colorId)) {
          const color = this.baseProduct.variants.data.find(({ id }) => id === colorId)

          colorRows[colorId] = {
            color,
            sizes: color.size_variants.data,
          }
        }
      })

      return colorRows
    },
    /**
     * @return {BaseProductSizeVariant[]}
     */
    allAvailableSizes() {
      if (!this.colorMatrix) {
        return []
      }

      return Object.values(this.colorMatrix).reduce((outerSizesArray, row) => {
        return row.sizes.reduce((innerSizesArray, size) => {
          if (!innerSizesArray.some((item) => item.size === size.size)) {
            return [ ...innerSizesArray, size ]
          }

          return innerSizesArray
        }, outerSizesArray)
      }, [])
    }
  },

}

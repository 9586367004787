<template>
  <aside
    class="hidden xl:block tracking-tight"
    style="flex-basis: 480px"
  >
    <div
      class="bg-grey-00 border-l border-grey-10 top-0 right-0 bottom-0 p-8 pt-0 bg-shirtigo-gradient w-100 fixed"
      style="width: 480px;"
    >
      <div class="relative h-full flex flex-col pt-8">
        <div class="text-white mb-6 border-b border-white-25 pb-6">
          <h2 class="text-2xl font-bold tracking-tight leading-8">
            {{ $t('views.cart.sidebar.configuration.headline') }}
          </h2>

          <p
            v-if="collectionName"
            class="text-blue-30"
          >
            {{ collectionName }}
          </p>
        </div>

        <template v-if="cart && priceForGroup">
          <div
            :class="[showProcessings ? 'mb-8' : 'flex-1 mb-8 overflow-auto']"
          >
            <sidebar-product-entry
              v-for="cartProduct in allCartProductsForCurrentGroup"
              :key="cartProduct.base_product_id"
              :product="cartProduct"
              :loading="loading"
              @update="$emit('update')"
              @removeProduct="() => removeProduct(cartProduct.base_product_id)"
            />

            <template v-if="relevantCartProducts.length === 0">
              <loader
                v-if="loading"
                color="white"
                size="large"
                class="flex-1 h-full"
              />

              <div
                v-else
                class="text-white mt-5"
              >
                <template v-if="allCartProductsForCurrentGroup.length === 0">
                  {{ $t('views.cart.sidebar.configuration.empty_state_no_product') }}
                </template>
                <template v-else>
                  {{ $t('views.cart.sidebar.configuration.empty_state_no_size') }}
                </template>
              </div>
            </template>
          </div>

          <div
            v-if="showProcessings"
            class="flex-1 border-t border-blue-50 pt-6 text-white"
          >
            <h3 class="text-white text-xl tracking-tight">
              {{ $t('views.cart.sidebar.printing_process.headline') }}
            </h3>

            <div
              v-for="processingarea_type_key in validPrintareaKeys"
              :key="processingarea_type_key"
              class="flex mt-4"
            >
              <div class="flex-1 flex">
                <span>{{ printareaNameForKey(processingarea_type_key) }}</span>

                <span
                  class="text-blue-30 block ml-2 cursor-pointer text-lg font-bold opacity-75"
                  @click="editProduct(processingarea_type_key)"
                >
                  <i class="icon-edit-outline" />
                </span>
              </div>

              <div class="bg-blue-60 rounded-full py-1 px-3 text-sm text-gray-200">
                {{ $t('views.cart.sidebar.printing_process.digital') }}
              </div>
            </div>

            <div
              v-if="!validPrintareaKeys.length"
              class="mt-4"
            >
              {{ $t('views.cart.sidebar.printing_process.empty_state_no_processings') }}
            </div>
          </div>

          <div class="pt-4 text-white">
            <div class="text-green-30 mb-1 flex items-center">
              <i class="font-bold text-lg icon-clock-outline mr-2" />
              <span class="font-bold text-sm mr-1">
                {{ $t('views.product_details.standard_delivery_days') }}
              </span> {{ $t('views.product_details.standard_delivery') }}
            </div>

            <div class="text-sm text-blue-30 flex mb-4">
              <div class="flex-1">
                {{ $t('views.product_details.delivery_country') }} ({{ cart.delivery_iso }})
              </div>

              <country-picker
                :selected="cart.delivery_iso || 'DE'"
                @change="updateCountry($event)"
              />
            </div>
          </div>
        </template>

        <template v-else>
          <loader
            v-if="loading"
            color="white"
            size="large"
            class="flex-1 h-full"
          />

          <div
            v-else
            class="mb-8"
          >
            <div class="text-white mt-5">
              {{ $t('views.cart.sidebar.configuration.empty_state_no_product') }}
            </div>
          </div>
        </template>

        <div class="border-t border-blue-50 pt-4 text-white">
          <div class="text-sm text-blue-30">
            <div class="flex mb-1">
              <div class="flex-1">
                {{
                  $t('views.cart.full_price_display.includes_vat', {
                    vat_rate: priceForGroup ? priceForGroup.vat_rate : 19
                  })
                }}
              </div>

              <loader
                v-if="isLoadingPrices || loading"
                color="blue-30"
                size="small"
                class="w-6 text-right"
              />

              <div v-else-if="priceForGroup">
                {{ priceForGroup.vat | currency }}
              </div>
            </div>

            <div
              v-if="calculatedDiscountTotal"
              class="flex mb-1 text-green-30"
            >
              <div class="flex-1">
                {{ $t('views.cart.full_price_display.total_discount') }}
              </div>

              <loader
                v-if="isLoadingPrices || loading"
                color="blue-30"
                size="small"
                class="w-6 text-right"
              />

              <div v-else>
                {{ calculatedDiscountPercentage }} ({{ calculatedDiscountTotal | currency }})
              </div>
            </div>
          </div>

          <div class="flex items-center leading-9 h-9">
            <div class="flex-1">
              {{ $t('views.cart.full_price_display.total_price') }}
            </div>

            <loader
              v-if="isLoadingPrices || loading"
              color="blue-30"
              size="small"
              class="w-6 text-right"
            />

            <div
              v-else
              class="text-yellow-50 font-bold text-2xl"
            >
              {{ priceForGroup.total | currency }}
            </div>
          </div>

          <button
            :disabled="loading ? 'disabled' : false"
            class="submit-button"
            @click="$emit('submit')"
          >
            <span v-if="loading">
              {{ $t('global.loading') }}
            </span>
            <span v-else>
              {{ submitButtonText }}
            </span>

            <loader
              v-if="loading"
              color="yellow-80"
              size="medium"
              class="ml-2 inline-flex opacity-50"
            />

            <i class="icon-arrow-forward-outline ml-2 font-semibold text-yellow-80 opacity-50"  v-else />
          </button>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { PropOptions } from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import SidebarProductEntry from './SidebarProductEntry'
  import CountryPicker from '../CountryPicker'
  import SidebarCalculation from '../../../mixins/SidebarCalculation'
  import Loader from '../../layout/Loader.vue'

  export default {

    mixins: [ SidebarCalculation ],

    props: {
      /** @type {PropOptions<ManufacturingJobPriceItem | null>} */
      priceForGroup: {
        type: Object,
        required: false,
      },
      showProcessings: {
        type: Boolean,
        defaultValue: true,
        required: false,
      },
      submitButtonText: {
        type: String,
        defaultValue: 'Weiter',
      },
      loading: {
        type: Boolean,
        defaultValue: false,
      },
      collectionName: {
        type: String,
        required: false,
      },
    },

    methods: {
      removeProduct(baseProductId) {
        this.$emit('removeProduct', baseProductId)
      },
      /** @param {string} printarea */
      editProduct(printarea) {
        let options = {}
        options.params = {
          id: this.allCartProductsForCurrentGroup[0].base_product_id,
          reference: this.cart.reference,
          groupid: this.currentManufacturingJobGroupId
        }

        options.query = { area: printarea }

        if (this.isMpc) {
          if (this.$route.name !== 'mpc-designer') {
            this.$router.push({ name: 'mpc-designer', ...options })
          }
        } else {
          if (this.$route.name !== 'single-product-designer') {
            this.$router.push({ name: 'single-product-designer', ...options })
          }
        }
      },
      /**
       * @param {string} processingarea_type_key
       * @return {string}
       */
      printareaNameForKey(processingarea_type_key) {
        const printarea = this.currentBaseProduct.printareas.data.find(({ key }) => key === processingarea_type_key)
        if (printarea) return printarea.name
        return ''
      },
      /**
       * @param {string} country
       */
      updateCountry(country) {
        this.setCartCountry(country)
        this.$emit('update')
      },
      ...mapActions([
        'setCartCountry'
      ])
    },

    computed: {
      relevantCartProducts() {
        return this.allCartProductsForCurrentGroup.filter((product) => {
          return product.amount > 0
        })
      },
      validPrintareaKeys() {
        if (!this.allCartProcessingsForCurrentGroup) return []

        const printareaKeys = []

        this.allCartProcessingsForCurrentGroup.forEach(({ processing_items, processingarea_type_key }) => {
          if (processing_items.data.length && !printareaKeys.includes(processingarea_type_key)) {
            printareaKeys.push(processingarea_type_key)
          }
        })

        return printareaKeys.sort(({ processingarea_type_key }) => processingarea_type_key === 'front' ? -1 : 1)
      },
      ...mapGetters([
        'cart',
        'allCartProductsForCurrentGroup',
        'allCartProcessingsForCurrentGroup',
        'currentBaseProduct',
        'baseProducts',
        'currentManufacturingJobGroupId',
        'isLoadingPrices',
        'isMpc'
      ])
    },

    components: { Loader, CountryPicker, SidebarProductEntry }

  }
</script>

<style lang="scss" scoped>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer components {
    .submit-button {
      @apply mt-6 w-full px-4 py-2 h-11;
      @apply flex items-center justify-center;
      @apply font-nunito-sans font-semibold leading-7 text-yellow-90 tracking-wide;
      @apply bg-yellow-50 rounded-full shadow-big transition-all duration-200;

      &:hover {
        @apply bg-yellow-40;
      }

      @media screen and (min-width: 480px) {
        @apply text-lg;
      }

      @media screen and (min-width: 768px) {
        @apply px-6;
      }
    }
  }
</style>

<template>
  <div class="flex h-20 mb-8">
    <button
      class="flex-1 border-b cursor-pointer flex items-center justify-center uppercase font-bold tracking-wider"
      :class="selectedTab === 'product' ? ' border-blue-50 text-blue-50' : 'border-r border-gray-10'"
      @click="$emit('change-tab', 'product')"
    >
      <i class="icon-shirt mr-3 text-2.5xl" />
      {{ $t('views.designer.sidebar.tab_product') }}
    </button>

    <button
      class="flex-1 border-b cursor-pointer flex items-center justify-center uppercase font-bold tracking-wider"
      :class="selectedTab === 'design' ? ' border-blue-50 text-blue-50' : 'border-l border-gray-10'"
      @click="$emit('change-tab', 'design')"
    >
      <i class="icon-droplet-outline mr-2 text-2.5xl" />
      {{ $t('views.designer.sidebar.tab_design') }}
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      selectedTab: {
        type: String,
      }
    }
  }
</script>

<template>
  <div class="mx-auto justify-center flex mt-5 hidden md:flex mb-10">
    <div
      v-for="printarea in printareas"
      :key="printarea.key"
      :class="selectedPrintarea.key === printarea.key ? 'bg-blue-100 text-blue-500 border-blue-30' : 'bg-white text-gray-50 border-gray-20'"
      class="py-1 px-4 first:rounded-l last:rounded-r border-r tracking-wide border-t border-b first:border-l text-md leading-7 cursor-pointer block"
      @click.prevent="$emit('select', printarea)"
    >
      {{ printarea.name }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      printareas: {
        required: true,
        type: Array
      },
      selectedPrintarea: {
        required: true,
        type: Object
      }
    }
  }
</script>

<style scoped>

</style>

<template>
  <div class="flex text-white mt-4">
    <div class="flex-1">
      <div class="flex flex-1 leading-8">
        <strong>{{ baseProduct.name }}</strong>

        <button
          class="text-blue-30 block ml-2 cursor-pointer text-lg font-bold opacity-75"
          :title="$t('global.remove')"
          :disabled="loading ? 'disabled' : false"
          @click="removeProduct"
        >
          <i class="icon-trash-2-outline" />
        </button>
      </div>

      <p
        v-if="printareaNames.length"
        class="text-sm text-blue-30 mb-1"
      >
        {{ $t('global.print') }}: {{ printareaNames.join(', ') }}
      </p>

      <div class="h-4">
        <loader
          v-if="loading"
          color="blue-30"
          size="small"
          class="w-4 text-right"
        />

        <p
          v-else
          class="text-blue-30 text-sm leading-4"
        >
          <strong>{{ (product.avg_item_price_gross || 0) | currency }}</strong> / {{ $t('global.piece') }}
        </p>
      </div>
    </div>

    <number-input
      placeholder="0"
      :min="0"
      :value="amount"
      :disabled="disableInput || loading"
      class="w-24 text-dark-blue mt-1"
      @input="updateSizeAmountForProduct"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Loader from '../../layout/Loader.vue'
  import { findManufacturingJobForProductInGroup } from '../../../utils/helpers'
  import _debounce from 'lodash/debounce'
  import NumberInput from '@/components/layout/NumberInput.vue'

  export default {

    props: {
      product: {
        type: Object,
        required: true
      },
      loading: {
        type: Boolean,
        defaultValue: false,
      }
    },

    data() {
      return {
        amount: 0,
      }
    },

    mounted() {
      this.determineAmount()
    },

    methods: {
      /** @param {string} amount */
      updateSizeAmountForProduct(amount) {
        if (parseInt(amount) === 0) {
          this.removeProduct()
          this.amount = 1
          this.sendUpdateEvent()
          return
        }

        const manufacturingJobForProduct = findManufacturingJobForProductInGroup(
          this.cart,
          this.currentManufacturingJobGroupId,
          this.product.base_product_id
        )
        if (!manufacturingJobForProduct) return

        this.updateOrAddProductVariantForJob({
          manufacturingJobUuid: manufacturingJobForProduct.uuid,
          baseProductId: this.product.base_product_id,
          variant: {
            amount: parseInt(amount),
            baseproduct_variant_id: this.product.variants[0].baseproduct_variant_id
          },
        })

        this.sendUpdateEvent()
      },
      removeProduct() {
        this.$emit('removeProduct', this.baseProduct.id)
      },
      sendUpdateEvent: _debounce(function () {
        this.$emit('update')
      }, 500),
      determineAmount() {
        if (!this.product) return

        if (this.product.amount) {
          this.amount = this.product.amount
        } else {
          this.amount = this.product.variants.reduce((sum, { amount }) => sum + amount, 0)
        }
      },
      ...mapActions([
        'updateOrAddProductVariantForJob',
        'removeProductFromCart',
      ])
    },

    computed: {
      /**
       * We can't change amounts on multiple variants at the same time
       * @return {boolean}
       */
      disableInput() {
        return this.product.variants.filter(({ amount }) => amount > 0).length > 1
      },
      /** @return {string[]} */
      printareaNames() {
        if (!this.baseProduct) {
          return []
        }

        let printareaNames = []
        let availablePrintareas = this.baseProduct.printareas.data.filter(a =>
          a.is_printable &&
            this.currentManufacturingJob.processings.data.find(p =>
              p.processingarea_type_key === a.key
            ))

        availablePrintareas
          .forEach((printarea) => {
            if (this.activePrintareasForCurrentGroup.includes(printarea.key) && !printareaNames.includes(printarea.name)) {
              printareaNames.push(printarea.name)
            }
          })

        return printareaNames
      },
      /** @return {BaseProduct|null} */
      baseProduct() {
        return this.baseProducts.find(({ id }) => id === this.product.base_product_id) || null
      },
      ...mapGetters([
        'currentManufacturingJob',
        'currentManufacturingJobGroupId',
        'baseProducts',
        'cart',
        'activePrintareasForCurrentGroup',
      ])
    },

    watch: {
      'product.amount'() {
        this.determineAmount()
      },
    },

    components: { NumberInput, Loader },

  }
</script>

<template>
  <div>
    <div>
      <div class="lg:flex lg:justify-center lg:max-w-container-large w-full mx-auto">
        <main class="w-full">
          <mobile-nav
            :product-categories="activeProductCategories"
            :current-category="currentCategory"
            @change:category="changeCategory"></mobile-nav>
          <breadcrumb :current-page-name="selectedCategory.name" />

          <div class="mt-3 lg:flex lg:gap-8">
            <div class="xs:flex xs:justify-between lg:w-64">
              <div class="text-2xl font-bold text-dark-blue mb-3 text-center lg:hidden ">
                {{ selectedCategory.name }}
              </div>
              <sidebar
                :product-categories="activeProductCategories"
                :product-colors="productColors"
                :current-category="currentCategory"
                :selected-colors="selectedColors"
                :selected-brands="selectedBrands"
                :brands="brands"
                :genders="genders"
                :selected-genders="selectedGenders"
                :methods="methods"
                :selected-methods="selectedMethods"
                :catalog-products="catalogProducts"
                @change:category="changeCategory"
                @change:color="changeColor"
                @change:gender="changeGender"
                @change:method="changeMethod"
                @brand:toggle="changeBrand"></sidebar>
            </div>
            <section
              aria-labelledby="product-heading"
              class="mt-6 lg:mt-0 flex-1">
              <div class="mb-4 hidden lg:flex lg:justify-between w-full">
                <div>
                  <h1 class="font-bold text-h7 text-dark-blue">
                    {{ selectedCategory.name }}
                  </h1>
                </div>
                <div>
                  <div class="flex items-center gap-1">
                    <span class="text-sm text-gray-50">
                      {{ $t('views.catalog.order_by.label') }}
                    </span>
                    <select
                      id="orderBy"
                      class="mt-1 block w-full pl-3 pr-10 bg-white py-2 text-base border-gray-20 border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-box-sm"
                      @change="changeOrder">
                      <option :value="null">
                        {{ $t('views.catalog.order_by.popularity') }}
                      </option>
                      <option value="price_asc">
                        {{ $t('views.catalog.order_by.price_asc') }}
                      </option>
                      <option value="price_desc">
                        {{ $t('views.catalog.order_by.price_desc') }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-6 lg:justify-between lg:items-center hidden lg:flex">
                <div class="flex space-x-2 max-w-full overflow-x-auto h-full">
                  <span
                    v-for="color in selectedColors"
                    :key="color.id"
                    class="inline-flex rounded-full items-center px-2 text-xs font-medium bg-gray-50 text-white leading-5 whitespace-no-wrap">
                    {{ color.name }}
                    <button
                      type="button"
                      class="flex-shrink-0 ml-1.5 -mr-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center bg-white text-gray-50 focus:outline-none"
                      @click="removeColor(color)">
                      <span class="icon-close-outline"></span>
                    </button>
                  </span>
                  <span
                    v-for="method in selectedMethods"
                    :key="method.id"
                    class="inline-flex rounded-full items-center px-2 text-xs font-medium bg-gray-50 text-white leading-5 whitespace-no-wrap">
                    {{ method.name }}
                    <button
                      type="button"
                      class="flex-shrink-0 ml-1.5 -mr-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center bg-white text-gray-50 focus:outline-none"
                      @click="removeMethod(method)">
                      <span class="icon-close-outline"></span>
                    </button>
                  </span>
                  <span
                    v-for="gender in selectedGenders"
                    :key="gender.id"
                    class="inline-flex rounded-full items-center px-2 text-xs font-medium bg-gray-50 text-white leading-5 whitespace-no-wrap">
                    {{ gender.name }}
                    <button
                      type="button"
                      class="flex-shrink-0 ml-1.5 -mr-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center bg-white text-gray-50 focus:outline-none"
                      @click="removeGender(gender)">
                      <span class="icon-close-outline"></span>
                    </button>
                  </span>
                  <span
                    v-for="brand in selectedBrands"
                    :key="brand.id"
                    class="inline-flex rounded-full items-center px-2 text-xs font-medium bg-gray-50 text-white leading-5 whitespace-no-wrap">
                    {{ brand.name }}
                    <button
                      type="button"
                      class="flex-shrink-0 ml-1.5 -mr-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center bg-white text-gray-50 focus:outline-none"
                      @click="removeBrand(brand)">
                      <span class="icon-close-outline"></span>
                    </button>
                  </span>
                </div>
              </div>
              <h2
                id="product-heading"
                class="sr-only">
                {{ $t('views.catalog.products') }}
              </h2>
              <div class="grid xs:grid-cols-2 gap-3 lg:grid-cols-3 xs:gap-4 xl:grid-cols-4">
                <product-card
                  v-for="(product, index) in products"
                  :key="index"
                  :product="product"
                  :vat-method="vatMethod"
                ></product-card>
              </div>

              <div
                v-if="pagination && pagination.total_pages > 1"
                class="mt-12">
                <div class="flex justify-center items-center space-x-3">
                  <span
                    v-if="pagination.links.previous"
                    class="icon-arrow-ios-back-outline text-h7 text-blue-50 cursor-pointer"
                    @click="changePage(pagination.links.previous)"></span>
                  <div class="w-1/2 md:hidden">
                    <select
                      id="page"
                      class="mt-1 block w-full pl-3 pr-10 bg-white py-2 text-base border-gray-20 border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-box-sm"
                      @change="changePage">
                      <option
                        v-for="page in pagination.total_pages"
                        :key="page"
                        :value="page"
                        :selected="currentPage === page">
                        {{ $t('views.catalog.page_number') }} {{ page }}
                      </option>
                    </select>
                  </div>
                  <div class="hidden md:flex space-x-1">
                    <div
                      v-for="page in pagination.total_pages"
                      :key="page"
                      class="flex items-center justify-center h-12 w-12 rounded-box-sm cursor-pointer"
                      :class="currentPage === page ? 'bg-white shadow-box font-bold text-blue-50' : 'hover:bg-white hover:shadow-box transform duration-200 ease-in-out'"
                      @click="changePage(page)">
                      {{ page }}
                    </div>
                  </div>
                  <span
                    v-if="pagination.links.next"
                    class="icon-arrow-ios-forward-outline text-h7 text-blue-50 cursor-pointer"
                    @click="changePage(pagination.links.next)"></span>
                </div>
              </div>
              <div>
                <p
                  v-if="vatMethod === 'net'"
                  class="text-gray-50 text-sm mt-16"
                  v-html="$t('views.catalog.product_price_caption')"
                />
                <p
                  v-else
                  class="text-gray-50 text-sm mt-16"
                  v-html="$t('views.catalog.product_price_caption_gross')"
                />
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
  import ProductCard from 'sb/common/components/catalog/ProductCard'
  import Sidebar from 'sb/common/components/catalog/Sidebar'
  import Breadcrumb from 'sb/common/components/catalog/Breadcrumb'
  import MobileNav from 'sb/common/components/catalog/MobileNav'

  export default {

    props: {
      catalogProducts: {
        type: Object,
      },
      productCategories: {
        type: Array,
      },
      productColors: {
        type: Array,
      },
      brands: {
        type: Array,
      },
      genders: {
        type: Array,
        default() {
          return []
        }
      },
      methods: {
        type: Array,
        default() {
          return []
        }
      },
      selectedColors: {
        type: Array,
      },
      selectedGenders: {
        type: Array,
      },
      selectedMethods: {
        type: Array,
      },
      currentPage: {
        type: Number,
      },
      orderBy: {
        type: String,
      },
      selectedBrands: {
        type: Array,
      },
      selectedCategory: {
        type: Object
      },
      currentCategory: {
        type: String
      },
      vatMethod: {
        type: String,
        default: 'gross'
      }
    },

    methods: {
      changePage(page) {
        this.$emit('change:page', page)
      },
      changeCategory(category) {
        this.$emit('change:category', category)
      },
      changeColor(color) {
        this.$emit('change:color', color)
      },
      changeMethod(method) {
        this.$emit('change:method', method)
      },
      changeGender(gender) {
        this.$emit('change:gender', gender)
      },
      changeBrand(brand) {
        this.$emit('change:brand', brand)
      },
      removeColor(color) {
        this.$emit('remove:color', color)
      },
      removeMethod(method) {
        this.$emit('remove:method', method)
      },
      removeGender(gender) {
        this.$emit('remove:gender', gender)
      },
      removeBrand(gender) {
        this.$emit('remove:brand', gender)
      },
      changeOrder(order) {
        this.$emit('change:order', order)
      }
    },

    computed: {
      activeProductCategories() {
        return this.productCategories.filter((category) => category.is_active)
      },
      products() {
        return this.catalogProducts.data
      },
      pagination() {
        if (this.catalogProducts && this.catalogProducts.meta) {
          return this.catalogProducts.meta.pagination
        }

        return null
      },
    },

    components: {
      ProductCard,
      Sidebar,
      MobileNav,
      Breadcrumb
    }
  }
</script>

<style scoped>

</style>

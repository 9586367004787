import Vue from 'vue'
import Router from 'vue-router'
import ShirtigoRoutes from './shirtigo.js'

Vue.use(Router)

export function createRouter({ app }) {
  return new Router({
    mode: 'history',
    routes: ShirtigoRoutes,
    scrollBehavior() {
      return { x: 0, y: 0 }
    }
  })
}

<template>
  <form-group :has-danger="hasDanger">
    <label :class="['form-group__label form-group__checkbox-label', { disabled }]">
      <sg-radio
        :id="id"
        :name="name"
        :class="className"
        :value="value"
        :disabled="disabled"
        :required="required"
        :tabindex="tabindex"
        :autofocus="autofocus"
        :autocomplete="autocomplete"
        :checked="checked"
        @change="$emit('change', $event)" />
      <span>
        <slot></slot>
      </span>
    </label>
  </form-group>
</template>

<script>
  import FormGroup from './FormGroup.vue'
  import SgRadio from './SgRadio.vue'

  export default {

    props: {
      id: String,
      value: String,
      name: String,
      className: String,
      disabled: Boolean,
      required: Boolean,
      tabindex: Number,
      autofocus: Boolean,
      autocomplete: String,
      hasDanger: Boolean,
      checked: {
        type: Boolean,
        default: false,
        required: true,
      },
    },

    components: {
      FormGroup,
      SgRadio,
    },

  }
</script>

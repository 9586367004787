<template>
  <component
    :is="as"
    :class="[`sg-btn btn-outline icon-btn`, { 'btn-lg': size === 'lg', 'btn-sm': size === 'sm' }]"
    :type="isLink ? '' : type"
    :title="loading ? loadingLabel : title"
    :disabled="disabled || loading"
    @click="$emit('click')">
    <template v-if="loading">
      <icon
        fa="spinner"
        class="fa-spin fa-fw" />
    </template>
    <template v-else>
      <span class="sr-only">
        <slot></slot>
      </span>
      <icon :fa="fa" />
    </template>
  </component>
</template>

<script>
  import SgBtn from './SgBtn.vue'

  export default {

    extends: SgBtn,

    props: {
      fa: {
        type: String,
        required: true,
      },
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .icon-btn {
    width: 3rem;
    height: 3rem;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .icon {
      margin: 0 !important;
    }
  }
</style>

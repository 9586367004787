import client from './client'

export const getBaseProduct = (slug) => {
  return client.get(`/api/catalog/${slug}`)
}

export const getAllBaseProducts = () => {
  return client.get(`/api/base-products`)
}
export const getPaginatedBaseProducts = ({ category, color, currentPage, orderBy, brands }) => {
  return client.get(`/api/catalog`, {
    category,
    color,
    currentPage,
    orderBy,
    brands
  })
}

export const getBaseProductColors = (category) => {
  return client.get(`/api/catalog/colors`, {
    category
  })
}

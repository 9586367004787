<template>
  <g
    :ref="`boundingBox_preview_${processingItem._internal_id || processingItem.uuid}`"
    class="design-bounding-box"
  >
    <processing-item-type-text-path
      v-if="processingItem.type === 'text'"
      ref="type_text"
      :processing-item="processingItem"
      :style="designTransformations"
      :width="printWidth"
      :height="printHeight"
      :designer-fonts="designerFonts"
      :y="posY"
      :x="posX"
      @element-created="(createdProcessingItem) => $emit('element-created', createdProcessingItem)"
      @element-updated="(updatedProcessingItem) => $emit('element-updated', updatedProcessingItem)"
    />

    <processing-item-type-media
      v-else-if="processingItem.type === 'media'"
      ref="type_media"
      :processing-item="processingItem"
      :style="designTransformations"
      :width="printWidth"
      :height="printHeight"
      :y="posY"
      :x="posX"
      @element-created="(createdProcessingItem) => $emit('element-created', createdProcessingItem)"
      @element-updated="(updatedProcessingItem) => $emit('element-updated', updatedProcessingItem)"
    />

    <processing-item-type-notes
      v-else-if="processingItem.type === 'notes'"
      ref="type_notes"
      :processing-item="processingItem"
      @element-created="(createdProcessingItem) => $emit('element-created', createdProcessingItem)"
      @element-updated="(updatedProcessingItem) => $emit('element-updated', updatedProcessingItem)"
    />

    <processing-item-type-asset
      v-else-if="processingItem.type === 'asset'"
      ref="type_asset"
      :processing-item="processingItem"
      :style="designTransformations"
      :width="printWidth"
      :height="printHeight"
      :y="posY"
      :x="posX"
      @element-created="(createdProcessingItem) => $emit('element-created', createdProcessingItem)"
      @element-updated="(updatedProcessingItem) => $emit('element-updated', updatedProcessingItem)"
    />

    <processing-item-type-design
      v-else
      ref="type_design"
      :processing-item="processingItem"
      :style="designTransformations"
      :width="printWidth"
      :height="printHeight"
      :y="posY"
      :x="posX"
      @element-created="(createdProcessingItem) => $emit('element-created', createdProcessingItem)"
      @element-updated="(updatedProcessingItem) => $emit('element-updated', updatedProcessingItem)"
    />
  </g>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { PropOptions } from 'vue'
  import ProcessingItemTypeDesign from './ProcessingItemTypes/ProcessingItemTypeDesign'
  import ProcessingItemTypeTextPath from './ProcessingItemTypes/ProcessingItemTypeTextPath'
  import ProcessingItemTypeMedia from './ProcessingItemTypes/ProcessingItemTypeMedia'
  import ProcessingItemTypeNotes from './ProcessingItemTypes/ProcessingItemTypeNotes'
  import ProcessingItemTypeAsset from './ProcessingItemTypes/ProcessingItemTypeAsset'
  import objectContainsChanges from '../../common/utils/objectContainsChanges'

  const INITIAL_PRINT_WIDTH = 220

  export default {

    props: {
      /** @type {PropOptions<ApiFormattedProcessingItem>} */
      processingItem: {
        type: Object,
        required: true
      },
      mockupScale: {
        type: Number,
        required: true
      },
      /** @type {PropOptions<BaseProductPrintarea>} */
      printarea: {
        type: Object,
        required: true
      },
      designerFonts: {
        type: Array,
        required: true
      },
      printareaWidth: {
        type: Number,
        required: true
      },
      mockup: {
        type: Object,
        required: true
      }
    },

    methods: {
      onResize(value) {
        if (!this.$refs[`type_${this.processingItem.type}`]) return
        this.$refs[`type_${this.processingItem.type}`].onResize(value)
      },
      getRotationMatrix(rad) {
        let cx = this.posX + this.printWidth / 2
        let cy = this.posY + (typeof this.printHeight === 'number' ? this.printHeight : this.printWidth) / 2
        let ca = Math.cos(rad * Math.PI / 180)
        let sa = Math.sin(rad * Math.PI / 180)

        let a = Math.round(ca * 1000) / 1000
        let b = Math.round(sa * 1000) / 1000
        let c = Math.round(-sa * 1000) / 1000
        let d = a
        let e = Math.round((-ca * cx + sa * cy + cx) * 1000) / 1000
        let f = Math.round((-sa * cx - ca * cy + cy) * 1000) / 1000

        return `${a}, ${b}, ${c}, ${d}, ${e}, ${f}`
      },
      /**
       * @param {Partial<ApiFormattedProcessingItem>} updatedValues
       */
      updateProcessingItem(updatedValues) {
        if (objectContainsChanges(updatedValues, this.processingItem)) {
          this.$emit('element-updated', {
            ...this.processingItem,
            ...updatedValues,
          })
        }
      },
    },

    computed: {
      pixelsPerMillimeter() {
        return (this.printarea.height_px * this.mockupScale) / this.printarea.height
      },
      designTransformations() {
        return {
          'transform': `matrix(${this.getRotationMatrix(this.processingItem.rotation || 0)})`
        }
      },
      posX() {
        const x = this.printarea.width / 2 + this.processingItem.position_x_mm

        // anchor is left, therefore, remove half of width
        return (x - this.processingItem.width_mm / 2) * this.mockupScale
      },
      posY() {
        return this.processingItem.position_y_mm * this.mockupScale
      },
      printWidth() {
        return (this.processingItem.width_mm || INITIAL_PRINT_WIDTH) * this.mockupScale
      },
      printHeight() {
        if (!this.printWidth || !this.designAspectRatio) {
          return 0
        }

        return this.printWidth * this.designAspectRatio
      },
      designAspectRatio() {
        if (this.processingItem.aspect_ratio) {
          return this.processingItem.aspect_ratio
        }

        if (!this.processingItem.asset) {
          return 1
        }

        return this.processingItem.asset.height / this.processingItem.asset.width
      },
      maxPrintWidthMM() {
        return Math.round(Math.min(this.printarea.width, this.printarea.height / this.designAspectRatio))
      },
    },

    watch: {
      'processingItem.width_mm'(width) {
        if (width > this.maxPrintWidthMM) {
          this.updateProcessingItem({ width_mm: this.maxPrintWidthMM })
        }
      },
      processingItem: {
        deep: true,
        handler(updatedElement) {
          this.updateProcessingItem(updatedElement)
        }
      }
    },

    components: {
      ProcessingItemTypeAsset,
      ProcessingItemTypeNotes,
      ProcessingItemTypeMedia,
      ProcessingItemTypeTextPath,
      ProcessingItemTypeDesign
    }

  }
</script>

<style lang="scss" scoped>
  .z-2 {
    z-index: 3;
  }
</style>

import accounting from 'accounting'

export default (function (value, vatMethod = 'gross', isGross = false) {
  value = value.data
  // Parse money object, e.g. {amount: 225, currency_symbol: "€", currency_code: "EUR", currency_factor: 100, vat_rate: 19}
  if (typeof value === 'object' && value !== null) {
    const amount = value.amount / value.currency_factor;

    // Check if user is business customer
    const vat = (value.vat_rate.data.amount && vatMethod !== 'net' && !isGross) ? 1 + (value.vat_rate.data.amount / 100) : 1;

    const decimal = accounting.toFixed(amount * vat, 2);
    value = accounting.formatMoney(decimal, {symbol: value.currency_symbol, format: "%v %s", decimal: ",", thousand: "."});
  }

  return value;
});

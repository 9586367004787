<template>
  <div class="bg-white border p-6 lg:p-8 mt-6 rounded">
    <div class="border-b border-grey-10 lg:flex">
      <coupon-code-form ref="couponCodeForm" />

      <full-price-display
        :allow-remove-coupon="true"
        @remove-coupon="removeCoupon"
        @update-cart="$emit('update-cart')"
      />
    </div>

    <div class="flex pt-6 items-center justify-center">
      <div class="flex-1 hidden md:flex">
        <img
          src="/img/sofort.svg"
          class="rounded border border-gray-10 align-middle p-2 mr-2"
          style="height: 40px; width: 80px;"
          alt="Sofortüberweisung"
        />
        <img
          src="/img/visa.svg"
          class="rounded border border-gray-10 align-middle p-2 mr-2"
          style="height: 40px; width: 80px;"
          alt="Visa"
        />
        <img
          src="/img/mastercard.svg"
          class="rounded border border-gray-10 align-middle p-2 mr-2"
          style="height: 40px; width: 80px;"
          alt="Mastercard"
        />
        <img
          src="/img/paypal.svg"
          class="rounded border border-gray-10 align-middle p-2"
          style="height: 40px; width: 80px;"
          alt="Paypal"
        />
      </div>

      <div
        v-if="allowPayWithPaypal"
        class="px-2"
      >
        <button
          class="text-center px-4 md:px-6 py-2 bg-yellow-50 text-yellow-90 rounded-full font-semibold leading-7 tracking-wide shadow-big min-h-11 xs:text-lg transition-all duration-200 hover:bg-yellow-40 font-nunito-sans"
        >
          {{ $t('views.cart.paypal_pay_with') }}

          <img
            src="/img/paypal.svg"
            class="inline-block w-auto h-6 px-2"
            style="margin-top: -0.35em"
            alt="PayPal"
            title="PayPal"
          />
        </button>
      </div>

      <div class="pl-2">
        <button
          class="text-center flex items-center px-4 md:px-6 py-2 bg-dark-blue text-white rounded-full font-semibold leading-7 tracking-wide shadow-big min-h-11 xs:text-lg transition-all duration-200 hover:bg-blue-60 font-nunito-sans disabled:opacity-50"
          :disabled="loading ? 'disabled' : false"
          @click="$emit('submit')"
        >
          <span v-if="loading">
            {{ $t('global.loading') }}
          </span>
          <span v-else>
            {{ $t('views.cart.submit_button') }}
          </span>

          <loader
            v-if="loading"
            color="white"
            size="medium"
            class="ml-2 inline-flex"
          />

          <i class="fas fa-arrow-right text-sm ml-2 opacity-90" v-else />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import FullPriceDisplay from './FullPriceDisplay.vue'
  import Loader from '@/components/layout/Loader.vue'
  import CouponCodeForm from '@/components/config/checkout/CouponCodeForm.vue'

  export default {

    props: {
      loading: Boolean,
    },

    data: () => ({
      allowPayWithPaypal: false,
    }),

    methods: {
      removeCoupon() {
        if (this.$refs.couponCodeForm) {
          this.$refs.couponCodeForm.removeCoupon()
        }
      }
    },

    components: { CouponCodeForm, Loader, FullPriceDisplay },

  }
</script>

import Vue from 'vue'
import { splitBaseProductVariantId } from '@/utils/helpers'
import { format } from 'date-fns/format'
import { getDummyManufacturingJob, getEmptyDummyCart } from '@/api/calculation'

/**
 * @param {State} state
 * @param {ApiFormattedManufacturingJobUuid} manufacturingJobUuid
 * @return {ApiFormattedManufacturingJob|null}
 */
const getManufacturingJobForUuid = (state, manufacturingJobUuid) => {
  if (!state.cart || !state.cart.manufacturing_jobs.data) {
    return null
  }

  if (manufacturingJobUuid !== null) {
    return state.cart.manufacturing_jobs.data.find((job) => job.uuid === manufacturingJobUuid) || null
  }

  return state.cart.manufacturing_jobs.data[0]
}

/**
 * @param {ApiFormattedManufacturingJob} manufacturingJob
 * @param {BaseProductId} baseProductId
 * @return {ApiFormattedProduct|null}
 */
const findProductInManufacturingJob = (manufacturingJob, baseProductId) => {
  return manufacturingJob.products.data.find(({ base_product_id }) => base_product_id === baseProductId) || null
}

/**
 * @param {State} state
 * @param {ApiFormattedManufacturingJobUuid|null} manufacturingJobUuid
 * @param {BaseProductId} baseProductId
 */
const findProductInCart = (state, manufacturingJobUuid, baseProductId) => {
  // The job's UUID is generated on the server. It's not yet present when first initializing the cart on the clientside
  if (manufacturingJobUuid) {
    return findProductInManufacturingJob(getManufacturingJobForUuid(state, manufacturingJobUuid), baseProductId)
  }
  let product = null
  state.cart.manufacturing_jobs.data.forEach((job) => {
    const foundProduct = findProductInManufacturingJob(job, baseProductId)
    if (foundProduct) {
      product = foundProduct
      return null
    }
  })

  return product
}

const mutations = {
  /**
   * @param {State} state
   * @param {string} baseProductReference
   * @constructor
   */
  SET_CURRENT_BASE_PRODUCT_REFERENCE(state, baseProductReference) {
    Vue.set(state, 'current_base_product_reference', baseProductReference)
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJobUuid} manufacturingJobUuid
   * @constructor
   */
  SET_CURRENT_MANUFACTURING_JOB(state, manufacturingJobUuid) {
    Vue.set(state, 'current_manufacturing_job_uuid', manufacturingJobUuid)
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJobGroupId} manufacturingJobGroupId
   * @constructor
   */
  SET_CURRENT_MANUFACTURING_JOB_GROUP_ID(state, manufacturingJobGroupId) {
    Vue.set(state, 'current_manufacturing_job_group_id', manufacturingJobGroupId)
  },
  SET_CURRENT_COLOR_ID(state, id) {
    Vue.set(state, 'current_color_id', id)
  },
  SET_CURRENT_IMAGE(state, id) {
    Vue.set(state, 'current_image', id)
  },
  SET_IMAGE_SIDE_QUERY(state, side) {
    Vue.set(state, 'image_side_query', side)
  },
  SET_BASE_PRODUCTS(state, baseProducts) {
    Vue.set(state, 'base_products', baseProducts)
  },
  /**
   * @param {State} state
   * @param {DesignerFontFamily[]} fonts
   * @constructor
   */
  SET_DESIGNER_FONTS(state, fonts) {
    Vue.set(state, 'designer_fonts', fonts)
  },

  SET_PRODUCT_COLORS(state, productColors) {
    Vue.set(state, 'product_colors', productColors)
  },
  SET_CART(state, cart) {
    // This is a workaround
    // When we send a manufacturing job without any products, the backend removes the manufacturing job entirely.
    // This would break the frontend, thus we have to re-insert the empty manufacturing job here
    if (!cart.manufacturing_jobs.data.length) {
      cart.manufacturing_jobs.data = [getDummyManufacturingJob({
        amount: 0,
      })]
    }

    Vue.set(state, 'cart', cart)
  },
  SET_CART_PRICES(state, cart) {
    Vue.set(state.cart, 'reference', cart.reference)
    Vue.set(state.cart, 'price_net', cart.price_net)
    Vue.set(state.cart, 'shipping_price_net', cart.shipping_price_net)
    Vue.set(state.cart, 'manufacturing_price_net', cart.manufacturing_price_net)
    Vue.set(state.cart, 'price_discount', cart.price_discount)
    Vue.set(state.cart, 'has_free_shipping', cart.has_free_shipping)
    Vue.set(state.cart, 'price_gross', cart.price_gross)
    Vue.set(state.cart, 'vat_calculation', cart.vat_calculation)
    Vue.set(state.cart, 'vat', cart.vat)
    Vue.set(state.cart, 'vat_rate', cart.vat_rate)
  },
  DELETE_CART(state) {
    Vue.set(state, 'cart', null)
    Vue.set(state, 'is_checked_out', false)
    Vue.set(state, 'is_mpc', false)
    Vue.set(state, 'current_manufacturing_job_uuid', null)
    Vue.set(state, 'current_manufacturing_job_group_id', null)
    Vue.set(state, 'active_cart_printareas', {})
  },
  /**
   * @param {State} state
   * @param {boolean} isMpc
   * @constructor
   */
  SET_IS_MPC(state, isMpc) {
    Vue.set(state, 'is_mpc', isMpc)
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJobUuid} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   * @param {BaseProductVariantId} baseProductVariantId
   * @param {number} amount
   * @param {boolean} append
   * @constructor
   */
  ADD_PRODUCT_TO_JOB(state, {
    manufacturingJobUuid,
    baseProductId,
    baseProductVariantId,
    amount,
    append,
  }) {
    let job = getManufacturingJobForUuid(state, manufacturingJobUuid)
    const { size_id, color_id } = splitBaseProductVariantId(baseProductVariantId)

    /** @type ApiFormattedProductBase */
    const product = {
      base_product_id: baseProductId,
      created_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      /** @type ApiFormattedVariantBase[] */
      variants: [ {
        amount,
        baseproduct_variant_id: baseProductVariantId,
        size_id,
        color_id
      } ]
    }

    if (!job.products) {
      job.products = {data: []}
    }

    // Don't allow the same product twice
    if (job.products.data.some(({ base_product_id }) => base_product_id === baseProductId)) {
      let existingProduct = job.products.data.find(p => p.base_product_id === baseProductId)

      if (existingProduct.variants.find(c => c.color_id === color_id)) {
        return
      } else {
        let variant = {
          baseproduct_variant_id: baseProductVariantId,
          amount: 0,
          size_id,
          color_id
        }
        this.commit('UPDATE_CART_PRODUCT_VARIANT_FOR_JOB', {
          manufacturingJobUuid,
          baseProductId,
          variant
        })

        return
      }
    }

    if (append) {
      job.products.data.push(product)
    } else {
      job.products.data.unshift(product)
    }
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJobUuid} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   * @constructor
   */
  REMOVE_PRODUCT_FROM_JOB(state, { manufacturingJobUuid, baseProductId }) {
    let job = getManufacturingJobForUuid(state, manufacturingJobUuid)
    job.products.data = job.products.data.filter((product) => product.base_product_id !== baseProductId)
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJobUuid} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   * @param {string} baseProductVariantId
   * @constructor
   */
  REMOVE_CART_PRODUCT_VARIANT_FROM_JOB(state, { manufacturingJobUuid, baseProductId, baseProductVariantId }) {
    let product = findProductInCart(state, manufacturingJobUuid, baseProductId)
    if (!product) return

    const newVariants = product.variants.filter(({ baseproduct_variant_id }) => baseproduct_variant_id !== baseProductVariantId)

    Vue.set(product, 'variants', newVariants)
    Vue.set(product, 'amount', newVariants.reduce((sum, { amount }) => sum + amount, 0))
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJobUuid|null} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   * @param {ApiFormattedVariantBase} variant
   * @constructor
   */
  UPDATE_CART_PRODUCT_VARIANT_FOR_JOB(state, { manufacturingJobUuid, baseProductId, variant }) {
    let product = findProductInCart(state, manufacturingJobUuid, baseProductId)
    if (!product) return

    const foundVariantIndex = product.variants.findIndex(
      ({ baseproduct_variant_id }) => baseproduct_variant_id === variant.baseproduct_variant_id
    )
    if (foundVariantIndex > -1) {
      product.variants.splice(foundVariantIndex, 1, variant)
    } else {
      product.variants.push(variant)
    }

    Vue.set(product, 'amount', product.variants.reduce((sum, { amount }) => sum + amount, 0))
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJobUuid|null} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   * @param {ApiFormattedVariantBase[]} variants
   * @constructor
   */
  SET_PRODUCT_VARIANTS_FOR_JOB(state, { manufacturingJobUuid, baseProductId, variants }) {
    let product = findProductInCart(state, manufacturingJobUuid, baseProductId)
    if (!product) return

    product.variants = variants

    Vue.set(product, 'amount', variants.reduce((sum, { amount }) => sum + amount, 0))
  },
  SET_CART_CONFIG(state, config) {
    Vue.set(state.cart, 'config', config)
  },
  SET_CART_COUPON_CODE(state, couponCode) {
    Vue.set(state.cart, 'coupon_code', couponCode)
  },
  SET_CART_COUNTRY(state, country) {
    Vue.set(state.cart, 'delivery_iso', country)
  },
  UPDATE_CART_COUNTRY(state, country) {
    state.cart.delivery_iso = country
  },
  /**
   * @param {State} state
   * @constructor
   */
  REMOVE_ALL_MANUFACTURING_JOBS(state) {
    state.cart.manufacturing_jobs.data = []
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJobGroupId} groupId
   * @constructor
   */
  REMOVE_ALL_MANUFACTURING_JOBS_FOR_GROUP(state, groupId) {
    state.cart.manufacturing_jobs.data = state.cart.manufacturing_jobs.data
      .filter((job) => job.group_id.toString() !== groupId && job.uuid.toString() !== groupId)
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJob|null} updatedManufacturingJob
   * @constructor
   */
  UPDATE_MANUFACTURING_JOB(state, updatedManufacturingJob) {
    if (!updatedManufacturingJob || typeof updatedManufacturingJob.uuid === 'undefined') {
      return
    }

    state.cart.manufacturing_jobs.data = state.cart.manufacturing_jobs.data.map((job) => {
      if (job.uuid.toString() === updatedManufacturingJob.uuid.toString()) {
        return { ...job, ...updatedManufacturingJob }
      }

      return job
    })
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJob|null} newManufacturingJob
   * @constructor
   */
  ADD_NEW_MANUFACTURING_JOB(state, newManufacturingJob) {
    if (!newManufacturingJob) {
      return
    }

    if (!state.cart.manufacturing_jobs.data) {
      state.cart.manufacturing_jobs.data = []
    }

    // New manufacturing jobs won't have a UUID until saved to the backend.
    state.cart.manufacturing_jobs.data.push(newManufacturingJob)
  },
  /**
   * @param {State} state
   * @param {ApiFormattedManufacturingJobUuid|null} manufacturingJobUuid
   * @constructor
   */
  REMOVE_MANUFACTURING_JOB(state, manufacturingJobUuid) {
    state.cart.manufacturing_jobs.data = state.cart.manufacturing_jobs.data
      .filter((job) => job.uuid.toString() !== manufacturingJobUuid)
  },
  /**
   * @param {State} state
   * @param {string[]} activePrintareas
   * @constructor
   */
  SET_ACTIVE_CART_PRINTAREAS(state, activePrintareas) {
    Vue.set(state, 'active_cart_printareas', activePrintareas)
  },
  ADD_ALERT(state, alert) {
    state.alerts.unshift(alert)
  },
  DELETE_ALERT(state, alert) {
    let index = state.alerts.indexOf(alert)
    state.alerts.splice(index, 1)
  },
  SET_ORDER_DETAILS(state, orderDetails) {
    Vue.set(state, 'order_details', orderDetails)
    Vue.set(state, 'is_checked_out', false)
  },
  DELETE_ORDER_DETAILS(state) {
    Vue.set(state, 'order_details', null)
    Vue.set(state, 'is_checked_out', false)
  },
  MARK_AS_CHECKED_OUT(state) {
    Vue.set(state, 'is_checked_out', true)
  },
  SET_IS_LOADING_CART(state, isLoadingCart) {
    Vue.set(state, 'is_loading_cart', isLoadingCart)
  },
  SET_IS_LOADING_PRICES(state, isLoadingPrices) {
    Vue.set(state, 'is_loading_prices', isLoadingPrices)
  },
  SET_IS_SAVING_BASE_DESIGN(state, isSavingBaseDesign) {
    Vue.set(state, 'is_saving_base_design', isSavingBaseDesign)
  },
}

export default mutations

<template>
  <div
    v-if="error.show"
    class="w-full absolute top-0 md:mt-5 z-50"
  >
    <div
      class="text-red-50 bg-red-00 z-50 table border-red-30 border rounded-lg md:w-480px max-w-full mx-4 md:mx-auto px-6 py-4"
    >
      <div class="pr-4 table-cell align-middle pt-1">
        <span class="icon-info-outline text-2xl" />
      </div>

      <div class="table-cell align-middle text-lg">
        {{ error.message }}
      </div>

      <div class="table-cell align-middle pt-1">
        <button
          class="icon-close-outline cursor-pointer text-2xl"
          @click="$emit('hide')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export const EMPTY_ERROR_OBJECT = { show: false, message: '', title: '' }

  export default {
    props: {
      error: {
        type: Object,
        default: EMPTY_ERROR_OBJECT,
      },
    },

    data: () => ({
      EMPTY_ERROR_OBJECT: EMPTY_ERROR_OBJECT,
    }),
  }
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"design-preview flex justify-center"},[_c('div',{staticClass:"large-preview"},[(!_vm.selectedItem || !_vm.selectedItem.preview)?_c('div',{staticClass:"z-10 inset-0 text-center text-blue-50 text-3xl absolute spinning flex justify-center items-center"},[_c('i',{staticClass:"icon-loader-outline"})]):_vm._e(),_vm._v(" "),(_vm.selectedItem)?_c('div',{class:{'grayscale opacity-50': !_vm.selectedItem.preview},domProps:{"innerHTML":_vm._s(_vm.selectedItem.preview.svg)}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"absolute z-10 p-4 gap-2 top-0 left-0 w-20 flex flex-col"},_vm._l((_vm.previewList),function(item){return _c('div',{key:item.printarea.key,staticClass:"preview-window relative cursor-pointer",on:{"click":() => {
        if (item !== null) _vm.select(item)
      }}},[(!item || !item.preview)?_c('div',{staticClass:"z-10 inset-0 text-center text-blue-50 text-2xl absolute spinning flex justify-center items-center h-full"},[_c('i',{staticClass:"icon-loader-outline"})]):_vm._e(),_vm._v(" "),_c('div',{class:{
          'thumbnail-preview': true,
          'grayscale opacity-50': !item || !item.preview,
          'opacity-50': _vm.selectedItem && item.printarea.key !== _vm.selectedItem.printarea.key,
          'border-blue-50': _vm.selectedItem && item.printarea.key === _vm.selectedItem.printarea.key
        },domProps:{"innerHTML":_vm._s(item.preview.svg)}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="rounded-md px-3 md:py-4 border-2 border-dashed border-gray-30 pb-4">
    <div
      v-if="isLoading"
      class="load8"
    >
      <div v-if="uploadPercent > 99" class="loader"></div>
      <div v-if="uploadPercent < 99" class="progress-bar" :style="'background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#039be5 ' + uploadPercent + '%, #ced7db 0);'">
        <progress max="100" :value="uploadPercent" style="display: none"></progress>
        <div>
          {{ uploadPercent }}%
        </div>
      </div>
      <div class="loader-text">
        <span v-if="uploadPercent < 99">
          {{ $t('views.designer.upload.uploading') }} / {{ currentSpeed }}
        </span>
        <span v-else>{{ $t('views.designer.upload.loader_text_wait') }}</span>
      </div>
    </div>

    <form
      v-else
      class="cursor-pointer "
      method="post"
      enctype="multipart/form-data"
      @drop.prevent="readFilesFromDragEvent"
      @dragleave.prevent="isDragover = false"
      @dragend.prevent="isDragover = false"
      @dragover.prevent="isDragover = true"
      @dragenter.prevent="isDragover = true"
    >
      <input
        :id="`file${area}`"
        class="file-form-input"
        type="file"
        name="files[]"
        :accept="accept"
        multiple
        @change="readFilesFromInput"
      >

      <label
        :for="`file${area}`"
        class="flex flex-col align-middle justify-center items-center text-center"
      >
        <span class="block">
          <img
            src="/images/upload-file.svg"
            class="w-16 mb-5"
            aria-hidden="true"
            alt=""
          />
        </span>

        <span
          v-if="hint"
          class="block text-lg"
        >
          {{ $t('views.designer.upload.upload_asset_hint') }}
        </span>

        <span
          class="flex cursor-pointer items-center px-4 md:px-6 py-2 bg-dark-blue text-white rounded-full font-semibold leading-7 tracking-wide shadow-big h-11 xs:text-lg transition-all duration-200 hover:bg-blue-60 font-nunito-sans mb-5 mt-5"
        >
          {{ $t('views.designer.upload.upload_asset') }}
        </span>
      </label>

      <span class="block text-center text-gray-50 text-sm mt-2">
        JPG & PNG, max. 32MB.<br />
        <span class="hidden md:inline">{{ $t('views.designer.upload.upload_asset_text_part_1') }}</span>
        <a
          class="text-blue-50"
          target="_blank"
          href="https://helpcenter.shirtigo.de/faq/design-print/druckmotiv/welches-dateiformat-sollte-ich-fuer-mein-design-verwenden/"
        >
          {{ $t('views.designer.upload.upload_asset_text_part_2') }}
        </a>
        .
      </span>
    </form>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { fileIsValidImage } from '@/utils/helpers'
  import { uploadAssetFile } from '@/api/design'

  export default {

    props: {
      hint: {
        type: Boolean,
        default: () => true
      },
      accept: String,
      area: String
    },

    data() {
      return {
        droppedFiles: [],
        fileNames: [],
        isDragover: false,
        isLoading: false,
        mediaData: [],
        mediaDataBack: [],
        imageFrontData: {},
        imageFront: false,
        imageBackData: {},
        imageBack: false,
        uploadPercent: 0,
        currentUploadSpeed: 0
      }
    },

    methods: {
      /**
       * @param {Event<HTMLInputElement>} event
       */
      readFilesFromInput(event) {
        const target = /** @type HTMLInputElement */event.target
        this.droppedFiles = this.getValidFiles(target.files)
        this.isDragover = false
        this.submitForm()
      },
      /**
       * @param {DragEvent} event
       */
      readFilesFromDragEvent(event) {
        this.droppedFiles = this.getValidFiles(event.dataTransfer.files)
        this.isDragover = false
        this.submitForm()
      },
      getValidFiles(fileList) {
        let validFiles = []

        Array.from(fileList).forEach((file) => {
          if (fileIsValidImage(file) === 'valid') {
            validFiles.push(file)
          } else if (fileIsValidImage(file) === 'too_large') {
            this.$emit(
              'upload:error',
              this.$t('views.designer.upload.error.file_too_large'),
              this.$t('views.designer.upload.error.headline')
            )
            this.isLoading = false
            this.$emit('close:modal')
          } else {
            this.$emit(
              'upload:error',
              this.$t('views.designer.upload.error.file_type_invalid'),
              this.$t('views.designer.upload.error.headline')
            )
            this.isLoading = false
            this.$emit('close:modal')
          }
        })

        return validFiles
      },
      submitForm() {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'upload started',
        })

        if (this.droppedFiles.length) {
          this.uploadPercent = 0
          this.currentUploadSpeed = 0
          this.isLoading = true

          let startTime = null
          Promise.all(this.droppedFiles.map((file) => {
            uploadAssetFile(file, {
              onUploadProgress: (upload) => {
                if (!startTime) {
                  startTime = new Date()
                }
                let now = new Date()
                let duration = (now - startTime) / 1000
                this.currentUploadSpeed = upload.loaded / duration
                this.uploadPercent = Math.round(upload.loaded / upload.total * 100)
              }
            })
              .then((response) => {
                this.$emit('asset-uploaded', response.data)
              })
              .then(() => {
                this.$emit('close:modal')
                this.isLoading = false
                this.droppedFiles = []
                window.dataLayer.push({
                  event: 'upload finished',
                })
              })
              .catch((error) => {
                let message = this.$t('views.designer.upload.error.unknown')
                if (error.response && error.response.data.error) {
                  message = error.response.data.error
                }
                window.dataLayer.push({
                  event: 'upload failed',
                })
                this.$emit('upload:error', message, this.$t('views.designer.upload.error.headline'))
                this.isLoading = false
                this.$emit('close:modal')
              })
          }))
        }
      },
      ...mapActions([
        'addAlert',
      ]),
    },

    computed: {
      currentSpeed() {
        if (!this.currentUploadSpeed || this.currentUploadSpeed === Infinity) {
          return null
        }

        if (this.currentUploadSpeed / 1000000 < 1) {
          return (Math.round((this.currentUploadSpeed / 1000) * 100) / 100).toFixed(2) + ' KB/s'
        }

        return (Math.round((this.currentUploadSpeed / 1000000) * 100) / 100).toFixed(2) + ' MB/s'

      },
    },

    watch: {
      isLoading() {
        this.$emit('status-change', this.isLoading)
      }
    }

  }
</script>

<style lang="scss">
  $foreground: #ccc;
  .progress-bar {
    width: 6em;
    height: 6em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: 4em auto 4em;
  }
  .load8 {
    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      min-height: 10em;
    }

    .loader {
      margin: 4em auto 4.75em;
      font-size: .5em;
      position: relative;
      text-indent: -9999em;
      border-top: 1.1em solid rgba($foreground, 0.2);
      border-right: 1.1em solid rgba($foreground, 0.2);
      border-bottom: 1.1em solid rgba($foreground, 0.2);
      border-left: 1.1em solid rgba($foreground, 1);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }

    .loader-text {
      text-align: center;
      color: #3e3e3e;
      font-weight: 400;
      font-size: .875rem;
      margin-top: -1em;
    }

    &.dark {
      .loader {
        border-color: rgba(#ccc, .5);
        border-left-color: #ccc;
      }
    }
  }

  @mixin load8-frames() {

    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }

  }

  @keyframes load8 {
    @include load8-frames;
  }

  .file-form-input {
    visibility: hidden;
    opacity: 0;
    width: 0;
  }
</style>

<script>
  import SgBtn from './SgBtn.vue'

  export default {

    extends: SgBtn,

    data() {
      return {
        size: 'lg',
      }
    },

  }
</script>

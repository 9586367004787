<template>
  <component
    :is="as"
    :class="[`sg-btn btn-${kind}`, { 'btn-lg': size === 'lg', 'btn-sm': size === 'sm' }]"
    :type="isLink ? '' : type"
    :title="title"
    :disabled="disabled || loading"
    :href="href"
    :to="to"
    @click="$emit('click')">
    <template v-if="loading">
      <icon
        fa="spinner"
        class="fa-spin fa-fw" />
      <span>{{ loadingLabel }}</span>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </component>
</template>

<script>
  import Icon from 'sb/common/components/Icon.vue'

  export default {

    props: {
      kind: {
        type: String,
        default: 'default'
      },
      as: {
        type: String,
        default: 'button'
      },
      type: {
        type: String,
        default: 'button'
      },
      title: String,
      disabled: Boolean,
      loading: Boolean,
      loadingLabel: {
        type: String,
        default: 'Bitte warten'
      },
      href: String,
      to: [String, Object],
    },

    data() {
      return {
        size: '',
      }
    },

    computed: {
      isLink() {
        return this.as === 'a' || this.as === 'router-link'
      },
    },

    components: {
      Icon,
    },

  }
</script>

<style lang="scss" scoped>
@import "~sb_sass/variables";
@import "~sb_sass/mixins";

.sg-btn {
  display: inline-block;
  background: $grey-10;
  cursor: pointer;
  font-family: $nunito-sans;
  border: 1px solid $grey-20;
  color: $blue-dark;
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: 0.025em;
  text-align: center;
  padding: .75rem 1.75rem;
  border-radius: 100px;
  text-decoration: none;
  transition: .2s ease;

  &:hover {
    background-color: $grey-20;
  }

  &:focus {
    box-shadow: $focus-outline, $button-shadow;
    outline: none;
  }

  .icon:first-child {
    margin-right: .5rem;
  }

  .icon:last-child {
    margin-left: .5rem;
  }

  img, svg {
    height: 1.5em;
    width: auto;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .75;
    background-image: none !important;
    box-shadow: none !important;
  }

  &.btn-lg {
    font-size: 1.125rem;
    padding: .5rem 1.625rem;
  }

  &.btn-sm {
    font-size: .875rem;
    padding: .25rem 1rem;
    box-shadow: none;

    &:focus {
      box-shadow: $focus-outline;
    }
  }

  &.btn-fullwidth {
    width: 100%;
  }

  &.btn-primary {
    background-color: $blue;
    border-color: $blue;
    color: $white;
    border: none;

    &:not(:focus) {
      box-shadow: $button-shadow;
    }

    &:hover {
      background-color: $blue-60;
      border-color: $blue-60;
      background-image: none;
      color: $white;
      box-shadow: $button-shadow-hover;
    }
  }

  &.btn-yellow {
    background-color: $sg-yellow;
    border-color: $sg-yellow;
    color: $yellow-90;

    &:not(:focus) {
      box-shadow: $button-shadow;
    }

    &:hover {
      background-color: $yellow-40;
      border-color: $yellow-40;
      color: $yellow-90;
      box-shadow: $button-shadow-hover;
    }
  }

  &.btn-outline {
    background-color: transparent;
    color: $blue-50;
    border-color: $blue-30;

    &:hover {
      border-color: $blue-50;
    }
  }

  &.btn-outline-invert {
    background-color: transparent;
    color: $blue-00;
    border-color: $blue-00;

    &:hover {
      color: $white;
      border-color: $white;
    }
  }

  &.btn-white {
    background-color: $white;
    color: $blue;

    &:not(:focus) {
      box-shadow: $button-shadow;
    }

    &:hover {
      color: $blue-60;
      box-shadow: $button-shadow-hover;
    }
  }
}
</style>

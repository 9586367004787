<template>
  <div class="flex">
    <div class="bg-grey-00 min-h-full flex-1">
      <div class="min-h-footer-padding">
        <config-side-header @discard="discardCollection">
          <header-nav-steps v-if="$route.name === 'mpc-size-select'" />
        </config-side-header>

        <main class="config-container">
          <h1 class="config-headline">
            {{ $t('views.sizes_config.headline') }}
          </h1>

          <template v-if="allCartProductsForCurrentGroup">
            <config-product-group
              v-for="cartProduct in allCartProductsForCurrentGroup"
              :key="cartProduct.base_product_id"
              :product="cartProduct"
              :previews="allPreviewsForCurrentGroup"
              :read-only="isLoadingCart"
              :loading="isLoadingCart || isLoadingPrices || !cartProduct.avg_item_price_gross"
              @update="updatePrice"
            />
          </template>

          <div class="bg-white border px-4 pt-6 pb-8 lg:p-10 mt-6 rounded-lg">
            <div class="flex flex-wrap gap-4 items-center">
              <p class="text-dark-blue">
                {{ $t('views.calculator.naming.headline') }}
              </p>

              <sg-input
                v-model="collectionName"
                :disabled="isLoadingCart"
                :placeholder="collectionPlaceholderName"
                class="w-360px lg:w-128 lg:ml-auto"
                type="text"
              />
            </div>
          </div>

          <config-mobile-bottom
            v-if="cart"
            :price-for-group="currentPriceObject"
            :cart="cart"
            :submit-button-text="$t('views.sizes_config.submit_button')"
            :loading="isLoadingCart || !currentPriceObject"
            @update="updatePrice"
            @submit="addToCart"
          />
        </main>
      </div>

      <config-footer />
    </div>

    <config-desktop-sidebar
      :show-processings="true"
      :price-for-group="currentPriceObject"
      :submit-button-text="$t('views.sizes_config.submit_button')"
      :loading="isLoadingCart || !currentPriceObject"
      :collection-name="collectionName || collectionPlaceholderName"
      @removeProduct="removeProduct($event)"
      @update="updatePrice"
      @submit="addToCart"
    />

    <confirm-modal
      v-if="showRemoveProductAlert"
      @confirm="removeProductConfirmed"
      @close="cancelRemoveProduct"
    >
      <strong>{{ $t('views.cart.product_group.remove_product_confirm') }}</strong>
    </confirm-modal>

    <alert-modal
      v-if="showNoProductAddedModal"
      @close="showNoProductAddedModal = false"
    >
      <strong>{{ $t('views.sizes_config.alert.no_product_added') }}</strong>
    </alert-modal>

    <alert-modal
      v-if="showNoProductInCartModal"
      @close="showNoProductInCartModal = false"
    >
      <strong>{{ $t('views.sizes_config.alert.no_product_in_cart') }}</strong>
    </alert-modal>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ConfigSideHeader from '@/components/config/header/ConfigSideHeader'
  import ConfigProductGroup from '@/components/config/sizes/ConfigProductGroup'
  import ConfigDesktopSidebar from '@/components/config/sidebar/ConfigDesktopSidebar'
  import ConfigFooter from '@/components/config/ConfigFooter'
  import AlertModal from '@/components/layout/AlertModal'
  import ConfigMobileBottom from '@/components/config/sidebar/ConfigMobileBottom'
  import Prices from '../../mixins/Prices'
  import { format } from 'date-fns/format'
  import ConfirmModal from '@/components/layout/ConfirmModal.vue'
  import HeaderNavSteps from '@/components/config/header/HeaderNavSteps.vue'

  export default {

    mixins: [ Prices ],

    data() {
      return {
        showNoProductAddedModal: false,
        showNoProductInCartModal: false,
        showRemoveProductAlert: false,
        removeProductId: null,
        collectionName: '',
      }
    },

    mounted() {
      if (!this.baseProducts) {
        this.loadAllBaseProducts()
      }

      if (this.cartIsEmpty) {
        console.info('Cart is empty.')
        this.$router.push({ name: 'single-product-designer' })
      }

      if (this.isCheckedOut) {
        this.deleteCart()
        this.deleteOrderDetails()
        console.info('Is checked out already.')
        this.$router.push({ name: 'single-product-designer' })
      }

      if (!this.designerFonts) {
        this.loadDesignerFonts()
      }

      if (this.currentManufacturingJob && this.currentManufacturingJob.group_name) {
        this.collectionName = this.currentManufacturingJob.group_name
      }

      if (!this.currentPriceObject) {
        this.updatePrice()
      }

      this.determineCurrentManufacturingJob()
    },

    methods: {
      discardCollection() {
        if (confirm(this.$t('views.designer.discard_collection.confirm') || '')) {
          this.removeAllManufacturingJobsForGroup(this.currentManufacturingJobGroupId)
          this.$router.push({ name: 'cart', params: {reference: this.cart.reference} })
        }
      },
      determineCurrentManufacturingJob() {
        const groupId = this.$route.params.groupid
        if (typeof groupId === 'undefined') return

        const baseProductsMapping = this.manufacturingJobProductsMapping[groupId]

        if (groupId === '0') {
          this.setCurrentManufacturingJobGroupId(0)
          this.setCurrentManufacturingJobUuid(0)
        } else {
          this.setCurrentManufacturingJobGroupId(groupId)
        }

        if (baseProductsMapping) {
          this.setCurrentManufacturingJobUuid(Object.values(baseProductsMapping)[0])
        }
      },
      removeProduct(baseProductId) {
        this.removeProductId = baseProductId
        this.showRemoveProductAlert = true
      },
      removeProductConfirmed() {
        if (!this.removeProductId) return

        this.removeProductFromCart(this.removeProductId)
        this.updatePrice()

        this.removeProductId = null
        this.showRemoveProductAlert = false
      },
      cancelRemoveProduct() {
        this.removeProductId = null
        this.showRemoveProductAlert = false
      },
      async addToCart() {
        if (this.currentGroupIsEmpty) {
          this.showNoProductInCartModal = true
          return
        }

        // Remove all empty variants
        this.allCartProductsForCurrentGroup.forEach((product) => {
          if (!product) return
          product.variants.forEach((variant) => {
            if (variant.amount === 0) {
              this.removeProductVariantFromJob({
                manufacturingJobUuid: this.currentManufacturingJob.uuid,
                baseProductId: product.base_product_id,
                baseProductVariantId: variant.baseproduct_variant_id,
              })
            }
          })
        })

        await this.saveCollectionName()
        await this.updatePrice().catch()

        setTimeout(() => {
          if (!this.cart.price_gross) {
            this.showNoProductAddedModal = true
            return
          }

          this.$router.push({
            name: 'cart',
            params: {
              reference: this.cart.reference
            }
          })
        })
      },
      async saveCollectionName() {
        return Promise.all(
          this.manufacturingJobsForCurrentGroup.map((job) => this.updateManufacturingJob({
            ...job,
            created_at: job.created_at || format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            group_name: this.collectionName || this.collectionPlaceholderName,
          })),
        )
      },
      ...mapActions([
        'deleteCart',
        'deleteOrderDetails',
        'loadDesignerFonts',
        'loadAllBaseProducts',
        'removeProductVariantFromJob',
        'updateManufacturingJob',
        'setCurrentManufacturingJobGroupId',
        'setCurrentManufacturingJobUuid',
        'removeProductFromCart',
        'removeAllManufacturingJobsForGroup',
      ])
    },

    computed: {
      /**
       * @returns {ManufacturingJobPriceItem | null}
       */
      currentPriceObject() {
        if (!this.currentManufacturingJob) return null
        return this.priceForGroup(this.currentManufacturingJob.group_id)
      },
      collectionPlaceholderName() {
        return `Kollektion vom ${format(new Date(), 'dd.MM.yy - HH:mm')} Uhr`
      },
      ...mapGetters([
        'cartIsEmpty',
        'currentGroupIsEmpty',
        'baseProducts',
        'cart',
        'currentBaseProduct',
        'currentManufacturingJob',
        'isCheckedOut',
        'designerFonts',
        'allCartProductsForCurrentGroup',
        'manufacturingJobsForCurrentGroup',
        'isLoadingCart',
        'isLoadingPrices',
        'allPreviewsForCurrentGroup',
        'manufacturingJobProductsMapping',
        'currentManufacturingJobGroupId',
      ])
    },

    components: {
      HeaderNavSteps,
      ConfirmModal,
      ConfigMobileBottom,
      AlertModal,
      ConfigFooter,
      ConfigDesktopSidebar,
      ConfigSideHeader,
      ConfigProductGroup
    }

  }
</script>

<style lang="scss" scoped>
  body {
    background: #f8f9fa;
  }
</style>

<template>
  <aside>
    <mobile-designer-sidebar-content
      ref="mobileSidebar"
      :designer-ref="designerRef"
      :available-colors="availableColors"
      :current-price="currentPrice"
      :current-printarea="currentPrintarea"
      :printareas="printareas"
      :loading-next-step="isLoadingNextStep"
      :is-editing-mpc-base-design="isEditingMpcBaseDesign"
      @printarea:change="$emit('printarea:change', $event)"
      @nextStep="$emit('nextStep')"
      @saveBaseDesign="$emit('saveBaseDesign')"
      @openBaseDesignEditor="$emit('openBaseDesignEditor')"
      @closeBaseDesignEditor="$emit('closeBaseDesignEditor')"
      @update="$emit('update')"
    />

    <designer-sidebar-content
      ref="content"
      :designer-ref="designerRef"
      :available-colors="availableColors"
      :current-price="currentPrice"
      :shipping-price="shippingPrice"
      :loading-next-step="isLoadingNextStep"
      @countryChange="$emit('updateCountry')"
      @openBaseDesignEditor="$emit('openBaseDesignEditor')"
      @nextStep="$emit('nextStep')"
      @update="$emit('update')"
      @selectProduct="$emit('selectProduct', $event)"
    />
  </aside>
</template>

<script>
  import MobileDesignerSidebarContent from './MobileDesignerSidebarContent'
  import DesignerSidebarContent from './DesignerSidebarContent'

  export default {

    props: {
      currentPrintarea: {
        validator: prop => typeof prop === 'object' || prop == null,
        required: true
      },
      printareas: {
        type: Array,
        default: () => []
      },
      isValid: {
        type: Boolean,
        required: true
      },
      designerRef: {
        type: Object,
      },
      isEditingMpcBaseDesign: {
        type: Boolean,
      },
      availableColors: {
        type: Array,
        required: true
      },
      isLoadingNextStep: {
        type: Boolean,
      },
      currentPrice: {
        type: Number,
        required: true,
      },
      shippingPrice: {
        type: Number,
        required: true,
      },
      currentProcessingSpecifications: {
        type: Array,
      },
    },

    components: {
      MobileDesignerSidebarContent,
      DesignerSidebarContent,
    }

  }
</script>

<template>
  <div class="border px-4 py-5 sm:p-0">
    <dl class="sm:divide-y sm:divide-gray-200">
      <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-ms text-gray-50 uppercase font-bold tracking-1">
          {{ $t('views.product_details.data_sheet.product_name') }}
        </dt>
        <dd class="mt-1 text-gray-80 sm:mt-0 sm:col-span-2">
          {{ catalogProduct.name }}
        </dd>
      </div>
      <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-ms text-gray-50 uppercase font-bold tracking-1">
          {{ $t('views.product_details.data_sheet.brand') }}
        </dt>
        <dd class="mt-1 text-gray-80 sm:mt-0 sm:col-span-2">
          {{ catalogProduct.brand.data.name }}
        </dd>
      </div>
      <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-ms text-gray-50 uppercase font-bold tracking-1">
          {{ $t('views.product_details.data_sheet.brand_product_name') }}
        </dt>
        <dd class="mt-1 text-gray-80 sm:mt-0 sm:col-span-2">
          {{ catalogProduct.brand_product_name }}
        </dd>
      </div>
      <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-ms text-gray-50 uppercase font-bold tracking-1">
          {{ $t('global.colors') }}
        </dt>
        <dd class="mt-1 text-gray-80 sm:mt-0 sm:col-span-2">
          {{ productColors }} {{ productColors > 1 ? $t('global.colors') : $t('global.colors') }}
        </dd>
      </div>
      <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-ms text-gray-50 uppercase font-bold tracking-1">
          {{ $t('global.sizes') }}
        </dt>
        <dd class="mt-1 text-gray-80 sm:mt-0 sm:col-span-2">
          {{ productSizes }}
        </dd>
      </div>
      <div
        v-for="attribute in sortedAttributes"
        :key="attribute.key"
        class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-ms text-gray-50 uppercase font-bold tracking-1">
          {{ attribute.type.data.name }}
        </dt>
        <dd class="mt-1 text-gray-80 sm:mt-0 sm:col-span-2">
          <div v-if="!attribute.type.data.is_ordinal">
            {{ attribute.name }}
          </div>
          <ordinal-attribute
            v-else
            :attribute="attribute"
            :big="false"></ordinal-attribute>
        </dd>
      </div>
      <div
        v-for="(attribute, index) in listedBrandAttributes"
        :key="index"
        class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-ms text-gray-50 uppercase font-bold tracking-1">
          {{ attribute.type.data.name }}
        </dt>
        <dd class="mt-1 text-gray-80 sm:mt-0 sm:col-span-2">
          {{ attribute.value }}
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
  import OrdinalAttribute from './OrdinalAttribute.vue'

  export default {

    props: {
      catalogProduct: {
        type: Object,
      },
    },

    computed: {
      productColors() {
        if (!this.catalogProduct) {
          return null
        }

        return this.catalogProduct.variants.data.length
      },
      defaultProductVariant() {
        if (!this.catalogProduct) {
          return null
        }
        let sku = this.catalogProduct.default_variant_sku.split('.')
        let productVariant = this.catalogProduct.variants.data.find(p => {
          return p.id === parseInt(sku[1])
        })
        if (!productVariant) {
          productVariant = this.catalogProduct.variants.data[0]
        }
        return productVariant
      },
      productSizes() {
        if (!this.catalogProduct) {
          return null
        }

        let sizes = this.defaultProductVariant.size_variants.data[0].size

        if (this.defaultProductVariant.size_variants.data.length > 1) {
          sizes = ''
          this.defaultProductVariant.size_variants.data.forEach(s => {
            if (sizes !== '') {
              sizes += ', ' + s.size
            } else {
              sizes += s.size
            }
          })
        }

        return sizes
      },
      sortedAttributes() {
        let attributes = JSON.parse(
          JSON.stringify(
            this.catalogProduct.attributes.data
          )
        )

        attributes = attributes.filter(a => {
          return a.type.data.listed_information === true
        })

        return attributes.sort((a, b) => {
          return a.type.data.sort_weight - b.type.data.sort_weight
        })
      },
      listedBrandAttributes() {
        return this.catalogProduct.brand_attributes.data.filter(b => {
          return !!b.type.data.productAttributeTypes
        })
      },
    },

    components: {
      OrdinalAttribute,
    },
  }
</script>

<template>
  <div class="sg-select-wrapper relative">
    <button
      class="sg-select text-left text-gray-80"
      style="height: 50px"
      :style="{ fontFamily: activeFontFamilyName }"
      @click="showFontSelect = !showFontSelect"
    >
      {{ activeFontFamilyName }}
    </button>

    <div
      v-if="showFontSelect"
      class="absolute bottom-0 md:bottom-auto left-0 right-0  mb-10 md:mb-0 md:-top-10 border-t border-gray-20 bg-white rounded-lg shadow-lg z-10 max-h-300px overflow-y-auto"
    >
      <ul>
        <li
          v-for="(font, index) in designerFonts"
          :key="font.reference"
          class="group cursor-pointer border-gray-20"
          :class="index > 0 ? 'border-t' : ''"
        >
          <button
            class="py-3 px-4 w-full block text-left text-gray-80 bg-white group-hover:bg-gray-00"
            :style="{ fontFamily: font.name }"
            @click.prevent="selectFont(font)"
          >
            {{ font.name }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {

    name: 'FontSelection',

    props: {
      activeFontFamilyName: String,
      designerFonts: {
        type: Array,
        required: true
      },
    },

    data: () => ({
      showFontSelect: false,
    }),

    methods: {
      selectFont(font) {
        this.showFontSelect = false
        this.$emit('selectFont', font)
      },
      documentClick() {
        this.showFontSelect = false
      },
    },

    watch: {
      showFontSelect() {
        if (this.showFontSelect) {
          setTimeout(() => {
            document.addEventListener('click', this.documentClick)
          }, 200)
        } else {
          document.removeEventListener('click', this.documentClick)
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .sg-select {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    border: 1px solid $grey-30;
    border-radius: 3px;
    box-shadow: inset 1px 0 3px 0 rgba(0, 0, 0, .05);
    font-size: 1rem;
    font-family: $nunito-sans;
    padding: .75rem;
    transition: .2s ease;
    background: $white;

    &-wrapper {
      position: relative;

      &::after {
        @include fa;

        content: "\f0dc";
        color: $grey-70;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: .75rem;
        bottom: 0;
        pointer-events: none;
      }
    }

    &:focus {
      border-color: $blue-50;
      outline: none;
    }
  }
</style>

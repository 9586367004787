<template>
    <div class="flex flex-col lg:w-5/12 ml-auto">
      <div class="flex text-grey-50 text-sm">
        <div class="flex-1">
          {{ $t('views.cart.full_price_display.cost_products') }}
        </div>

        <loader
          v-if="isLoadingPrices"
          color="grey-50"
          size="small"
        />

        <div v-else>
          {{ cart.price_gross - cart.shipping_price_net | currency }}
        </div>
      </div>

      <div
        v-if="cart.coupon_code"
        class="flex text-grey-50 text-sm"
      >
        <div class="flex flex-1">
          {{ $t('views.cart.full_price_display.coupon.headline') }}

          <button
            v-if="allowRemoveCoupon"
            class="h-5 ml-2 flex items-center text-red-50 font-bold cursor-pointer group"
            :title="$t('views.cart.full_price_display.coupon.remove_coupon')"
            @click="$emit('remove-coupon')"
          >
            <i class="icon-close-outline text-xl mr-1" />

            <span class="invisible group-hover:visible">
              {{ $t('views.cart.full_price_display.coupon.remove_coupon') }}
            </span>
          </button>
        </div>

        <div class="text-green-70">
          -{{ cart.price_discount | currency }}
        </div>
      </div>

      <div class="flex text-grey-50 text-sm">
        <div class="flex-1">
          {{ $t('views.cart.full_price_display.shipping') }}
        </div>

        <loader
          v-if="isLoadingPrices"
          color="grey-50"
          size="small"
        />

        <div :class="!cart.shipping_price_net ? 'text-green-60' : ''" v-else>
          {{ cart.shipping_price_net | currency }}
        </div>
      </div>

      <div class="flex text-grey-80 text-2xl pt-2 leading-9">
        <div
          class="flex-1"
          style="margin-left: -2px"
        >
          {{ $t('views.cart.full_price_display.total_price') }}
        </div>

        <loader
          v-if="isLoadingPrices"
          color="blue-50"
          size="medium"
        />

        <div class="text-blue-50 font-semibold" v-else>
          {{ cart.price_gross | currency }}
        </div>
      </div>

      <div class="flex text-grey-50 text-sm pb-4">
        <div class="flex-1">
          {{ $t('views.cart.full_price_display.includes_vat', { vat_rate: cart.vat_rate }) }}
        </div>

        <loader
          v-if="isLoadingPrices"
          color="grey-50"
          size="small"
        />

        <div v-else>
          {{ cart.vat | currency }}
        </div>
      </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Loader from '@/components/layout/Loader.vue'

  export default {

    props: {
      allowRemoveCoupon: {
        type: Boolean,
        default: false
      },
    },

    computed: {
      ...mapGetters([
        'isLoadingPrices',
        'cart',
      ])
    },

    components: { Loader }

  }
</script>

<style scoped>

</style>

<template>
  <div
    class="mockup-preview notarget"
    @click="removeActive"
  >
    <div class="mockup-preview-inner">
      <mockup-product-preview
        ref="productMockup"
        :mockup="mockup"
        :color="color"
        :scale="scale"
      />

      <svg
        ref="printareaBox"
        class="printarea-box notarget"
        :class="{
          'hover-enabled': processingItems.length,
          'outline': hoverElement || activeElement
        }"
        :width="renderWidth"
        :height="renderHeight"
        :viewBox="`0 0 ${renderWidth} ${renderHeight}`"
        @wheel.prevent="() => false"
      >
        <svg
          v-show="!loading"
          ref="printareaSVG"
          :width="printareaWidth"
          :height="printareaHeight"
          :viewBox="`0 0 ${printareaWidth} ${printareaHeight}`"
          :x="printareaPosX"
          :y="printareaPosY"
        >
          <rect
            ref="printareaDimensions"
            :width="printareaWidth"
            x="0"
            y="0"
            fill="none"
            :height="printareaHeight"
          />

          <rect
            v-if="hoverElement || activeElement || !isValid"
            :width="printareaWidth"
            :height="printareaHeight"
            :stroke="isValid ? '#3e3e3e' : '#ff0000'"
            stroke-linecap="round"
            :stroke-width="isValid ? 2 : 3"
            stroke-dasharray="5,5"
            fill="none"
          />

          <processing-item-preview
            v-for="processingItem in processingItems"
            :ref="`processingItemPreview_${processingItem._internal_id || processingItem.uuid}`"
            :key="`processingItemPreview_${processingItem._internal_id || processingItem.uuid}`"
            :mockup-scale="mockupScale"
            :printarea-width="printareaWidth"
            :processing-item="processingItem"
            :mockup="mockup"
            :printarea="printarea"
            :class="{ 'outline': hoverElement === processingItem || activeElement === processingItem }"
            :designer-fonts="designerFonts"
            @mouseenter.native="hoverElement = processingItem"
            @mouseleave.native="hoverElement === processingItem ? hoverElement = null : null"
            @element-updated="(updatedProcessingItem) => updateSingleProcessingItem(updatedProcessingItem)"
          />

          <rect
            v-if="isSticky"
            width="2"
            :x="printareaWidth / 2"
            :height="printareaHeight"
            fill="#38d2c2"
          />
          <rect
            v-if="isStickyVertical"
            height="2"
            :y="printareaHeight / 2"
            :width="printareaWidth"
            fill="#38d2c2"
          />
        </svg>
      </svg>

      <div
        v-if="$refs.printareaSVG"
        class="handles-parent"
      >
        <processing-item-handles
          v-for="processingItem in processingItems"
          :key="`handles_${processingItem._internal_id || processingItem.uuid}`"
          :mockup-scale="mockupScale"
          :printarea="printarea"
          :processing-item="processingItem"
          :active="hoverElement === processingItem || activeElement === processingItem"
          :preview-element="$refs[`processingItemPreview_${processingItem._internal_id || processingItem.uuid}`]"
          :printarea-svg="$refs.printareaSVG"
          @processing-item-click="processingItemClick($event)"
          @delete="elementDeleted($event)"
          @edit="processingItemEdit($event)"
          @sticky="isSticky = $event"
          @sticky:vert="isStickyVertical = $event"
          @mouseenter.native="hoverElement = processingItem"
          @mouseleave.native="hoverElement === processingItem ? hoverElement = null : null"
          @element-updated="(updatedProcessingItem) => updateSingleProcessingItem(updatedProcessingItem)"
        />
      </div>

      <div
        class="fixed"
        :style="{
          visibility: processingItems.length ? 'hidden' : 'visible',
          width: `${renderWidth}px`,
          marginTop: `${(-renderHeight)}px`
        }"
      >
        <div
          :style="{
            marginTop: `${printareaPosY}px`,
            width: `${printareaWidth}px`,
            height: `${printareaHeight}px`
          }"
          class="mx-auto"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import Vue, { PropOptions } from 'vue'
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'
  import ProcessingItemPreview from '../../elements/Designer/ProcessingItemPreview'
  import ProcessingItemHandles from '../../elements/Designer/ProcessingItemHandles'
  import generateUniqueId from '../../common/utils/uniqueId'
  import MockupProductPreview from './MockupProductPreview.vue'

  export default {

    props: {
      /** @type {PropOptions<BaseProductColorVariant|null>} */
      color: {
        type: Object
      },
      /** @type {PropOptions<BaseProductPrintarea>} */
      printarea: {
        type: Object,
        required: true
      },
      /** @type {PropOptions<ApiFormattedProcessingItem[]>} */
      processingItems: {
        type: Array,
        required: true
      },
      /** @type {PropOptions<DesignerFontFamily[]>} */
      designerFonts: {
        type: Array,
        required: true
      },
      scale: {
        type: Number,
        default: 1,
        required: false
      },
      mockup: {
        type: Object,
        required: true
      },
      loading: {
        type: Boolean,
        default: false,
        required: false,
      }
    },

    data() {
      return {
        isInitialized: false,
        draw: false,
        backgroundLayer: null,
        isSticky: false,
        isStickyVertical: false,
        scaleFactor: 1,
        heatherPattern: null,
        activeElement: null,
        hoverElement: null,
        isValid: false
      }
    },

    beforeMount() {
      this.generateInternalIds()
    },

    mounted() {
      this.adjustScale()

      Vue.nextTick(() => {
        this.validateBounds()
      })
    },

    methods: {
      /**
       * @param {ApiFormattedProcessingItem} updatedProcessingItem
       */
      updateSingleProcessingItem(updatedProcessingItem) {
        if (this.$parent.selectedProcessingItem) {
          if (typeof updatedProcessingItem._internal_id !== 'undefined' && updatedProcessingItem._internal_id === this.$parent.selectedProcessingItem._internal_id) {
            this.processingItemClick(updatedProcessingItem)
          }

          if (typeof updatedProcessingItem.uuid !== 'undefined' && updatedProcessingItem.uuid === this.$parent.selectedProcessingItem.uuid) {
            this.processingItemClick(updatedProcessingItem)
          }
        }

        this.updateProcessingItems([ updatedProcessingItem ])
      },
      /**
       * @param {ApiFormattedProcessingItem[]} newProcessingItems
       */
      updateProcessingItems(newProcessingItems) {
        this.$emit('processing-items-updated', newProcessingItems)
      },
      /**
       * @param {ApiFormattedProcessingItem} deletedProcessingItem
       */
      elementDeleted(deletedProcessingItem) {
        this.$emit('processing-item-deleted', deletedProcessingItem)
      },
      generateInternalIds() {
        // Define internal IDs for each processing item. Makes it easier to keep track
        this.updateProcessingItems(this.processingItems.map((item) => {
          if (!item._internal_id) {
            return {
              ...item,
              _internal_id: generateUniqueId(),
            }
          }
          return item
        }))
      },
      processingItemEdit(processingItem) {
        this.$emit('processing-item-edit', processingItem)
        this.activeElement = processingItem
      },
      processingItemClick(processingItem) {
        this.$emit('processing-item-click', processingItem)
        this.activeElement = processingItem
      },
      removeActive(event) {
        if (!event.target.classList.contains('notarget')) {
          // only remove if the user clicked somewhere that's not a target
          return
        }
        this.$emit('processing-item-deselected')
        this.activeElement = null
        this.hoverElement = null
        this.isSticky = false
      },
      validateBounds() {
        if (!this.$refs.printareaDimensions || !this.processingItems) return

        const printareaDimensions = this.$refs.printareaDimensions.getBoundingClientRect()
        let isValid = true

        this.processingItems.forEach((processingItem) => {
          if (!Object.hasOwnProperty.call(
            this.$refs,
            `processingItemPreview_${processingItem._internal_id || processingItem.uuid}`
          )) return
          const processingItemElement = this.$refs[`processingItemPreview_${processingItem._internal_id || processingItem.uuid}`][0]
          if (!processingItemElement || !processingItemElement.$el.children) return
          const itemBoxElement = processingItemElement.$el.children[0]
          if (!itemBoxElement) return

          const TOLERANCE = 3
          const itemDimensions = itemBoxElement.getBoundingClientRect()

          if (
            itemDimensions.top + TOLERANCE < printareaDimensions.top ||
            itemDimensions.bottom - TOLERANCE > printareaDimensions.bottom ||
            itemDimensions.left + TOLERANCE < printareaDimensions.left ||
            itemDimensions.right - TOLERANCE > printareaDimensions.right
          ) {
            isValid = false
          }
        })

        this.isValid = isValid
        this.$emit('validity-changed', this.isValid)
      },
      adjustScale() {
        if (this.mockup && screen.width < this.mockup.width_px * this.scale) {
          this.scaleFactor = screen.width / this.mockup.width_px
        } else {
          this.scaleFactor = this.scale
        }
      },
    },

    computed: {
      /**
       * @return {number}
       */
      mockupScale() {
        return this.printareaWidth / this.printarea.width
      },
      /**
       * @return {number}
       */
      printareaWidth() {
        if (!this.mockup) return 0
        return Math.round(this.scaleFactor * (this.printarea.width / this.mockup.px_to_mm))
      },
      /**
       * @return {number}
       */
      printareaHeight() {
        if (!this.mockup) return 0
        return Math.round(this.scaleFactor * (this.printarea.height / this.mockup.px_to_mm))
      },
      /**
       * @return {number}
       */
      renderWidth() {
        if (!this.mockup) return 0
        return Math.round(this.scaleFactor * this.mockup.width_px)
      },
      /**
       * @return {number}
       */
      renderHeight() {
        if (!this.mockup) return 0
        return Math.round(this.scaleFactor * this.mockup.height_px)
      },
      /**
       * @return {number}
       */
      printareaPosX() {
        if (!this.mockup) return 0
        return this.renderWidth / 2 - this.printareaWidth / 2
      },
      /**
       * @return {number}
       */
      printareaPosY() {
        if (!this.mockup) return 0
        return Math.round(this.scaleFactor * (this.mockup.offset_y_top_px + (this.printarea.offset_y_collar_mm / this.mockup.px_to_mm)))
      },
    },

    watch: {
      color() {
        if (!this.backgroundLayer) {
          return false
        }

        this.backgroundLayer.attr({
          fill: '#' + this.color.hexcode
        })

        if (this.heatherPattern) {
          this.heatherPattern.attr({ opacity: this.color.is_heathered ? 1 : 0 })
        }

        return true
      },
      printarea() {
        Vue.nextTick(() => {
          if (this.$refs.productMockup) {
            this.$refs.productMockup.initMockup()
          }

          this.validateBounds()
        })
      },
      processingItems() {
        this.validateBounds()
      }
    },

    components: { MockupProductPreview, ProcessingItemHandles, ProcessingItemPreview }

  }
</script>

<style lang="scss" scoped>
  .mockup-preview {
    display: flex;
    position: relative;
    justify-content: center;

    .mockup-preview-inner {
      position: relative;
      width: 100%;
    }

    .add-design-button {
      display: flex;
      align-items: center;
    }
  }

  .printarea-box {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px dashed transparent;
    box-sizing: content-box;
    transition: transform ease .3s, border ease .2s, border-color ease .2s;

    .axis {
      opacity: 0;
      display: none;
      position: absolute;
      width: 2px;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: #38d2c2;
      pointer-events: none;

      &.active {
        opacity: 1;
        display: block;
      }

      &.vertical {
        width: 100%;
        height: 2px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  .handles-parent {
    position: relative;
  }

  .mockup-mask-wrapper {
    position: relative;

    .mockup-mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
      max-width: initial;
    }
  }
</style>

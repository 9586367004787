import Vue from 'vue'

export default {
  SET_CATALOG_PRODUCTS(state, baseProducts) {
    Vue.set(state, 'catalog_products', baseProducts)
  },

  SET_PRODUCT_CATEGORIES(state, productCategories) {
    Vue.set(state, 'product_categories', productCategories)
  },

  SET_GENDERS(state, genders) {
    Vue.set(state, 'genders', genders)
  },

  SET_BRANDS(state, brands) {
    Vue.set(state, 'brands', brands)
  },

  SET_CATALOG_PRODUCT(state, baseProduct) {
    Vue.set(state, 'catalog_product', baseProduct)
  },
}

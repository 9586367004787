<template>
  <div class="bg-gray-00">
    <div class="hidden lg:flex px-4 justify-between items-center max-w-container mx-auto">
      <div class="flex py-2 text-sm text-gray-70">
        <header-benefits-modal
          button-icon="icon-car-outline"
          cta-link="https://helpcenter.shirtigo.de/helpcenter-sg/einzel-und-kleinauflagen/ist-der-versand-kostenfrei/"
          :button-title="$t('navigation.header.meta.benefits.free_shipping.button_title') || ''"
          :modal-headline="$t('navigation.header.meta.benefits.free_shipping.modal_headline') || ''"
          :modal-text="$t('navigation.header.meta.benefits.free_shipping.modal_text') || ''"
        />

        <header-benefits-modal
          button-icon="icon-pricetags-outline"
          cta-link="https://helpcenter.shirtigo.de/helpcenter-sg/einzel-und-kleinauflagen/bietet-ihr-einen-mengenrabatt-fuer-t-shirts-co-an/"
          :button-title="$t('navigation.header.meta.benefits.coupons.button_title') || ''"
          :modal-headline="$t('navigation.header.meta.benefits.coupons.modal_headline') || ''"
          :modal-text="$t('navigation.header.meta.benefits.coupons.modal_text') || ''"
        />

        <header-benefits-modal
          button-icon="icon-smiling-face-outline"
          cta-link="https://helpcenter.shirtigo.de/helpcenter-sg/einzel-und-kleinauflagen/gibt-es-versteckte-kosten/"
          :button-title="$t('navigation.header.meta.benefits.no_hidden_fees.button_title') || ''"
          :modal-headline="$t('navigation.header.meta.benefits.no_hidden_fees.modal_headline') || ''"
          :modal-text="$t('navigation.header.meta.benefits.no_hidden_fees.modal_text') || ''"
        />
      </div>

      <div class="flex text-sm">
        <div class="text-gray-70 mr-6">
          <i class="icon-phone-outline mr-1" />
          +49 (0)2233 / 71 32 282
        </div>

        <div
          class="text-blue-50 mr-6 cursor-pointer"
          @click="showContactModal = true"
        >
          <i class="icon-email-outline mr-1" />
          {{ $t('navigation.header.meta.contact') }}
        </div>

        <a
          href="https://helpcenter.shirtigo.de/#shirtigo"
          class="text-blue-50"
          target="_blank"
        >
          <i class="icon-question-mark-circle-outline mr-1" />
          {{ $t('navigation.header.meta.helpcenter') }}
        </a>
      </div>
    </div>

    <header-contact-modal
      v-if="showContactModal"
      @close:modal="showContactModal = false"
    />
  </div>
</template>

<script>
  import HeaderBenefitsModal from '@/components/layout/header/HeaderBenefitsModal.vue'
  import HeaderContactModal from '@/components/layout/header/HeaderContactModal.vue'

  export default {

    data() {
      return {
        showContactModal: false,
      }
    },

    components: { HeaderBenefitsModal, HeaderContactModal },

  }
</script>

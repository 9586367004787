export default {
  de: {
    shirtigo: {
      login: {
        login_headline: 'Melde dich mit deiner Shirtigo-ID an.',
        register_headline: 'Kostenloses Shirtigo-ID Konto erstellen.',
        link_to_register: {
          label: 'Du hast noch kein Konto?',
          action: 'Jetzt erstellen',
        },
        link_to_login: {
          label: 'Du hast bereits ein Konto?',
          action: 'Jetzt anmelden',
        },
        email_input_label: 'E-Mail',
        password_input_label: 'Passwort',
        password_confirm_input_label: 'Passwort wiederholen',
        password_reset_link: 'Passwort vergessen?',
        agb_confirmation_text: 'Ich habe die <a href="/agb" target="_blank">AGB</a>, die <a href="/agb#widerrufsrecht" target="_blank">Informationen zum Widerrufsrecht</a> sowie die <a href="/privacy" target="_blank">Hinweise zum Datenschutz und den Datenrichtlinien</a> der Shirtigo GmbH gelesen und erkenne diese an.',
        cta_login: 'Anmelden',
        cta_register: 'Konto erstellen',
        social_login_label: 'Oder Login mit',
        hero: {
          headline: 'Ein Account für alle Plattformen',
          text: 'Organisieren, Verwalten und Verkaufen - Nutze Shirtigo und Seedshirt mit nur einem Account',
          action: 'Mehr erfahren',
        },
        footer_legal_text: 'Ich bin damit einverstanden, dass bei der Anmeldung über "Google-Login" oder "Facebook-Connect" automatisch Daten an die Shirtigo GmbH übermittelt und anschließend verwendet werden.',
        footer_nav: {
          imprint: 'Impressum',
          agb: 'AGB',
          privacy: 'Datenschutz',
          withdrawal: 'Widerrufsrecht',
        },
      },
      auth: {
        link_to_register: {
          label: 'Noch keinen Account?',
          action: 'Jetzt registrieren',
        },
        headline: 'Setze dein Passwort zurück',
        description: 'Du hast dein Passwort vergessen? Kein Problem. Gib die E-Mail-Adresse ein mit der du bei uns registriert bist und wir schicken dir eine E-Mail mit weiteren Instruktionen.',
        description_new_password: 'Bitte gib deine Email-Adresse sowie dein neues Passwort ein.',
        email_input_label: 'Deine E-Mail-Adresse',
        email_input_placeholder: 'Gib deine E-Mail-Adresse ein',
        password_input_placeholder: 'Gib dein Passwort ein',
        password_confirmation_input_placeholder: 'Wiederhole dein Passwort',
        primary_action: 'Passwort zurücksetzen',
        save_new_password: 'Neues Passwort speichern',
        secondary_action: 'Zurück zum Login',
        email_sent: 'Der Link zum Zurücksetzen des Passworts wurde dir per Email zugeschickt.',
        footer_nav: {
          imprint: 'Impressum',
          agb: 'AGB',
          privacy: 'Datenschutz',
          withdrawal: 'Widerrufsrecht',
        },
      },
    },
  },
  en: {
    shirtigo: {
      login: {
        login_headline: 'Login with your Shirtigo-ID.',
        register_headline: 'Create a free Shirtigo-ID account.',
        link_to_register: {
          label: 'No account yet?',
          action: 'Sign up for free',
        },
        link_to_login: {
          label: 'Already have an account?',
          action: 'Login now',
        },
        email_input_label: 'Email',
        password_input_label: 'Password',
        password_confirm_input_label: 'Confirm Password',
        password_reset_link: 'Forgot your password?',
        agb_confirmation_text: 'I accept the <a href="/agb" target="_blank">Terms and Conditions</a>, the <a href="/widerrufsrecht" target="_blank">Right of Withdrawal</a> and the <a href="/privacy" target="_blank">Privacy Policy</a>.',
        cta_login: 'Login',
        cta_register: 'Get started!',
        social_login_label: 'Or login with',
        hero: {
          headline: 'One account for all our platforms',
          text: 'Organize, manage and sell - use Shirtigo and Seedshirt with one single account',
          action: 'More',
        },
        footer_legal_text: 'I accept, that information are transferred to Shirtigo GmbH by using "Google-Login" or "Facebook-Connect".',
        footer_nav: {
          imprint: 'Imprint',
          agb: 'Terms & Conditions',
          privacy: 'Privacy',
          withdrawal: 'Right of withdrawl',
        },
      },
      auth: {
        link_to_register: {
          label: 'Need an account?',
          action: 'Sign up for free',
        },
        headline: 'Reset your password',
        description: 'Forgot your password? No problem! Enter the email address you used for registration and we will send you a reset link via email.',
        description_new_password: 'Please enter you email-address and you new password.',
        email_input_label: 'Your email address',
        email_input_placeholder: 'Enter your email address',
        password_input_placeholder: 'Enter your password',
        password_confirmation_input_placeholder: 'Repeat your password',
        primary_action: 'Reset password',
        save_new_password: 'Save new password',
        secondary_action: 'Back to login',
        email_sent: 'The link to reset your password was sent to you by email.',
        footer_nav: {
          imprint: 'Imprint',
          agb: 'Terms & Conditions',
          privacy: 'Privacy',
          withdrawal: 'Right of withdrawl',
        },
      },
    },
  }
}

/* eslint-disable camelcase */

import {
  getBaseProduct,
  getAllBaseProducts,
  getBaseProductColors,
} from '@/api/baseProduct'
import { submitCheckout, submitOrderDetails, submitPayment } from '@/api/checkout'
import { getFirstSortPosition, makeArrayFromImageObject, findManufacturingJobForProductInGroup, splitBaseProductVariantId } from '@/utils/helpers'
import { getDesignerFonts } from '@/api/design'

require('../typedefs')

const actions = {
  loadBaseProduct({ commit }, slug) {
    return getBaseProduct(slug).then(({ base_product }) => {
      commit('SET_CURRENT_BASE_PRODUCT_REFERENCE', base_product.reference)
      return commit('SET_BASE_PRODUCT', base_product)
    })
  },
  loadAllBaseProducts({ commit }) {
    return getAllBaseProducts().then((baseProducts) => {
      return commit('SET_BASE_PRODUCTS', baseProducts.data)
    })
  },

  loadProductColors({ commit }, category) {
    return getBaseProductColors(category).then((product_colors) => {
      return commit('SET_PRODUCT_COLORS', product_colors)
    })
  },

  loadDesignerFonts({ commit }) {
    return getDesignerFonts().then((/** @type DesignerFontFamily[] */fonts) => {
      return commit('SET_DESIGNER_FONTS', fonts)
    })
  },
  /**
   * @param commit
   * @param {ApiFormattedManufacturingJobUuid} manufacturingJobUuid
   */
  setCurrentManufacturingJobUuid({ commit }, manufacturingJobUuid) {
    commit('SET_CURRENT_MANUFACTURING_JOB', manufacturingJobUuid)
  },
  /**
   * @param commit
   * @param {ApiFormattedManufacturingJobGroupId} manufacturingJobGroupId
   */
  setCurrentManufacturingJobGroupId({ commit }, manufacturingJobGroupId) {
    commit('SET_CURRENT_MANUFACTURING_JOB_GROUP_ID', manufacturingJobGroupId)
  },
  /**
   * @param commit
   * @param {string} reference
   */
  setCurrentBaseProductReference({ commit }, reference) {
    commit('SET_CURRENT_BASE_PRODUCT_REFERENCE', reference)
  },
  /**
   * @param commit
   * @param state
   * @param getters
   * @param {string|number} id
   */
  setCurrentColorId({ commit, state, getters }, id) {
    const currentColor = getters.currentBaseProduct.variants.data.find(c => c.id === id)
    const side = getters.currentImage ? getters.currentImage.side : 'front'
    if (!currentColor) return

    const images = makeArrayFromImageObject(currentColor.images.data).filter(img => img.side === side)
    const firstImage = getFirstSortPosition(images)

    if (firstImage) {
      commit('SET_CURRENT_IMAGE', firstImage.reference)
    }

    commit('SET_CURRENT_COLOR_ID', parseInt(id))
  },
  /**
   * @param commit
   * @param {string} reference
   */
  setCurrentImageReference({ commit }, reference) {
    commit('SET_CURRENT_IMAGE', reference)
  },
  /**
   * @param commit
   * @param {string} side
   */
  setImageSideQuery({ commit }, side) {
    commit('SET_IMAGE_SIDE_QUERY', side)
  },
  /**
   * @param commit
   * @param {ApiFormattedCart|null} cart
   */
  setCart({ commit }, cart) {
    commit('SET_CART', cart)
  },
  /**
   * @param commit
   * @param {ApiFormattedCart|null} cart
   */
  setCartPrices({ commit }, cart) {
    commit('SET_CART_PRICES', cart)
  },
  /**
   * @param commit
   * @param {manufacturingJobUuid} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   * @param {BaseProductVariantId} baseProductVariantId
   * @param {string} colorId
   * @param {string} sizeId
   * @param {number} amount
   * @param {?boolean} append
   */
  addProductToJob({ commit, getters }, {
    manufacturingJobUuid,
    baseProductId,
    baseProductVariantId,
    amount,
    append = true
  }) {
    commit('ADD_PRODUCT_TO_JOB', {
      manufacturingJobUuid,
      baseProductId,
      baseProductVariantId,
      amount,
      append
    })
  },
  /**
   * @param commit
   * @param dispatch
   * @param {BaseProductId} baseProductId
   */
  removeProductFromCart({ state, dispatch }, baseProductId) {
    const manufacturingJobForProduct = findManufacturingJobForProductInGroup(state.cart, state.current_manufacturing_job_group_id, baseProductId)
    if (!manufacturingJobForProduct) return

    dispatch('removeProductFromJob', {
      manufacturingJobUuid: manufacturingJobForProduct.uuid,
      baseProductId
    })
  },
  /**
   * @param commit
   * @param state
   * @param {ApiFormattedManufacturingJobUuid} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   */
  removeProductFromJob({ commit, state }, { manufacturingJobUuid, baseProductId }) {
    commit('REMOVE_PRODUCT_FROM_JOB', { manufacturingJobUuid, baseProductId })
  },
  /**
   * @param state
   * @param dispatch
   * @param {BaseProductId} baseProductId
   * @param {string} baseProductVariantId
   */
  removeProductVariantFromCart({ state, dispatch }, {
    baseProductId,
    baseProductVariantId,
  }) {
    const manufacturingJobForProduct = findManufacturingJobForProductInGroup(state.cart, state.current_manufacturing_job_group_id, baseProductId)
    if (!manufacturingJobForProduct) return

    dispatch('removeProductVariantFromJob', {
      manufacturingJobUuid: manufacturingJobForProduct.uuid,
      baseProductId,
      baseProductVariantId
    })
  },
  /**
   * @param commit
   * @param {ApiFormattedManufacturingJobUuid|null} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   * @param {string} baseProductVariantId
   */
  removeProductVariantFromJob({ commit }, {
    manufacturingJobUuid = null,
    baseProductId,
    baseProductVariantId,
  }) {
    // TODO: Make sure to delete the product if the last variant is deleted
    //  and subsequently if we remove the last product of the job, delete the manufacturing job
    commit('REMOVE_CART_PRODUCT_VARIANT_FROM_JOB', { manufacturingJobUuid, baseProductId, baseProductVariantId })
  },
  /**
   * @param state
   * @param dispatch
   * @param {BaseProductId} baseProductId
   * @param {ApiFormattedVariant} variant
   */
  updateOrAddProductVariantInCurrentGroup({ state, dispatch }, {
    baseProductId,
    variant,
  }) {
    const manufacturingJobForProduct = findManufacturingJobForProductInGroup(state.cart, state.current_manufacturing_job_group_id, baseProductId)
    if (!manufacturingJobForProduct) return

    dispatch('updateOrAddProductVariantForJob', {
      manufacturingJobUuid: manufacturingJobForProduct.uuid || null,
      baseProductId,
      variant
    })
  },
  /**
   * @param commit
   * @param {ApiFormattedManufacturingJobUuid|null} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   * @param {ApiFormattedVariant} variant
   */
  updateOrAddProductVariantForJob({ commit }, {
    manufacturingJobUuid = null,
    baseProductId,
    variant,
  }) {
    if (!variant.color_id && !variant.size_id) {
      // Add missing size_id and color_id - those are needed sometimes
      const { size_id, color_id } = splitBaseProductVariantId(variant.baseproduct_variant_id)
      variant = {
        ...variant,
        size_id,
        color_id,
      }
    }

    commit('UPDATE_CART_PRODUCT_VARIANT_FOR_JOB', { manufacturingJobUuid, baseProductId, variant })
  },
  /**
   * @param state
   * @param dispatch
   * @param {BaseProductId} baseProductId
   * @param {ApiFormattedVariant[]} variants
   */
  setVariantsForProductInCurrentGroup({ state, dispatch }, {
    baseProductId,
    variants,
  }) {
    const manufacturingJobForProduct = findManufacturingJobForProductInGroup(state.cart, state.current_manufacturing_job_group_id, baseProductId)
    if (!manufacturingJobForProduct) return

    dispatch('setVariantsForProductInJob', {
      manufacturingJobUuid: manufacturingJobForProduct.uuid || null,
      baseProductId,
      variants
    })
  },
  /**
   * @param commit
   * @param {ApiFormattedManufacturingJobUuid|null} manufacturingJobUuid
   * @param {BaseProductId} baseProductId
   * @param {ApiFormattedVariant[]} variants
   */
  setVariantsForProductInJob({ commit }, {
    manufacturingJobUuid = null,
    baseProductId,
    variants,
  }) {
    variants = variants.map((variant) => {
      if (!variant.color_id && !variant.size_id) {
        // Add missing size_id and color_id - those are needed sometimes
        const { size_id, color_id } = splitBaseProductVariantId(variant.baseproduct_variant_id)
        return {
          ...variant,
          size_id,
          color_id,
        }
      }

      return variant
    })

    commit('SET_PRODUCT_VARIANTS_FOR_JOB', { manufacturingJobUuid, baseProductId, variants })
  },
  /**
   * @param commit
   * @param {ApiFormattedManufacturingJob|null} manufacturingJob
   */
  updateManufacturingJob({ commit }, manufacturingJob) {
    commit('UPDATE_MANUFACTURING_JOB', manufacturingJob)
  },
  /**
   * @param commit
   * @param {ApiFormattedManufacturingJob|null} manufacturingJob
   */
  addManufacturingJob({ commit }, manufacturingJob) {
    commit('ADD_NEW_MANUFACTURING_JOB', manufacturingJob)
  },
  /**
   * @param commit
   * @param {ApiFormattedManufacturingJobUuid|null} manufacturingJobUuid
   */
  removeManufacturingJob({ commit }, manufacturingJobUuid) {
    commit('REMOVE_MANUFACTURING_JOB', manufacturingJobUuid)
  },
  /**
   * @param commit
   * @param {ApiFormattedManufacturingJobGroupId} groupId
   */
  removeAllManufacturingJobsForGroup({ commit }, groupId) {
    commit('REMOVE_ALL_MANUFACTURING_JOBS_FOR_GROUP', groupId)
  },
  /**
   * @param commit
   */
  removeAllManufacturingJobs({ commit }) {
    commit('REMOVE_ALL_MANUFACTURING_JOBS')
  },
  /**
   * @param commit
   * @param {string|null} coupon_code
   */
  setCartCouponCode({ commit }, coupon_code) {
    commit('SET_CART_COUPON_CODE', coupon_code)
  },
  /**
   * @param commit
   * @param {string} country
   */
  setCartCountry({ commit }, country) {
    commit('SET_CART_COUNTRY', country)
  },
  checkoutCart({ state }, { formData }) {
    return submitCheckout(state, formData)
  },
  async validateOrderDetails({ state }) {
    return submitOrderDetails(state)
  },
  startPayment({ state }, { paymentData }) {
    return submitPayment(paymentData)
  },
  deleteCart({ commit, state }) {
    commit('DELETE_CART', state)
  },
  addAlert({ commit }, alert) {
    commit('ADD_ALERT', alert)
  },
  deleteAlert({ commit }, alert) {
    commit('DELETE_ALERT', alert)
  },
  setOrderDetails({ commit }, orderDetails) {
    commit('SET_ORDER_DETAILS', orderDetails)
  },
  deleteOrderDetails({ commit, state }) {
    commit('DELETE_ORDER_DETAILS', state)
  },
  markAsCheckedOut({ commit, state }) {
    commit('MARK_AS_CHECKED_OUT', state)
  },
  setIsMpc({ commit }, isMpc) {
    commit('SET_IS_MPC', isMpc)
  },
  /**
   * @param commit
   * @param {string[]} activePrintareas
   */
  setActiveCartPrintareas({ commit }, activePrintareas) {
    commit('SET_ACTIVE_CART_PRINTAREAS', activePrintareas)
  },
  setIsLoadingCart({ commit }, isLoadingCart) {
    commit('SET_IS_LOADING_CART', isLoadingCart)
  },
  setIsLoadingPrices({ commit }, isLoadingPrices) {
    commit('SET_IS_LOADING_PRICES', isLoadingPrices)
  },
  setIsSavingBaseDesign({ commit }, isSavingBaseDesign) {
    commit('SET_IS_SAVING_BASE_DESIGN', isSavingBaseDesign)
  },
}

export default actions

<template>
  <div class="overflow-scroll pb-5 lg:hidden -mx-4">
    <div class="flex space-x-box">
      <div
        class="shadow-box rounded-box flex-none cursor-pointer"
        :class="!currentCategory ? 'font-bold text-blue-50' : ''"

        @click="changeCategory(null)">
        <div class="py-2 px-4 bg-white rounded-box">
          {{ $t('views.catalog.all_products') }}
        </div>
      </div>
      <div
        v-for="(category, index) in productCategories"
        :key="index"
        class="shadow-box rounded-box flex-none cursor-pointer"
        :class="getCategoryKey(category.key) === currentCategory ? 'font-bold text-blue-50' : ''"
        @click="changeCategory(getCategoryKey(category.key))"
      >
        <div class="py-2 px-4 bg-white rounded-box flex gap-1">
          <product-category-icon
            :category="category"
            :active="getCategoryKey(category.key) === currentCategory"
          />
          {{ category.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProductCategoryIcon from 'sb/common/components/catalog/ProductCategoryIcon'
  export default {

    props: {
      productCategories: {
        type: Array,
      },
      currentCategory: {
        type: String
      }
    },

    methods: {
      getCategoryKey(key) {
        if (key.startsWith('creator')) {
          return key.slice(8)
        }

        return key.slice(9)
      },
      changeCategory(category) {
        this.$emit('change:category', category)
      },
    },

    components: {
      ProductCategoryIcon,
    },

  }
</script>

<style scoped>

</style>

<template>
  <div class="app auth">
    <header class="auth__header">
      <img
        class="auth__brand-logo"
        src="/images/shirtigo-id-logo-color.svg"
        alt="Shirtigo ID">
      <p class="auth__header-label">
        <span>
          {{ $t('shirtigo.auth.link_to_register.label') }}
        </span>
        <sg-link
          class="auth__header-link"
          to="/login#register">
          {{ $t('shirtigo.auth.link_to_register.action') }}
        </sg-link>
      </p>
    </header>

    <div class="auth__content-wrapper">
      <h1 class="auth__headline">
        {{ $t('shirtigo.auth.headline') }}
      </h1>

      <div v-if="!formDataSent">
        <p class="auth__body-text">
          {{ $t('shirtigo.auth.description') }}
        </p>

        <form
          class="auth__form"
          @submit.prevent="submitForm">
          <sg-form-input
            v-model="email"
            type="email"
            class="auth__form-input"
            class-name="input-lg"
            :placeholder="$t('shirtigo.auth.email_input_placeholder')"
            :value="email"
            autofocus
            required>
          </sg-form-input>

          <sg-btn
            type="submit"
            kind="reset"
            class="btn-lg">
            {{ $t('shirtigo.auth.primary_action') }}
          </sg-btn>

          <sg-link
            class="auth__login-link"
            to="/login">
            {{ $t('shirtigo.auth.secondary_action') }}
          </sg-link>
        </form>
      </div>

      <div v-if="formDataSent">
        <p class="auth__body-text">
          {{ $t('shirtigo.auth.email_sent') }}
        </p>
        <sg-link
          class="auth__login-link"
          to="/login">
          {{ $t('shirtigo.auth.secondary_action') }}
        </sg-link>
      </div>
    </div>

    <footer class="auth__footer-links">
      <a href="/imprint">
        {{ $t('shirtigo.auth.footer_nav.imprint') }}
      </a>
      <a href="/agb">
        {{ $t('shirtigo.auth.footer_nav.agb') }}
      </a>
      <a href="/privacy">
        {{ $t('shirtigo.auth.footer_nav.privacy') }}
      </a>
      <a href="/agb#widerrufsrecht">
        {{ $t('shirtigo.auth.footer_nav.withdrawal') }}
      </a>
    </footer>
  </div>
</template>

<script>
  import SgFormInput from '../elements/Form/SgFormInput.vue'
  import SgBtn from '../elements/Buttons/SgBtn.vue'
  import SgLink from '../common/components/SgLink.vue'

  export default {

    data() {
      return {
        email: '',
        formDataSent: false
      }
    },

    methods: {
      submitForm() {
        this.$emit('submit', { email: this.email })
        this.formDataSent = true
      }
    },

    components: {
      SgFormInput,
      SgBtn,
      SgLink,
    },

  }
</script>

import client from './client'

require('../typedefs')

export const uploadAssetFile = (file, options = {}) => {
  let formData = new FormData()
  formData.append('file', file)

  return client.post(`/api/campaign_asset/file`, formData, {}, options)
}

export const createAssetText = (text, options = {}) => {
  let formData = new FormData()
  formData.append('text', text)

  return client.post(`/api/campaign_asset/text`, formData, {}, options)
}

export const getDesignerFonts = () => {
  return client.get('/api/design/fonts')
}

/**
 * @param {ApiFormattedCart} cart
 * @param {ApiFormattedManufacturingJobUuid} manufacturingJobUuid
 * @return {Promise<ApiFormattedCart>}
 */
export const createDesignFromProcessingItems = (cart, manufacturingJobUuid) => {
  return client.post('/api/design/consolidate', {
    cart,
    manufacturing_job_uuid: manufacturingJobUuid || 0,
  })
}

<template>
  <div class="input-icon-group">
    <slot></slot>
    <a
      v-if="isVisible"
      href="#"
      class="input-icon"
      :title="title"
      @click.prevent="$emit('click')">
      <icon :fa="fa" />
    </a>
  </div>
</template>

<script>
  import Icon from 'sb/common/components/Icon.vue'

  export default {

    props: {
      title: String,
      isVisible: {
        type: Boolean,
        default: true
      },
      fa: String,
    },

    components: {
      Icon,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";

  .input-icon-group {
    position: relative;

    .input-icon {
      position: absolute;
      top: 0;
      right: .5em;
      bottom: 0;
      display: flex;
      align-items: center;
      font-size: 1.25em;
      color: $blue;

      &:hover,
      &:focus {
        color: $blue;
      }
    }
  }
</style>

<template>
  <div
    v-if="big"
    class="mb-8">
    <div class="uppercase text-sm tracking-4 text-gray-80 font-semibold">
      {{ attribute.type.data.name }}
    </div>
    <div class="mt-2">
      <div
        class="grid  gap-1"
        :class="`grid-cols-${totalAttributes}`">
        <div
          v-for="a in sortedAttributes"
          :key="a.sort_weight"
          :class="a.key === attribute.key ? 'bg-blue-50' : 'bg-gray-20'"
          class="h-2"></div>
      </div>
      <div class="flex justify-between text-xs text-gray-50 lowercase">
        <div>
          {{ lowestValue.name }}
        </div>
        <div>
          {{ highestValue.name }}
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex items-center">
      <div class="mr-3">
        -
      </div>
      <div
        class="grid gap-1 w-48 mr-3"
        :class="`grid-cols-${totalAttributes}`">
        <div
          v-for="a in sortedAttributes"
          :key="a.sort_weight"
          :class="a.key === attribute.key ? 'bg-blue-50' : 'bg-gray-20'"
          class="h-2"></div>
      </div>
      <div>
        +
      </div>
    </div>
  </div>
</template>

<script>
  export default {

    props: {
      attribute: {
        type: Object,
      },
      big: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      lowestValue() {
        return this.sortedAttributes[0]
      },
      highestValue() {
        return this.sortedAttributes[this.totalAttributes - 1]
      },
      totalAttributes() {
        return this.attribute.type.data.productAttributes.data.length
      },
      sortedAttributes() {
        let attributes = JSON.parse(
          JSON.stringify(
            this.attribute.type.data.productAttributes.data
          )
        )
        return attributes.sort((a, b) => a.sort_weight - b.sort_weight)
      }
    },

  }
</script>

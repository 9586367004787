<template>
  <header>
    <nav class="z-50 bg-white border-b border-grey-10">
      <header-meta-bar v-if="showMetaBar" />

      <div class="px-4 flex justify-between items-center max-w-container m-auto">
        <a
          href="/"
          class="block mr-6"
        >
          <img
            src="/img/Logo_Shirtigo_WEB.svg"
            alt="Shirtigo"
            class="h-10 hidden xs:block lg:hidden xl:block"
          />

          <img
            src="/img/Logo_Shirtigo_Bildmarke_RGB.svg"
            alt="Shirtigo"
            class="h-10 xs:hidden lg:block xl:hidden"
          />
        </a>

        <!-- desktop menu -->
        <div
          class="hidden lg:flex lg:items-center max-w-96 flex-1 h-full font-bold text-lg text-dark-blue tracking-normal"
        >
          <div
            class="mx-1 my-4 py-2 px-3 h-full table hover:bg-blue-00 hover:text-blue-50 transform duration-200 ease-in-out"
          >
            <router-link :to="{ name: 'catalog-index' }">
              {{ $t('navigation.header.catalog_index') }}
            </router-link>
          </div>

          <div
            class="mx-1 my-4 py-2 px-3 h-full table hover:bg-blue-00 hover:text-blue-50 transform duration-200 ease-in-out"
          >
            <router-link :to="{ name: 'single-product-designer' }">
              {{ $t('navigation.header.product_designer') }}
            </router-link>
          </div>

          <div
            class="mx-1 my-4 py-2 px-3 h-full table hover:bg-blue-00 hover:text-blue-50 transform duration-200 ease-in-out"
          >
            <router-link :to="{ name: 'mpc-calculator' }">
              {{ $t('navigation.header.mpc_calculator') }}
            </router-link>
          </div>

          <div
            class="mx-1 my-4 py-2 px-3 h-full table hover:bg-blue-00 hover:text-blue-50 transform duration-200 ease-in-out"
          >
            <a href="https://cockpit.shirtigo.com/">
              {{ $t('navigation.header.cockpit') }}
            </a>
          </div>
        </div>

        <!-- mobile menu -->
        <div
          class="lg:hidden flex-1 font-bold text-lg absolute bg-white shadow-2xl right-0 w-full z-10"
          :class="{hidden: !openMenu}"
          style="top: 70px"
        >
          <div class="px-6 border-b w-full border-gray-20 h-16 table hover:text-blue-50">
            <router-link
              :to="{ name: 'catalog-index' }"
              class="table-cell align-middle"
            >
              {{ $t('navigation.header.catalog_index') }}
            </router-link>
          </div>

          <div class="px-6 border-b w-full border-gray-20 h-16 table hover:text-blue-50">
            <router-link
              :to="{ name: 'single-product-designer', params: { id: 1 } }"
              class="table-cell align-middle"
            >
              {{ $t('navigation.header.product_designer') }}
            </router-link>
          </div>

          <div class="px-6 border-b w-full border-gray-20 h-16 table hover:text-blue-50">
            <router-link
              :to="{ name: 'mpc-calculator' }"
              class="table-cell align-middle"
            >
              {{ $t('navigation.header.mpc_calculator') }}
            </router-link>
          </div>

          <div class="px-6 border-b w-full border-gray-20 h-16 table hover:text-blue-50">
            <a
              class="table-cell align-middle"
              href="https://cockpit.shirtigo.com/"
            >
              {{ $t('navigation.header.cockpit') }}
            </a>
          </div>
        </div>

        <div class="flex items-center">
          <a
            class="mr-4 h-full hidden xs:block"
            href="https://www.shirtigo.de/stanley-stella-nachhaltig-fair-produziert-shirtigo?&cat=collection"
          >
            <img
              src="/img/ss-official-dealer.svg"
              class="h-13"
            />
          </a>

          <img
            src="/img/nachhaltigkeit.svg"
            class="h-13 mr-4 hidden xs:block"
          />

          <a
            class="text-blue-50 text-xs text-center cursor-pointer mx-1.5"
            href="/cart"
          >
            <i class="icon-shopping-bag-outline text-h7 block" />

            {{ $t('navigation.header.cart') }}
          </a>

          <div
            class="lg:hidden ml-8 text-blue-50 text-xs text-center cursor-pointer py-3"
            @click="openMenu = !openMenu"
          >
            <i class="icon-menu-outline text-2.5xl block" />

            {{ $t('navigation.header.menu') }}
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
  import HeaderMetaBar from '@/components/layout/header/HeaderMetaBar.vue'

  export default {

    props: {
      showMetaBar: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        openMenu: false,
      }
    },

    components: { HeaderMetaBar },

  }
</script>

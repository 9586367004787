<template>
  <div class="lg:flex xl:w-full mt-10 ">
    <div class="flex lg:w-1/3 mb-6">
      <div class="mr-3 flex text-green-50 text-2xl">
        <span class="icon-globe-outline"></span>
      </div>
      <div>
        <div class="text-lg font-bold text-blue-80 mb-2">
          {{ $t('views.checkout.benefits.headline_1') }}
        </div>
        <span class="text-gray-70">{{ $t('views.checkout.benefits.text_1') }}</span>
      </div>
    </div>
    <div class="flex lg:w-1/3 mb-6">
      <div class="mr-3 flex text-green-50 text-2xl">
        <span class="icon-car-outline"></span>
      </div>
      <div>
        <div class="text-lg font-bold text-blue-80 mb-2">
          {{ $t('views.checkout.benefits.headline_2') }}
        </div>
        <span class="text-gray-70">{{ $t('views.checkout.benefits.text_2') }}</span>
      </div>
    </div>
    <div class="flex lg:w-1/3 mb-6">
      <div class="mr-3 flex text-green-50 text-2xl">
        <span class="icon-phone-outline"></span>
      </div>
      <div>
        <div class="text-lg font-bold text-blue-80 mb-2">
          {{ $t('views.checkout.benefits.headline_3') }}
        </div>
        <span class="text-gray-70">{{ $t('views.checkout.benefits.text_3') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BenefitsFooter'
  }
</script>

import { createApp } from './app'

const { app, router, store } = createApp({ app: window.__APP__ })

if (window.__INITIAL_STATE__) {
  // We initialize the store state with the data injected from the server
  // however, we want to just replace keys that are present in the initial state
  // everything else should be copied over from localStorage, so we'll merge both
  store.replaceState({ ...store.state, ...JSON.parse(window.__INITIAL_STATE__) })
}

router.onReady(() => {
  app.$mount('#app', true)
})

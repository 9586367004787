<template>
  <div class="overflow-x-hidden overflow-y-auto max-h-80s lg:min-w-modal">
    <h2 class="text-2xl font-bold tracking-1 flex items-center mb-6">
      {{ $t('views.calculator.select_products_dialog.headline') }}
    </h2>

    <div class="md:flex">
      <div class="flex-1 mb-3 md:mb-0">
        <label
          for="category"
          class="block mb-2"
        >
          {{ $t('views.calculator.select_products_dialog.category') }}
        </label>

        <select
          id="category"
          v-model="selectedCategory"
          class="block w-full bg-shirtigo-select-gradient border border-grey-30 hover:border-grey-50 px-4 py-2 pr-8 rounded-sm shadow-sm leading-tight focus:outline-none"
        >
          <option
            v-for="category in categories"
            :key="category"
          >
            {{ category }}
          </option>
        </select>
      </div>

      <div
        v-if="false"
        class="flex-1 md:pl-2"
      >
        <label
          for="sort"
          class="block mb-2"
        >
          {{ $t('views.calculator.select_products_dialog.sort_by') }}
        </label>

        <select
          id="sort"
          v-model="selectedOrder"
          class="block w-full bg-shirtigo-select-gradient border border-grey-30 hover:border-grey-50 px-4 py-2 pr-8 rounded-sm shadow-sm leading-tight focus:outline-none"
        >
          <option value="1">
            {{ $t('views.calculator.select_products_dialog.popularity') }}
          </option>
        </select>
      </div>
    </div>

    <hr
      class="border-t border-grey-20 mt-12"
      style="margin-left: -2.5rem; margin-right: -2.5rem"
    >

    <div class="mt-8 md:flex md:flex-wrap">
      <select-product-tile
        v-for="product in productsForCategory"
        :key="product.id"
        :product="product"
        :current-product="currentBaseProduct"
        :available-colors="currentBaseProduct.variants.data.map(color => ({
          id: color.id,
          color_name: color.color_name,
          hexcode: color.hexcode,
        }))"
        @close="$emit('close')"
        @select-product="$emit('select-product', $event)"
      />

      <div
        v-if="productsForCategory.length === 0"
        class="ml-4"
      >
        {{ $t('views.calculator.select_products_dialog.no_products_for_category') }}
      </div>
    </div>

    <div class="text-gray-50 text-sm mt-6">
      {{ $t('views.calculator.select_products_dialog.caption') }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SelectProductTile from '@/components/config/productSelect/SelectProductTile'

  export default {

    data() {
      return {
        selectedCategory: null,
        selectedOrder: 1
      }
    },

    mounted() {
      this.selectedCategory = this.categories[0]

      if (typeof window !== 'undefined') {
        window.addEventListener('keydown', this.handleKeydown, {once: true, capture: true})
      }
    },

    beforeDestroy() {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', this.handleKeydown)
      }
    },

    methods: {
      /**
       * @param {BaseProduct[]} baseProducts
       * @return {BaseProduct[]}
       */
      filterProductsNotInCart(baseProducts) {
        if (!this.cart) return baseProducts

        // Only products that haven't already been added to the cart
        return baseProducts.filter(({ id }) => !this.allCartProductsForCurrentGroup.some(({ base_product_id }) => base_product_id === id))
      },
      /**
       * @param {BaseProduct[]} baseProducts
       * @return {BaseProduct[]}
       */
      filterProductsInSelectedCategory(baseProducts) {
        if (!this.selectedCategory) return baseProducts

        // Only products of current category
        return baseProducts.filter((baseProduct) => {
          return baseProduct.attributes.data.find(({ name }) => name === this.selectedCategory)
        })
      },
      /**
       * @param {KeyboardEvent} event
       */
      handleKeydown(event) {
        if (event.key === 'Escape') {
          event.stopPropagation()
          this.$emit('close')
        }
      }
    },

    computed: {
      /** @return {string[]} */
      categories() {
        if (!this.baseProducts) return []

        /** @type {BaseProductAttribute[]} */
        const attributes = this.baseProducts.map((/** BaseProduct */ baseProduct) => baseProduct.attributes.data.find((/** BaseProductAttribute */{ type_key }) => type_key === 'category'))
        return attributes.reduce((categories, { name }) => {
          if (!categories.includes(name)) {
            return [ ...categories, name ]
          }
          return categories
        }, [])
      },
      /** @return {BaseProduct[]} */
      productsForCategory() {
        return this.filterProductsNotInCart(this.filterProductsInSelectedCategory(this.baseProducts || []))
      },
      ...mapGetters([
        'cart',
        'allCartProductsForCurrentGroup',
        'baseProducts',
        'currentBaseProduct'
      ])
    },

    components: {
      SelectProductTile
    }

  }
</script>

<template>
  <div
    v-if="baseProduct"
    class="mt-4 pb-4 border-b border-gray-20 last:border-b-0"
  >
    <div class="lg:flex">
      <design-preview
        class="flex justify-start mt-3 w-1/2 md:w-1/6 mb-4"
        :base-product="baseProduct"
        :previews="previews"
        :current-color-id="parseInt(product.variants[0].color_id, 10)"
      />

      <div
        class="lg:pl-4 lg:w-5/12"
      >
        <div class="text-lg text-blue-80 mb-1 font-bold lg:mt-4">
          {{ $t('views.cart.product_group.product_title_incl_print', {amount, name: baseProduct.name}) }}
        </div>

        <div class="text-sm text-grey-50 pr-6">
          <p>{{ baseProduct.short_description }}</p>

          <span
            v-for="processingarea_type_key in validPrintareaKeys"
            :key="processingarea_type_key"
            class="text-sm text-grey-50 block"
          >
            <span class="font-bold text-grey-50">
              {{ printareaNameForKey(processingarea_type_key) }}:
            </span>

            <span class="text-sm lg:leading-6">
              {{ $t('views.cart.product_group.printing_process.digital') }}
            </span>
          </span>

          <button
            class="my-1 text-blue-50 hover:text-blue-60 transition-colors duration-200"
            @click.prevent="showDetails = !showDetails"
          >
            {{ $t('views.cart.product_group.colors_and_sizes') }}

            <i
              v-if="!showDetails"
              class="icon-chevron-down-outline"
            />
            <i
              v-else
              class="icon-chevron-up-outline"
            />
          </button>
        </div>
      </div>

      <div class="text-sm text-blue-90 lg:w-1/6 lg:text-lg lg:pt-4 lg:text-right flex justify-end items-start">
        <span class="font-bold text-grey-50 mr-2 lg:hidden">{{ $t('views.cart.product_group.price_per_item') }}:</span>
        <span v-if="product.avg_item_price_gross">
          {{ product.avg_item_price_gross | currency }}
        </span>
        <loader
          v-else
          color="blue-50"
          class="w-6 justify-end"
        />
      </div>

      <div class="text-sm text-blue-90 lg:w-1/6 lg:text-lg lg:pt-4 lg:text-right flex justify-end items-start">
        <span class="font-bold text-grey-50 mr-2 lg:hidden">{{ $t('views.cart.product_group.total_price') }}:</span>
        <span v-if="product.sum_item_price_gross">
          {{ product.sum_item_price_gross | currency }}
        </span>
        <loader
          v-else
          color="blue-50"
          class="w-6"
        />
      </div>

      <div class="lg:ml-3 lg:w-1/12 lg:pt-2 flex gap-3 justify-end">
        <icon-button
          v-if="!readonly"
          size-class="h-11 w-11 text-xl"
          color-class="text-blue-50 border-transparent hover:border-blue-60"
          :title="$t('views.cart.product_group.edit_product')"
          :disabled="loading"
          @click="$emit('edit-product', product.base_product_id)"
        >
          <i class="icon icon-edit-outline" />
        </icon-button>

        <icon-button
          v-if="!readonly"
          size-class="h-11 w-11 text-xl"
          color-class="text-blue-50 border-transparent hover:text-red-50 hover:border-red-400"
          :title="$t('views.cart.product_group.remove_product')"
          :disabled="loading"
          @click="remove()"
        >
          <i class="icon icon-trash-2-outline" />
        </icon-button>
      </div>
    </div>

    <transition name="fade">
      <div v-if="showDetails">
        <config-product-color-table-mobile
          class="lg:hidden mt-1 mb-4"
          :base-product="baseProduct"
          :product="product"
          :loading="loading"
          :read-only="readonly"
          @updateAmount="updateSizeAmountForProduct"
          @removeColor="removeAllVariantsForColor"
        />

        <config-product-color-table-desktop
          class="hidden lg:block mt-2 mb-0"
          :base-product="baseProduct"
          :product="product"
          :loading="loading"
          :read-only="readonly"
          @updateAmount="updateSizeAmountForProduct"
          @removeColor="removeAllVariantsForColor"
        />
      </div>
    </transition>

    <confirm-modal
      v-if="showRemoveAlert"
      @confirm="removeConfirmed()"
      @close="showRemoveAlert = false"
    >
      <strong>{{ $t('views.cart.product_group.remove_product_confirm') }}</strong>
    </confirm-modal>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { PropOptions } from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import ConfirmModal from '../../layout/ConfirmModal'
  import ConfigProductColorTableMobile from '../colors/ConfigProductColorTableMobile'
  import ConfigProductColorTableDesktop from '../colors/ConfigProductColorTableDesktop'
  import DesignPreview from '../sizes/DesignPreview'
  import IconButton from '../../buttons/IconButton.vue'
  import _debounce from 'lodash/debounce'
  import Loader from '@/components/layout/Loader.vue'

  export default {

    props: {
      readonly: {
        type: Boolean,
        default: false
      },
      /** @type {PropOptions<ApiFormattedProduct>} */
      product: {
        type: Object,
        required: true
      },
      /** @type {PropOptions<ApiFormattedProcessing[]>} */
      processings: {
        type: Array,
        required: true,
      },
      /** @type {PropOptions<ManufacturingJobPreview[]>} */
      previews: {
        type: Array,
        required: true,
      },
      loading: {
        type: Boolean,
        defaultValue: false,
      }
    },

    data() {
      return {
        showDetails: false,
        showRemoveAlert: false
      }
    },

    methods: {
      removeConfirmed() {
        this.$emit('remove-product', this.baseProduct.id)
      },
      remove() {
        this.showRemoveAlert = true
      },
      /**
       * @param {string} processingarea_type_key
       * @return {string}
       */
      printareaNameForKey(processingarea_type_key) {
        const printarea = this.baseProduct.printareas.data.find(({ key }) => key === processingarea_type_key)
        if (printarea) return printarea.name
        return ''
      },
      /**
       * @param {string} colorId
       */
      removeAllVariantsForColor(colorId) {
        this.product.variants
          .map((variant) => variant.baseproduct_variant_id)
          .filter((id) => id.match(new RegExp(`\\d+\\.${colorId}\\.\\d+`)))
          .forEach((baseProductVariantId) => {
            this.removeProductVariantFromCart({
              baseProductId: this.baseProduct.id,
              baseProductVariantId,
            })
          })

        this.sendUpdateEvent()
      },
      /**
       * @param {string} amount
       * @param {string} baseproduct_variant_id
       */
      updateSizeAmountForProduct(amount, baseproduct_variant_id) {
        if (parseInt(amount) === 0) {
          this.remove()
          return
        }

        this.updateOrAddProductVariantInCurrentGroup({
          baseProductId: this.baseProduct.id,
          variant: {
            amount: parseInt(amount) || 0,
            baseproduct_variant_id,
          },
        })

        this.sendUpdateEvent()
      },
      sendUpdateEvent: _debounce(function () {
        this.$emit('update')
      }, 500),
      ...mapActions([
        'setCurrentManufacturingJobGroupId',
        'removeProductVariantFromCart',
        'updateOrAddProductVariantInCurrentGroup',
      ]),
    },

    computed: {
      amount() {
        if (!this.product) return 0

        if (this.product.amount) {
          return this.product.amount
        }

        return this.product.variants.reduce((sum, { amount }) => sum + amount, 0)
      },
      baseProduct() {
        return this.baseProducts.find(({ id }) => this.product.base_product_id === id)
      },
      validPrintareaKeys() {
        if (!this.processings) return []

        const printareaKeys = []

        this.processings.forEach(({ processing_items, processingarea_type_key }) => {
          if (processing_items.data.length && !printareaKeys.includes(processingarea_type_key)) {
            printareaKeys.push(processingarea_type_key)
          }
        })

        return printareaKeys.sort(({ processingarea_type_key }) => processingarea_type_key === 'front' ? -1 : 1)
      },
      ...mapGetters([
        'baseProducts',
        'allCartProcessingsForCurrentGroup',
        'cart'
      ])
    },

    components: {
      Loader,
      IconButton,
      DesignPreview,
      ConfirmModal,
      ConfigProductColorTableMobile,
      ConfigProductColorTableDesktop
    }

  }
</script>

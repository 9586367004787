<template>
  <button
    :title="title"
    :class="[styling, { active, dark }]"
    :disabled="disabled ? 'disabled' : false"
    :style="`background-color: #${color}`"
    @click.prevent="$emit('click', $event)"
  />
</template>

<script>
  export default {

    props: {
      title: String,
      color: String,
      active: Boolean,
      dark: Boolean,
      small: Boolean,
      disabled: Boolean,
    },

    data() {
      return {
        styling: 'color-swatch mr-3 mb-3'
      }
    },

    created() {
      if (this.small) {
        this.styling = 'color-swatch mr-2 mb-2'
      }
    }

  }
</script>

<style lang="scss">
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .color-swatch {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    margin-bottom: 12px;
    float: left;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-style: solid;
    border-width: 3px;
    border-color: #fff;
    border-radius: 50px;
    background-color: #fafafa;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 12px;
    line-height: 3px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 0 1px #039be5;
    }

    &:focus {
      box-shadow: $color-swatch-shadow, $focus-outline;
    }

    &.active {
      box-shadow: 0 0 0 2px #039be5;
    }
  }

  .color-swatch--button {
    border: 1px dashed #ced7db;
    box-shadow: none;
  }
</style>

<template>
  <div></div>
</template>

<script>
  export default {
    props: {
      designerFonts: {
        type: Array,
        required: true
      }
    },
    mounted() {
      this.designerFonts.forEach(font => {
        let fontStyle = document.createElement('link')
        fontStyle.setAttribute('rel', 'stylesheet')
        fontStyle.setAttribute('crossorigin', 'anonymous')
        fontStyle.setAttribute('href', font.base_path + '/font.css')
        document.head.appendChild(fontStyle)
      })
    },
  }
</script>

<style scoped>

</style>

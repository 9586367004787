import { getPrices, saveCart } from '@/api/calculation'
import { mapActions, mapGetters } from 'vuex'
import { getBaseProductVariantId } from '@/utils/helpers'
import { format } from 'date-fns/format'
import axios from 'axios'

export default {

  data: () => ({
    saveCartAbortController: new AbortController(),
    loadPricesAbortController: new AbortController(),
  }),

  mounted() {
    this.setIsLoadingCart(false)
    this.setIsLoadingPrices(false)
  },

  methods: {
    /**
     * @return {Promise<ApiFormattedCart>}
     */
    async updateCart() {
      this.$Progress.start()

      if (!this.cart) {
        this.$Progress.fail()
        return Promise.reject(new Error('Cart is empty'))
      }

      if (this.isLoadingCart) {
        this.$Progress.start()
        this.saveCartAbortController.abort()
        this.saveCartAbortController = new AbortController()
      }

      this.setIsLoadingCart(true)

      return new Promise((resolve, reject) => {
        saveCart(Object.freeze(this.cart), true, false, this.currentManufacturingJobUuid, this.saveCartAbortController.signal)
          .then(([
            updatedCart,
            currentManufacturingJobUuid,
            currentManufacturingJobGroupId
          ]) => {
            this.setIsLoadingCart(false)
            this.setIsLoadingPrices(false)

            this.setCart(updatedCart)

            if (currentManufacturingJobUuid !== this.currentManufacturingJobUuid) {
              this.setCurrentManufacturingJobUuid(currentManufacturingJobUuid)
              this.setCurrentManufacturingJobGroupId(currentManufacturingJobGroupId)
            }

            this.$Progress.finish()
            resolve(updatedCart)
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              this.$Progress.fail()
              this.setIsLoadingCart(false)
              reject(error)
            }
          })
      })
    },
    /**
     * @param {ApiFormattedCart} cart
     * @param {ApiFormattedProduct} product
     * @param {number} totalProductCount
     * @return {[{variants: ApiFormattedVariant[], base_product_id: (number), created_at: string}]}
     */
    createDummyProducts(cart, product, totalProductCount) {
      const baseProduct = this.baseProducts.find(({ id }) => id === product.base_product_id)
      const firstColor = baseProduct.variants.data[0]
      const firstSize = firstColor.size_variants.data[0]

      return [ {
        base_product_id: product.base_product_id,
        created_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        variants: [ /** @type ApiFormattedVariant */{
          baseproduct_variant_id: getBaseProductVariantId(product.base_product_id, firstColor.id, firstSize.size_id),
          amount: totalProductCount,
          color_id: firstColor.id,
          size_id: firstSize.size_id,
        } ]
      } ]
    },
    /**
     * @param {?boolean} generateThumbnail
     * @return {Promise<ApiFormattedCart>}
     */
    async updatePrice(generateThumbnail = true, preserveManufacturingJobs = false) {
      this.$Progress.start()

      if (!this.cart) {
        this.$Progress.fail()
        return Promise.reject(new Error('Cart is empty'))
      }

      if (this.isLoadingPrices) {
        this.$Progress.start()
        this.loadPricesAbortController.abort()
        this.loadPricesAbortController = new AbortController()
      }

      this.setIsLoadingPrices(true)

      return new Promise((resolve, reject) => {
        getPrices(Object.freeze(this.cart), generateThumbnail, this.currentManufacturingJobUuid, this.loadPricesAbortController.signal)
          .then(([
            updatedCart,
            currentManufacturingJobUuid,
            currentManufacturingJobGroupId
          ]) => {
            this.setIsLoadingPrices(false)

            if (updatedCart) {
              if (preserveManufacturingJobs) {
                this.setCartPrices(updatedCart)
              } else {
                this.setCart(updatedCart)
              }
            }

            if (currentManufacturingJobUuid !== this.currentManufacturingJobUuid) {
              this.setCurrentManufacturingJobUuid(currentManufacturingJobUuid)
              this.setCurrentManufacturingJobGroupId(currentManufacturingJobGroupId)
            }

            this.$Progress.finish()
            resolve(updatedCart)
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              this.$Progress.fail()
              this.setIsLoadingPrices(false)
              reject(error)
            }
          })
      })
    },
    /**
     * @param {ApiFormattedManufacturingJobGroupId} manufacturingJobGroupId
     * @returns {ManufacturingJobPriceItem | null}
     */
    priceForGroup(manufacturingJobGroupId) {
      if (!this.cart) return null

      const vatRate = parseFloat(this.cart.vat_rate) || 0;

      /** @type ManufacturingJobPriceItem */
      let priceObjectForGroup = {
        total: 0,
        discount: 0,
        vat_rate: vatRate,
        vat: 0,
        products: {}
      }

      this.cart.manufacturing_jobs.data.forEach(manufacturingJob => {
        if (manufacturingJob.group_id !== manufacturingJobGroupId) return

        const vatRatePercent = vatRate / 100
        const vatForJob = Math.round(manufacturingJob.sum_item_price_gross / (1 + vatRatePercent) * vatRatePercent)

        /** @type ManufacturingJobPriceItem */
        let priceObjectForJob = {
          total: manufacturingJob.sum_item_price_gross || 0,
          discount: manufacturingJob.discount || 0,
          vat_rate: vatRate || 0,
          vat: vatForJob || 0
        }

        // Calculate the sum of all manufacturing jobs contained in the group
        priceObjectForGroup.vat = priceObjectForGroup.vat + priceObjectForJob.vat
        priceObjectForGroup.total = priceObjectForGroup.total + priceObjectForJob.total
        priceObjectForGroup.discount = priceObjectForGroup.discount + priceObjectForJob.discount
      })

      return priceObjectForGroup
    },
    ...mapActions([
      'setCart',
      'setCartPrices',
      'setIsLoadingCart',
      'setIsLoadingPrices',
      'setCurrentManufacturingJobUuid',
      'setCurrentManufacturingJobGroupId',
    ])
  },

  computed: {
    ...mapGetters([
      'baseProducts',
      'isLoadingCart',
      'isLoadingPrices',
      'currentManufacturingJobUuid',
      'cart',
    ])
  }

}

import getters from './getters.js'
import actions from './actions.js'
import mutations from './mutations.js'

const state = {
  brands: [],
  genders: [],
  catalog_products: {},
  product_categories: [],
  product_colors: [],
  catalog_product: null
}

export default {
  state,
  getters,
  actions,
  mutations
}

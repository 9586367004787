<template>
  <section class="product-info__print-details">
    <sg-table>
      <thead>
        <tr>
          <th>Druckfläche</th>
          <th>Max. Druckbreite (B)</th>
          <th>Max. Druckhöhe (H)</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="area in baseProduct.processing_areas"
          :key="area.position_id">
          <th>{{ area.position_name }}</th>
          <td>{{ area.width_mm / 10 }}cm</td>
          <td>{{ area.height_mm / 10 }}cm</td>
        </tr>
      </tbody>
    </sg-table>
  </section>
</template>

<script>
  import SgTable from 'sb/elements/Layout/SgTable.vue'

  export default {

    props: {
      baseProduct: {
        type: Object,
        required: true,
      },
    },

    components: {
      SgTable,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .product-info__print-details {

  }
</style>

<template>
  <div>
    <template v-if="isLoading">
      Loading...
    </template>

    <mockup-preview
      v-else
      :color="color"
      :printarea="printarea"
      :scale="scale"
      :processing-items="processingItems"
      :designer-fonts="designerFonts"
      :mockup="mockup"
      :loading="loadingMockup"
      @processing-items-updated="$emit('processing-items-updated', $event)"
      @processing-item-deleted="$emit('processing-item-deleted', $event)"
      @validity-changed="$emit('validity-changed', $event)"
      @processing-item-click="selectProcessingItem"
      @processing-item-edit="editProcessingItem"
      @processing-item-deselected="selectedProcessingItem = null"
    >
      <slot />
    </mockup-preview>

    <designer-font-loader :designer-fonts="designerFonts" />
  </div>
</template>

<script>
  import MockupPreview from '../../elements/Designer/MockupPreview'
  import DesignerFontLoader from '../../elements/Designer/DesignerFontLoader'

  export default {

    props: {
      /** @type {PropOptions<DesignerFontFamily[]>} */
      designerFonts: {
        type: Array,
        required: true
      },
      /** @type {PropOptions<ApiFormattedProcessingItem[]>} */
      processingItems: {
        type: Array,
        required: true
      },
      /** @type {PropOptions<BaseProductPrintarea>} */
      printarea: {
        type: Object,
        required: true
      },
      /** @type {PropOptions<BaseProductColorVariant|null>} */
      color: {
        type: Object,
        required: true
      },
      scale: {
        type: Number,
        default: 1,
        required: false
      },
      mockup: {
        type: Object,
        required: true
      },
      loadingMockup: {
        type: Boolean,
        default: false,
        required: false,
      },
    },

    data() {
      return {
        isLoading: false,
        selectedProcessingItem: null
      }
    },

    methods: {
      editProcessingItem(processingItem) {
        if (this.selectedProcessingItem === processingItem && processingItem && processingItem.type === 'text') {
          // Only register a click if it has already been selected, and if the element is of type text
          this.$emit('processing-item-click', processingItem)
        }

        this.$emit('processing-item-edit', processingItem)
        this.selectedProcessingItem = processingItem
      },
      selectProcessingItem(processingItem) {
        this.$emit('processing-item-click', processingItem)
        this.selectedProcessingItem = processingItem
      }
    },

    watch: {
      printarea() {
        this.selectedProcessingItem = null
      }
    },

    components: { DesignerFontLoader, MockupPreview }

  }
</script>

<template>
  <router-link
    v-if="$useRouter"
    :to="to">
    <slot></slot>
  </router-link>
  <a
    v-else
    :href="to">
    <slot></slot>
  </a>
</template>

<script>
  export default {

    props: {
      to: {
        type: [String, Object],
        required: true
      },
    },

  }
</script>

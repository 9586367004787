<template>
  <div class="flex flex-col-reverse lg:grid lg:grid-cols-4 lg:gap-6">
    <div class="px-4 sm:px-6 lg:px-8 col-span-3">
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden ring-1 ring-black ring-opacity-5">
              <table class="min-w-full divide-y divide-gray-300 border">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="p-3 text-sm font-bold text-gray-90 uppercase">
                    </th>
                    <th
                      scope="col"
                      class="p-3 text-sm font-bold text-gray-90 uppercase tracking-1">
                      {{ $t('views.product_details.size_chart.width') }}
                    </th>
                    <th
                      scope="col"
                      class="p-3 text-sm font-bold text-gray-90 uppercase tracking-1">
                      {{ $t('views.product_details.size_chart.height') }}
                    </th>
                    <th
                      v-if="hasSleve"
                      scope="col"
                      class="p-3 text-sm font-bold text-gray-90 uppercase tracking-1">
                      {{ $t('views.product_details.size_chart.sleeve_length') }}
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="size in catalogProduct.sizings.data"
                    :key="size.size">
                    <td class="text-sm font-bold text-gray-50 text-center p-3">
                      {{ size.size }}
                    </td>
                    <td class="text-center text-gray-80 p-3">
                      {{ size.sizing_width_mm / 10 }} cm
                    </td>
                    <td class="text-center text-gray-80 p-3">
                      {{ size.sizing_height_mm / 10 }} cm
                    </td>
                    <td
                      v-if="hasSleve"
                      class="text-center text-gray-80 p-3">
                      {{ size.sizing_sleeve_mm / 10 }} cm
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex lg:block">
      <span v-html="svgImage"></span>
    </div>
  </div>
</template>

<script>

  export default {

    props: {
      catalogProduct: {
        type: Object,
      },
    },

    data() {
      return {
        svgImage: null
      }
    },

    created() {
      let sizeChartImage = this.catalogProduct.attributes.data.find(a => a.type.data.key === 'size-chart-image')

      if (sizeChartImage) {
        this.getTemplateSVG(sizeChartImage.image_url, true)
      } else {
        this.getTemplateSVG('/img/sizechart-tshirt.svg')
      }
    },

    methods: {
      getTemplateSVG(path, addViewport = false) {
        fetch(path).then(response => response.text())
          .then(svg => {
            if (addViewport) {
              svg = svg.replace('<svg>', '<svg viewBox="0 0 147.7 137.3">')
            }

            this.svgImage = svg
          })
      }
    },

    computed: {
      hasSleve() {
        if (!this.catalogProduct) {
          return false
        }

        return !!this.catalogProduct.sizings.data.find(s => {
          return !!s.sizing_sleeve_mm
        })
      }
    },
  }
</script>

<template>
  <div class="sg-select-wrapper">
    <select
      :id="id"
      :class="['sg-select', { 'has-danger': hasDanger, 'input-lg': size === 'lg' }]"
      :name="name"
      :disabled="disabled"
      :required="required"
      :tabindex="tabindex"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
      @blur="$emit('blur')"
      @change="$emit('input', $event.target.value)">
      <option
        v-for="(val, key) in options"
        :key="key"
        :value="key"
        :selected="{ selected: key === value }">
        {{ val }}
      </option>
    </select>
  </div>
</template>

<script>
  export default {

    props: {
      id: String,
      value: String,
      options: {
        type: Object,
        required: true,
      },
      name: String,
      disabled: Boolean,
      required: Boolean,
      tabindex: Number,
      autofocus: Boolean,
      autocomplete: {
        type: String,
        default: 'on'
      },
      hasDanger: Boolean,
      size: String,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .sg-select {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    border: 1px solid $grey-30;
    border-radius: 3px;
    box-shadow: inset 1px 0 3px 0 rgba(0, 0, 0, .05);
    font-size: 1rem;
    font-family: $nunito-sans;
    padding: .75rem;
    transition: .2s ease;
    background: $white;

    &-wrapper {
      position: relative;

      &::after {
        content: "\f0dc";
        @include fa;
        color: $grey-70;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: .75rem;
        bottom: 0;
        pointer-events: none;
      }
    }

    &:focus {
      border-color: $blue-50;
      outline: none;
    }

    &.has-danger {
      border-color: $red !important;
    }

    &.input-lg {
      height: 3.25rem;
      padding: .875rem 1rem;
      border-radius: 5px;
    }
  }
</style>

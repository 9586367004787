/* eslint-disable camelcase */

import client from './client'
import { getBaseProductVariantId } from '@/utils/helpers'
import { format } from 'date-fns/format'

require('../typedefs')

/**
 * @param {string} url
 * @param {ApiFormattedCart} cart
 * @param {?ApiFormattedManufacturingJobUuid|0} currentManufacturingJobUuid
 * @param {Record<string, unknown>} additionalData
 * @param {?AbortSignal} signal
 * @returns {Promise<CartReturnArray>}
 */
const makeCartRequest = async (url, cart, currentManufacturingJobUuid, additionalData, signal) => {
  if (!cart) return /** @type CartReturnArray */ [ null, null, null ]

  /**
   * When a new manufacturing job is first created, we assign `0` for uuid and group_id.
   * The server will then return the manufacturing job with randomly generated IDs. We will need to use those
   * from then on
   */
  /** @type ApiFormattedManufacturingJobUuid[] */
  const existingManufacturingJobUuids = cart.manufacturing_jobs.data ? cart.manufacturing_jobs.data.reduce((acc, job) => {
    if (job.uuid === 0) return acc
    return [ ...acc, job.uuid ]
  }, []) : []

  const response = await client.post(url, { data: cart, ...additionalData }, {}, { signal })

  /** @type ApiFormattedCart */
  const cartResponse = response.data

  if (!cartResponse.manufacturing_jobs || !cartResponse.manufacturing_jobs.data) {
    return /** @type CartReturnArray */ [ null, null, null ]
  }

  const newManufacturingJobs = cartResponse.manufacturing_jobs.data
  let currentManufacturingJob = newManufacturingJobs.find(({ uuid }) => {
    if (currentManufacturingJobUuid === null) {
      return true
    }

    if (currentManufacturingJobUuid !== 0) {
      return currentManufacturingJobUuid === uuid
    }

    // If manufacturing job was recently added, assume that's the current one.
    return !existingManufacturingJobUuids.includes(uuid)
  })

  if (!currentManufacturingJob) {
    currentManufacturingJob = newManufacturingJobs[0]
  }

  return /** @type CartReturnArray */ [
    cartResponse,
    currentManufacturingJob ? currentManufacturingJob.uuid : null,
    currentManufacturingJob ? currentManufacturingJob.group_id : null,
  ]
}

/**
 * @param {ApiFormattedCart} cart
 * @param {boolean} generateThumbnail
 * @param {boolean} validateCoupon
 * @param {?ApiFormattedManufacturingJobUuid|0} currentManufacturingJobUuid
 * @param {?AbortSignal} signal
 * @returns {Promise<CartReturnArray>}
 */
export const saveCart = async (
  cart,
  generateThumbnail = false,
  validateCoupon = false,
  currentManufacturingJobUuid,
  signal = null,
) => makeCartRequest(
  `/api/cart`,
  cart,
  currentManufacturingJobUuid,
  {
    generate_thumbnail: generateThumbnail,
    validate_coupon: validateCoupon,
  },
  signal
)

/**
 * @param {ApiFormattedCart} cart
 * @param {boolean} generateThumbnail
 * @param {?ApiFormattedManufacturingJobUuid|0} currentManufacturingJobUuid
 * @param {?AbortSignal} signal
 * @returns {Promise<CartReturnArray>}
 */
export const getPrices = async (
  cart,
  generateThumbnail = false,
  currentManufacturingJobUuid,
  signal = null
) => makeCartRequest(
  `/api/prices`,
  cart,
  currentManufacturingJobUuid,
  {
    generate_thumbnail: generateThumbnail,
  },
  signal
)

export const getCountries = () => {
  return client.post(`/api/countries`)
}

/**
 * @param {string} processingAreaType
 * @param {?string} processingMethodKey
 * @param {?(ApiFormattedProcessingItem[])} processingItems
 * @return {ApiFormattedProcessing}
 */
export const getDummyProcessing = (processingAreaType, processingMethodKey = 'dtg', processingItems = []) => {
  return {
    processingarea_type_key: processingAreaType,
    processingmethod_key: processingMethodKey,
    processing_items: { data: processingItems }
  }
}

/**
 * @param {string} baseproduct_variant_id
 * @param {number} productPrice
 * @param {number} amount
 * @return {ApiFormattedVariant}
 */
export const getDummyVariant = (baseproduct_variant_id, productPrice, amount) => {
  return {
    amount,
    baseproduct_variant_id,
    item_price_net: productPrice,
    sum_item_price_net: productPrice * amount,
  }
}

/**
 * @param {number} amount
 * @param {string|number} productId
 * @param {string|number} colorId
 * @param {string|number} sizeId
 * @param {?number} productPrice
 * @return {ApiFormattedProduct[]}
 */
export const getDummyProductsArray = ({
  amount,
  productId,
  colorId,
  sizeId,
  productPrice,
}) => {
  return [
    {
      base_product_id: productId,
      created_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      amount,
      variants: [
        getDummyVariant(getBaseProductVariantId(productId, colorId, sizeId), productPrice, amount),
      ],
    },
  ]
}

/**
 * @param {?string|number} uuid
 * @param {?string|number} group_id
 * @param {number} amount
 * @param {ApiFormattedProduct[]} products
 * @param {ApiFormattedProcessing[]} processings
 * @return {ApiFormattedManufacturingJob}
 */
export const getDummyManufacturingJob = ({
  uuid = 0,
  group_id = 0,
  amount,
  products = [],
  processings = [],
}) => {
  return {
    uuid,
    group_id,
    amount,
    created_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    processings: { data: processings || [] },
    products: { data: products || [] },
    group_name: null,
  }
}

/**
 * @param {ApiFormattedManufacturingJob[]} manufacturingJobs
 * @param {?string} deliveryIso
 * @return {ApiFormattedCart}
 */
export const getEmptyDummyCart = (manufacturingJobs = [], deliveryIso = 'DE') => {
  return /** @type ApiFormattedCart */{
    reference: null,
    platform: 'SG',
    payment_method: null,
    vat_calculation: 'total',
    coupon_code: null,
    manufacturing_jobs: { data: manufacturingJobs },
    delivery_iso: deliveryIso,
  }
}

/**
 * @param {number} amount
 * @param {string|number} productId
 * @param {string|number} colorId
 * @param {string|number} sizeId
 * @param {?number} productPrice
 * @param {ApiFormattedProcessing[]} processings
 * @param {?string} deliveryIso
 * @return {ApiFormattedCart}
 */
export const getDummyCart = ({
  amount,
  productId,
  colorId,
  sizeId,
  productPrice = 0,
  processings = [],
  deliveryIso = 'DE'
}) => {
  const products = getDummyProductsArray({ amount, productId, colorId, sizeId, productPrice })
  const manufacturingJobs = [getDummyManufacturingJob({
    amount,
    products,
    processings,
  })]

  return getEmptyDummyCart(manufacturingJobs, deliveryIso)
}

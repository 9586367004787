<template>
  <div id="app">
    <router-view />

    <vue-progress-bar />
  </div>
</template>

<script>
  export default {

    mounted() {
      this.$router.beforeEach((to, from, next) => {
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress
          this.$Progress.parseMeta(meta)
        }
        this.$Progress.start()
        next()
      })

      this.$router.afterEach((to, from) => {
        this.$Progress.finish()
      })
    },

  }
</script>

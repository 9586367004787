<template>
  <div class="py-10 leading-7 px-3 bg-white">
    <div class="mx-auto lg:max-w-container-wrapper">
      <div class="lg:flex">
        <div class="lg:flex lg:mx-auto">
          <div
            class="group flex justify-between items-center uppercase text-sm font-bold py-4 px-2 border-b-2 cursor-pointer lg:mx-4"
            :class="activeTab === 'description'
              ? 'text-gray-70 lg:border-blue-50 lg:text-blue-50'
              : 'text-gray-70 lg:border-transparent'"
            @click="changeTab('description')"
          >
            <div
              class="group-hover:text-blue-50 tracking-1"
            >
              {{ $t('views.product_details.tabs.description') }}
            </div>

            <i
              v-if="!showDescription"
              class="icon-plus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
            <i
              v-else
              class="icon-minus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
          </div>

          <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
          >
            <product-description
              v-show="showDescription"
              class="lg:hidden py-3 border-b-2"
              :catalog-product="catalogProduct"
            />
          </transition>

          <div
            class="group flex justify-between items-center uppercase text-sm font-bold py-4 px-2 border-b-2 cursor-pointer lg:mx-4"
            :class="activeTab === 'datasheet'
              ? 'lg:border-blue-50 lg:text-blue-50'
              : 'text-gray-70 lg:border-transparent'"
            @click="changeTab('datasheet')"
          >
            <div class="group-hover:text-blue-50 tracking-1">
              {{ $t('views.product_details.tabs.data_sheet') }}
            </div>
            <i
              v-if="!showData"
              class="icon-plus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
            <i
              v-else
              class="icon-minus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
          </div>

          <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
          >
            <product-data-sheet
              v-show="showData"
              class="lg:hidden py-3 border-b-2"
              :catalog-product="catalogProduct"
            />
          </transition>

          <div
            v-if="catalogProduct.show_size_chart"
            class="group flex justify-between items-center uppercase text-sm font-bold py-4 px-2 border-b-2 cursor-pointer lg:mx-4"
            :class="activeTab === 'sizechart' ? 'lg:border-blue-50 lg:text-blue-50' : 'text-gray-70 lg:border-transparent'"
            @click="changeTab('sizechart')"
          >
            <div class="group-hover:text-blue-50 tracking-1">
              {{ $t('views.product_details.tabs.size_chart') }}
            </div>
            <i
              v-if="!showSize"
              class="icon-plus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
            <i
              v-else
              class="icon-minus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
          </div>

          <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
          >
            <product-size-chart
              v-show="showSize"
              class="lg:hidden py-3 border-b-2"
              :catalog-product="catalogProduct"
            />
          </transition>

          <div
            class="group flex justify-between items-center uppercase text-sm font-bold py-4 px-2 border-b-2 cursor-pointer lg:mx-4"
            :class="activeTab === 'prices'
              ? 'lg:border-blue-50 lg:text-blue-50'
              : 'text-gray-70 lg:border-transparent'"
            @click="changeTab('prices')"
          >
            <div class="group-hover:text-blue-50 tracking-1">
              {{ $t('views.product_details.tabs.prices') }}
            </div>
            <i
              v-if="!showPrices"
              class="icon-plus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
            <i
              v-else
              class="icon-minus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
          </div>

          <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
          >
            <product-prices
              v-show="showPrices"
              class="lg:hidden py-3 border-b-2"
              :catalog-product="catalogProduct"
            />
          </transition>

          <div
            v-if="hasDimensions"
            :class="activeTab === 'printinfo'
              ? 'lg:border-blue-50 lg:text-blue-50'
              : 'text-gray-70 lg:border-transparent'"
            class="group flex justify-between items-center uppercase text-sm font-bold py-4 px-2 border-b-2 cursor-pointer lg:mx-4"
            @click="changeTab('printinfo')"
          >
            <div class="group-hover:text-blue-50 tracking-1">
              {{ $t('views.product_details.tabs.printinfo') }}
            </div>
            <i
              v-if="!showDimensions"
              class="icon-plus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
            <i
              v-else
              class="icon-minus-outline inline-block mr-2 text-lg group-hover:text-blue-50 lg:hidden"
            />
          </div>
          <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave">
            <product-print-dimensions
              v-show="showDimensions"
              class="lg:hidden py-3 border-b-2"
              :catalog-product="catalogProduct"
            />
          </transition>
        </div>
      </div>

      <div class="mt-8">
        <product-description
          v-if="activeTab === 'description'"
          class="hidden lg:flex"
          :catalog-product="catalogProduct"
        />
        <product-data-sheet
          v-if="activeTab === 'datasheet'"
          class="hidden lg:flex"
          :catalog-product="catalogProduct"
        />
        <product-size-chart
          v-if="activeTab === 'sizechart'"
          class="hidden lg:flex"
          :catalog-product="catalogProduct"
        />
        <product-prices
          v-if="activeTab === 'prices'"
          class="hidden lg:flex"
          :catalog-product="catalogProduct"
        />
        <product-print-dimensions
          v-if="activeTab === 'printinfo' && hasDimensions"
          class="hidden lg:flex"
          :catalog-product="catalogProduct"
        />
      </div>

      <div class="mx-auto lg:max-w-container bg-white">
        <p
          v-if="vatMethod === 'net'"
          class="text-gray-50 text-sm mt-16"
          v-html="$t('views.product_details.price_info_net')"
        />
        <p
          v-else
          class="text-gray-50 text-sm mt-16"
          v-html="$t('views.product_details.price_info_gross')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ProductDescription from 'sb/common/components/catalog/ProductDescription'
  import ProductDataSheet from 'sb/common/components/catalog/ProductDataSheet'
  import ProductSizeChart from 'sb/common/components/catalog/ProductSizeChart'
  import ProductPrices from 'sb/common/components/catalog/ProductPrices'
  import ProductPrintDimensions
    from 'sb/common/components/catalog/ProductPrintDimensions'

  export default {
    props: {
      selectTab: {
        type: String,
        default: ''
      },
      catalogProduct: {
        type: Object
      },
      vatMethod: {
        type: String,
        default: 'gross'
      }
    },

    data() {
      return {
        activeTab: 'description',
        showDescription: false,
        showData: false,
        showSize: false,
        showPrices: false,
        showDimensions: false,
      }
    },

    methods: {
      changeTab(tab) {
        this.activeTab = tab
        this.$emit('tab:changed')
        if (tab === 'description') {
          this.showDescription = !this.showDescription
          this.showData = false
          this.showSize = false
          this.showPrices = false
          this.showDimensions = false
        }
        if (tab === 'datasheet') {
          this.showDescription = false
          this.showData = !this.showData
          this.showSize = false
          this.showPrices = false
          this.showDimensions = false
        }
        if (tab === 'sizechart') {
          this.showDescription = false
          this.showData = false
          this.showSize = !this.showSize
          this.showPrices = false
          this.showDimensions = false
        }
        if (tab === 'prices') {
          this.showDescription = false
          this.showData = false
          this.showSize = false
          this.showPrices = !this.showPrices
          this.showDimensions = false
        }
        if (tab === 'printinfo') {
          this.showDescription = false
          this.showData = false
          this.showSize = false
          this.showPrices = false
          this.showDimensions = !this.showDimensions
        }
      },
      enter(element) {
        const width = getComputedStyle(element).width

        element.style.width = width
        element.style.position = 'absolute'
        element.style.visibility = 'hidden'
        element.style.height = 'auto'

        const height = getComputedStyle(element).height

        element.style.width = null
        element.style.position = null
        element.style.visibility = null
        element.style.height = 0

        // Force repaint to make sure the
        // animation is triggered correctly.
        // getComputedStyle(element).height FIXME: How would this work? you can't put in an expression like this

        // Trigger the animation.
        // We use `requestAnimationFrame` because we need
        // to make sure the browser has finished
        // painting after setting the `height`
        // to `0` in the line above.
        requestAnimationFrame(() => {
          element.style.height = height
        })
      },
      afterEnter(element) {
        element.style.height = 'auto'
      },
      leave(element) {
        const height = getComputedStyle(element).height

        element.style.height = height

        // Force repaint to make sure the
        // animation is triggered correctly.
        // getComputedStyle(element).height FIXME: How would this work? you can't put in an expression like this

        requestAnimationFrame(() => {
          element.style.height = 0
        })
      },
    },

    computed: {
      hasDimensions() {
        return !!this.catalogProduct.sizings.data.find(s => {
          return Object.values(s.print_dimensions.data).find(d => {
            return Object.values(d).length
          })
        })
      }
    },

    watch: {
      selectTab(newValue, oldValue) {
        if (newValue) {
          this.changeTab(newValue)
        }
      }
    },

    components: {
      ProductDescription,
      ProductDataSheet,
      ProductSizeChart,
      ProductPrices,
      ProductPrintDimensions
    },
  }
</script>

<style lang="scss" scoped>
  .expand-enter-active,
  .expand-leave-active {
    transition: height .5s ease-in-out;
    overflow: hidden;
  }

  .expand-enter,
  .expand-leave-to {
    height: 0;
  }
</style>

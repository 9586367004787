// eslint-disable-next-line no-unused-vars
import { PropOptions } from 'vue'

/**
 * @typedef {{preview: ManufacturingJobPreview, printarea: BaseProductPrintarea}} PreviewListItem
 */

export default {

  props: {
    /** @type {PropOptions<BaseProduct>} */
    baseProduct: {
      type: Object,
      required: true
    },
    currentColorId: {
      type: [Number, null],
      default: null,
    },
    /** @type {PropOptions<ManufacturingJobPreview[]>} */
    previews: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    this.determinePreviews()
  },

  data() {
    return {
      /** @type {PreviewListItem | null} */
      selectedItem: null,
      /** @type {PreviewListItem[]} */
      previewList: [],
      selectItemOnInit: false,
    }
  },

  methods: {
    /**
     * @param item {PreviewListItem}
     */
    select(item) {
      this.selectedItem = item
    },
    determinePreviews() {
      if (!this.previews) return

      /** @type {Record<string, PreviewListItem>} */
      let previewListWithKeys = {}
      this.baseProduct.printareas.data
        .forEach((printarea) => {
          if (!printarea.is_printable) return

          const preview = this.previewFor(printarea.key)
          if (preview && !previewListWithKeys.hasOwnProperty(printarea.key)) {
            previewListWithKeys[printarea.key] = /** @type PreviewListItem */{ printarea, preview }
          }
        })

      this.previewList = Object.values(previewListWithKeys)

      if (this.selectItemOnInit) {
        this.selectedItem = this.previewList.find((item) => item.preview.customized) || this.previewList[0]
      }
    },
    /**
     * @param {string} key
     * @return {ManufacturingJobPreview|null}
     */
    previewFor(key) {
      if (!this.previews) {
        return null
      }

      const previewsForPrintarea = this.previews.filter((preview) => {
        return preview.base_product_id === this.baseProduct.id && preview.processingarea_type_key === key
      })

      if (this.currentColorId !== null) {
        const previewForCurrentColor = previewsForPrintarea.find((preview) => preview.color_id.toString() === this.currentColorId.toString())
        if (previewForCurrentColor) return previewForCurrentColor
      }

      return previewsForPrintarea.length ? previewsForPrintarea[0] : null
    },
  },

  watch: {
    previews() {
      this.determinePreviews()
    },
    currentColorId() {
      this.determinePreviews()
    },
  },

}

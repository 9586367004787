<template>
  <div class="login-form-wrapper">
    <h1
      class="login-form__headline"
    >
      {{ hasUserAccount ? $t('shirtigo.login.login_headline') : $t('shirtigo.login.register_headline') }}
    </h1>

    <p
      v-if="hasUserAccount"
      class="login-form__switch-link"
    >
      <span>{{ $t('shirtigo.login.link_to_register.label') }}</span>

      <a :href="buildUrl('/register')">
        {{ $t('shirtigo.login.link_to_register.action') }}
      </a>
    </p>

    <p
      v-else
      class="login-form__switch-link"
    >
      <span>{{ $t('shirtigo.login.link_to_login.label') }}</span>

      <a :href="buildUrl('/login')">
        {{ $t('shirtigo.login.link_to_login.action') }}
      </a>
    </p>

    <form
      class="login-form"
      @submit.prevent="submitForm"
    >
      <div
        v-if="formError"
        class="login-form__errors alert alert-danger"
      >
        <span>{{ formError }}</span>
      </div>

      <div
        v-if="successMessage"
        class="login-form__errors alert alert-success"
      >
        <span>{{ successMessage }}</span>
      </div>

      <div class="login-form__input-wrapper">
        <form-group :has-danger="errors && errors.email">
          <float-label-input
            type="email"
            class-name="login-form__input input-lg"
            icon="/images/icons/input-post-icon.svg"
            :title="$t('shirtigo.login.email_input_label')"
            :value="email"
            :has-danger="errors && errors.email"
            autocomplete="email"
            autofocus
            required
            @input="$emit('input:email', $event)"
          />
        </form-group>

        <form-group :has-danger="errors && errors.password">
          <password-input
            class-name="login-form__input input-lg"
            :title="$t('shirtigo.login.password_input_label')"
            :value="password"
            :has-danger="errors && errors.password"
            required
            @input="$emit('input:password', $event)"
            @keydown.enter="submitForm"
          />
        </form-group>
        <form-group
          v-if="!hasUserAccount"
          :has-danger="errors && errors.password"
        >
          <password-input
            class-name="login-form__input input-lg"
            :title="$t('shirtigo.login.password_confirm_input_label')"
            :value="passwordConfirm"
            :has-danger="errors && errors.password"
            required
            @input="$emit('input:passwordConfirm', $event)"
            @keydown.enter="submitForm"
          />
        </form-group>
      </div>

      <a
        v-if="hasUserAccount"
        class="login-form__password-reset"
        href="/reminder"
      >
        {{ $t('shirtigo.login.password_reset_link') }}
      </a>

      <form-group
        v-else
        class="login-form__agb-text"
        :text-danger="errors && errors.agb"
      >
        <label>
          <input
            type="checkbox"
            :value="agbConsent"
            @change="$emit('input:agb-consent', !agbConsent)"
          />&nbsp;
          <span v-html="$t('shirtigo.login.agb_confirmation_text')"></span>
        </label>
      </form-group>

      <div class="login-form__actions">
        <sg-btn
          kind="yellow"
          type="submit"
          class="btn-fullwidth"
          :loading="isLoading"
        >
          {{ hasUserAccount ? $t('shirtigo.login.cta_login') : $t('shirtigo.login.cta_register') }}
        </sg-btn>
      </div>
    </form>

    <p class="login-form__social-btns">
      {{ $t('shirtigo.login.social_login_label') }}

      <social-login-btn provider="facebook">
        Facebook
      </social-login-btn>

      <social-login-btn provider="google">
        Google
      </social-login-btn>
    </p>
  </div>
</template>

<script>
  import SgBtn from 'sb/elements/Buttons/SgBtn.vue'
  import SocialLoginBtn from 'sb/elements/Buttons/SocialLoginBtn.vue'
  import FloatLabelInput from 'sb/elements/Form/FloatLabelInput.vue'
  import FormGroup from 'sb/elements/Form/FormGroup.vue'
  import PasswordInput from './PasswordInput.vue'

  export default {

    props: {
      formError: String,
      email: String,
      password: String,
      passwordConfirm: String,
      agbConsent: Boolean,
      hasUserAccount: {
        type: Boolean,
        default: true
      },
      successMessage: String
    },

    data() {
      return {
        isLoading: false,
        showPassword: false,
        errors: {},
      }
    },

    methods: {
      submitForm() {
        this.isLoading = true
        this.errors = {}

        if (this.email.length === 0) {
          this.$set(this.errors, 'email', true)
        }

        if (this.password.length === 0) {
          this.$set(this.errors, 'password', true)
        }

        if (!this.hasUserAccount && !this.agbConsent) {
          this.$set(this.errors, 'agb', true)
        }

        this.isLoading = false

        if (Object.keys(this.errors).length > 0) {
          return false
        }

        if (this.hasUserAccount) {
          this.$emit('login')
        } else if (this.agbConsent) {
          this.$emit('register')
        }
      },
      buildUrl(path) {
        let url = new URL(path, location.origin)
        url.search = new URLSearchParams(location.search)
        return url
      }
    },

    computed: {
      hasFailed() {
        return Object.keys(this.errors).length > 0
      }
    },

    watch: {
      hasUserAccount() {
        this.errors = {}
      }
    },

    components: {
      SgBtn,
      SocialLoginBtn,
      FloatLabelInput,
      FormGroup,
      PasswordInput,
    },

  }
</script>

<style lang="scss">
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .login-form {
    margin-bottom: 2.5em;

    &-wrapper {
      width: 100%;
      margin: auto;
      max-width: 492px;
    }

    &__errors {
      margin-bottom: 1.5em;
      border-radius: 5px;
    }

    &__input-wrapper {
      margin-bottom: 1em;

      .form-group {
        margin: 0;
      }

      .login-form__input {
        border-radius: 0;

        &:focus {
          border-color: $grey-30;
        }
      }

      .form-group:first-child .login-form__input {
        border-radius: 5px 5px 0 0;
      }

      .form-group:last-child .login-form__input {
        border-radius: 0 0 5px 5px;
      }

      .form-group:not(:last-child) .login-form__input {
        border-bottom: transparent;
      }
    }

    &__headline {
      text-align: left;
      font-size: 1.375em;
      color: $white;
      font-weight: 700;
      margin-bottom: .5rem;
    }

    &__switch-link {
      text-align: left;
      display: block;
      font-size: 1rem;
      margin-bottom: 2rem;

      &, a {
        color: $blue-30;
        font-weight: 600;
      }

      a {
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $blue-10;
          text-decoration: none;
        }
      }
    }

    &__password-reset {
      color: $blue-30;
      font-weight: 600;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $blue-10;
      }
    }

    &__agb-text {
      color: $blue-30;
      font-size: .875em;

      a {
        color: $blue-30;
        font-weight: 600;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $blue-10;
          text-decoration: none;
        }
      }
    }

    &__actions {
      margin-top: 2.5em;
    }

    &__social-btns {
      color: $blue-30;
      line-height: 1.75;
      margin-bottom: 1.5em;
    }
  }
</style>

<template>
  <div class="w-full h-full 2xl:w-2/3 flex justify-center">
    <nav-step
      step="1"
      :title="$t('views.cart.navigation.product_designer')"
      :active="isRouteActive(1)"
      :disabled="!canLoadRoute(1)"
      @click="loadRoute(1)"
    />

    <nav-step
      step="2"
      :title="$t('views.cart.navigation.config_sizes')"
      :active="isRouteActive(2)"
      :disabled="!canLoadRoute(2)"
      @click="loadRoute(2)"
    />

    <nav-step
      step="3"
      :title="$t('views.cart.navigation.cart')"
      :active="isRouteActive(3)"
      :disabled="!canLoadRoute(3)"
      @click="loadRoute(3)"
    />
  </div>
</template>

<script>
  import NavStep from './NavStep.vue'
  import { mapGetters } from 'vuex'

  export default {

    name: 'HeaderNavSteps',

    methods: {
      loadRoute(step) {
        if (!this.canLoadRoute(step)) {
          return
        }

        let params = {
          reference: this.cart ? this.cart.reference : undefined,
          groupid: this.currentManufacturingJobGroupId
        }

        switch (step) {
          case 1:
            const firstProduct = this.allCartProductsForCurrentGroup[0]
            if (firstProduct) {
              params.id = firstProduct.base_product_id
            }

            if (this.$route.name !== 'mpc-collection') {
              this.$router.push({ name: 'mpc-collection', params })
            }
            break
          case 2:
            if (this.$route.name !== 'mpc-size-select') {
              this.$router.push({ name: 'mpc-size-select', params })
            }
            break
          case 3:
            if (this.$route.name !== 'cart') {
              this.$router.push({ name: 'cart', params })
            }
            break
        }
      },

      canLoadRoute(step) {
        switch (step) {
          case 1:
            return true
          case 2:
          case 3:
            return this.allCartProductsForCurrentGroup.length > 0
        }
        return false
      },

      isRouteActive(step) {
        if (step === 1) {
          return [ 'mpc-designer', 'mpc-collection' ].includes(this.$route.name)
        }

        if (step === 2) {
          return [ 'mpc-size-select', 'config-sizes' ].includes(this.$route.name)
        }

        return false
      }
    },

    computed: {
      ...mapGetters([
        'cart',
        'allCartProductsForCurrentGroup',
        'currentManufacturingJobGroupId',
      ])
    },

    components: {
      NavStep,
    },

  }
</script>

<template>
  <div class="dropdown__wrapper">
    <span
      ref="trigger"
      class="dropdown__trigger"
      @click.prevent="toggleDropdown">
      <slot name="trigger"></slot>
    </span>
    <popper
      v-if="showDropdown"
      ref="dropdown"
      class="dropdown"
      :placement="placement"
      :target="triggerElement">
      <slot name="content"></slot>
    </popper>
  </div>
</template>

<script>
  export default {

    props: {
      placement: String,
    },

    data() {
      return {
        showDropdown: false,
      }
    },

    mounted() {
      document.addEventListener('click', this.checkOutsideClick)
    },

    beforeDestroy() {
      document.removeEventListener('click', this.checkOutsideClick)
    },

    methods: {
      toggleDropdown() {
        if (this.showDropdown) {
          this.closeDropdown()
        } else {
          this.openDropdown()
        }
      },
      openDropdown() {
        this.$emit('open:dropdown')
        this.showDropdown = true
      },
      closeDropdown() {
        this.$emit('close:dropdown')
        this.showDropdown = false
      },
      checkOutsideClick(e) {
        const p = e.path || e.composedPath()
        const dropdown = this.$refs.dropdown ? this.$refs.dropdown.$el : null
        const includesSelf = (
          p.includes(this.$refs.trigger) || p.includes(dropdown)
        )
        if (this.showDropdown && !includesSelf) {
          this.closeDropdown()
        }
      },
    },

    computed: {
      triggerElement() {
        return this.$refs.trigger
      },
    },

  }
</script>

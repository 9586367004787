<template>
  <modal
    @close:modal="$emit('close')"
  >
    <h3 class="font-bold text-dark-blue text-3xl mb-3 tracking-wide">
      <slot name="title">
        {{ $t('navigation.modal.alert.headline_fallback') }}
      </slot>
    </h3>

    <div class="text-gray-50 mt-6 font-light text-lg mb-6">
      <slot></slot>
    </div>

    <div class="flex mt-4 items-center justify-center">
      <div class="px-3">
        <button
          class="confirm-button"
          @click.prevent="$emit('confirm'); $emit('close')"
        >
          <slot name="confirm">
            {{ $t('navigation.modal.alert.confirm_fallback') }}
          </slot>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from 'sb/elements/Modals/Modal'

  export default {

    name: 'AlertModal',

    components: { Modal }

  }
</script>

<style lang="scss" scoped>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer components {
    .confirm-button {
      @apply mt-3 w-full px-4 py-2  rounded-full h-11 transition-all duration-200;
      @apply text-center bg-dark-blue text-white font-semibold leading-7 tracking-wide shadow-md font-nunito-sans;

      @media screen and (min-width: 480px) {
        @apply text-lg;
      }

      @media screen and (min-width: 768px) {
        @apply w-auto px-6;
      }

      &:hover,
      &:focus {
        @apply bg-blue-60;
      }
    }
  }
</style>

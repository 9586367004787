<template>
  <div class="flex-1 flex flex-col">
    <template v-if="selectedCategory">
      <div class="flex uppercase text-xs tracking-1 mb-1">
        <span class="font-semibold">
          {{ $t('views.calculator.select_products_dialog.category') }}:
        </span>
        <span class="text-grey-50 ml-2">
          {{ selectedCategory.name }}
        </span>
      </div>

      <div class="flex justify-start">
        <button
          v-for="category in categories"
          :key="category.key"
          :aria-label="category.name"
          class="group border rounded mr-2 cursor-pointer flex items-center px-6 py-2"
          :class="selectedCategory.key === category.key ? 'bg-blue-00 text-blue-50' : 'bg-white border-grey-20'"
          :style="selectedCategory.key === category.key ? 'border-color: rgba(3, 155, 229, 0.35);' : ''"
          @click="changeCategory(category)"
        >
          <product-category-icon
            :category="category"
            :active="selectedCategory.key === category.key"
            color="grey-50"
          />
        </button>
      </div>
    </template>

    <div class="mt-6 flex-1 flex flex-col">
      <div class="uppercase text-xs tracking-1 mb-1 font-semibold">
        {{ $t('views.calculator.select_products_dialog.product') }}
      </div>

      <div class="flex-1 relative">
        <div
          id="products-list"
          class="top-0 bottom-0 w-full overflow-x-auto absolute"
        >
          <select-product
            v-for="baseProduct in productsForCategory"
            :key="baseProduct.id"
            :base-product="baseProduct"
            class="relative"
            :current-base-product="currentBaseProduct"
            :available-colors="availableColors"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { uniqBy } from 'lodash'
  import SelectProduct from './SelectProduct'
  import ProductCategoryIcon from './ProductCategoryIcon.vue'

  export default {

    props: {
      currentImage: {
        type: Object,
        required: true
      },
      availableColors: {
        type: Array
      }
    },

    data() {
      return {
        REDUCED_COLOR_COUNT: 18,
        selectedCategory: '',
        moreColors: true,
        colorWidth: 110
      }
    },

    async created() {
      if (!this.currentBaseProduct) {
        this.selectedCategory = this.categories[0]
      } else {
        this.selectedCategory = this.currentBaseProduct.attributes.data.find(({ type_key }) => type_key === 'category')
      }
    },

    methods: {
      changeCategory(category) {
        this.selectedCategory = category
      },
      updateColor(newColorId) {
        this.setCurrentColorId(newColorId)
      },
      allColors() {
        this.moreColors = false
      },
      reduceColors() {
        this.moreColors = true
      },
      ...mapActions([
        'setCurrentColorId'
      ])
    },

    computed: {
      reducedColors() {
        if (!this.moreColors) return this.availableColors

        return this.availableColors.slice(0, this.REDUCED_COLOR_COUNT)
      },
      categories() {
        if (!this.baseProducts || !this.baseProducts.length) return []

        const attributes = this.baseProducts.map(product => product.attributes.data.find(attributes => attributes.type_key === 'category'))
        return uniqBy(attributes, (item) => item.key)
      },
      productsForCategory() {
        if (!this.baseProducts || !this.baseProducts.length || !this.selectedCategory) {
          return []
        }

        return this.baseProducts
          .filter(baseProduct => baseProduct.attributes.data.some(a => a.key === this.selectedCategory.key))
          .filter(baseProduct => {
            const hasPrintareas = baseProduct.printareas.data.length > 0
            if (!hasPrintareas) {
              console.warn(`Removing product ${baseProduct.name} (${baseProduct.reference}) because it has no printareas assigned.`)
            }
            return hasPrintareas
          })
      },
      ...mapGetters([
        'baseProducts',
        'currentBaseProduct',
      ])
    },

    watch: {
      reducedColors() {
        this.colorWidth = 20 + (Math.ceil(this.reducedColors.length / 9) * 45)
      }
    },

    components: {
      ProductCategoryIcon,
      SelectProduct
    }

  }
</script>

<template>
  <div :class="['form-group', { 'has-danger': hasDanger, 'text-danger': textDanger }]">
    <slot></slot>
  </div>
</template>

<script>
  export default {

    props: {
      hasDanger: Boolean,
      textDanger: Boolean,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .form-group {
    margin-bottom: 1rem;

    &__label {
      display: block;
      font-size: 1rem;
      color: $grey-60;
      margin-bottom: .5em;
      text-align: left;
    }

    &__checkbox-label {
      display: flex;
      color: $grey-70;
      margin-bottom: 0;

      span:last-of-type {
        margin-left: .5em;
      }

      &.disabled {
        color: $grey-60;
      }
    }
  }
</style>

<template>
  <sg-link
    class="social-login-btn"
    :to="'/auth/' + provider">
    <slot></slot>
  </sg-link>
</template>

<script>
  import SgLink from 'sb/common/components/SgLink.vue'

  export default {

    props: {
      provider: {
        type: String,
        required: true
      },
    },

    components: {
      SgLink,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .social-login-btn {
    margin-left: 1em;

    color: $blue-30;
    font-weight: 600;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $blue-30;
    }
  }
</style>

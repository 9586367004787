<template>
  <div class="bg-white border px-4 pt-6 pb-8 mb-4 rounded-lg">
    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-26 lg:mr-4 flex justify-center sm:justify-start pb-4 lg:pb-0">
        <img
          :src="baseProductImage"
          alt=""
          class="w-26"
        >
      </div>

      <div class="flex justify-between flex-col lg:flex-row lg:items-center w-full gap-4">
        <div class="flex-1">
          <div class="text-lg font-bold flex items-center">
            {{ `${amount}x ${baseProduct.name}` }}
          </div>

          <p class="text-grey-70 mt-1">
            {{ baseProduct.description_preview }}

            <button
              class="text-blue-50 ml-1"
              @click.prevent="showProductInfo = true"
            >
              {{ $t('views.calculator.product_group.more_infos') }}
            </button>
          </p>

          <div class="h-6 mt-2">
            <loader
              v-if="loading"
              color="blue-50"
              class="w-6"
            />

            <p
              v-else
              class="tracking-tight"
            >
              <template v-if="discountPercentage && product.amount === 0">
                <span class="text-blue-50 font-bold">
                  {{ product.avg_item_price_gross | currency }}*
                </span>
              </template>

              <template v-else-if="product.avg_item_price_gross && discountPercentage">
                <span class="text-grey-50 font-normal line-through mr-2">
                  {{ baseProductPrice | currency }}
                </span>

                <span class="text-blue-50 font-bold">
                  {{ product.avg_item_price_gross | currency }}*
                </span>

                <span
                  v-if="discountPercentage"
                  class="text-green-70 text-sm"
                >
                  {{ discountPercentage }}
                </span>
              </template>

              <template v-else-if="product.avg_item_price_gross">
                <span class="text-blue-50 font-bold">
                  {{ product.avg_item_price_gross | currency }}*
                </span>
              </template>
            </p>
          </div>
        </div>

        <div class="flex items-center">
          <number-input
            placeholder="0"
            :min="0"
            :value="amount"
            :disabled="loading"
            class="w-full lg:w-3/4"
            @input="updateSizeAmountForProduct"
          />

          <button
            :title="$t('views.calculator.product_group.remove_product')"
            class="ml-6 border border-blue-30 hover:border-red-30 rounded-full text-blue-50 hover:text-red-50 text-center flex flex-shrink-0 items-center justify-center w-11 h-11 transition duration-200"
            :disabled="loading ? 'disabled' : false"
            @click.prevent="removeProduct"
          >
            <i class="block icon-trash-2-outline text-xl font-bold" />
          </button>
        </div>
      </div>
    </div>

    <modal
      v-if="showProductInfo"
      @close:modal="showProductInfo = false"
    >
      <product-info-popup :base-product-details="baseProduct" />
    </modal>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { PropOptions } from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import _debounce from 'lodash/debounce'
  import ProductInfoPopup from '../ProductInfoPopup'
  import Modal from 'sb/elements/Modals/Modal'
  import Loader from '../../layout/Loader.vue'
  import NumberInput from '@/components/layout/NumberInput.vue'

  export default {

    props: {
      loading: {
        type: Boolean,
        default: false
      },
      /** @type {PropOptions<ApiFormattedProduct>} */
      product: {
        type: Object,
        required: true
      },
    },

    data() {
      return {
        showColorSelector: false,
        showProductInfo: false,
        showRemoveAlert: false,
      }
    },

    methods: {
      removeProduct() {
        this.$emit('removeProduct', this.baseProduct.id)
      },
      /**
       * @param amount {number}
       */
      updateSizeAmountForProduct(amount) {
        if (amount === 0) {
          this.removeProduct()
          return;
        }

        this.updateOrAddProductVariantInCurrentGroup({
          baseProductId: this.baseProduct.id,
          variant: {
            amount,
            baseproduct_variant_id: this.product.variants[0].baseproduct_variant_id
          },
        })

        this.sendUpdateEvent()
      },
      sendUpdateEvent: _debounce(function () {
        this.$emit('update')
      }, 500),
      ...mapActions([
        'updateOrAddProductVariantInCurrentGroup',
      ])
    },

    computed: {
      /**
       * @return {number}
       */
      amount() {
        if (!this.product) return 0

        if (this.product.amount) {
          return this.product.amount
        }

        return this.product.variants.reduce((/** @type {number} */ sum, /** @type {BaseProductColorVariant} */ baseProductVariant) => {
          return /** @type {number} */ sum + baseProductVariant.amount
        }, /** @type {number} */ 0)
      },
      discountPercentage() {
        if (!this.product.avg_item_price_gross || !this.baseProductPrice) return ''

        const discount = 1 - (this.product.avg_item_price_gross / this.baseProductPrice).toFixed(2)
        if (discount <= 0) {
          return ''
        }

        return `-${Math.round(discount * 100)} %`
      },
      baseProductPrice() {
        if (!this.baseProduct) return 0

        return parseInt(this.baseProduct.baseprice_processing_default.data.amount)
      },
      baseProductImage() {
        if (!this.baseProduct) return null

        let image = this.baseProduct.default_image
        return image.large || image.standard
      },
      baseProduct() {
        return this.baseProducts.find(({ id }) => this.product.base_product_id === id)
      },
      availableColors() {
        if (!this.baseProduct) return []

        // Only return colors that aren't already added to cart
        return this.baseProduct.variants.data.filter(({ id }) => {
          return !this.product.variants.some((variant) => variant.color_id === id)
        })
      },
      ...mapGetters([
        'baseProducts',
        'currentManufacturingJob',
        'currentManufacturingJobGroupId',
        'cart'
      ])
    },

    components: {
      NumberInput,
      Loader,
      ProductInfoPopup,
      Modal,
    }

  }
</script>

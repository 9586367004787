export default {
  methods: {
    /**
     * @param {string} title
     * @param {string} description
     */
    setHead(title, description = '') {
      document.title = title

      if (description) {
        description = description.length > 200 ? description.substring(0, 200) : description
        document.querySelector('meta[name="description"]').setAttribute('content', description)
      }
    }
  }
}

<template>
  <div class="hidden lg:flex lg:items-center lg:col-span-3 mb-4 text-gray-70 text-sm">
    <div
      v-if="backUrl"
      class="flex items-center gap-1 mr-2"
    >
      <a
        :href="backUrl"
        class="text-blue-50 hover:text-blue-60 transform duration-200 ease-in-out flex gap-1 items-center"
      >
        <svg
          class="h-3 w-3"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          fill="currentColor">
          <path
            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
        {{ $t('global.back') }}
      </a>
      <span class="text-xs text-gray-30 ml-1">|</span>
    </div>
    <a
      v-if="!isProductPage"
      href="/"
      class="text-gray-70 hover:text-gray-80 transform duration-200 ease-in-out"
    >
      {{ $t('global.breadcrumb_home') }}
    </a>
    <a
      v-if="isProductPage"
      href="/catalog"
      class="text-gray-70 hover:text-gray-80 transform duration-200 ease-in-out"
    >
      {{ $t('views.catalog.categories.all_products') }}
    </a>

    <i class="icon-arrow-ios-forward-outline text-gray-50 mx-2" />

    <template v-for="page in pages">
      <a
        :key="`link-${page.href}`"
        :href="page.href"
        class="hover:text-gray-80"
      >
        {{ page.title }}
      </a>

      <i
        :key="`divider-${page.href}`"
        class="icon-arrow-ios-forward-outline text-gray-50 mx-2"
      />
    </template>

    <span class="font-bold text-gray-90">
      {{ currentPageName }}
    </span>
  </div>
</template>

<script>
  export default {

    props: {
      pages: {
        type: Array,
      },
      currentPageName: String,
      backUrl: {
        type: String
      },
      isProductPage: {
        type: Boolean,
        default: false
      }
    },

  }
</script>

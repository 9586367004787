<template>
  <div class="tracking-4">
    <div class="bg-grey-00 min-h-footer-padding flex-1 px-5 md:px-8 lg:px-10">
      <div class="py-6">
        <catalog-index-view
          :catalog-products="catalogProducts"
          :product-categories="sortedCategories"
          :product-colors="productColors"
          :brands="brands"
          :genders="genders"
          :selected-genders="selectedGenders"
          :selected-colors="selectedColors"
          :current-page="currentPage"
          :order-by="orderBy"
          :selected-brands="selectedBrands"
          :selected-category="selectedCategory"
          :current-category="currentCategory"
          @change:category="changeCategory"
          @change:color="changeColor"
          @change:brand="changeBrands"
          @change:order="changeOrder"
          @change:gender="changeGenders"
          @change:page="changePage"
          @remove:brand="removeBrand"
          @remove:gender="removeGender"
          @remove:color="removeColor"></catalog-index-view>
      </div>
    </div>
    <loader-full-page v-if="loading" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ShirtigoHeader from '../../components/layout/ShirtigoHeader'
  import ShirtigoFooter from '../../components/layout/ShirtigoFooter'
  import LoaderFullPage from '../../components/layout/LoaderFullPage'

  // TODO: Add Type Definitions to methods
  // TODO: Destructure Template into separate components

  export default {

    data() {
      return {
        loading: false,
        fullLoading: false,
        productsLoading: false,
        brandsLoading: false,
        selectedColors: [],
        selectedBrands: [],
        selectedGenders: [],
        currentPage: 1,
        orderBy: null,
      }
    },

    created() {
      this.loadAllProductCategories()
      this.initView()
      if (this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page)
      }

      if (this.$route.query.color) {
        this.$route.query.color.split('!').forEach(queriedKey => {
          const color = this.productColors.find(({ key }) => key === queriedKey)
          if (color) {
            this.selectedColors.push(color)
          }
        })
      }

      if (this.$route.params.key && this.productCategories) {
        if (!this.productCategories.some(({ key }) => key.slice(9) === this.currentCategory)) {
          this.$router.push({
            name: 'catalog-index',
            params: {
              key: null
            },
          })
        }
      }
    },

    methods: {
      initView() {
        this.getBrands().then(() => {
          this.getGenders().then(() => {
            this.loadProducts()
          })
        })
      },
      changeOrder(order) {
        this.orderBy = order.target.value
        this.loadProducts()
      },
      changeBrands(brand) {
        this.selectedBrands = this.addOrRemoveItemFromArray(this.selectedBrands, brand, ({ reference }) => reference)

        const query = Object.assign({}, this.$route.query)
        query.brand = this.addOrRemoveItemFromQueryString(query.brand, brand.name)
        if (!query.brand) {
          delete query.brand
        }

        this.$router.push({ query })
        this.currentPage = 1

        this.loadProducts()
      },
      changeGenders(gender) {
        this.selectedGenders = this.addOrRemoveItemFromArray(this.selectedGenders, gender, ({ key }) => key)

        const query = Object.assign({}, this.$route.query)
        query.gender = this.addOrRemoveItemFromQueryString(query.gender, gender.key)
        if (!query.gender) {
          delete query.gender
        }

        this.$router.push({ query })
        this.currentPage = 1

        this.loadProducts()
      },
      removeBrand(brand) {
        this.selectedBrands = this.selectedBrands.filter(({ name }) => name !== brand.name)

        const query = Object.assign({}, this.$route.query)
        query.brand = this.addOrRemoveItemFromQueryString(query.brand, brand.name)
        if (!query.brand) {
          delete query.brand
        }
        this.$router.push({ query })

        this.loadProducts()
      },
      removeGender(gender) {
        this.selectedGenders = this.selectedGenders.filter(({ key }) => key !== gender.key)

        const query = Object.assign({}, this.$route.query)
        query.gender = this.addOrRemoveItemFromQueryString(query.gender, gender.key)
        if (!query.gender) {
          delete query.gender
        }
        this.$router.push({ query })

        this.loadProducts()
      },
      async getBrands() {
        await this.loadBrands({
          category: this.selectedCategory.key,
          colors: this.selectedColors.map(({ key }) => key),
        })
        if (this.$route.query.brand) {
          this.$route.query.brand.split('!').forEach((queriedBrandName) => {
            const brand = this.brands.find(({ name }) => name === queriedBrandName)
            if (brand) {
              this.selectedBrands.push(brand)
            }
          })
        }
      },
      async getGenders() {
        await this.loadGenders()
        if (this.$route.query.gender) {
          this.$route.query.gender.split('!').forEach((queriedGender) => {
            const gender = this.genders.find(({ key }) => key === queriedGender)
            if (gender) {
              this.selectedGenders.push(gender)
            }
          })
        }
      },
      /**
       * @param {*[]} array
       * @param {string} item
       * @param {function} [transformFn=(x) => x]
       * @returns {*[]}
       */
      addOrRemoveItemFromArray(array, item, transformFn = (x) => x) {
        if (array.find(arrayItem => transformFn(arrayItem) === transformFn(item))) {
          array = array.filter(arrayItem => transformFn(arrayItem) !== transformFn(item))
        } else {
          array.push(item)
        }

        return array
      },
      /**
       * @param color
       */
      removeColor(color) {
        this.selectedColors = this.selectedColors.filter(({ id }) => id !== color.id)

        const query = Object.assign({}, this.$route.query)
        query.color = this.addOrRemoveItemFromQueryString(query.color, color.name)

        if (query.color) {
          this.$router.push({ query })
        }

        this.loadProducts()
      },
      /**
       * @param {string|undefined} queryString
       * @param {string} queriedItem
       * @returns {string}
       */
      addOrRemoveItemFromQueryString(queryString, queriedItem) {
        if (!queryString) return queriedItem

        return this.addOrRemoveItemFromArray(queryString.split('!'), queriedItem).join('!')
      },
      changeCategory(category) {
        this.currentPage = 1
        this.selectedColors = []
        this.selectedBrands = []

        if (this.$route.params.key !== category) {
          this.$router.push({
            name: 'catalog-index',
            params: {
              key: category || null
            },
          })
        }
      },
      /**
       * @param {string|MouseEvent} page
       */
      changePage(page) {
        if (typeof page === 'object') {
          page = page.target.value
        }

        this.currentPage = page

        const query = Object.assign({ page }, this.$route.query)
        if (query.page) {
          this.$router.push({ query })
        }

        this.loadProducts()
      },
      changeColor(color) {
        this.selectedColors = this.addOrRemoveItemFromArray(this.selectedColors, color, ({ id }) => id)

        const query = Object.assign({}, this.$route.query)
        query.color = this.addOrRemoveItemFromQueryString(query.color, color.key)

        if (query.color) {
          this.$router.push({ query })
          this.currentPage = 1
        }

        this.loadProducts()
        this.loadBrands({
          category: this.selectedCategory.key,
          colors: this.selectedColors.map(({ key }) => key),
        })
      },
      async loadProducts() {
        this.loading = true

        await this.loadCatalogProducts({
          category: this.selectedCategory.key,
          currentPage: this.currentPage,
          orderBy: this.orderBy,
          brands: this.selectedBrands.map(({ name }) => name),
          genders: this.selectedGenders.map(({ key }) => key),
        })

        this.loading = false
      },
      ...mapActions([
        'loadCatalogProducts',
        'loadAllProductCategories',
        'loadProductColors',
        'loadBrands',
        'loadGenders'
      ]),
    },

    computed: {
      activeProductCategories() {
        return this.productCategories.filter((category) => category.is_active)
      },
      sortedCategories() {
        if (!this.activeProductCategories.length) {
          return []
        }

        let categories = JSON.parse(JSON.stringify(this.activeProductCategories))
        return categories.sort((a, b) => {
          if (a.sort_weight > b.sort_weight) {
            return 1
          } else if (a.sort_weight < b.sort_weight) {
            return -1
          }

          return 0
        })
      },
      products() {
        return this.catalogProducts.data
      },
      pagination() {
        if (this.catalogProducts && this.catalogProducts.meta) {
          return this.catalogProducts.meta.pagination
        }

        return null
      },
      currentCategory() {
        return this.$route.params.key
      },
      selectedCategory() {
        if (this.currentCategory) {
          const selectedProductCategory = this.productCategories.find(({ key }) => key.slice(9) === this.currentCategory)
          if (selectedProductCategory) {
            return selectedProductCategory
          }
        }

        return {
          name: this.$t('views.catalog.categories.all_products'),
          description: this.$t('views.catalog.categories.all_products_description'),
          key: null,
        }
      },
      ...mapGetters([
        'catalogProducts',
        'productCategories',
        'productColors',
        'brands',
        'genders',
      ]),
    },

    watch: {
      currentCategory: function () {
        this.loadProducts()
        this.loadBrands({
          category: this.selectedCategory.key,
          colors: this.selectedColors.map(({ name }) => name)
        })
      }
    },

    components: {
      ShirtigoFooter,
      ShirtigoHeader,
      LoaderFullPage
    }

  }
</script>

<template>
  <div>
    <div class="bg-grey-00 min-h-footer-padding pb-16 flex-1">
      <shirtigo-checkout-header />

      <div class="flex">
        <main
          class="h-full w-full lg:m-auto lg:flex max-w-container"
        >
          <div class="lg:flex-1 lg:pr-6 mt-10 lg:mt-12">
            <div
              v-if="errorMessage"
              class="py-6 px-4"
            >
              <div class="flex w-full border border-red-50 rounded text-red-50 p-4 bg-red-00">
                <i class="icon-close-circle-outline text-lg font-bold block mr-2 mt-1" />

                <div class="flex-1">
                  {{ errorMessage }}
                </div>
              </div>
            </div>

            <div class="m-auto w-full mt-4 mb-6 flex">
              <div
                class="text-xl font-medium rounded-3xl bg-blue-50 leading-10 text-white w-10 h-10 text-center align-middle ml-4"
              >
                1
              </div>

              <div class="font-semibold text-xl ml-4 leading-10 align-middle">
                {{ $t('views.checkout.address.headline') }}
              </div>
            </div>

            <div class="px-4">
              <form-group
                class="text-gray-70 w-full"
                :has-danger="!!(formErrors.primary['email'] || false)"
              >
                <label class="leading-8">
                  {{ $t('views.checkout.address.email') }}
                </label>

                <sg-input
                  v-model="primary.email"
                  type="email"
                  :has-danger="!!(formErrors.primary['email'] || false)"
                  @blur="validate('email', 'primary')"
                />
              </form-group>

              <address-selection
                :address="primary"
                :countries="countries"
                :form-errors="formErrors.primary"
              />

              <div class="text-gray-70 mt-6">
                <label class="block mb-4 flex cursor-pointer">
                  <span class="mr-2 block w-6">
                    <sg-radio
                      name="shipping_equals_primary"
                      value="1"
                      :checked="shipping_equals_primary"
                      @change="updateShippingEqualsPrimary"
                    />
                  </span>

                  <span class="block w-full">
                    {{ $t('views.checkout.address.shipping_equals_primary') }}
                  </span>
                </label>

                <label class="block mb-4 flex cursor-pointer">
                  <span class="mr-2 block w-6">
                    <sg-radio
                      name="shipping_equals_primary"
                      value="0"
                      :checked="!shipping_equals_primary"
                      @change="updateShippingEqualsPrimary"
                    />
                  </span>
                  <span class="block w-full">
                    {{ $t('views.checkout.address.shipping_not_equals_primary') }}
                  </span>
                </label>
              </div>

              <div
                v-if="!shipping_equals_primary"
                class="border-t border-gray-10 pt-6 mt-8"
              >
                <div class="font-semibold text-lg mb-4">
                  {{ $t('views.checkout.address.delivery_address') }}
                </div>

                <address-selection
                  :address="shipping"
                  :countries="countries"
                  :form-errors="formErrors.shipping"
                  :country-read-only="primary.country"
                />
              </div>
            </div>

            <div class="border-t border-gray-10 my-12 mx-4"></div>

            <div class="m-auto w-full mt-4 mb-6 flex">
              <div
                class="text-xl font-medium rounded-3xl bg-blue-50 leading-10 text-white w-10 h-10 text-center align-middle ml-4"
              >
                2
              </div>

              <div class="font-semibold text-xl ml-4 leading-10 align-middle">
                {{ $t('views.checkout.production_time.headline') }}
              </div>
            </div>

            <div
              class="flex rounded-lg m-4 p-5 border cursor-pointer"
              :class="[shipping_method === 'default' ? 'bg-blue-00 text-blue-50 border-blue-30' : 'bg-white border-white']"
              @click="updateShippingMethod('default')"
            >
              <div class="mr-4 pt-1">
                <sg-radio
                  name="shipping_method"
                  value="default"
                  :checked="shipping_method === 'default'"
                  @change="updateShippingMethod"
                />
              </div>

              <div class="flex-1">
                <span class="text-lg font-bold">
                  {{ $t('views.checkout.production_time.default_shipping_conditions_title') }}
                </span>

                <p
                  class="text-sm"
                  :class="[shipping_method === 'default' ? 'text-blue-50' : 'text-gray-70']"
                >
                  {{ $t('views.checkout.production_time.default_shipping_conditions') }}
                </p>
              </div>
              <div
                class="rounded-xl text-xs px-2 py-1 font-semibold mb-auto"
                :class="[shipping_method === 'default' ? 'text-blue-60 bg-blue-30' : 'bg-gray-00 text-gray-50']"
              >
                {{ cart.shipping_price_net | currency }}
              </div>
            </div>

            <div class="border-t border-gray-10 my-12 mx-4"></div>

            <div
              ref="paymentWidth"
              class="m-auto w-full mt-4 mb-6 flex"
            >
              <div
                class="text-xl font-medium rounded-3xl bg-blue-50 leading-10 text-white w-10 h-10 text-center align-middle ml-4"
              >
                3
              </div>
              <div class="font-semibold text-xl ml-4 leading-10 align-middle">
                {{ $t('views.checkout.payment.headline') }}
              </div>
            </div>
            <div
              class="w-full"
              :style="{width: pppWidth + 'px'}"
            >
              <div
                id="ppplus"
                class="lg:px-4"
                :style="{width: pppWidth + 'px'}"
                :class="{hidden: paymentLoading}"
              />

              <div
                v-if="paymentLoading"
                class="mt-10 px-5 md:justify-between md:content-center"
              >
                <div class="load8">
                  <div class="loader"></div>
                  <div class="loader-text">
                  </div>
                </div>
              </div>
            </div>

            <div v-if="false">
              <div class="border-t border-gray-10 my-12 mx-4"></div>
              <div class="m-auto w-full mt-4 mb-6 flex">
                <div
                  class="text-xl font-medium rounded-3xl bg-blue-50 leading-10 text-white w-10 h-10 text-center align-middle ml-4">
                  4
                </div>
                <div class="font-semibold text-xl ml-4 leading-10 align-middle">
                  Weitere Angaben
                </div>
              </div>

              <div class="mx-4">
                <form-group
                  class="text-gray-70"
                  :has-danger="!!(formErrors.details['reference'] || false)">
                  <label class="leading-8">Referenz oder Nachbestellung</label>
                  <sg-input
                    v-model="details.reference"
                    :has-danger="!!(formErrors.details['reference'] || false)"></sg-input>
                </form-group>

                <form-group
                  v-if="false"
                  class="text-gray-70"
                  :has-danger="!!(formErrors.details['comment'] || false)">
                  <label class="leading-8">Kommentarfeld</label>
                  <sg-textarea
                    v-model="details.comment"
                    placeholder="z.B. Hinweise zu deiner Bestellung"
                    :has-danger="!!(formErrors.details['comment'] || false)"></sg-textarea>
                </form-group>

                <div
                  v-if="false"
                  class="flex w-full mb-6">
                  <div class="pr-4 pt-1">
                    <sg-checkbox
                      :checked="details.polybags"
                      value="1"
                      @change="updatePolybags"></sg-checkbox>
                  </div>
                  <div class="flex-1">
                    <div class="mb-2 leading-7">
                      Einzelverpackung in Polybeuteln (+ 19,46 €)
                    </div>
                    <p class="text-sm text-gray-50 leading-7">
                      Die Produkte werden einzeln in Polybeutel verpackt in einem Versandkarton geliefert.
                    </p>
                  </div>
                </div>
                <div
                  v-if="false"
                  class="flex w-full">
                  <div class="pr-4 pt-1">
                    <sg-checkbox
                      :checked="details.print_check"
                      value="1"
                      @change="updatePrintCheck"></sg-checkbox>
                  </div>
                  <div class="flex-1">
                    <div class="mb-2 leading-7">
                      Druckdatencheck und Druckvisualisierung (+ 10,25 €)
                    </div>
                    <p class="text-sm text-gray-50 leading-7">
                      Wir prüfen deine Druckdatei auf Fehler und du erhältst von uns eine unverbindliche Visualisierung
                      deines Drucks per E-Mail. Erst nach deiner Freigabe wird der Auftrag durchgeführt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-5/12 mt-16 px-4" id="sidebar-parent">
            <div
              class="lg:mr-4 xl:mr-0 xl:w-480px"
              id="sidebar-child"
              :class="{'lg:fixed lg:top-0 lg:mt-10': scrollFixed}"
            >
              <div class="rounded bg-white shadow-sm p-6">
                <div class="text-dark-blue text-3xl font-bold mb-4">
                  {{ $t('views.checkout.headline') }}
                </div>

                <div class="leading-8">
                  <div class="flex text-gray-70">
                    <div class="flex-1">
                      {{ $t('views.cart.full_price_display.cost_textiles_and_print') }}
                    </div>
                    <div>{{ cart.price_gross - cart.shipping_price_net | currency }}</div>
                  </div>

                  <div class="flex text-gray-70">
                    <div class="flex-1">
                      {{ $t('views.cart.full_price_display.delivery_to', { countryName: countryName }) }}
                    </div>
                    <div class="text-green-50">
                      {{ cart.shipping_price_net | currency }}
                    </div>
                  </div>

                  <div class="flex text-gray-70 leading-10 text-2xl">
                    <div class="flex-1">
                      {{ $t('views.checkout.payment.total_price') }}
                    </div>
                    <div class="text-blue-50 font-bold">
                      {{ cart.price_gross | currency }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4 mb-12">
                <button
                  id="ppp_continue"
                  disabled
                  class="my-3 text-center w-full px-4 md:px-6 py-2 bg-dark-blue text-white rounded-full font-semibold leading-7 tracking-wide shadow-big min-h-11 xs:text-lg transition-all duration-200 hover:bg-blue-60 font-nunito-sans disabled:opacity-50"
                  :class="redirectLoading ? 'opacity-50' : ''"
                  @click="submitCheckout"
                >
                  <span v-if="redirectLoading">{{ $t('views.checkout.ppp.loading') }}</span>
                  <span v-else-if="ppp.paymentMethod === 'prepayment'">{{ $t('views.checkout.ppp.check_order') }}</span>
                  <span v-else>{{ $t('views.checkout.ppp.authorize_payment') }}</span>
                  <span
                    v-if="!redirectLoading"
                    class="opacity-90">
                    <i class="fas fa-arrow-right text-sm ml-2"></i>
                  </span>
                </button>

                <div
                  v-if="ppp.paymentMethod !== 'prepayment'"
                  class="mt-5 text-sm text-gray-50"
                >
                  {{ $t('views.checkout.ppp.caption') }}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div class="lg:m-auto max-w-container">
        <div class="border-t border-gray-10 my-12 mx-4"></div>
        <benefits-footer />
      </div>
    </div>

    <config-footer />

    <loader-full-page v-if="redirectLoading">
      {{ $t('views.checkout.redirect_loading') }}
    </loader-full-page>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import AddressSelection from '@/components/config/AddressSelection'
  import ConfigFooter from '@/components/config/ConfigFooter'
  import ShirtigoCheckoutHeader from '../../components/layout/ShirtigoCheckoutHeader'
  import BenefitsFooter from '../../components/config/BenefitsFooter'
  import LoaderFullPage from '../../components/layout/LoaderFullPage'
  import Prices from '../../mixins/Prices'

  // TODO: Refactoring! This component is way too big

  export default {

    mixins: [ Prices ],

    data() {
      return {
        shipping_equals_primary: true,
        shipping_method: 'default',
        pppWidth: 0,
        redirectLoading: false,
        primary: {
          email: '',
          company_name: '',
          lastname: '',
          firstname: '',
          street: '',
          streetnumber: '',
          zip: '',
          city: '',
          country: 'DE'
        },
        shipping: {
          company_name: '',
          lastname: '',
          firstname: '',
          street: '',
          streetnumber: '',
          zip: '',
          city: '',
        },
        details: {
          reference: '',
          comment: '',
          polybags: false,
          print_check: false
        },
        formErrors: {
          primary: {},
          shipping: {},
          details: {}
        },
        countries: [
          // Loaded through API, adding dummy to show default country to customer on load
          { id: 1, code: 'DE', country: 'Deutschland' }
        ],
        errorMessage: null,
        paymentLoading: true,
        scrollFixed: false,
        ppp: {
          handle: null,
          paymentMethod: null,
          paymentId: null,
          linkPay: null,
          mode: null,
          readyForCheckout: false
        }
      }
    },
    mounted() {
      if (!this.cart || this.cartIsEmpty) {
        window.location.href = '/'
      }

      if (this.isCheckedOut) {
        this.deleteCart()
        this.deleteOrderDetails()
        window.location.href = '/'
      }

      if (this.orderDetails) {
        this.shipping = this.orderDetails.shipping || this.shipping
        this.primary = this.orderDetails.primary || this.primary
        this.shipping_equals_primary = this.orderDetails.shipping_equals_primary
        this.shipping_method = this.orderDetails.shipping_method || 'default'
        this.details = this.orderDetails.details || this.details
      }

      this.setPPPWidth()
      window.addEventListener('resize', this.setPPPWidth)
      window.addEventListener('resize', this.adjustWidth)
      document.addEventListener('scroll', this.onScroll)

      this.calculatePrice()

      this.countries = window.__COUNTRIES__
      Vue.set(this.primary, 'country', this.cart.delivery_iso || 'DE')
      this.adjustWidth()

      this.initPPP()
    },

    methods: {
      validate(field, category) {
        let invalid = false
        switch (field) {
          case 'email':
            if (this[category].email && !/\S+@\S+\.\S+/.test(this[category].email)) {
              invalid = this.$t('views.checkout.error_invalid_email')
            }
            break
        }

        this.$set(this.formErrors[category], field, invalid)
      },
      setPPPWidth() {
        // We need to first set it to 0 to allow the other elements to stretch accordingly
        // Otherwise they would just take on the fixed size of the widget
        this.pppWidth = 0
        this.$nextTick(() => {
          this.pppWidth = this.$refs.paymentWidth ? this.$refs.paymentWidth.clientWidth : 0
        })
      },
      adjustWidth() {
        const parent = document.getElementById('sidebar-parent');
        const child = document.getElementById('sidebar-child');
        const parentWidth = parent.offsetWidth;

        child.style.width = `${parentWidth - 32}px`;
      },
      onScroll() {
        this.scrollFixed = document.documentElement.scrollTop > 117
      },
      updateShippingEqualsPrimary(value) {
        this.shipping_equals_primary = value === '1'
      },
      updateShippingMethod(value) {
        this.shipping_method = value
      },
      updatePolybags(value) {
        this.details.polybags = value === '1'
      },
      updatePrintCheck(value) {
        this.details.print_check = value === '1'
      },
      initPPP() {
        this.$loadScript('https://www.paypalobjects.com/webstatic/ppplus/ppplus.min.js')
          .then(this.refreshPayPalPlus)
          .catch(() => {
            console.warn('PayPal couldn\'t load')
          })
      },
      refreshPayPalPlus() {
        this.paymentLoading = true
        var me = this
        this.$http.post('/api/ppp/init', this.cart)
          .then((res) => {
            this.ppp.paymentId = res.body.paymentInfo.paymentId
            me.ppp.linkPay = res.body.link_pay
            me.ppp.mode = res.body.mode

            var ppp = window.PAYPAL.apps.PPP({
              'approvalUrl': me.ppp.linkPay,
              'placeholder': 'ppplus',
              'language': (navigator.language || navigator.userLanguage).replace('-', '_'),
              'mode': me.ppp.mode,
              'preselection': 'paypal',
              'country': me.primary.country,
              'useraction': 'commit',
              'buttonLocation': 'outside',
              'showLoadingIndicator': false,
              'thirdPartyPaymentMethods':
                [ {
                  'redirectUrl': 'https://shirtigo.de',
                  'methodName': this.$t('views.checkout.ppp.method'),
                  'imageUrl': 'https://www.seedshirt.de/images/prepayment.svg',
                  'description': this.$t('views.checkout.ppp.description')
                } ],
              'onThirdPartyPaymentMethodSelected': function (args) {
                setTimeout(function () {
                  if (args.thirdPartyPaymentMethod === 'Vorkasse (SEPA)') {
                    me.ppp.paymentMethod = 'prepayment'
                  }
                })
              },
              'onThirdPartyPaymentMethodDeselected': function (args) {
                me.ppp.paymentMethod = null
              },
              // PayPal does no longer allow callbacks here, so we have to enable/disable the button directly
              'disableContinue': 'ppp_continue',
              'enableContinue': 'ppp_continue'
            })
            window.setTimeout(() => {
              this.paymentLoading = false
            }, 1000)
            this.ppp.handle = ppp
          })
      },
      submitCheckout() {
        if (this.redirectLoading) {
          return
        }

        this.setOrderDetails({
          coupon_code: this.orderDetails ? this.orderDetails.coupon_code : null,
          primary: this.primary,
          shipping: this.shipping,
          shipping_equals_primary: this.shipping_equals_primary,
          shipping_method: this.shipping_method,
          details: this.details
        })

        this.validateAndContinue()
      },
      async startPaymentProcess() {
        try {
          await this.checkoutCart({ formData: this.formData })
        } catch (e) {
          if (e.request.status === 404) {
            this.errorMessage = this.$t('views.checkout.error_product_failed')
          }
          if (e.request.status === 403) {
            this.errorMessage = e.errorMessage
          } else {
            this.errorMessage = this.$t('views.checkout.error_unknown')
          }
          this.redirectLoading = false
          window.scrollTo(0, 0)
          return
        }

        sessionStorage.setItem('payment_method', this.ppp.paymentMethod || 'ppp')
        if (this.ppp.paymentMethod === 'prepayment') {
          this.$router.push({ name: 'checkout-confirm', params: {reference: this.cart.reference} })
          return
        }

        this.startPayment({ paymentData: this.paymentData }).then(response => {
          if ((typeof response.orderSaved !== 'undefined') && response.orderSaved === 'ok') {
            this.ppp.handle.doContinue()
          }
        })
      },
      async validateAndContinue() {
        this.redirectLoading = true
        try {
          await this.validateOrderDetails()
        } catch (e) {
          this.redirectLoading = false

          if (e.response && e.response.data && e.response.data.errors) {
            this.errorMessage = this.$t('views.checkout.error_fields_required')

            // Reset form errors
            this.formErrors = { primary: {}, shipping: {}, details: {} }

            for (const [ key, val ] of Object.entries(e.response.data.errors)) {
              if (key.includes('primaryAddress')) {
                this.formErrors.primary[key.replace('primaryAddress.', '')] = val
              } else if (key.includes('deliveryAddress')) {
                this.formErrors.shipping[key.replace('deliveryAddress.', '')] = val
              } else {
                this.formErrors.details[key] = val
              }
            }
            window.location.href = '#'
          } else {
            this.errorMessage = this.$t('views.checkout.error_unknown')
          }
          return
        }

        // All clear!
        this.startPaymentProcess()
      },
      calculatePrice(updatePP = false) {
        this.updatePrice().catch()

        if (updatePP) {
          this.refreshPayPalPlus()
        }
      },
      ...mapActions([
        'setOrderDetails',
        'validateOrderDetails',
        'startPayment',
        'checkoutCart',
        'deleteCart',
        'deleteOrderDetails',
        'setCartCountry'
      ])
    },
    computed: {
      countryName() {
        return window.__COUNTRIES__.filter(c => c.code === this.primary.country)[0].name
      },
      formData() {
        let fd = {
          'cart_reference': this.cart.reference,
          'is_user_sale': false,
          'coupon_code': this.orderDetails.coupon_code,
          'agb_checkbox': '1', // These boxes will be checked at a later date (order confirmation)
          'age_checkbox': '1',
          'validPayment': '1', // PayPal will disable the button if it's not ready
          'primaryAddress': this.primary,
          'user_reference': this.details.reference,
          'comment': this.details.comment
        }
        if (this.shipping_equals_primary) {
          fd['deliveryAddress'] = 0
        } else {
          fd['deliveryAddress'] = this.shipping
        }

        return fd
      },
      paymentData() {
        let pd = {}
        if (this.shipping_equals_primary) {
          pd['address'] = JSON.stringify(this.primary)
        } else {
          pd['address'] = JSON.stringify(this.shipping)
        }

        pd['paymentId'] = this.ppp.paymentId
        pd['paymentMethod'] = this.ppp.paymentMethod || 'ppp'

        return pd
      },
      ...mapGetters([
        'cart',
        'orderDetails',
        'isCheckedOut',
        'cartIsEmpty',
      ])
    },

    watch: {
      'primary.country'() {
        if (!this.primary.country) {
          return
        }
        this.cart.delivery_iso = this.primary.country
        this.setCartCountry(this.primary.country)
        this.paymentLoading = true

        this.calculatePrice(true)
      }
    },

    components: {
      LoaderFullPage,
      BenefitsFooter,
      ShirtigoCheckoutHeader,
      ConfigFooter,
      AddressSelection,
    },

  }
</script>

<style lang="scss" scoped>
  body {
    background: #f8f9fa;
  }

  .load8 {
    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      min-height: 10em;
    }

    .loader {
      margin: 4em auto 4.75em;
      font-size: .5em;
      position: relative;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(#90a0b0, 0.2);
      border-right: 1.1em solid rgba(#90a0b0, 0.2);
      border-bottom: 1.1em solid rgba(#90a0b0, 0.2);
      border-left: 1.1em solid rgba(#90a0b0, 1);
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }

    .loader-text {
      text-align: center;
      color: #3e3e3e;
      font-weight: 400;
      font-size: 1rem;
      margin-top: -1em;
    }

    &.dark {
      .loader {
        border-color: rgba(#ccc, .5);
        border-left-color: #ccc;
      }
    }
  }

  @mixin load8-frames() {

    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }

  }

  @keyframes load8 {
    @include load8-frames;
  }
</style>

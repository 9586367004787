<template>
  <div>
    <table class="bg-grey-10 px-1 table-auto w-full rounded text-grey-70">
      <thead>
        <tr class="border-b border-white">
          <th
            class="text-grey-70 font-semibold uppercase text-sm text-left pl-4"
            style="width: 200px;"
          >
            {{ $t('global.color') }}
          </th>

          <th
            v-for="(size, key) in allAvailableSizes"
            :key="key"
            class="py-2 px-1 mt-px text-grey-70 text-sm font-semibold"
          >
            {{ size.size }}
          </th>

          <th>&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(row, colorId) in colorMatrix">
          <tr
            :key="`${colorId}.inputs`"
            class="bg-grey-00 border-b border-white text-sm"
          >
            <td class="py-3 pr-1 pl-4 font-light flex">
              <span
                class="block h-8 w-8 rounded-full border-white mr-3"
                style="box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); border-width: 3px"
                :style="{ backgroundColor: `#${row.color.hexcode}` }"
              />
              <span class="block mt-1">
                {{ row.color.color_name }}
              </span>
            </td>

            <td
              v-for="(size, key) in allAvailableSizes"
              :key="key"
              class="py-3 px-1 text-center"
            >
              <input
                v-if="sizeIsActiveForRow(size, row.sizes)"
                :name="`${getSizeForRow(size, row.sizes).sku}`"
                :disabled="readOnly"
                type="text"
                placeholder="0"
                :value="getSizeAmountForVariantId(getSizeForRow(size, row.sizes).sku)"
                :class="{'h-9 border rounded text-center w-3/4': true, 'text-gray-90 bg-gray-10': readOnly}"
                @change="updateSizeAmountForVariantId(getSizeForRow(size, row.sizes).sku, $event.target.value)"
              >

              <input
                v-else
                value="–"
                disabled="disabled"
                class="h-9 border rounded text-center text-gray-50 bg-gray-00 w-3/4"
              >
            </td>

            <td class="py-3 pl-1 pr-4 text-blue-50">
              <button
                v-if="!readOnly && Object.keys(colorMatrix).length > 1"
                class="cursor-pointer"
                :title="$t('global.remove')"
                @click.prevent="removeColor(colorId)"
              >
                <i class="icon-trash-2-outline text-xl" />
              </button>
            </td>
          </tr>
        </template>

        <tr class="bg-grey-00 font-light border-b-4 border-white text-sm">
          <td class="py-3 pr-1 pl-4">
            {{ $t('views.sizes_config.color_table.price') }}
          </td>

          <td
            v-for="(size, key) in allAvailableSizes"
            :key="key"
            class="py-3 px-1 font-light text-center"
          >
            <loader
              v-if="loading"
              size="small"
              color="grey-70"
              class="w-full h-4 text-center"
            />
            <span
              v-else-if="product.avg_item_price_gross"
              class="leading-4"
            >
              {{
                {
                  amount: product.avg_item_price_gross,
                  currency_symbol: '',
                  currency_code: 'EUR',
                  currency_factor: 100
                } | currency
              }} &euro;
            </span>
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import ConfigProductColorTable from '../../../mixins/ConfigProductColorTable'
  import Loader from '../../layout/Loader.vue'

  export default {

    mixins: [ ConfigProductColorTable ],

    components: { Loader },

  }
</script>

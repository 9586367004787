<template>
  <div
    v-if="baseProduct"
    class="bg-white border px-4 pt-6 pb-8 mt-6 rounded-lg"
  >
    <div class="flex flex-col-reverse lg:flex-row lg:items-center">
      <design-preview
        class="flex justify-start mt-5 lg:mt-0 lg:mr-6"
        :base-product="baseProduct"
        :current-color-id="parseInt(product.variants[0].color_id, 10)"
        :previews="previews"
      />

      <div class="w-full">
        <div class="text-lg font-bold flex items-center">
          {{ `${amount}x ${baseProduct.name}` }}
        </div>

        <button
          class="text-blue-50 flex items-center"
          @click.prevent="showProductInfo = true"
        >
          {{ $t('views.sizes_config.product_group.product_information') }}
          <i class="icon-info-outline ml-1" />
        </button>
      </div>
    </div>

    <config-product-color-table-mobile
      class="lg:hidden mt-6"
      :base-product="baseProduct"
      :product="product"
      :loading="loading"
      :read-only="readOnly"
      @updateAmount="updateSizeAmountForProduct"
      @removeColor="removeAllVariantsForColor"
    />

    <config-product-color-table-desktop
      class="hidden lg:block mt-6"
      :base-product="baseProduct"
      :product="product"
      :loading="loading"
      :read-only="readOnly"
      @updateAmount="updateSizeAmountForProduct"
      @removeColor="removeAllVariantsForColor"
    />

    <modal
      v-if="showProductInfo"
      @close:modal="showProductInfo = false"
    >
      <product-info-popup :base-product-details="baseProduct" />
    </modal>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { PropOptions } from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import _debounce from 'lodash/debounce'
  import ConfigProductColorTableMobile from '@/components/config/colors/ConfigProductColorTableMobile'
  import ConfigProductColorTableDesktop from '@/components/config/colors/ConfigProductColorTableDesktop'
  import ProductInfoPopup from '@/components/config/ProductInfoPopup'
  import DesignPreview from '@/components/config/sizes/DesignPreview'
  import Modal from 'sb/elements/Modals/Modal'
  import { getBaseProductVariantId } from '../../../utils/helpers'

  export default {

    props: {
      loading: {
        type: Boolean,
        default: false
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      /** @type {PropOptions<ApiFormattedProduct>} */
      product: {
        type: Object,
        required: true
      },
      /** @type {PropOptions<ManufacturingJobPreview[]>} */
      previews: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        showProductInfo: false
      }
    },

    methods: {
      /**
       * @param {string} colorId
       */
      removeAllVariantsForColor(colorId) {
        this.product.variants
          .map((variant) => variant.baseproduct_variant_id)
          .filter((id) => id.match(new RegExp(`\\d+\\.${colorId}\\.\\d+`)))
          .forEach((baseProductVariantId) => {
            this.removeProductVariantFromCart({
              baseProductId: this.baseProduct.id,
              baseProductVariantId,
            })
          })

        this.sendUpdateEvent()
      },
      /**
       * @param {string} amount
       * @param {string} baseproduct_variant_id
       */
      updateSizeAmountForProduct(amount, baseproduct_variant_id) {
        this.updateOrAddProductVariantInCurrentGroup({
          baseProductId: this.baseProduct.id,
          variant: {
            amount: parseInt(amount) || 0,
            baseproduct_variant_id,
          },
        })

        this.sendUpdateEvent()
      },
      sendUpdateEvent: _debounce(function () {
        this.$emit('update')
      }, 500),
      ...mapActions([
        'removeProductVariantFromCart',
        'updateOrAddProductVariantInCurrentGroup',
      ])
    },

    computed: {
      amount() {
        if (!this.product) return 0

        if (this.product.amount) {
          return this.product.amount
        }

        return this.product.variants.reduce((sum, { amount }) => sum + amount, 0)
      },
      baseProduct() {
        return this.baseProducts.find(({ id }) => this.product.base_product_id === id)
      },
      availableColors() {
        if (!this.baseProduct) return []

        // Only return colors that aren't already added to cart
        return this.baseProduct.variants.data.filter(({ id }) => {
          return !this.product.variants.some((variant) => variant.color_id === id)
        })
      },
      ...mapGetters([
        'baseProducts',
        'cart'
      ])
    },

    components: {
      DesignPreview,
      ProductInfoPopup,
      ConfigProductColorTableMobile,
      ConfigProductColorTableDesktop,
      Modal,
    }

  }
</script>

<template>
  <div
    :title="color.color_name"
    :class="['color-swatch mr-3 mb-3', { active: color.id === currentColor.id }]"
    :style="`background-color: #${color.hexcode}`">
  </div>
</template>

<script>
  export default {
    props: {
      color: {
        type: Object,
        required: true
      },
      currentColor: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>

<style lang="scss" scoped>
.color-swatch {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  margin-bottom: 12px;
  float: left;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 50px;
  background-color: #fafafa;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: 'Fa solid 900', sans-serif;
  font-size: 12px;
  line-height: 3px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px #039be5;
  }

  &:focus {
    // TODO
  }

  &.active {
    box-shadow: 0 0 0 2px #039be5;
  }
}
</style>

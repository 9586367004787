<template>
  <div v-if="selectedProcessingSpecification">
    <create-control-element
      ref="creator"
      :designer-fonts="designerFonts"
      :processing-items="selectedProcessingSpecification.processing_items"
      :disable-features="disableFeatures"
      :selected-processing-item="selectedProcessingItem"
      :color="selectedColor"
      @select-graphic="$emit('select-graphic')"
      @upload-graphic="e => $emit('upload-graphic', e)"
      @processing-items-updated="updateProcessingItems">
      <template v-slot:upload>
        <slot name="upload"></slot>
      </template>
    </create-control-element>
    <designer
      :color="selectedColor"
      :scale="scale"
      :designer-fonts="designerFonts"
      :printarea="selectedPrintarea"
      :processing-items="selectedProcessingSpecification.processing_items"
      :mockup="mockup"
      @processing-items-updated="updateProcessingItems"
      @processing-item-click="onProcessingItemClick"
      @validity-changed="e => $emit('validity-changed', e)"
    >
      <slot></slot>
    </designer>
    <printarea-selection
      :printareas="baseProduct.printareas.data"
      :selected-printarea="selectedPrintarea"
      @select="selectPrintarea"
    />
    <color-selection
      :available-colors="baseProduct.variants.data"
      :current-color="selectedColor"
      :has-more="false"
      @select="selectColor"
    />
  </div>
</template>

<script>
  import Designer from 'sb/modules/Designer/Designer'
  import ColorSelection from 'sb/elements/Designer/Controls/ColorSelection'
  import PrintareaSelection from 'sb/elements/Designer/Controls/PrintareaSelection'
  import CreateControlElement from 'sb/elements/Designer/Controls/CreateControlElement'

  export default {
    props: {
      disableFeatures: {
        type: Array,
        // Possible options: textInput, colorSelection
        default: () => []
      },
      baseProduct: {
        type: Object,
        required: true
      },
      designerFonts: {
        type: Array,
        required: true
      },
      processingSpecifications: {
        type: Array,
        required: true
      },
      selectedColor: {
        type: Object,
        required: false
      },
      scale: {
        type: Number,
        default: 1,
        required: false
      },
      mockup: {
        type: Object,
        required: true
      },
    },

    data() {
      return {
        selectedProcessingSpecification: null,
        selectedPrintarea: null,
        selectedProcessingItem: null
      }
    },

    mounted() {
      this.initializeBaseProduct()
      this.determineProcessingSpecification()
    },

    methods: {
      initializeBaseProduct() {
        this.selectedPrintarea = this.baseProduct.printareas.data[0]
        if (!this.selectedColor) {
          this.selectColor(this.baseProduct.variants.data[0])
        }
      },
      updateProcessingItems(processingItems) {
        this.selectedProcessingSpecification.processing_items = processingItems
        this.$emit('processing-items-updated', processingItems)
      },
      onProcessingItemClick(processingItem) {
        this.selectedProcessingItem = processingItem
        this.$refs.creator.showEditFor(processingItem)
      },
      selectPrintarea(printarea) {
        this.selectedPrintarea = printarea
        this.determineProcessingSpecification()
        this.$emit('printarea-selected', printarea)
      },
      selectColor(color) {
        this.$emit('color-selected', color)
      },
      determineProcessingSpecification() {
        this.selectedProcessingSpecification = this.processingSpecifications.find(processingSpecification => processingSpecification.area === this.selectedPrintarea.key)
        if (!this.selectedProcessingSpecification) {
          // need to create one
          this.selectedProcessingSpecification = { area: this.selectedPrintarea.key, processing_items: [] }
          this.processingSpecifications.push(this.selectedProcessingSpecification)
        }
      }
    },

    watch: {
      baseProduct() {
        this.initializeBaseProduct()
      }
    },

    components: { CreateControlElement, PrintareaSelection, ColorSelection, Designer }
  }
</script>

<template>
  <div>
    <template v-if="orderDetails">
      <div class="lg:grid mt-6 grid-cols-3 grid-flow-row auto-rows-max auto-cols-max">
        <div class="w-full py-2 lg:px-2 lg:pl-0">
          <address-confirmation-display
            :address="orderDetails.primary"
            :readonly="readonly"
            :name="$t('views.thank_you.cart_overview.billing_address') || ''"
          />
        </div>

        <div class="w-full py-2 lg:px-2">
          <address-confirmation-display
            :readonly="readonly"
            :address="orderDetails.shipping_equals_primary ? orderDetails.primary : orderDetails.shipping"
            :name="$t('views.thank_you.cart_overview.delivery_address') || ''"
          />
        </div>

        <div class="w-full py-2 lg:px-2 lg:pr-0">
          <div class="bg-white border h-full rounded px-4 py-6">
            <div class="text-blue-80 font-bold">
              {{ $t('views.thank_you.cart_overview.payment_method') }}

              <i
                v-if="!readonly"
                class="icon-edit-outline cursor-pointer text-blue-50"
                :title="$t('global.edit')"
                @click="$router.push({ name: 'checkout', params: {reference: cart.reference } })"
              />
            </div>

            <div class="text-gray-50 text-sm">
              {{ $t(`views.thank_you.cart_overview.payment_method_${paymentMethod === 'prepayment' ? 'prepayment' : 'paypal'}`) }}
            </div>

            <div class="text-blue-80 font-bold mt-6">
              {{ $t('views.thank_you.cart_overview.production') }}

              <i
                v-if="!readonly"
                class="icon-edit-outline cursor-pointer text-blue-50"
                :title="$t('global.edit')"
                @click="$router.push({ name: 'checkout', params: {reference: cart.reference } })"
              />
            </div>

            <div class="text-gray-50 text-sm">
              {{ orderDetails.shipping_method === 'default' ? 'Standard' : orderDetails.shipping_method }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <cart-collection
      v-for="collection in orderedCartCollections"
      :key="collection.groupId"
      :products="collection.products"
      :processings="collection.processings"
      :previews="collection.previews"
      :title="collection.groupName"
      :loading="isLoadingCart || isLoadingPrices"
      :readonly="readonly"
      @update="updatePrice"
      @remove-collection="removeCollection(collection.groupId)"
      @remove-product="(baseProductId) => removeProduct(baseProductId, collection.groupId)"
      @edit-product="(baseProductId) => editProduct(baseProductId, collection.groupId)"
    />

    <confirm-modal
      v-if="emptyCartConfirm"
      @confirm="emptyCart()"
      @close="emptyCartConfirm = false"
    >
      <strong>{{ $t('views.cart.empty_cart.confirm') }}</strong>
    </confirm-modal>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import AddressConfirmationDisplay from './AddressConfirmationDisplay'
  import CartProductGroup from './CartProductGroup'
  import Prices from '../../../mixins/Prices'
  import { findManufacturingJobForProductInGroup } from '@/utils/helpers'
  import CartCollection from '@/components/config/checkout/CartCollection.vue'
  import ConfirmModal from '@/components/layout/ConfirmModal.vue'

  export default {

    name: 'CartOverview',

    mixins: [Prices],

    props: {
      paymentMethod: String,
      readonly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        emptyCartConfirm: false,
      }
    },

    mounted() {
      this.calcualtePrice()
    },

    methods: {
      editCollection(groupId) {
        this.setCurrentManufacturingJobGroupId(groupId)
        this.setCurrentManufacturingJobUuid(this.getJobUuidForGroupId(groupId))

        this.$router.push({ name: 'mpc-size-select', params: { groupid: groupId, reference: this.cart.reference } })
      },
      removeCollection(groupId) {
        if (Object.keys(this.allCartProductsForCurrentGroup).length === 1) {
          this.emptyCartConfirm = true
        } else {
          this.removeAllManufacturingJobsForGroup(groupId)
        }

        Vue.nextTick(() => this.updatePrice())
      },
      removeProduct(baseProductId, groupId) {
        if (this.allCartProducts.length === 1) {
          this.emptyCartConfirm = true
          return
        }

        this.setCurrentManufacturingJobGroupId(groupId)
        const jobForProduct = findManufacturingJobForProductInGroup(this.cart, groupId, baseProductId)
        this.setCurrentManufacturingJobUuid(jobForProduct.uuid || this.getJobUuidForGroupId(groupId))

        this.removeProductFromJob({
          manufacturingJobUuid: jobForProduct.uuid,
          baseProductId,
        })

        Vue.nextTick(() => this.updatePrice())
      },
      editProduct(baseProductId, groupId) {
        this.setCurrentManufacturingJobGroupId(groupId)
        const jobForProduct = findManufacturingJobForProductInGroup(this.cart, groupId, baseProductId)
        this.setCurrentManufacturingJobUuid(jobForProduct.uuid || this.getJobUuidForGroupId(groupId))

        this.$router.push({
          name: this.isMpc ? 'mpc-designer' : 'single-product-designer',
          params: { id: baseProductId, reference: this.cart.reference, groupid: groupId }
        })
      },
      /**
       * @param {ApiFormattedManufacturingJobGroupId} groupId
       * @return {ApiFormattedManufacturingJobUuid}
       */
      getJobUuidForGroupId(groupId) {
        const firstJobInGroup = this.cart.manufacturing_jobs.data.find((job) => {
          return job.group_id === groupId
        })

        return firstJobInGroup ? firstJobInGroup.uuid : 0
      },
      calcualtePrice() {
        this.updatePrice().catch()
      },
      emptyCart() {
        this.deleteCart()
        this.deleteOrderDetails()

        window.location.href = '/'
      },
      ...mapActions([
        'deleteCart',
        'deleteOrderDetails',
        'setCurrentManufacturingJobGroupId',
        'setCurrentManufacturingJobUuid',
        'removeAllManufacturingJobsForGroup',
        'removeProductFromJob',
      ])
    },

    computed: {
      ...mapGetters([
        'orderDetails',
        'cart',
        'isCheckedOut',
        'allCartProducts',
        'allCartProductsForCurrentGroup',
        'isMpc',
        'isLoadingCart',
        'isLoadingPrices',
        'orderedCartCollections'
      ])
    },

    components: {
      ConfirmModal,
      CartCollection,
      CartProductGroup,
      AddressConfirmationDisplay,
    },

  }
</script>

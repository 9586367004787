<template>
  <div class="flex">
    <div class="bg-grey-00 min-h-full flex-1">
      <div class="min-h-footer-padding">
        <config-side-header @discard="discardCollection">
          <header-nav-steps />
        </config-side-header>

        <main class="config-container">
          <h1 class="config-headline mb-4">
            {{ $t('views.mpc_overview.headline') }}
          </h1>

          <div class="bg-blue-00 border border-blue-30 text-blue-50 leading-6 rounded p-4 pr-10 mb-6 flex">
            <i class="h-5 w-5 mr-2 mt-px font-bold text-xl icon-info-outline" />
            <p v-html="$t('views.mpc_overview.alert')" />
          </div>

          <template v-if="allCartProductsForCurrentGroup">
            <div class="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 gap-4">
              <mpc-product-card
                v-for="product in allCartProductsForCurrentGroup"
                :key="product.base_product_id"
                :product="product"
                @update="updatePrice"
              />
            </div>
          </template>

          <config-mobile-bottom
            v-if="cart"
            :price-for-group="currentPriceObject"
            :cart="cart"
            :loading="isLoadingCart || !currentPriceObject"
            :submit-button-text="$t('views.mpc_overview.submit_button')"
            @update="updatePrice"
            @submit="loadNextStep"
          />

          <h2 class="text-h6 text-dark-blue font-bold mt-10">
            {{ $t('views.mpc_overview.add_products.headline') }}
          </h2>

          <add-product-button
            @click="openSelectProductsDialog"
          />
        </main>

        <modal
          v-if="showSelectProductsDialog"
          @close:modal="closeSelectProductsDialog"
        >
          <select-products-dialog
            @select-product="selectProduct"
            @close="closeSelectProductsDialog"
          />
        </modal>
      </div>

      <config-footer />
    </div>

    <config-desktop-sidebar
      :show-processings="false"
      :collection-name="collectionName"
      :price-for-group="currentPriceObject"
      :loading="isLoadingCart || !currentPriceObject"
      submit-button-text="Größen wählen"
      @removeProduct="removeProduct($event)"
      @update="updatePrice"
      @submit="loadNextStep"
    />

    <confirm-modal
      v-if="showRemoveProductAlert"
      @confirm="removeProductConfirmed"
      @close="cancelRemoveProduct"
    >
      <strong>{{ $t('views.cart.product_group.remove_product_confirm') }}</strong>
    </confirm-modal>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Prices from '../../mixins/Prices'
  import ConfigSideHeader from '@/components/config/header/ConfigSideHeader'
  import ConfigFooter from '@/components/config/ConfigFooter'
  import ConfigDesktopSidebar from '@/components/config/sidebar/ConfigDesktopSidebar'
  import MpcProductCard from '@/components/config/mpc/MpcProductCard'
  import ConfigMobileBottom from '@/components/config/sidebar/ConfigMobileBottom'
  import Modal from 'sb/elements/Modals/Modal'
  import AddProductButton from '@/components/config/AddProductButton.vue'
  import SelectProductsDialog from '@/components/config/productSelect/SelectProductsDialog'
  import ConfirmModal from '@/components/layout/ConfirmModal.vue'
  import Cart from '@/mixins/Cart'
  import HeaderNavSteps from '@/components/config/header/HeaderNavSteps.vue'

  export default {

    mixins: [ Prices, Cart ],

    data() {
      return {
        showSelectProductsDialog: false,
        showRemoveProductAlert: false,
        removeProductId: null,
      }
    },

    mounted() {
      this.updatePrice(true).catch()

      this.setIsMpc(true)

      if (this.cartIsEmpty) {
        this.$router.replace({ name: 'mpc-calculator' })
      }

      this.determineCurrentManufacturingJob()
    },

    methods: {
      discardCollection() {
        if (confirm(this.$t('views.designer.discard_collection.confirm') || '')) {
          this.removeAllManufacturingJobsForGroup(this.currentManufacturingJobGroupId)
          this.$router.push({ name: 'cart', params: {reference: this.cart.reference} })
        }
      },
      determineCurrentManufacturingJob() {
        let groupId = this.$route.params.groupid
        if (typeof groupId === 'undefined') {
          groupId = Object.keys(this.manufacturingJobProductsMapping)[0]
        }

        const baseProductsMapping = this.manufacturingJobProductsMapping[groupId]

        if (groupId === '0') {
          this.setCurrentManufacturingJobGroupId(0)
          this.setCurrentManufacturingJobUuid(0)
        } else {
          this.setCurrentManufacturingJobGroupId(groupId)
        }

        if (baseProductsMapping) {
          this.setCurrentManufacturingJobUuid(Object.values(baseProductsMapping)[0])
        }
      },
      async loadNextStep() {
        await this.updateCart()
        await this.$router.push({
          name: 'mpc-size-select',
          params: {
            reference: this.cart.reference,
            groupid: this.currentManufacturingJobGroupId
          }
        })
      },
      openSelectProductsDialog() {
        this.showSelectProductsDialog = true
      },
      async selectProduct(baseProduct) {
        if (this.isMpc) {
          this.setIsMpc(true)
        }

        if (!this.cart) {
          await this.initEmptyCart(baseProduct)
        } else {
          await this.addBaseProductToCart(baseProduct)
          this.updatePrice().catch()
        }

        this.closeSelectProductsDialog()
      },
      closeSelectProductsDialog() {
        this.showSelectProductsDialog = false
      },
      removeProduct(baseProductId) {
        this.removeProductId = baseProductId
        this.showRemoveProductAlert = true
      },
      removeProductConfirmed() {
        if (!this.removeProductId) return

        this.removeProductFromCart(this.removeProductId)
        this.updatePrice().catch()

        this.removeProductId = null
        this.showRemoveProductAlert = false
      },
      cancelRemoveProduct() {
        this.removeProductId = null
        this.showRemoveProductAlert = false
      },
      ...mapActions([
        'setIsMpc',
        'setCurrentManufacturingJobGroupId',
        'setCurrentManufacturingJobUuid',
        'removeProductFromCart',
        'removeAllManufacturingJobsForGroup',
      ]),
    },

    computed: {
      /**
       * @returns {ManufacturingJobPriceItem | null}
       */
      currentPriceObject() {
        if (!this.currentManufacturingJob) return null
        return this.priceForGroup(this.currentManufacturingJob.group_id)
      },
      collectionName() {
        if (!this.currentManufacturingJob) return undefined

        return this.currentManufacturingJob.group_name || undefined
      },
      ...mapGetters([
        'cart',
        'isMpc',
        'allCartProductsForCurrentGroup',
        'cartIsEmpty',
        'isLoadingCart',
        'currentManufacturingJob',
        'manufacturingJobProductsMapping',
        'currentManufacturingJobGroupId',
        'currentManufacturingJobUuid',
      ])
    },

    components: {
      HeaderNavSteps,
      ConfirmModal,
      ConfigSideHeader,
      ConfigFooter,
      ConfigDesktopSidebar,
      MpcProductCard,
      ConfigMobileBottom,
      Modal,
      AddProductButton,
      SelectProductsDialog,
    },

  }
</script>

<style lang="scss" scoped>
  body {
    background: #f8f9fa;
  }
</style>

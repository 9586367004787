<template>
  <div class="absolute left-0 top-0 pt-32 2xl:pt-48 w-24 pl-4 gap-4 flex flex-col justify-center">
    <button
      v-for="printarea in printareas"
      :key="printarea.key"
      :title="printarea.name"
      :class="[
        'bg-white w-20 h-20 box-content border rounded-md transition-all ease-in-out duration-200 items-center justify-center',
        selectedPrintarea && selectedPrintarea.key === printarea.key ? 'border-blue-50' : 'border-gray-20 hover:border-gray-30'
      ]"
      @click="$emit('select', printarea)"
    >
      <mockup-preview
        :color="color"
        :printarea="printarea"
        :scale="0.15"
        :processing-items="processingItemsForPrintarea(printarea.key)"
        :designer-fonts="designerFonts"
        :mockup="mockupForPrintarea(printarea.key)"
      />
    </button>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import MockupPreview from 'sb/elements/Designer/MockupPreview.vue'
  import { PropOptions } from 'vue'

  export default {

    props: {
      /** @type {PropOptions<BaseProductPrintarea[]>} */
      availablePrintareas: {
        type: Array,
        required: true,
      },
      /** @type {PropOptions<BaseProductPrintarea[]>} */
      usedPrintareas: {
        type: Array,
        required: true,
      },
      /** @type {PropOptions<BaseProductPrintarea|null>} */
      selectedPrintarea: {
        type: Object,
        required: false,
      },
      /** @type {PropOptions<ApiFormattedProcessing[]>} */
      processingSpecifications: {
        type: Array,
        required: true
      },
      /** @type {PropOptions<BaseProductMockup[]>} */
      mockups: {
        type: Array,
        required: false,
      },
      /** @type {PropOptions<DesignerFontFamily[]>} */
      designerFonts: {
        type: Array,
        required: true
      },
      /** @type {PropOptions<BaseProductColorVariant|null>} */
      color: {
        type: Object,
        required: false,
      }
    },

    data() {
      return {}
    },

    methods: {
      /**
       * @param {string} key
       * @return {BaseProductMockup|undefined}
       */
      mockupForPrintarea(key) {
        return this.mockups.find((mockup) => mockup.processingarea_key === key) ?? undefined
      },
      processingItemsForPrintarea(key) {
        const specification = this.processingSpecifications.find((processingSpecification) => {
          return processingSpecification.processingarea_type_key === key
        })

        if (!specification || !specification.processing_items.data) return []

        return specification.processing_items.data.filter((processingItem) => processingItem.uuid && !processingItem.consolidated_id && processingItem.type === 'design')
      }
    },

    computed: {
      printareas() {
        return this.availablePrintareas.filter((printarea) => {
          return printarea.is_printable && this.mockupForPrintarea(printarea.key)
        })
      },
    },

    components: {
      MockupPreview
    }

  }
</script>

<template>
  <div class="app auth">
    <header class="auth__header">
      <img
        class="auth__brand-logo"
        src="/images/shirtigo-id-logo-color.svg"
        alt="Shirtigo ID">
      <p class="auth__header-label">
        <span>
          {{ $t('shirtigo.auth.link_to_register.label') }}
        </span>
        <sg-link
          class="auth__header-link"
          to="/login#register">
          {{ $t('shirtigo.auth.link_to_register.action') }}
        </sg-link>
      </p>
    </header>
    <div class="auth__content-wrapper">
      <h1 class="auth__headline">
        {{ $t('shirtigo.auth.headline') }}
      </h1>

      <p class="auth__body-text">
        {{ $t('shirtigo.auth.description_new_password') }}
      </p>

      <form
        class="auth__form"
        @submit.prevent="submitForm">
        <sg-form-input
          v-model="email"
          type="email"
          class="auth__form-input"
          class-name="input-lg"
          :placeholder="$t('shirtigo.auth.email_input_placeholder')"
          :value="email"
          autofocus
          required>
          {{ $t('shirtigo.auth.email_input_label') }}
        </sg-form-input>

        <sg-form-input
          v-model="password"
          type="password"
          class="auth__form-input"
          class-name="input-lg"
          :placeholder="$t('shirtigo.auth.password_input_placeholder')"
          :value="password"
          autofocus
          required>
          {{ $t('shirtigo.login.password_input_label') }}
        </sg-form-input>

        <sg-form-input
          v-model="passwordConfirmation"
          type="password"
          class="auth__form-input"
          class-name="input-lg"
          :placeholder="$t('shirtigo.auth.password_confirmation_input_placeholder')"
          :value="passwordConfirmation"
          autofocus
          required>
          {{ $t('shirtigo.login.password_confirm_input_label') }}
        </sg-form-input>

        <sg-btn
          type="submit"
          kind="primary"
          :disabled="!arePasswordsEqualAndNotEmpty()"
          class="btn-lg btn-fullwidth">
          {{ $t('shirtigo.auth.save_new_password') }}
        </sg-btn>

        <sg-link
          class="auth__login-link"
          to="/login">
          {{ $t('shirtigo.auth.secondary_action') }}
        </sg-link>
      </form>
    </div>
    <footer class="auth__footer-links">
      <a href="/imprint">
        {{ $t('shirtigo.auth.footer_nav.imprint') }}
      </a>
      <a href="/agb">
        {{ $t('shirtigo.auth.footer_nav.agb') }}
      </a>
      <a href="/privacy">
        {{ $t('shirtigo.auth.footer_nav.privacy') }}
      </a>
      <a href="/agb#widerrufsrecht">
        {{ $t('shirtigo.auth.footer_nav.withdrawal') }}
      </a>
    </footer>
  </div>
</template>

<script>
  import SgFormInput from '../../elements/Form/SgFormInput.vue'
  import SgBtn from '../../elements/Buttons/SgBtn.vue'
  import SgLink from '../../common/components/SgLink.vue'

  export default {

    data() {
      return {
        email: '',
        password: '',
        passwordConfirmation: '',
        token: this.getUrlToken()
      }
    },

    methods: {
      submitForm() {
        this.$emit('submit', {
          token: this.getUrlToken(),
          email: this.email,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation
        })
      },

      getUrlToken() {
        const query = window.location.search
        const urlParams = new URLSearchParams(query)
        window.console.log('Token: ', urlParams.get('token'))
        return urlParams.get('token')
      },

      arePasswordsEqualAndNotEmpty() {
        window.console.log('Checking if passwords are empty or equal.')
        return !!this.token &&
          this.password !== '' &&
          this.passwordConfirmation !== '' &&
          this.password === this.passwordConfirmation
      }
    },

    components: {
      SgFormInput,
      SgBtn,
      SgLink,
    },

  }
</script>

<template>
  <div>NOTES TODO</div>
</template>

<script>
  export default {
    props: {
      processingItem: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>

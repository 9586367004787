import accounting from 'accounting'

export default function (value) {
  if (typeof value === 'number') {
    value = {
      amount: value,
      currency_symbol: '€',
      currency_factor: 100,
      currency_code: 'EUR',
    }
  }

  // Parse money object, e.g. {amount: 225, currency_symbol: '€', currency_code: 'EUR', currency_factor: 100}
  if (typeof value === 'object' && value !== null) {
    let decimal = accounting.toFixed(value.amount / value.currency_factor, 2)
    value = accounting.formatMoney(decimal, {
      symbol: value.currency_symbol,
      format: '%v %s',
      decimal: ',',
      thousand: '.',
    })
  }

  return value
}

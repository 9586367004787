<template>
  <div class="lg:hidden">
    <div class="fixed bottom-0 left-0 px-2 pb-6 mb-mobile-bar">
      <icon-button @click="selectColor = true">
        <i class="icon-color-palette-outline" />
      </icon-button>
    </div>

    <div class="fixed bottom-0 right-0 px-2 pb-6 mb-mobile-bar">
      <div
        v-if="!isMpc"
        class="mb-2"
      >
        <icon-button @click="openAddElementDialog">
          <i class="icon-plus-outline" />
        </icon-button>
      </div>
      <div>
        <icon-button
          v-if="isMpc"
          @click="showMpcProductDetails = true"
        >
          <i class="icon-edit-outline" />
        </icon-button>

        <icon-button
          v-else
          @click="selectProduct = true"
        >
          <i class="icon-shirt" />
        </icon-button>
      </div>
    </div>

    <div class="fixed bottom-0 w-full">
      <div class="bg-grey-00 w-full border-t border-grey-10 py-2 px-4 flex justify-between items-center">
        <div>
          <div class="text-h5 text-blue-50 font-semibold">
            {{ currentPrice | currency }}
          </div>
          <div
            class="text-grey-50 tracking-4"
            style="font-size: .6rem"
          >
            {{ $t('views.designer.sidebar.includes_tax_shipping') }}
          </div>
        </div>

        <button
          class="flex items-center px-4 md:px-6 py-2 text-white rounded-full font-semibold leading-7 tracking-wide shadow-big h-11 xs:text-lg transition-all duration-200 font-nunito-sans"
          :class="loadingNextStep ? 'bg-gray-500' : 'bg-dark-blue hover:bg-blue-60'"
          :disabled="loadingNextStep || isSavingBaseDesign"
          @click.prevent="loadNextStep"
        >
          <template v-if="loadingNextStep || isSavingBaseDesign">
            {{ $t('global.loading') }}
          </template>
          <template v-else-if="isEditingMpcBaseDesign">
            {{ $t('views.designer.sidebar.submit_base_design') }}
          </template>
          <template v-else>
            {{ $t('views.designer.sidebar.submit_sizes') }}
          </template>

          <i class="fas fa-arrow-right text-sm ml-2 opacity-90" />
        </button>
      </div>

      <config-footer
        class-name="bg-grey-10 w-full text-xs pl-4 text-grey-50 py-2"
        :show-price-info="false"
      />
    </div>

    <div
      v-if="selectElement"
      class="absolute bottom-0 w-full pt-4 bg-white shadow-nav z-40"
    >
      <create-control-element
        v-if="designerRef"
        ref="creator"
        :color="currentBaseProductColor"
        :designer-fonts="designerFonts"
        :processing-items="designerRef.processingItems"
        :disable-features="[]"
        :selected-processing-item="designerRef.selectedProcessingItem"
        @close="selectElement = false"
        @selected-processing-item-updated="(e) => designerRef.selectProcessingItem(e)"
        @select-graphic="$emit('select-graphic')"
        @upload-graphic="(e) => $emit('upload-graphic', e)"
      >
        <template v-slot:upload>
          <asset-uploader
            v-if="designerRef.printarea"
            :area="designerRef.printarea.key"
            @asset-uploaded="assetUploaded"
            @upload:error="(e) => $parent.$parent.showError(e)"
          />
        </template>
      </create-control-element>
    </div>

    <mobile-modal
      v-if="selectProduct"
      :current-image="currentImage || {}"
      @close="selectProduct = false"
    >
      <template v-slot:title>
        {{ $t('views.designer.sidebar.tab_product') }}
      </template>

      <div class="flex flex-col h-full pb-20">
        <select-products
          :current-image="currentImage || {}"
          :available-colors="availableColors"
        />

        <div class="mt-6 ml-1 text-green-70">
          <strong class="font-bold">
            <i class="icon-car-outline inline-block mr-1" />
            {{ $t('views.product_details.standard_delivery_days') }}
          </strong>
          {{ $t('views.product_details.standard_delivery') }}
        </div>
      </div>
    </mobile-modal>

    <mobile-modal
      v-if="selectColor"
      :current-image="currentImage || {}"
      @close="selectColor = false"
    >
      <template v-slot:title>
        {{ $t('views.designer.sidebar.color') }}
      </template>

      <color-select-dropdown
        :available-colors="availableColors"
        :current-color="currentBaseProductColor"
        @update:color="updateColor"
      />
    </mobile-modal>

    <mobile-modal
      v-if="showMpcProductDetails"
      @close="closeMpcProductDetails"
    >
      <template v-slot:title>
        {{ isEditingMpcBaseDesign ? $t('views.designer.sidebar.edit_base_design') : $t('views.designer.sidebar.tab_design') }}
      </template>

      <div
        v-if="isEditingMpcBaseDesign"
        class="flex-1 pt-5"
      >
        <div
          class="text-blue-50 bg-blue-00 table border-blue-30 border rounded-lg mx-2 mb-4 px-6 py-4"
        >
          <div class="pr-4 table-cell align-middle pt-1">
            <span class="icon-info-outline text-2xl" />
          </div>

          <div class="table-cell align-middle">
            {{ $t('views.designer.sidebar.edit_base_design_info') }}
          </div>
        </div>

        <!-- TODO: outsource into new component for use in all sidebar content components -->
        <create-control-element
          v-if="designerRef"
          ref="creator"
          class="px-2"
          :color="currentBaseProductColor"
          :designer-fonts="designerFonts"
          :processing-items="designerRef.processingItems"
          :disable-features="[]"
          :selected-processing-item="designerRef.selectedProcessingItem"
          @selected-processing-item-updated="designerRef.selectProcessingItem($event)"
          @processing-items-updated="(updatedProcessingItems) => $parent.$parent.updateVisibleProcessingItems(updatedProcessingItems)"
          @select-graphic="$emit('select-graphic')"
          @upload-graphic="e => $emit('upload-graphic', e)"
        >
          <template v-slot:upload>
            <asset-uploader
              v-if="designerRef.printarea"
              :area="designerRef.printarea.key"
              @asset-uploaded="(d) => $parent.$parent.addCampaignAsset(d)"
              @upload:error="(e) => $parent.$parent.showError(e)"
            />
          </template>
        </create-control-element>

        <div class="py-6 md:px-6">
          <button
            class="submit-button"
            @click.prevent="$emit('saveBaseDesign')"
          >
            <span>
              {{ $t('views.designer.sidebar.submit_base_design') }}
            </span>
          </button>
        </div>
      </div>

      <template v-else>
        <div class="flex-1 p-6">
          <div class="bg-white border border-grey-10 py-4 px-6 text-grey-80 rounded leading-7 tracking-4">
            <p>
              {{ $t('views.designer.sidebar.width') }}: <strong>{{ printWidth }}</strong> mm
            </p>

            <p>
              {{ $t('views.designer.sidebar.height') }}: <strong>{{ printHeight }}</strong> mm
            </p>

            <p class="flex items-center">
              {{ $t('views.designer.sidebar.print_quality') }}:

              <span
                v-if="printQualityLabel"
                :class="`${printQualityColor} px-2 rounded-full ml-2 text-xs leading-5 inline-flex`"
              >
                {{ printQualityLabel }}
              </span>
            </p>
          </div>

          <button
            class="rounded-full border border-blue-50-50 text-blue-50 flex items-center justify-center py-2 px-6 mt-4 w-full hover:border-blue-50 transition-all duration-200 font-semibold"
            @click.prevent="$emit('openBaseDesignEditor')"
          >
            <i class="mr-1 icon-edit-outline text-xl" />
            <span>{{ $t('views.designer.sidebar.edit_base_design') }}</span>
          </button>
        </div>
      </template>
    </mobile-modal>

    <loader-full-page v-if="isSavingBaseDesign">
      {{ $t('global.loading') }}
    </loader-full-page>
  </div>
</template>

<script>
  // import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import MobileModal from '../../layout/MobileModal'
  import SelectProducts from '../productSelect/SelectProducts'
  import IconButton from '../../buttons/IconButton'
  import AssetUploader from '../designer/AssetUploader'
  import ConfigFooter from '../ConfigFooter.vue'
  import ColorSelectDropdown from '../colors/ColorSelectDropdown.vue'
  import LoaderFullPage from '../../layout/LoaderFullPage.vue'
  import CreateControlElement from 'sb/elements/Designer/Controls/CreateControlElement.vue'

  const INITIAL_PRINT_WIDTH = 220

  export default {

    props: {
      availableColors: {
        type: Array,
        required: true
      },
      currentPrice: {
        type: Number,
        required: true
      },
      currentPrintarea: {
        validator: prop => typeof prop === 'object' || prop == null,
        required: true
      },
      printareas: {
        type: Array,
        default: () => []
      },
      loadingNextStep: {
        type: Boolean,
        required: true
      },
      designerRef: {
        type: Object,
      },
      isEditingMpcBaseDesign: {
        type: Boolean,
      },
    },

    data() {
      return {
        selectColor: false,
        selectProduct: false,
        showTooltip: false,
        selectElement: false,
        showMpcProductDetails: false,
      }
    },

    methods: {
      assetUploaded(a) {
        this.selectElement = false
        this.$parent.$parent.addCampaignAsset(a)
      },
      /** @see ProductDesigner.vue -> onProcessingItemClick() */
      showEditFor() {
        if (!this.isMpc) {
          this.selectElement = true
        } else {
          this.showMpcProductDetails = true
          this.$emit('openBaseDesignEditor')
        }
      },
      openAddElementDialog() {
        if (this.designerRef) {
          this.designerRef.selectedProcessingItem = null
        }

        this.selectElement = true
      },
      updateColor(newColorId) {
        this.setCurrentColorId(newColorId)
        // Close modal
        this.selectColor = false
      },
      loadNextStep() {
        if (this.isEditingMpcBaseDesign) {
          this.$emit('saveBaseDesign')
        } else {
          this.$emit('nextStep')
        }
      },
      closeMpcProductDetails() {
        this.showMpcProductDetails = false
      },
      ...mapActions([
        'setCurrentColorId',
      ]),
    },

    computed: {
      // TODO: Eliminate duplicated code
      /**
       * @return {ApiFormattedProcessing|null}
       */
      currentProcessingSpecificationForPrintarea() {
        if (!this.currentPrintarea || !this.currentProcessingSpecifications) return null

        const specification = this.currentProcessingSpecifications
          .find(({ processingarea_type_key }) => processingarea_type_key === this.currentPrintarea.key)

        return specification ? Object.freeze(specification) : null
      },
      /**
       * @return {ApiFormattedDesignProcessingItem|null}
       */
      currentConsolidatedProcessingItem() {
        if (!this.currentProcessingSpecificationForPrintarea) return null

        return this.currentProcessingSpecificationForPrintarea.processing_items.data.find((item) => {
          return item.hasOwnProperty('type') && item.type === 'design' && item.uuid
        }) || null
      },
      printWidth() {
        if (!this.currentConsolidatedProcessingItem) return INITIAL_PRINT_WIDTH

        return this.currentConsolidatedProcessingItem.width_mm || INITIAL_PRINT_WIDTH
      },
      designAspectRatio() {
        if (this.currentConsolidatedProcessingItem) {
          return this.currentConsolidatedProcessingItem.aspect_ratio
        }

        return 1
      },
      /**
       * @return {string}
       */
      printHeight() {
        if (!this.designAspectRatio) {
          return '–'
        }

        const calculatedHeight = this.printWidth * this.designAspectRatio

        if (calculatedHeight % 10 !== 1) {
          return `~${Math.round(calculatedHeight)}`
        }

        return calculatedHeight.toString()
      },
      /** @return {string} */
      printQualityColor() {
        if (typeof (this.currentConsolidatedProcessingItem || {}).dpi !== 'number') {
          return ''
        }

        if (this.currentConsolidatedProcessingItem.dpi <= 100) {
          return 'bg-red-30 text-red-90'
        }

        if (this.currentConsolidatedProcessingItem.dpi >= 300) {
          return 'bg-green-30 text-green-90'
        }

        return 'bg-yellow-30 text-yellow-90'
      },
      /** @return {string|null} */
      printQualityLabel() {
        if (typeof (this.currentConsolidatedProcessingItem || {}).dpi !== 'number') {
          return null
        }

        if (this.currentConsolidatedProcessingItem.dpi <= 100) {
          return this.$t('views.designer.sidebar.print_quality_bad')
        }

        if (this.currentConsolidatedProcessingItem.dpi >= 300) {
          return this.$t('views.designer.sidebar.print_quality_excellent')
        }

        return this.$t('views.designer.sidebar.print_quality_good')
      },
      ...mapGetters([
        'currentBaseProduct',
        'currentBaseProductColor',
        'currentImage',
        'designerFonts',
        'isMpc',
        'isSavingBaseDesign',
      ]),
    },

    watch: {
      currentBaseProduct() {
        this.selectProduct = false
      }
    },

    components: {
      LoaderFullPage,
      ConfigFooter,
      AssetUploader,
      SelectProducts,
      IconButton,
      MobileModal,
      ColorSelectDropdown,
      CreateControlElement,
    }

  }
</script>

<style lang="scss" scoped>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer components {
    .submit-button {
      @apply mt-0 w-full px-4 py-2 h-11;
      @apply flex items-center justify-center;
      @apply font-nunito-sans font-semibold leading-7 text-white tracking-wide;
      @apply bg-dark-blue rounded-full shadow-big transition-all duration-200;

      &:hover {
        @apply bg-blue-60;
      }

      &:disabled {
        @apply bg-gray-500;
      }

      @media screen and (min-width: 480px) {
        @apply text-lg;
      }

      @media screen and (min-width: 768px) {
        @apply px-6;
      }
    }
  }
</style>

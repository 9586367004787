<template>
  <div class="bg-grey-00 min-h-screen">
    <div class="lg:flex h-full">
      <main class="h-full w-full lg:mr-120 overflow-hidden min-h-screen flex flex-col">
        <div class="flex-1 w-full h-full lg:relative mb-32 xl:my-16 flex justify-center items-center">
          <error-message
            :error="error"
            @hide="hideError"
          />

          <designer
            v-if="fontsLoaded && currentBaseProductColor && productMockup"
            ref="designer"
            :color="currentBaseProductColor"
            :designer-fonts="designerFonts"
            :scale="designerScale"
            :printarea="currentPrintarea || {}"
            :processing-items="visibleProcessingItems"
            :mockup="productMockup"
            :loading-mockup="isSplittingManufacturingJob"
            @processing-items-updated="updateVisibleProcessingItems($event)"
            @processing-item-deleted="deleteVisibleProcessingItem($event)"
            @validity-changed="updateValidity"
          >
            <add-design-button
              v-if="currentPrintarea && isEditingMpcBaseDesign"
              @click="uploadGraphic"
            />
          </designer>

          <designer-printarea-selection
            v-if="currentBaseProduct"
            :available-printareas="currentBaseProduct.printareas ? currentBaseProduct.printareas.data : []"
            :used-printareas="availablePrintareasForCurrentProduct"
            :selected-printarea="currentPrintarea"
            :processing-specifications="currentProcessingSpecifications"
            :mockups="currentBaseProduct.mockups ? currentBaseProduct.mockups.data : []"
            :designer-fonts="designerFonts"
            :color="currentBaseProductColor"
            @select="selectPrintarea"
          />

          <div
            v-if="isSavingBaseDesign || isSplittingManufacturingJob"
            class="bg-white-25 absolute inset-0 items-center justify-center"
          >
            <loader
              color="blue-50"
              size="large"
              class="h-full"
            />
          </div>
        </div>

        <config-upload-form
          v-if="currentPrintarea"
          ref="uploadForm"
          :area="currentPrintarea.key"
          @show-design-form="$emit('show-design-form')"
          @asset-uploaded="addCampaignAsset"
          @add-text="addText"
          @upload:error="showError"
        />
      </main>

      <mpc-designer-sidebar
        ref="sidebar"
        :designer-ref="$refs.designer"
        :printareas="availablePrintareasForCurrentProduct"
        :current-printarea="currentPrintarea"
        :is-valid="isValid"
        :is-editing-mpc-base-design="isEditingMpcBaseDesign"
        :is-loading-next-step="isLoadingNextStep"
        :current-processing-specifications="currentProcessingSpecifications"
        :current-country="currentCountry"
        :available-colors="availableColors"
        :colors-in-use="colorsInUse"
        :current-color-id="currentColorId"
        :current-price="cart ? cart.price_gross : 0"
        :shipping-price="cart ? cart.shipping_price_net : 0"
        :show-color-selector="showColorSelector"
        @validation:error="showError"
        @printarea:change="selectPrintarea"
        @openBaseDesignEditor="openBaseDesignEditor()"
        @closeBaseDesignEditor="resetTemporaryProcessingSpecifications()"
        @saveBaseDesign="saveTemporaryProcessingSpecificationsConfirmation()"
        @openColorSelector="openColorSelector()"
        @closeColorSelector="closeColorSelector()"
        @nextStep="loadNextStepConfirm"
        @cancel="backToCollection"
        @deleteColor="deleteColor($event)"
        @addColor="addColor($event)"
        @selectColor="selectColor($event)"
        @updateCountry="updateCountry"
      />
    </div>

    <config-footer
      class-name="hidden lg:flex absolute bottom-0 justify-between left-0 ml-3 mb-6 text-xs text-grey-50"
      :show-price-info="false"
    />

    <confirm-modal
      v-if="showSaveTemporaryProcessingsConfirm"
      @decline="resetTemporaryProcessingSpecifications()"
      @confirm="saveTemporaryProcessingSpecifications()"
      @close="showSaveTemporaryProcessingsConfirm = false"
    >
      <strong v-html="$t('views.designer.save_temporary_processings_modal.text_html')">
      </strong>

      <template v-slot:confirm>
        {{ $t('views.designer.save_temporary_processings_modal.confirm') }}
      </template>
      <template v-slot:deny>
        {{ $t('views.designer.save_temporary_processings_modal.deny') }}
      </template>
    </confirm-modal>

    <loader-full-page v-if="!baseProductsLoaded">
      {{ $t('views.calculator.loading_products') }}
    </loader-full-page>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import cloneDeep from 'lodash/cloneDeep'
  import { createAssetText, createDesignFromProcessingItems } from '@/api/design'
  import {
    getBaseProductVariantId,
    getProcessingsWithItems,
  } from '@/utils/helpers'
  import { getDummyManufacturingJob, getDummyProcessing } from '@/api/calculation'
  import MpcDesignerSidebar from '../../components/config/sidebar/MpcDesignerSidebar'
  import ConfigUploadForm from '../../components/config/designer/ConfigUploadForm'
  import ErrorMessage, { EMPTY_ERROR_OBJECT } from '../../components/config/ErrorMessage'
  import AddDesignButton from '../../components/config/designer/AddDesignButton.vue'
  import ConfigFooter from '../../components/config/ConfigFooter.vue'
  import ConfigSideHeader from '../../components/config/header/ConfigSideHeader.vue'
  import Designer from 'sb/modules/Designer/Designer.vue'
  import ConfirmModal from '../../components/layout/ConfirmModal.vue'
  import LoaderFullPage from '../../components/layout/LoaderFullPage.vue'
  import Cart from '../../mixins/Cart'
  import generateUniqueId from 'sb/common/utils/uniqueId'
  import DesignerPrintareaSelection from '@/components/config/designer/DesignerPrintareaSelection.vue'
  import Loader from '@/components/layout/Loader.vue'
  import Prices from '@/mixins/Prices'
  import HeaderNavSteps from '@/components/config/header/HeaderNavSteps.vue'
  import DesignerSidebar from '@/components/config/sidebar/DesignerSidebar.vue'
  import _debounce from 'lodash/debounce'

  const DEFAULT_RENDER_HEIGHT = 504

  // TODO: Keyboard Shortcuts dem User erklären
  // TODO: Refactoring - Datei ist viel zu lang. Ggf. trennen in View Teil und Logik Teil
  export default {

    name: 'MpcProductDesigner',

    mixins: [ Cart, Prices ],

    data() {
      return {
        currentPrintarea: null,
        /** @type ApiFormattedProcessing[] */
        currentProcessingSpecifications: [],
        /** @type ApiFormattedProcessing[] */
        snapshotProcessingSpecifications: [], // The processings Array which was inherited by the manufacturing job
        initialViewportHeight: 0,
        error: Object.assign({}, EMPTY_ERROR_OBJECT),
        designerScale: 1.5,
        isValid: false,
        fontsLoaded: false,
        isEditingMpcBaseDesign: false,
        showSaveTemporaryProcessingsConfirm: false,
        baseProductsLoaded: false,
        isLoadingNextStep: false,
        isSplittingManufacturingJob: false,
        showColorSelector: false,
        currentColorId: null,
        colorsInUse: [],
      }
    },

    beforeMount() {
      // dirty hack for iOS, because safari will just ignore overflow-hidden on divs apparently
      document.body.classList.add('overflow-hidden')
    },

    beforeDestroy() {
      document.body.classList.remove('overflow-hidden')

      if (typeof window !== 'undefined') {
        window.visualViewport.removeEventListener('resize', this.handleWindowResize)
        window.removeEventListener('keydown', this.handleKeydown.bind(this))
      }
    },

    async mounted() {
      this.setIsLoadingCart(false)
      this.setIsLoadingPrices(false)
      this.setIsSavingBaseDesign(false)

      if (this.isCheckedOut) {
        this.discardCart()
        return
      }

      this.adjustScale()

      await this.loadAllBaseProducts()
      this.baseProductsLoaded = true

      await this.determineCurrentBaseProduct()
      this.determineCurrentManufacturingJob()
      this.determineCurrentPrintarea()
      this.determineCurrentProcessingSpecifications()
      this.determineCountry()
      this.determineCurrentColorId()

      Vue.nextTick(() => {
        // Open the base design editor when no processing has been added yet
        if (!this.currentProcessingSpecificationForPrintarea || !this.currentProcessingSpecificationForPrintarea.processing_items.data.length) {
          this.openBaseDesignEditor()
        }
      })

      if (typeof window !== 'undefined') {
        this.initialViewportHeight = window.visualViewport.height
        window.visualViewport.addEventListener('resize', this.handleWindowResize)
      }

      await this.loadDesignerFonts()
      this.fontsLoaded = true

      this.currentColorId = parseInt(this.$route.query.color) ?? null
      this.setCurrentColorId(this.currentColorId)

      if (this.currentBaseProduct && (!this.cart || !this.cart.manufacturing_jobs.data.length)) {
        await this.initEmptyCart(this.currentBaseProduct, this.currentColorId)
      }
    },

    // TODO: outsource duplicated code in here and SingleProductDesigner.vue
    methods: {
      saveTemporaryProcessingSpecificationsConfirmation() {
        if (this.cart && this.allCartProductsForCurrentGroup.length > 1) {
          this.showSaveTemporaryProcessingsConfirm = true
        } else {
          this.saveTemporaryProcessingSpecifications().catch((error) => {
            this.showError(error.message)
          })
        }
      },
      updateValidity(isValid) {
        this.isValid = isValid
      },
      updateCountry(country) {
        this.setCartCountry(country)
        this.updatePrice(false, true)
      },
      adjustScale() {
        this.designerScale = Math.min(Math.round((window.visualViewport.height - 256) / DEFAULT_RENDER_HEIGHT), 2)
      },
      /**
       * @param {BaseProductPrintarea} printarea
       */
      selectPrintarea(printarea) {
        if (
          !printarea
          || JSON.stringify(this.currentPrintarea) === JSON.stringify(printarea)
          || !this.currentManufacturingJobGroupId
        ) return

        if (!this.activePrintareasForCurrentGroup.includes(printarea.key)) {
          this.setActiveCartPrintareas({
            ...this.activePrintareasForCart,
            [this.currentManufacturingJobGroupId.toString()]: [...this.activePrintareasForCurrentGroup, printarea.key ]
          })
        }

        this.currentPrintarea = printarea
      },
      hideError() {
        this.error = EMPTY_ERROR_OBJECT
      },
      /**
       * @param {string} message
       * @param {?string} title
       */
      showError(message, title = this.$t('views.designer.validation.error_title_fallback')) {
        this.error.message = message
        this.error.title = title
        this.error.show = true

        setTimeout(() => {
          this.error.show = false
        }, 5000)
      },
      uploadGraphic() {
        if (!this.isEditingMpcBaseDesign) {
          this.isEditingMpcBaseDesign = true
        }

        this.$refs.uploadForm.addDesignPopup()
      },
      /**
       * @param {ApiFormattedProcessingItem[]} updatedProcessingItems
       */
      async updateVisibleProcessingItems(updatedProcessingItems) {
        if (!updatedProcessingItems || !this.currentProcessingSpecifications) return

        this.currentProcessingSpecifications = /** @type ApiFormattedProcessing[] */this.currentProcessingSpecifications
          .map((specification) => {
            if (specification.processingarea_type_key !== this.currentPrintarea.key) return specification

            const newProcessingItemsArray = cloneDeep(specification.processing_items.data)
            updatedProcessingItems.forEach((updatedProcessingItem) => {
              const foundItemIndex = newProcessingItemsArray.findIndex((processingItem) => {
                if (typeof updatedProcessingItem.uuid !== 'undefined') {
                  return processingItem.uuid === updatedProcessingItem.uuid

                }

                if (typeof updatedProcessingItem._internal_id !== 'undefined') {
                  return processingItem._internal_id === updatedProcessingItem._internal_id
                }

                return false
              })

              if (updatedProcessingItem.type === 'text' && updatedProcessingItem.content && !updatedProcessingItem.asset && !updatedProcessingItem.asset_creating) {
                updatedProcessingItem.asset_creating = true

                createAssetText(updatedProcessingItem.content).then(response => {
                  updatedProcessingItem.asset = response.data
                }).finally(() => {
                  updatedProcessingItem.asset_creating = false
                })
              }

              if (foundItemIndex > -1) {
                newProcessingItemsArray.splice(foundItemIndex, 1, updatedProcessingItem)
              } else {
                newProcessingItemsArray.push(updatedProcessingItem)
              }
            })

            specification.processing_items.data = newProcessingItemsArray

            return specification
          })
      },
      deleteVisibleProcessingItem(deletedProcessingItem) {
        if (!this.currentProcessingSpecifications) return

        this.currentProcessingSpecifications = /** @type ApiFormattedProcessing[] */this.currentProcessingSpecifications
          .map((specification) => {
            if (specification.processingarea_type_key !== this.currentPrintarea.key) return specification

            specification.processing_items.data = specification.processing_items.data.filter((processingItem) => {
              if (typeof deletedProcessingItem.uuid !== 'undefined') {
                return processingItem.uuid !== deletedProcessingItem.uuid
              }

              if (typeof deletedProcessingItem._internal_id !== 'undefined') {
                return processingItem._internal_id !== deletedProcessingItem._internal_id
              }

              return true
            })

            return specification
          })
      },
      /**
       * @param {string} printareaKey
       * @return {ApiFormattedProcessing}
       */
      addEmptyProcessingSpecificationForPrintarea(printareaKey) {
        const newProcessingSpecification = getDummyProcessing(printareaKey)

        const newProcessingSpecificationsArray = /** @type ApiFormattedProcessing[] */[
          ...this.currentProcessingSpecifications,
          newProcessingSpecification,
        ]

        this.updateManufacturingJob({
          ...this.currentManufacturingJob,
          processings: {
            data: newProcessingSpecificationsArray,
          }
        })

        this.currentProcessingSpecifications = newProcessingSpecificationsArray

        return newProcessingSpecification
      },
      handleWindowResize(event) {
        this.adjustScale()
        this.fixViewportScroll(event)
      },
      fixViewportScroll(event) {
        // On iOS, opening the keyboard will change the viewport height and break the already broken overflow: hidden
        // attribute. To work around this, once the keyboard closes, we scroll back to the top to fix the viewport again
        if (event.target.height === this.initialViewportHeight && typeof window !== 'undefined') {
          window.scrollTo(0, 0)
        }
      },
      addText() {
        if (this.$refs.sidebar.$refs.content.selectTab) {
          this.$refs.sidebar.$refs.content.selectTab('design')
        }

        Vue.nextTick(() => {
          this.$refs.sidebar.$refs.content.$refs.creator.addText()
        })
      },
      /**
       * @param {ProcessingItemAsset} campaignAsset
       */
      addCampaignAsset(campaignAsset) {
        const maxHeight = this.currentPrintarea.height * 0.7
        let scaledWidth = this.currentPrintarea.width * 0.7

        const assetAspectRatio = campaignAsset.height / campaignAsset.width

        let scaledHeight = scaledWidth * assetAspectRatio
        if (scaledHeight > maxHeight) {
          scaledHeight = maxHeight
          scaledWidth = scaledHeight / assetAspectRatio
        }

        /** @type ApiFormattedAssetProcessingItem */
        const newProcessingItem = {
          position_x_mm: 0,
          position_y_mm: 0,
          width_mm: scaledWidth,
          height_mm: scaledHeight,
          rotation: 0,
          type: 'asset',
          preview: null,
          asset: campaignAsset,
          _internal_id: generateUniqueId(),
          initialize: true,
        }

        this.updateVisibleProcessingItems([
          ...this.visibleProcessingItems,
          newProcessingItem,
        ])
      },
      async requireBaseProduct() {
        return new Promise((resolve) => {
          if (this.currentBaseProduct) {
            return resolve(this.currentBaseProduct)
          }

          return this.loadBaseProduct(this.$route.params.id || this.baseProducts[0].id)
            .then(() => resolve(this.currentBaseProduct))
            .catch(() => {
              this.showError(
                this.$t('views.designer.validation.error_product_not_found'),
                this.$t('views.designer.validation.error_title_fallback')
              )
            })
        })
      },
      async determineCurrentBaseProduct() {
        if (!this.currentBaseProduct) {
          await this.requireBaseProduct()

          if (this.currentBaseProduct) {
            this.setHead(`${this.currentBaseProduct.name} | Shirtigo`, this.currentBaseProduct.description)
          }
        } else if (this.baseProducts) {
          // Selection might have changed
          const selectedProduct = this.baseProducts.find(product => {
            // Default base product is Organic Shirt (ID: 2)
            const defaultBaseProductId = 2
            return product.id === parseInt(this.$route.params.id || defaultBaseProductId) || product.slug === this.$route.params.id
          })

          if (selectedProduct && selectedProduct.id !== this.currentBaseProduct.id) {
            this.setCurrentBaseProductReference(selectedProduct.reference)
          }
        }

        if (this.$route.query.color) {
          // Set the color that has been passed in through parameter
          this.setCurrentColorId(parseInt(this.$route.query.color))
        }
      },
      determineCurrentManufacturingJob() {
        const groupId = this.$route.params.groupid
        if (typeof groupId === 'undefined') return

        const baseProductsMapping = this.manufacturingJobProductsMapping[groupId]

        if (groupId === '0') {
          this.setCurrentManufacturingJobGroupId(0)
          this.setCurrentManufacturingJobUuid(/** @type ApiFormattedManufacturingJobUuid */ 0)
        } else {
          this.setCurrentManufacturingJobGroupId(groupId)
        }

        if (this.currentBaseProduct && baseProductsMapping && {}.hasOwnProperty.call(baseProductsMapping, this.currentBaseProduct.id)) {
          this.setCurrentManufacturingJobUuid(baseProductsMapping[this.currentBaseProduct.id])
        }
      },
      determineCurrentPrintarea() {
        let currentPrintarea = null
        if (this.availablePrintareasForCurrentProduct.length >= 0) {
          currentPrintarea = this.availablePrintareasForCurrentProduct[0] || null

          if (this.$route.query.area) {
            const requestedPrintarea = this.currentBaseProduct.printareas.data.find(
              (printarea) => printarea.key === this.$route.query.area && printarea.is_printable
            )

            if (requestedPrintarea) {
              currentPrintarea = requestedPrintarea
            }
          }
        }

        this.currentPrintarea = currentPrintarea
      },
      determineCurrentProcessingSpecifications() {
        if (this.currentManufacturingJob) {
          this.currentProcessingSpecifications = cloneDeep(this.currentManufacturingJob.processings.data)
          this.snapshotProcessingSpecifications = cloneDeep(this.currentManufacturingJob.processings.data)
        }
      },
      determineCurrentColorId() {
        if (!this.currentCartProduct || !this.currentCartProduct.variants) return

        let currentColorId = parseInt(this.$route.query.color) || parseInt(this.currentCartProduct.variants[0].color_id)
        if (this.currentBaseProductColor !== null) {
          if (this.currentCartProduct.variants.some(({ color_id }) => color_id === this.currentBaseProductColor.id)) {
            currentColorId = this.currentBaseProductColor.id
          }
        }

        this.currentColorId = currentColorId
        this.setCurrentColorId(currentColorId)
        this.colorsInUse = this.determineColorsInUse(this.currentCartProduct.variants)
      },
      determineColorsInUse(productVariants) {
        if (!this.currentBaseProduct) return []

        return this.colors.filter((baseProductVariant) => {
          return productVariants.some((variant) => {
            return variant.color_id && variant.color_id.toString() === baseProductVariant.id.toString()
          })
        })
      },
      backToCollection() {
        this.$router.push({
          name: 'mpc-collection',
          params: {
            reference: this.cart.reference,
            groupid: this.currentManufacturingJobGroupId
          }
        })
      },
      /**
       * @param {BaseProductColorVariant} color
       */
      deleteColor(color) {
        const existingColorIndex = this.colorsInUse.indexOf(color)
        this.colorsInUse.splice(existingColorIndex, 1)

        const baseProductVariantId = getBaseProductVariantId(
          this.currentBaseProduct.id,
          color.id.toString(),
          color.size_variants.data[0].size_id.toString()
        )

        const updatedVariants = this.currentCartProduct.variants.filter(({ baseproduct_variant_id }) => baseproduct_variant_id !== baseProductVariantId)

        // Make sure the product has at least an amount of 1 (for price calculation)
        if (updatedVariants.length && updatedVariants.every((variant) => variant.amount === 0)) {
          updatedVariants[0].amount = 1
        }

        this.setVariantsForProductInCurrentGroup({
          baseProductId: this.currentBaseProduct.id,
          variants: updatedVariants
        })

        if (this.currentColorId === color.id) {
          this.determineCurrentColorId()
        }

        this.updatePrice(false, true)
      },
      /**
       * @param {BaseProductColorVariant} color
       */
      addColor(color) {
        this.colorsInUse.push(color)

        const baseproduct_variant_id = getBaseProductVariantId(
          this.currentBaseProduct.id,
          color.id.toString(),
          color.size_variants.data[0].size_id.toString()
        )

        this.currentColorId = color.id
        this.setCurrentColorId(color.id)

        this.updateOrAddProductVariantInCurrentGroup({
          baseProductId: this.currentBaseProduct.id,
          variant: {
            baseproduct_variant_id,
            amount: 0,
          },
        })

        this.saveColorToCart(color.id)
      },
      saveColorToCart: _debounce(function () {
        this.closeColorSelector()
        this.updatePrice(false, true)
      }, 1000),
      openColorSelector() {
        this.showColorSelector = true
      },
      closeColorSelector() {
        this.showColorSelector = false
      },
      async saveProcessingsAndSplitManufacturingJobIfNeeded() {
        if (!this.currentManufacturingJob) return

        const oldProcessingsArray = this.snapshotProcessingSpecifications
        const changedProcessingsArray = cloneDeep(this.currentProcessingSpecifications)

        this.isSplittingManufacturingJob = true

        if (this.currentManufacturingJob.products.data.length <= 1) {
          await this.updateManufacturingJob({
            ...this.currentManufacturingJob,
            processings: { data: changedProcessingsArray },
          })
          return
        }

        const existingProductsArray = cloneDeep(this.currentManufacturingJob.products.data)

        // Split manufacturing job; create new job with current product
        const affectedProductIndex = existingProductsArray.findIndex(
          ({ base_product_id }) => this.currentBaseProduct && base_product_id === this.currentBaseProduct.id
        )
        // Removes the product from `existingProductsArray` and adds it to `productsArrayForNewJob`
        const productsArrayForNewJob = existingProductsArray.splice(affectedProductIndex, 1)

        await this.updateManufacturingJob({
          ...this.currentManufacturingJob,
          products: { data: existingProductsArray },
          processings: { data: oldProcessingsArray },
        })

        /**
         * FIXME:
         *   Beim Splitten des ManufacturingJobs wird kurzzeitig das falsche Design angezeigt,
         *   bis der Warenkorb gespeichert ist.
         *   Wir zeigen jetzt einfach nur einen Loader statt des Designs an.
         */
        await this.addManufacturingJob(getDummyManufacturingJob({
          group_id: this.currentManufacturingJobGroupId,
          amount: 1,
          products: productsArrayForNewJob,
          processings: changedProcessingsArray,
        }))
      },
      async loadNextStepConfirm() {
        if (this.isLoadingNextStep) return

        await Vue.nextTick()

        if (!getProcessingsWithItems(this.currentProcessingSpecifications).length) {
          this.isLoadingNextStep = false
          this.showError(
            this.$t('views.designer.validation.error_missing_design'),
            this.$t('views.designer.validation.error_title_fallback')
          )
          return
        }

        if (!this.isValid) {
          this.isLoadingNextStep = false
          this.showError(
            this.$t('views.designer.validation.error_design_too_big_for_product'),
            this.$t('views.designer.validation.error_title_fallback')
          )
          return
        }

        return this.loadNextStep()
      },
      /**
       * @async
       * @returns {Promise<void>}
       */
      async loadNextStep() {
        this.isLoadingNextStep = true

        if (this.currentBaseProductColor === null) {
          throw new Error('`currentBaseProductColor` cannot be null')
        }

        await this.saveProcessingsAndSplitManufacturingJobIfNeeded()

        if (!this.colorsInUse.some(({ id }) => this.currentBaseProductColor.id === id)) {
          this.addColorVariant(/** @type BaseProductColorVariant */this.currentBaseProductColor)
        }

        await this.saveCart(this.cart, true, false)

        this.isSplittingManufacturingJob = false

        await this.$router.push({
          name: 'mpc-collection',
          params: {
            reference: this.cart.reference,
            groupid: this.currentManufacturingJobGroupId
          }
        })
      },
      /**
       * @param {BaseProductColorVariant} color
       */
      addColorVariant(color) {
        if (!this.currentBaseProduct) return

        const baseproduct_variant_id = getBaseProductVariantId(
          this.currentBaseProduct.id,
          color.id.toString(),
          color.size_variants.data[0].size_id.toString()
        )

        this.showColorSelector = false

        this.updateOrAddProductVariantInCurrentGroup({
          baseProductId: this.currentBaseProduct.id,
          variant: {
            baseproduct_variant_id,
            amount: 0
          },
        })

        this.setCurrentColorId(color.id)
      },
      /**
       * @param {number} colorId
       */
      selectColor(colorId) {
        this.currentColorId = colorId
        this.setCurrentColorId(colorId)
      },
      /**
       * @param {KeyboardEvent} event
       */
      handleKeydown(event) {
        if (this.isEditingMpcBaseDesign) {
          if (event.key === 'Escape') {
            this.resetTemporaryProcessingSpecifications()
          }

          if (event.key === 'Enter') {
            this.saveTemporaryProcessingSpecificationsConfirmation()
          }
        }
      },
      openBaseDesignEditor() {
        const primaryCartProduct = this.allCartProductsForCurrentGroup[0]
        if (primaryCartProduct) {
          const primaryBaseProduct = this.baseProducts.find((baseProduct) => {
            if (!this.allCartProductsForCurrentGroup.length) {
              return true
            }

            return baseProduct.id === primaryCartProduct.base_product_id
          })

          this.setCurrentBaseProductReference(primaryBaseProduct.reference)

          if (!primaryCartProduct.variants.some(({ color_id }) => {
            return parseInt(color_id) === this.currentBaseProductColor.id
          })) {
            this.setCurrentColorId(parseInt(primaryCartProduct.variants[0].color_id))
          }
        }

        this.isEditingMpcBaseDesign = true

        if (typeof window !== 'undefined') {
          window.addEventListener('keydown', this.handleKeydown)
        }
      },
      async resetTemporaryProcessingSpecifications() {
        if (typeof window !== 'undefined') {
          window.removeEventListener('keydown', this.handleKeydown)
        }

        await this.determineCurrentBaseProduct()

        this.isEditingMpcBaseDesign = false
        this.currentProcessingSpecifications = cloneDeep(this.snapshotProcessingSpecifications)
      },
      /**
       * @return {Promise<ApiFormattedCart, Error>}
       */
      saveTemporaryProcessingSpecifications() {
        return new Promise(async (resolve, reject) => {
          if (!this.currentProcessingSpecifications) {
            this.setIsSavingBaseDesign(false)
            reject(new Error(this.$t('views.designer.validation.error_missing_design') || ''))
            return
          }

          this.setIsSavingBaseDesign(true)

          // Apply the same processings to all manufacturing jobs in the same group
          /** @type ApiFormattedProcessing[] */
          const temporaryProcessingSpecifications = this.currentProcessingSpecifications
            .map((processingSpecification) => {
              const processingItems = processingSpecification.processing_items.data.filter(
                // We need to exclude the consolidated processing items
                (processingItem) => !processingItem.uuid || processingItem.consolidated_id
              )

              return {
                processingarea_type_key: processingSpecification.processingarea_type_key,
                processingmethod_key: processingSpecification.processingmethod_key,
                processing_items: { data: processingItems }
              }
            })

          this.determineActivePrintareasForGroup(temporaryProcessingSpecifications)

          if (!temporaryProcessingSpecifications.some(({ processing_items }) => processing_items.data.length > 0)) {
            await this.determineCurrentBaseProduct()
            this.setIsSavingBaseDesign(false)
            reject(new Error(this.$t('views.designer.validation.error_missing_design') || ''))
            return
          }

          let cart
          if (!this.cart) {
            cart = this.getEmptyCart(
              this.currentBaseProduct,
              /** @type BaseProductColorVariant */this.currentBaseProductColor,
              this.currentCountry,
              temporaryProcessingSpecifications,
            )
          } else {
            cart = JSON.parse(JSON.stringify(this.cart))
            cart.manufacturing_jobs.data = cart.manufacturing_jobs.data.map((job) => {
              if (job.uuid === this.manufacturingJobUuid) {
                return {
                  ...job,
                  processings: { data: temporaryProcessingSpecifications }
                }
              }

              return job
            })
          }

          createDesignFromProcessingItems(cart, this.manufacturingJobUuid)
            .then((response) => {
              const updatedCart = /** @type ApiFormattedCart */ response.data
              this.setCart(updatedCart)

              this.determineCurrentBaseProduct()
              this.setIsSavingBaseDesign(false)

              const updatedManufacturingJob = updatedCart.manufacturing_jobs.data
                .find(({ uuid }) => uuid === this.manufacturingJobUuid)
              this.currentProcessingSpecifications = updatedManufacturingJob.processings.data
              this.snapshotProcessingSpecifications = updatedManufacturingJob.processings.data

              this.isEditingMpcBaseDesign = false
              resolve(updatedCart)
            })

        })
      },
      /**
       * @param {BaseProductId} baseProductId
       */
      removeAnyCartProductsOtherThan(baseProductId) {
        this.allCartProductsForCurrentGroup.forEach(({ base_product_id }) => {
          if (base_product_id !== baseProductId) {
            this.removeProductFromCart(base_product_id)
          }
        })
      },
      /**
       * @param {ApiFormattedProcessing[]} processingSpecifications
       */
      determineActivePrintareasForGroup(processingSpecifications) {
        const printareas = processingSpecifications.reduce((acc, processingSpecification) => {
          const hasRawProcessingItems = processingSpecification.processing_items.data.some((processingItem) => processingItem.type !== 'design')
          if (hasRawProcessingItems) {
            if (!(/** @type {string[]} */ acc).includes(processingSpecification.processingarea_type_key)) {
              return [...acc, processingSpecification.processingarea_type_key]
            }
          }

          return acc
        }, [])

        this.setActiveCartPrintareas({
          ...this.activePrintareasForCart,
          [this.currentManufacturingJobGroupId.toString()]: printareas
        })
      },
      discardCart() {
        this.deleteCart()
        this.deleteOrderDetails()
        window.location.href = '/'
      },
      ...mapActions([
        'loadAllBaseProducts',
        'setImageSideQuery',
        'loadBaseProduct',
        'updateManufacturingJob',
        'addManufacturingJob',
        'setCurrentColorId',
        'setCurrentImageReference',
        'setCurrentBaseProductReference',
        'deleteCart',
        'deleteOrderDetails',
        'loadDesignerFonts',
        'setActiveCartPrintareas',
        'setCurrentManufacturingJobUuid',
        'setCurrentManufacturingJobGroupId',
        'setIsLoadingCart',
        'setIsLoadingPrices',
        'updateOrAddProductVariantInCurrentGroup',
        'removeProductFromCart',
        'setIsSavingBaseDesign',
        'setVariantsForProductInCurrentGroup',
      ]),
    },

    computed: {
      /**
       * @return {ApiFormattedManufacturingJobUuid}
       */
      manufacturingJobUuid() {
        return this.currentManufacturingJob?.uuid ?? 0
      },
      /**
       *
       * @return {?BaseProductMockup}
       */
      productMockup() {
        if (!this.currentPrintarea || !this.currentBaseProduct) return undefined

        if (typeof this.currentBaseProduct.mockups === 'undefined') {
          throw new Error('`currentBaseProduct.mockups` is not defined')
        }

        const mockups = /** @type BaseProductMockup[] */this.currentBaseProduct.mockups.data
        return mockups.find((mockup) => {
          return mockup.processingarea_key === this.currentPrintarea.key && mockup.is_creator_editable
        })
      },
      /**
       * @return {ApiFormattedProcessing|null}
       */
      currentProcessingSpecificationForPrintarea() {
        if (!this.currentPrintarea || !this.currentProcessingSpecifications) return null

        const specification = this.currentProcessingSpecifications
          .find(({ processingarea_type_key }) => processingarea_type_key === this.currentPrintarea.key)

        if (!specification) {
          return this.addEmptyProcessingSpecificationForPrintarea(this.currentPrintarea.key)
        }

        return specification || null
      },
      /**
       * @return {ApiFormattedProcessingItem[]}
       */
      visibleProcessingItems() {
        if (!this.currentProcessingSpecificationForPrintarea || typeof this.currentProcessingSpecificationForPrintarea.processing_items === 'undefined') {
          return []
        }

        return this.currentProcessingSpecificationForPrintarea.processing_items.data.filter((processingItem) => {
          if (!processingItem) return false

          if (processingItem.asset_creating || processingItem.initialize) return true

          if (this.isEditingMpcBaseDesign) {
            // Show processing items that are individual components of the consolidated design
            return !!processingItem.consolidated_id
          }

          // Show consolidated processing item
          return processingItem.uuid && !processingItem.consolidated_id && processingItem.type === 'design'
        })
      },
      /**
       * @return {ApiFormattedDesignProcessingItem|null}
       */
      currentDesignProcessingItem() {
        if (!this.visibleProcessingItems) return null

        return this.visibleProcessingItems.find(({ type }) => type === 'design') || null
      },
      /**
       * @return {BaseProductColorVariant[]}
       */
      colors() {
        return this.currentBaseProduct.variants.data
      },
      /**
       * @return {BaseProductColorVariant[]}
       */
      availableColors() {
        return this.colors.filter(({ id }) => !this.colorsInUse.some((color) => color.id === id))
      },
      /**
       * @return {ApiFormattedProduct|null}
       */
      currentCartProduct() {
        if (!this.allCartProductsForCurrentGroup || !this.currentBaseProduct) return null

        return this.allCartProductsForCurrentGroup.find((product) => {
          return product.base_product_id === this.currentBaseProduct.id
        }) || null
      },
      ...mapGetters([
        'baseProducts',
        'currentBaseProduct',
        'currentBaseProductColor',
        'currentImage',
        'imageSideQuery',
        'isCheckedOut',
        'designerFonts',
        'cart',
        'allCartProductsForCurrentGroup',
        'availablePrintareasForCurrentProduct',
        'manufacturingJobProductsMapping',
        'currentManufacturingJobGroupId',
        'currentManufacturingJobUuid',
        'currentManufacturingJob',
        'isSavingBaseDesign',
        'activePrintareasForCart',
        'activePrintareasForCurrentGroup',
      ])
    },

    watch: {
      availablePrintareasForCurrentProduct(newValue) {
        if (newValue.length >= 0 && typeof newValue[0] !== 'undefined') {
          this.selectPrintarea(newValue[0])
        }
      },
      currentManufacturingJobUuid() {
        this.determineCurrentProcessingSpecifications()
      },
      'currentManufacturingJob.processings.data': {
        deep: true,
        handler(updatedProcessings) {
          this.currentProcessingSpecifications = updatedProcessings
        }
      }
    },

    components: {
      DesignerSidebar,
      HeaderNavSteps,
      Loader,
      LoaderFullPage,
      ConfigSideHeader,
      ConfigFooter,
      AddDesignButton,
      ConfigUploadForm,
      MpcDesignerSidebar,
      DesignerPrintareaSelection,
      Designer,
      ErrorMessage,
      ConfirmModal,
    }

  }
</script>

import { render, staticRenderFns } from "./MockupProductPreview.vue?vue&type=template&id=288d7d16&scoped=true"
import script from "./MockupProductPreview.vue?vue&type=script&lang=js"
export * from "./MockupProductPreview.vue?vue&type=script&lang=js"
import style0 from "./MockupProductPreview.vue?vue&type=style&index=0&id=288d7d16&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288d7d16",
  null
  
)

export default component.exports
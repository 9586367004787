<template>
  <div class="app login">
    <div
      :class="['hero-container', { 'is-small': !hasUserAccount }]"
      :style="`background-image: url(${bgImageUrl})`">
      <div class="login__hero-logos">
        <a href="https://cockpit.shirtigo.com/">
          <img
            src="/images/shirtigo-icon.svg"
            alt="Shirtigo">
        </a>
        <a href="https://www.seedshirt.de/">
          <img
            src="/images/seedshirt-icon.svg"
            alt="Seedshirt">
        </a>
      </div>

      <div class="login__hero-message">
        <h2 class="title">
          {{ $t('shirtigo.login.hero.headline') }}
        </h2>
        <p>{{ $t('shirtigo.login.hero.text') }}</p>

        <sg-btn
          kind="outline-invert"
          class="login__hero-message-btn"
          as="a"
          href="https://helpcenter.shirtigo.de/faq/allgemein/was-ist-shirtigo-id/"
          target="_blank">
          {{ $t('shirtigo.login.hero.action') }}
        </sg-btn>
      </div>
    </div>
    <div :class="['login-container', { 'is-wide': !hasUserAccount }]">
      <div class="login__logo-wrapper">
        <img
          class="login__brand-logo"
          src="/images/shirtigo-id-logo.svg"
          alt="Shirtigo ID">
      </div>

      <login-form
        :email="email"
        :password="password"
        :password-confirm="passwordConfirm"
        :agb-consent="agbConsent"
        :has-user-account="hasUserAccount"
        :form-error="error"
        :success-message="successMessage"
        @input:email="email = $event"
        @input:password="password = $event"
        @input:passwordConfirm="passwordConfirm = $event"
        @input:agb-consent="agbConsent = $event"
        @has-user-account="$emit('has-user-account', $event)"
        @login="login"
        @register="register">
      </login-form>

      <div class="login__bottom-wrapper">
        <p class="login__legal-text">
          {{ $t('shirtigo.login.footer_legal_text') }}
        </p>
        <div class="login__footer-links">
          <a href="/imprint">
            {{ $t('shirtigo.login.footer_nav.imprint') }}
          </a>
          <a href="/agb">
            {{ $t('shirtigo.login.footer_nav.agb') }}
          </a>
          <a href="/privacy">
            {{ $t('shirtigo.login.footer_nav.privacy') }}
          </a>
          <a href="/agb#widerrufsrecht">
            {{ $t('shirtigo.login.footer_nav.withdrawal') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LoginForm from 'sb/modules/Auth/LoginForm.vue'
  import SgBtn from 'sb/elements/Buttons/SgBtn.vue'

  export default {

    props: {
      error: {
        type: String,
        default: null,
      },
      successMessage: {
        type: String,
        default: null,
      },
      bgImageUrl: {
        type: String,
        default: '/images/login.jpg'
      },
      hasUserAccount: {
        type: Boolean,
        default: true
      },
    },

    data() {
      return {
        email: '',
        password: '',
        passwordConfirm: '',
        agbConsent: false,
      }
    },

    methods: {
      login() {
        this.$emit('login', { email: this.email, password: this.password })
      },
      register() {
        this.$emit('register', { email: this.email, password: this.password, passwordConfirm: this.passwordConfirm, agbConsent: this.agbConsent })
      },
    },

    components: {
      LoginForm,
      SgBtn,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .login {
    flex-direction: row !important;

    .hero-container {
      position: relative;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      display: none;

      @include breakpoint(lg) {
        display: flex;
        width: calc(100% - 620px);

        &.is-small {
          width: 620px;
        }
      }
    }

    &__hero-logos {
      position: absolute;
      display: flex;
      top: 1.5em;
      left: 1.5em;

      img {
        display: block;
        width: 2.5em;
        height: 2.5em;
        opacity: .8;
        margin-right: 1.25em;
      }
    }

    &__hero-message {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0), #000);
      color: $white;
      text-align: center;
      padding: 0 2em;
      padding-bottom: 4rem;
      padding-top: 2rem;

      .title {
        color: $white;
        font-weight: 700;
        font-size: 1.5rem;
        margin: 0 0 .5rem;
      }

      p {
        color: $white;
        font-size: 1rem;
        font-weight: 500;
        margin: 0 0 1.25rem;
      }

      &-btn {
        margin: auto;
      }
    }

    .login-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      background: linear-gradient(135deg, $blue-50, $blue-60);
      width: 100%;
      padding: 2.5rem 1rem;
      letter-spacing: 0.025em;
      font-weight: 400;

      @include breakpoint(md) {
        padding: 2.5rem 4rem;
        box-shadow: -1px 0 35px 0 rgba(0, 0, 0, .2);
      }

      @include breakpoint(lg) {
        width: 620px;

        &.is-wide {
          min-width: 620px;
          width: calc(100% - 620px);
        }
      }
    }

    &__logo-wrapper {
      display: flex;
      width: 100%;
    }

    &__brand-logo {
      width: auto;
      max-height: 2.5em;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5em;

      @include breakpoint(lg) {
        margin-left: 0;
      }
    }

    &__form-errors {
      background: $red-00;
      border-radius: 5px;
      padding: 1em 1.5em;
      margin-bottom: 1em;
    }

    &__bottom-wrapper {
      margin-top: auto;
      width: 100%;
    }

    &__legal-text {
      color: $blue-30;
      font-size: .75em;
      line-height: 1.6;
      margin: 0;
    }

    &__footer-links {
      display: inline;

      a {
        display: inline;
        font-size: .75rem;
        color: $blue-30;
        font-weight: 600;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $blue-30;
          text-decoration: none;
        }
      }

      a:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
</style>

<template>
  <section class="product-info__sizes-table">
    <sg-table>
      <thead>
        <tr>
          <th></th>
          <th>Breite (A)</th>
          <th>Höhe (B)</th>
          <th>Ärmellänge (C)</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="size in baseProduct.sizes"
          :key="size.reference">
          <th>{{ size.size }}</th>
          <td>
            <span class="mobile-label">Breite (A): </span>
            {{ size.sizing_width_mm / 10 }}cm
          </td>
          <td>
            <span class="mobile-label">Höhe (B): </span>
            {{ size.sizing_height_mm / 10 }}cm
          </td>
          <td>
            <span class="mobile-label">Ärmellänge (C): </span>
            {{ size.sizing_sleeve_mm / 10 }}cm
          </td>
        </tr>
      </tbody>
    </sg-table>
  </section>
</template>

<script>
  import SgTable from 'sb/elements/Layout/SgTable.vue'

  export default {

    props: {
      baseProduct: {
        type: Object,
        required: true,
      },
    },

    components: {
      SgTable,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .product-info__sizes-table {
    width: 100%;
  }
</style>

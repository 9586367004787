export default {
  catalogProducts: (state) => {
    return state.catalog_products
  },

  catalogProduct: (state) => {
    return state.catalog_product
  },
  catalogProduct2: (state) => {
    return state.catalog_product
  },

  brands: (state) => {
    return state.brands
  },

  genders: (state) => {
    return state.genders
  },

  productCategories: (state) => {
    return state.product_categories
  },

  productColors: (state) => {
    return state.product_colors
  },
}

<template>
  <!-- TODO: This is not accessibility conform -->
  <div class="sg-number-input">
    <button
      class="sg-number-input__button"
      @click.prevent="$emit('input', value - 1)"
      :disabled="typeof min !== 'undefined' ? value === min : false"
      tabindex="-1"
    >
      -
    </button>

    <input
      :id="id"
      :name="name"
      type="number"
      :class="`sg-number-input__input ${inputClass}`"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      :value="value"
      :required="required"
      :disabled="disabled ? 'disabled' : false"
      autocomplete="off"
      @blur="$emit('blur')"
      @input="handleInput"
      @keyup="$emit('keyup', $event)"
      @keydown.enter="$emit('keydown.enter')"
    >

    <button
      class="sg-number-input__button"
      @click.prevent="$emit('input', value + 1)"
      :disabled="typeof max !== 'undefined' ? value === max : false"
      tabindex="-1"
    >
      +
    </button>
  </div>
</template>

<script>
  // TODO: This component should be part of frontend-components
  export default {

    name: "NumberInput",

    props: {
      id: String,
      name: String,
      placeholder: String,
      min: Number,
      max: Number,
      value: Number,
      required: Boolean,
      disabled: Boolean,
      inputClass: {
        type: String,
        default: 'h-11 border bg-white disabled:opacity-75 disabled:bg-gray-10 text-center px-8 w-full rounded',
      },
    },

    methods: {
      handleInput(event) {
        if (!event.target.value) {
          return 0;
        }

        this.$emit('input', parseInt(event.target.value, 10))
      }
    }

  }
</script>

<style lang="scss" scoped>
  .sg-number-input {
    position: relative;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    &__button {
      color: #90a0b0;
      position: absolute;
      top: 1px;
      bottom: 1px;
      width: 2rem;
      height: 2.75rem;

      &:disabled {
        opacity: 0.5;
        color: #dde3e6;
      }

      &:first-of-type {
        left: 1px;
      }

      &:last-of-type {
        right: 1px;
      }
    }
  }
</style>

<template>
  <div>
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden ring-1 ring-black ring-opacity-5">
              <table class="min-w-full divide-y divide-gray-300 border">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="p-3 text-left text-sm font-bold text-gray-90 uppercase tracking-1">
                      {{ $t('views.product_details.prices.amount') }}
                    </th>
                    <th
                      scope="col"
                      class="p-3 text-left text-sm font-bold text-gray-90 uppercase tracking-1">
                      {{ $t('views.product_details.prices.price_gross') }}
                    </th>
                    <th
                      scope="col"
                      class="p-3 text-left text-sm font-bold text-gray-90 uppercase tracking-1">
                      {{ $t('views.product_details.prices.price_net') }}
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="step in catalogProduct.step_prices"
                    :key="step.amount">
                    <td class="text-left font-bold text-gray-50 p-3 uppercase tracking-1 text-sm">
                      {{ $t('views.product_details.prices.prices', {amount: step.amount}) }}
                    </td>
                    <td class="text-left text-gray-80 p-3">
                      {{ parseInt(step.price_gross) | currency }}
                    </td>
                    <td class="text-left text-gray-80 p-3">
                      {{ parseInt(step.price) | currency }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="text-sm text-gray-50 mt-10">
        {{ $t('views.product_details.prices.prices_note') }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {

    props: {
      catalogProduct: {
        type: Object,
      },
    },

  }
</script>

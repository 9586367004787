<template>
  <textarea
    :id="id"
    :class="['sg-textarea', { 'has-danger': hasDanger }]"
    :value="value"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    :required="required"
    :tabindex="tabindex"
    :autofocus="autofocus"
    :autocomplete="autocomplete"
    @blur="$emit('blur')"
    @input="$emit('input', $event.target.value)"
    @keyup="$emit('keyup', $event)"
    @keydown.enter="$emit('keydown.enter')">
  </textarea>
</template>

<script>
  export default {

    props: {
      id: String,
      value: {
        type: [String, Number],
        required: true
      },
      name: String,
      placeholder: String,
      disabled: Boolean,
      required: Boolean,
      tabindex: Number,
      autofocus: Boolean,
      autocomplete: {
        type: String,
        default: 'on'
      },
      hasDanger: Boolean,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";

  .sg-textarea {
    width: 100%;
    min-height: 2.625rem;
    border: 1px solid $grey-30;
    border-radius: 3px;
    box-shadow: inset 1px 0 3px 0 rgba(0, 0, 0, .05);
    font-size: 1rem;
    font-family: $nunito-sans;
    padding: .75rem;
    transition: .2s ease;
    resize: vertical;

    &:focus {
      border-color: $blue-50;
      outline: none;
    }

    &::placeholder {
      color: $grey-40;
      opacity: 1;
    }

    // Internet Explorer 10-11
    &:-ms-input-placeholder {
      color: $grey-40;
    }

    // Microsoft Edge
    &::-ms-input-placeholder {
      color: $grey-40;
    }

    &.has-danger {
      border-color: $red !important;
    }

    &.input-lg {
      min-height: 3.25rem;
      padding: .875rem 1rem;
      border-radius: 5px;
    }
  }
</style>

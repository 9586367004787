import axios from 'axios'

const request = (url, method, data, params, config = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data,
      params,
      ...config
    }).then((response) => {
      resolve(response.data)
    }).catch((errorResponse) => {
      reject(errorResponse)
    })
  })
}

export default {
  get(url, params = {}, config = {}) {
    return request(url, 'GET', {}, params, config)
  },
  post(url, data = {}, params = {}, config = {}) {
    return request(url, 'POST', data, params, config)
  },
  put(url, data = {}, params = {}, config = {}) {
    return request(url, 'PUT', data, params, config)
  },
  delete(url, params = {}, config = {}) {
    return request(url, 'DELETE', {}, params, config)
  },
}

<template>
  <input
    ref="slider"
    :value="value"
    type="range">
</template>

<script>
  export default {

    props: {
      min: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: 100,
      },
      step: {
        type: Number,
        default: 1,
      },
      value: {
        type: Number,
      },
    },

    mounted() {
      const slider = this.$refs.slider

      if (typeof window !== 'undefined') {
        const RangeSlider = require('rangeslider-pure')

        RangeSlider.create(slider, {
          rangeClass: 'range-slider',
          disabledClass: 'range-slider--disabled',
          fillClass: 'range-slider__fill',
          handleClass: 'range-slider__handle',
          min: this.min,
          max: this.max,
          step: this.step,
          value: this.value,
          onSlide: (position) => {
            this.$emit('input', position)
          },
        })
      }
    },

    watch: {
      value(val) {
        this.$refs.slider.rangeSlider.update({
          value: val,
        })
      },
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .range-slider {
    position: relative;
    width: 100%;
    height: .75rem;
    background: $grey-20;
    border-radius: 5em;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, .1);

    &__fill {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 5em 0 0 5em;
      background-color: $blue;
      background-image: linear-gradient(90deg, $blue-60, $blue-50);
    }

    &__handle {
      position: absolute;
      top: -.625rem;
      width: 1rem;
      height: 1rem;
      border: .5rem solid $white;
      cursor: pointer;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .2);
      border-radius: 50%;
      box-sizing: content-box;
      background-color: $blue;
      background-image: linear-gradient(315deg, $blue-60, $blue-50);
    }
  }
</style>

<template>
  <component
    :is="link ? 'sg-link' : 'div'"
    :class="[`chip chip--${kind}`, { 'chip--outline': outline , 'chip--is-large':isLarge }]"
    :to="to">
    <slot></slot>
  </component>
</template>

<script>
  export default {

    props: {
      link: {
        type: Boolean,
        default: false,
      },
      to: [String, Object],
      kind: {
        type: String,
        default: 'default',
      },
      isLarge: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        outline: false,
      }
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .chip {
    display: inline-flex;
    align-items: center;
    padding: 0 .5rem;
    font-size: .75rem;
    line-height: 1.25rem;
    text-decoration: none;
    background-color: $grey-30;
    border: 1px solid $grey-30;
    color: $grey-80;
    border-radius: 2rem;

    &:hover {
      color: $grey-80;
    }

    &:focus {
      text-decoration: none;
      color: $grey-80;
      box-shadow: $focus-outline;
    }

    &--is-large {
      grid-column-gap: .25rem;
      padding: 4px 10px;
      font-size: 16px;
    }

    &--yellow {
      color: $yellow-90;
      background-color: $yellow-30;
      border-color: $yellow-30;

      &.chip--outline {
        color: $yellow-70;
      }
    }

    &--green {
      color: $green-90;
      background-color: $green-30;
      border-color: $green-30;

      &.chip--outline {
        color: $green-70;
      }
    }

    &--blue {
      color: $blue-80;
      background-color: $blue-30;
      border-color: $blue-30;

      &.chip--outline {
        color: $blue-60;
      }
    }

    &--red {
      color: $red-90;
      background-color: $red-30;
      border-color: $red-30;

      &.chip--outline {
        color: $red-70;
      }
    }

    &--outline {
      background: transparent;
      color: $grey-70;

      &:hover {
        color: $grey-70;
      }
    }

    .icon {
      background: $white;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: .5rem;
      margin-right: -.375rem !important;
    }
  }
</style>

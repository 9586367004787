<template>
  <div class="design-preview flex justify-center">
    <div class="large-preview">
      <div
        v-if="!selectedItem || !selectedItem.preview"
        class="z-10 inset-0 text-center text-blue-50 text-3xl absolute spinning flex justify-center items-center"
      >
        <i class="icon-loader-outline" />
      </div>

      <div
        v-if="selectedItem"
        :class="{'grayscale opacity-50': !selectedItem.preview}"
        v-html="selectedItem.preview.svg"
      />
    </div>

    <div class="absolute z-10 p-4 gap-2 top-0 left-0 w-20 flex flex-col">
      <div
        v-for="item in previewList"
        :key="item.printarea.key"
        class="preview-window relative cursor-pointer"
        @click="() => {
          if (item !== null) select(item)
        }"
      >
        <div
          v-if="!item || !item.preview"
          class="z-10 inset-0 text-center text-blue-50 text-2xl absolute spinning flex justify-center items-center h-full"
        >
          <i class="icon-loader-outline" />
        </div>

        <div
          :class="{
            'thumbnail-preview': true,
            'grayscale opacity-50': !item || !item.preview,
            'opacity-50': selectedItem && item.printarea.key !== selectedItem.printarea.key,
            'border-blue-50': selectedItem && item.printarea.key === selectedItem.printarea.key
          }"
          v-html="item.preview.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DesignPreview from '@/mixins/DesignPreview'

  export default {

    mixins: [DesignPreview],

    data() {
      return {
        selectItemOnInit: true,
      }
    }

  }
</script>

<template>
  <i
    :class="[
      'w-6 h-6 text-xl',
      active ? 'bg-blue-50' : `bg-gray-80 group-hover:bg-blue-50`,
    ]"
    :style="`
      -webkit-mask-size: cover;
      mask-size: cover;
      -webkit-mask-image: url('/images/category_icons/${category.key}.svg')
      mask-image: url('/images/category_icons/${category.key}.svg')
    `"
  />
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      category: {
        type: Object,
        default: () => {
          return { name: 'Shirt', key: 'shirt' }
        }
      },
      color: {
        type: String,
        default: 'gray-80',
      },
    }
  }
</script>

<template>
  <div
    class="hidden lg:flex bg-white border-l border-grey-10 fixed top-0 right-0 bottom-0 pb-1 h-full flex-col justify-between"
    style="width: 480px"
  >
    <div
      v-if="isEditingMpcBaseDesign"
      class="flex-1 pt-5"
    >
      <div
        v-if="isSavingBaseDesign"
        class="absolute bg-white-50 inset-0 h-full z-20 items-center justify-center"
      >
        <loader
          color="blue-50"
          size="large"
          class="h-full flex-1 lg:max-h-40s"
        />
      </div>

      <div class="flex mb-4 items-center px-6">
        <div class="flex-1 text-xl tracking-3 leading-8 font-bold">
          {{ $t('views.designer.sidebar.edit_base_design') }}
        </div>

        <icon-button
          color-class="text-grey-70 border-grey-10 hover:border-grey-20"
          size-class="h-10 w-10 text-lg"
          @click="$emit('closeBaseDesignEditor')"
        >
          <i class="fas fa-times m-auto leading-10" />
        </icon-button>
      </div>

      <div
        class="text-blue-50 bg-blue-00 table border-blue-30 border rounded-lg mx-4 md:mx-6 px-6 py-4"
      >
        <div class="pr-4 table-cell align-middle pt-1">
          <span class="icon-info-outline text-2xl" />
        </div>

        <div class="table-cell align-middle">
          {{ $t('views.designer.sidebar.edit_base_design_info') }}
        </div>
      </div>

      <!-- TODO: outsource into new component for use in all sidebar content components -->
      <create-control-element
        v-if="designerRef"
        ref="creator"
        class="px-2"
        :color="currentBaseProductColor"
        :designer-fonts="designerFonts"
        :processing-items="designerRef.processingItems"
        :disable-features="[]"
        :selected-processing-item="designerRef.selectedProcessingItem"
        :is-uploading="$refs.assetUploader && $refs.assetUploader.isLoading"
        @selected-processing-item-updated="designerRef.selectProcessingItem($event)"
        @processing-items-updated="$parent.$parent.updateVisibleProcessingItems"
        @select-graphic="$emit('select-graphic')"
        @upload-graphic="e => $emit('upload-graphic', e)"
      >
        <template v-slot:upload>
          <asset-uploader
            ref="assetUploader"
            v-if="designerRef.printarea"
            :area="designerRef.printarea.key"
            @asset-uploaded="(d) => $parent.$parent.addCampaignAsset(d)"
            @upload:error="(e) => $parent.$parent.showError(e)"
          />
        </template>
      </create-control-element>

      <div class="p-6">
        <button
          class="submit-button"
          :disabled="isSavingBaseDesign ? 'disabled' : false"
          @click.prevent="$emit('saveBaseDesign')"
        >
          <span>{{ $t('views.designer.sidebar.submit_base_design') }}</span>
        </button>
      </div>
    </div>

    <template v-else>
      <div class="flex-1 p-6">
        <div class="border py-4 px-6 text-grey-80 rounded leading-7 tracking-4">
          <p>
            {{ $t('views.designer.sidebar.width') }}: <strong>{{ Math.ceil(printWidth) }}</strong> mm
          </p>
          <p>
            {{ $t('views.designer.sidebar.height') }}: <strong>{{ printHeight }}</strong> mm
          </p>
          <p class="flex items-center">
            {{ $t('views.designer.sidebar.print_quality') }}:

            <span
              v-if="printQualityLabel"
              :class="`${printQualityColor} px-2 rounded-full ml-2 text-xs leading-5 inline-flex`"
            >
              {{ printQualityLabel }}
            </span>
          </p>
        </div>

        <div class="pt-4">
          <p class="text-grey-80 leading-7 tracking-4 mb-1">
            {{ $t('views.designer.sidebar.print_width') }}
          </p>

          <div class="flex items-center">
            <div class="h-10 w-32 flex border rounded overflow-hidden flex-shrink-0">
              <input
                type="number"
                placeholder="0"
                min="0"
                class="h-full w-full border-0 text-center lg:w-3/4 pl-4 rounded"
                :value="Math.ceil(printWidth)"
                @change="updatePrintWidth($event.target.value)"
              >
              <span class="h-full w-12 flex justify-center items-center bg-grey-10 border-left text-grey-70">
                mm
              </span>
            </div>
          </div>
        </div>

        <button
          class="rounded-full border border-blue-50-50 text-blue-50 flex items-center justify-center py-2 px-6 my-4 w-full hover:border-blue-50 transition-all duration-200 font-semibold"
          @click.prevent="$emit('openBaseDesignEditor')"
        >
          <i class="mr-1 icon-edit-outline text-xl" />
          <span>{{ $t('views.designer.sidebar.edit_base_design') }}</span>
        </button>

        <div class="mt-8 text-sm">
          Farbe: <span class="font-bold">{{ currentColorName }}</span>
        </div>

        <div class="mt-2 flex flex-wrap">
          <div
            v-for="color in colorsInUse"
            :key="color.id"
            class="relative group"
          >
            <color-selector
              :title="color.color_name"
              :color="color.hexcode"
              :active="color.id === currentColorId"
              @click="$emit('selectColor', color.id)"
            />

            <button
              v-if="colorsInUse.length !== 1"
              class="delete-color-badge opacity-0 group-hover:opacity-100"
              @click="deleteColor(color)"
            >
              <i class="icon-close-outline text-sm font-icomoon cursor-pointer" />
            </button>
          </div>

          <div
            v-if="showColorSelector"
            class="absolute inset-0 p-4 bg-white z-30"
            @keydown.escape="$emit('closeColorSelector')"
          >
            <div class="flex justify-between">
              <h3 class="text-lg font-bold text-dark-blue mb-1 mt-1">
                {{ $t('views.mpc_overview.product_group.add_colors') }}
              </h3>

              <icon-button
                color-class="text-gray-50 border-grey-10 hover:text-blue-50 hover:border-grey-20"
                size-class="h-10 w-10 text-lg"
                :title="$t('global.close')"
                @click="$emit('closeColorSelector')"
              >
                <i class="fas fa-times m-auto leading-10" />
              </icon-button>
            </div>

            <div
              class="mt-3 flex flex-wrap"
            >
              <color-selector
                v-for="color in availableColors"
                :key="`add_color_${color.id}`"
                :title="color.color_name"
                :color="color.hexcode"
                @click="addColor(color)"
              />
            </div>
          </div>

          <button
            v-if="availableColors.length > 0"
            class="color-swatch color-swatch--button mr-3 mb-3 bg-white"
            :title="$t('views.mpc_overview.product_group.add_colors')"
            @click.prevent="$emit('openColorSelector')"
            @keydown.escape="$emit('closeColorSelector')"
          >
            <i class="icon-plus-outline font-icomoon text-lg cursor-pointer" />
          </button>
        </div>
      </div>

      <div class="w-full border-t border-gray-10 p-6">
        <button
          class="submit-button"
          :disabled="loadingNextStep"
          @click.prevent="$emit('nextStep')"
        >
          {{ loadingNextStep ? $t('global.loading') : $t('views.designer.sidebar.submit_save_product') }}
        </button>
        <button
          class="btn-platform-secondary mt-4"
          :disabled="loadingNextStep"
          @click.prevent="$emit('cancel')"
        >
          {{ loadingNextStep ? $t('global.loading') : $t('views.designer.sidebar.cancel') }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ColorSelector from '@/components/config/colors/ColorSelector.vue'
  import IconButton from '@/components/buttons/IconButton'
  import ColorSelection from 'sb/elements/Designer/Controls/ColorSelection.vue'
  import CreateControlElement from 'sb/elements/Designer/Controls/CreateControlElement.vue'
  import AssetUploader from '../designer/AssetUploader'
  import Loader from '../../layout/Loader.vue'

  const INITIAL_PRINT_WIDTH = 220

  export default {

    props: {
      colorsInUse: {
        type: Array
      },
      availableColors: {
        type: Array
      },
      shippingPrice: {
        type: Number,
        required: true
      },
      currentPrice: {
        type: Number,
        required: true
      },
      currentCountry: {
        type: String,
        required: true
      },
      loadingNextStep: {
        type: Boolean,
        required: true
      },
      designerRef: {
        type: Object,
      },
      isEditingMpcBaseDesign: {
        type: Boolean,
      },
      currentPrintarea: {
        validator: prop => typeof prop === 'object' || prop == null,
        required: true
      },
      currentProcessingSpecifications: {
        type: Array,
      },
      showColorSelector: {
        type: Boolean,
      },
      currentColorId: {
        type: Number,
      },
    },

    data() {
      return {
        showTooltip: false,
      }
    },

    methods: {
      onColorUpdate(colorId) {
        this.$emit('closeColorSelector')
        this.setCurrentColorId(colorId)
      },
      updatePrintWidth(value) {
        const updatedProcessingItems = this.$parent.$parent.visibleProcessingItems.map((processingItem) => {
          if (processingItem.uuid === this.currentConsolidatedProcessingItem.uuid) {
            return {
              ...processingItem,
              width_mm: parseInt(value, 10)
            }
          }

          return processingItem
        })

        this.$parent.$parent.updateVisibleProcessingItems(updatedProcessingItems)
      },
      /**
       * @param {BaseProductColorVariant} color
       */
      deleteColor(color) {
        this.$emit('deleteColor', color)
      },
      /**
       * @param {BaseProductColorVariant} color
       */
      addColor(color) {
        this.$emit('addColor', color)
      },
      ...mapActions([
        'setCurrentColorId'
      ]),
    },

    computed: {
      /**
       * @return {ApiFormattedProcessing|null}
       */
      currentProcessingSpecificationForPrintarea() {
        if (!this.currentPrintarea || !this.currentProcessingSpecifications) return null

        const specification = this.currentProcessingSpecifications
          .find(({ processingarea_type_key }) => processingarea_type_key === this.currentPrintarea.key)

        return specification ? Object.freeze(specification) : null
      },
      /**
       * @return {ApiFormattedDesignProcessingItem|null}
       */
      currentConsolidatedProcessingItem() {
        if (!this.currentProcessingSpecificationForPrintarea) return null

        return this.currentProcessingSpecificationForPrintarea.processing_items.data.find((item) => {
          return item && item.hasOwnProperty('type') && item.type === 'design' && item.uuid
        }) || null
      },
      printWidth() {
        if (!this.currentConsolidatedProcessingItem) return INITIAL_PRINT_WIDTH

        return this.currentConsolidatedProcessingItem.width_mm || INITIAL_PRINT_WIDTH
      },
      designAspectRatio() {
        if (!this.currentConsolidatedProcessingItem) {
          return 1
        }

        if (this.currentConsolidatedProcessingItem.aspect_ratio) {
          return this.currentConsolidatedProcessingItem.aspect_ratio
        } else {
          return this.currentConsolidatedProcessingItem.height_mm / this.currentConsolidatedProcessingItem.width_mm
        }
      },
      /**
       * @return {string}
       */
      printHeight() {
        if (!this.designAspectRatio) {
          return '–'
        }

        const calculatedHeight = this.printWidth * this.designAspectRatio

        if (calculatedHeight % 10 !== 1) {
          return `~${Math.round(calculatedHeight)}`
        }

        return calculatedHeight.toString()
      },
      /** @return {string} */
      printQualityColor() {
        if (typeof (this.currentConsolidatedProcessingItem || {}).dpi !== 'number') {
          return ''
        }

        if (this.currentConsolidatedProcessingItem.dpi <= 100) {
          return 'bg-red-30 text-red-90'
        }

        if (this.currentConsolidatedProcessingItem.dpi >= 300) {
          return 'bg-green-30 text-green-90'
        }

        return 'bg-yellow-30 text-yellow-90'
      },
      /** @return {string|null} */
      printQualityLabel() {
        if (typeof (this.currentConsolidatedProcessingItem || {}).dpi !== 'number') {
          return null
        }

        if (this.currentConsolidatedProcessingItem.dpi <= 100) {
          return this.$t('views.designer.sidebar.print_quality_bad')
        }

        if (this.currentConsolidatedProcessingItem.dpi >= 300) {
          return this.$t('views.designer.sidebar.print_quality_excellent')
        }

        return this.$t('views.designer.sidebar.print_quality_good')
      },
      currentColorName() {
        if (!this.colorsInUse || this.currentColorId === null) {
          return ''
        }

        const currentColor = this.colorsInUse.find((color) => color.id === this.currentColorId)
        if (currentColor) {
          return currentColor.color_name
        }

        return ''
      },
      ...mapGetters([
        'currentBaseProductColor',
        'designerFonts',
        'isSavingBaseDesign',
      ])
    },

    components: {
      Loader,
      ColorSelector,
      IconButton,
      ColorSelection,
      AssetUploader,
      CreateControlElement,
    },

  }
</script>


<template>
  <div class="lg:flex-1">
    <div class="mb-6 lg:w-10/12">
      <div class="text-green-70">
        <i class="icon-car-outline" />&nbsp;
        <span v-html="$t('views.cart.full_price_display.delivery_time', {days: '2-5'})" />
      </div>

      <div
        class="text-blue-50 mt-4"
      >
        <button
          class="flex items-center"
          @click.prevent="showCouponField = !showCouponField"
        >
          {{ $t('views.cart.full_price_display.coupon.open_button') }}

          <i :class="`icon-chevron-${showCouponField ? 'up' : 'down'}-outline text-xl`" />
        </button>
      </div>

      <div
        v-if="showCouponField"
        class="mt-4 flex w-full"
      >
        <div class="flex-1">
          <sg-input
            v-model="coupon_code"
            @keyup.enter="submitCoupon"
            :placeholder="$t('views.cart.full_price_display.coupon.input_placeholder') || ''"
            type="text"
          />
        </div>

        <button
          class="ml-4 m-auto h-11 w-11 flex items-center justify-center border border-blue-50 text-blue-50 rounded-full transition-all duration-200 hover:border-blue-60 hover:text-blue-60"
          :class="isApplyingCoupon ? 'opacity-50': ''"
          @click="submitCoupon"
          :aria-label="$t('views.cart.full_price_display.coupon.apply_coupon')"
        >
          <loader
            v-if="isApplyingCoupon"
            size="medium"
          />

          <i class="block icon-arrow-forward-outline text-2xl" v-else />
        </button>
      </div>

      <div
        v-if="couponError"
        class="mt-4 flex w-full border text-yellow-70 rounded border-yellow-50 p-4 bg-yellow-00"
      >
        <i class="block icon-alert-triangle-outline text-lg font-bold mr-2 mt-1" />

        <div class="flex-1">
          {{ couponError }}
        </div>
      </div>

      <div
        v-if="couponSuccess"
        class="mt-4 flex w-full border text-green-70 rounded border-green-50 p-4 bg-green-00"
      >
        <i class="block icon-checkmark-circle-2-outline text-lg font-bold mr-2 mt-1" />

        <div class="flex-1">
          <template v-if="coupon_code">
            {{ $t('views.cart.full_price_display.coupon.applied_successfully') }}
          </template>
          <template v-else>
            {{ $t('views.cart.full_price_display.coupon.removed_successfully') }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loader from '@/components/layout/Loader.vue'
  import SgInput from 'sb/elements/Form/SgInput'
  import { saveCart } from '@/api/calculation'
  import { mapActions, mapGetters } from 'vuex'

  export default {

    data() {
      return {
        coupon_code: '',
        showCouponField: false,
        isApplyingCoupon: false,
        couponError: null,
        couponSuccess: false,
      }
    },

    mounted() {
      this.coupon_code = this.orderDetails && this.orderDetails.coupon_code ? this.orderDetails.coupon_code : ''
    },

    methods: {
      async submitCoupon() {
        if (!this.coupon_code) {
          this.couponError = this.$t('views.cart.full_price_display.coupon.error_enter_valid_code')
          return
        }

        await this.applyCoupon()
      },
      async applyCoupon() {
        if (this.isApplyingCoupon) {
          return
        }

        try {
          this.couponError = null
          this.couponSuccess = false
          this.isApplyingCoupon = true

          const cartWithCoupon = /** @type ApiFormattedCart */ Object.freeze({ ...this.cart, coupon_code: this.coupon_code })
          const [ updatedCart ] = await saveCart(
            cartWithCoupon,
            false,
            true,
            this.currentManufacturingJobUuid
          )

          this.setCart(updatedCart)

          this.setCartCouponCode(this.coupon_code)
          this.setOrderDetails({
            coupon_code: this.coupon_code,
            shipping_equals_primary: this.orderDetails ? this.orderDetails.shipping_equals_primary : true,
          })

          this.$emit('update-cart')

          this.couponSuccess = true
          this.showCouponField = false
        } catch (e) {
          if (e.response && e.response.status === 400 && e.response.data.type === 'InvalidCouponForOrderException') {
            this.couponError = this.$t('views.cart.full_price_display.coupon.error_invalid_coupon')
          } else if (e.response && e.response.status === 400 && e.response.data.type === 'CouponDepletedException') {
            this.couponError = this.$t('views.cart.full_price_display.coupon.error_depleted_coupon')
          } else if (e.response && e.response.status === 400 && e.response.data.type === 'CouponRateLimitException') {
            this.couponError = this.$t('views.cart.full_price_display.coupon.error_rate_limiting')
          } else {
            this.couponError = this.$t('views.cart.full_price_display.coupon.error_unknown')
          }
        }

        this.isApplyingCoupon = false
      },
      async removeCoupon() {
        this.coupon_code = ''
        this.couponSuccess = false

        await this.applyCoupon()
      },
      ...mapActions([
        'setOrderDetails',
        'setCartCouponCode',
        'setCart'
      ])
    },

    computed: {
      ...mapGetters([
        'cart',
        'orderDetails',
        'currentManufacturingJobUuid',
      ])
    },

    components: { Loader, SgInput }

  }
</script>

<template>
  <div>
    <div v-if="methodKey === 'dtg'">
      <svg
        id="Canvas"
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12,23c-4.41,0-8-3.36-8-7.5s1.7-6.28,3.68-8.8c1.15-1.47,2.35-2.99,3.43-5.15.34-.68,1.45-.68,1.79,0,1.08,2.16,2.27,3.68,3.43,5.15,1.97,2.51,3.68,4.68,3.68,8.8s-3.59,7.5-8,7.5ZM12,4.08c-.92,1.53-1.88,2.75-2.75,3.86-1.89,2.4-3.25,4.14-3.25,7.56,0,3.03,2.69,5.5,6,5.5s6-2.47,6-5.5c0-3.42-1.36-5.16-3.25-7.56-.87-1.11-1.83-2.33-2.75-3.86Z"></path>
      </svg>
    </div>
    <div v-if="methodKey === 'sublimation'">
      <svg
        id="Canvas"
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M20,3c0,.55-.45,1-1,1H5c-.55,0-1-.45-1-1s.45-1,1-1h14c.55,0,1,.45,1,1ZM19,20H5c-.55,0-1,.45-1,1s.45,1,1,1h14c.55,0,1-.45,1-1s-.45-1-1-1ZM10,14.5c0,2.06,1.7,3.14,2.7,3.45.1.03.2.05.3.05.43,0,.82-.27.95-.7.16-.53-.13-1.09-.66-1.25-.01,0-1.3-.42-1.3-1.55,0-.64.39-1.25.84-1.96.54-.85,1.16-1.81,1.16-3.04,0-2.06-1.7-3.14-2.7-3.45-.53-.17-1.09.13-1.25.66-.16.53.13,1.09.66,1.25.01,0,1.3.42,1.3,1.55,0,.64-.39,1.25-.84,1.96-.54.85-1.16,1.81-1.16,3.04ZM15,14.5c0,2.06,1.7,3.14,2.7,3.45.1.03.2.05.3.05.43,0,.82-.27.95-.7.16-.53-.13-1.09-.66-1.25-.01,0-1.3-.42-1.3-1.55,0-.64.39-1.25.84-1.96.54-.85,1.16-1.81,1.16-3.04,0-2.06-1.7-3.14-2.7-3.45-.53-.17-1.09.13-1.25.66-.16.53.13,1.09.66,1.25.01,0,1.3.42,1.3,1.55,0,.64-.39,1.25-.84,1.96-.54.85-1.16,1.81-1.16,3.04ZM5,14.5c0,2.06,1.7,3.14,2.7,3.45.1.03.2.05.3.05.43,0,.82-.27.95-.7.16-.53-.13-1.09-.66-1.25-.01,0-1.3-.42-1.3-1.55,0-.64.39-1.25.84-1.96.54-.85,1.16-1.81,1.16-3.04,0-2.06-1.7-3.14-2.7-3.45-.53-.17-1.09.13-1.25.66-.16.53.13,1.09.66,1.25.01,0,1.3.42,1.3,1.55,0,.64-.39,1.25-.84,1.96-.54.85-1.16,1.81-1.16,3.04Z"></path>
      </svg>
    </div>
    <div v-if="methodKey === 'embroidery'">
      <svg
        id="Canvas"
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M21.21,2.72c-1.03-1.03-2.69-1.05-3.75-.06-1.29-.75-3.35-1.23-6,.5-.46.3-.59.92-.29,1.38.19.29.51.45.84.45.19,0,.38-.05.55-.16,1.29-.85,2.47-1.05,3.51-.63C12.43,8.36,2.56,19.82,1.24,21.35c-.34.4-.32.99.05,1.36.19.19.45.29.71.29.23,0,.47-.08.66-.25,1.53-1.33,12.97-11.27,17.09-14.9.14.68.09,1.59-.58,2.59-1.64,2.47-3.35,6.18-2.12,9.87.14.42.53.68.95.68.1,0,.21-.02.32-.05.52-.17.81-.74.63-1.27-.97-2.92.49-6.03,1.88-8.13,1.32-1.98,1.07-3.93.52-5.19.89-1.06.85-2.65-.15-3.64ZM18.36,4.62s0,0,0,0c0,0,0,0,0,0,.23-.26.38-.42.43-.48.14-.14.32-.21.5-.21s.36.07.5.21c.24.24.26.62.07.89-.17.02-.33.07-.47.19-.2.16-.31.37-.36.6-1.28,1.13-3.99,3.5-7.15,6.26,2.96-3.42,5.48-6.33,6.47-7.46Z"></path>
      </svg>
    </div>
    <div v-if="methodKey === 'engraving'">
      <svg
        id="Canvas"
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M8,19c0,.55-.45,1-1,1H3c-.55,0-1-.45-1-1s.45-1,1-1h4c.55,0,1,.45,1,1ZM21,18h-4c-.55,0-1,.45-1,1s.45,1,1,1h4c.55,0,1-.45,1-1s-.45-1-1-1ZM12,15c.55,0,1-.45,1-1V3c0-.55-.45-1-1-1s-1,.45-1,1v11c0,.55.45,1,1,1ZM4.71,10.29c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l4,4c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02,0-1.41l-4-4ZM16.71,15.71l4-4c.39-.39.39-1.02,0-1.41s-1.02-.39-1.41,0l-4,4c-.39.39-.39,1.02,0,1.41.2.2.45.29.71.29s.51-.1.71-.29ZM12,17c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Z"></path>
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      methodKey: {
        type: String,
      },
    },
  }
</script>

<style
  lang="scss"
  scoped>

</style>

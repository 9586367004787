<template>
  <div class="bg-white border pt-6 px-6 pb-2 lg:pt-6 lg:px-8 lg:pb-4 mt-6 rounded">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold text-blue-80">
        {{ title }}
      </h2>

      <div class="flex gap-3" v-if="!readonly">
        <icon-button
          size-class="h-11 w-11 text-xl"
          :title="$t('global.edit') || ''"
          :disabled="loading"
          @click="$emit('edit-collection')"
        >
          <i class="icon icon-edit-outline" />
        </icon-button>

        <icon-button
          size-class="h-11 w-11 text-xl"
          color-class="text-blue-50 border-blue-50-50 hover:text-red-50 hover:border-red-400"
          :title="$t('global.remove') || ''"
          :disabled="loading"
          @click="$emit('remove-collection')"
        >
          <i class="icon icon-trash-2-outline" />
        </icon-button>
      </div>
    </div>

    <div class="hidden lg:flex uppercase text-grey-50 tracking-wider text-sm font-semibold border-t border-b py-2">
      <div class="w-7/12">
        {{ $t('views.cart.product_group.product') }}
      </div>
      <div class="w-1/6 text-right">
        {{ $t('views.cart.product_group.price_per_item') }}
      </div>
      <div class="w-1/6 text-right">
        {{ $t('views.cart.product_group.total_price') }}
      </div>
      <div class="w-1/12 lg:mr-2" />
    </div>

    <cart-product-group
      v-for="cartProduct in products"
      :key="cartProduct.id"
      :product="cartProduct"
      :processings="processings"
      :previews="previews"
      :loading="loading"
      :readonly="readonly"
      @remove-product="(baseProductId) => $emit('remove-product', baseProductId)"
      @edit-product="(baseProductId) => $emit('edit-product', baseProductId)"
      @update="$emit('update')"
    />
  </div>
</template>

<script>
  import CartProductGroup from './CartProductGroup.vue'
  import IconButton from '../../buttons/IconButton.vue'

  export default {

    props: {
      /** @type {PropOptions<ApiFormattedProduct[]>} */
      products: {
        type: Array,
        required: true,
      },
      /** @type {PropOptions<ApiFormattedProcessing[]>} */
      processings: {
        type: Array,
        required: true,
      },
      /** @type {PropOptions<ManufacturingJobPreview[]>} */
      previews: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
      },
      loading: {
        type: Boolean,
        defaultValue: false,
      },
      readonly: {
        type: Boolean,
        defaultValue: false,
      },
    },

    components: { CartProductGroup, IconButton }

  }
</script>

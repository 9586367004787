<template>
  <div>
    <div
      class="w-full rounded-md border flex p-4 items-center mb-3 cursor-pointer"
      :class="currentBaseProduct.id === baseProduct.id ? 'bg-blue-00' : 'bg-white'"
      :style="currentBaseProduct.id === baseProduct.id ? 'border-color: rgba(3, 155, 229, 0.35);' : ''"
      @click="changeCurrentBaseProduct"
    >
      <img
        class="block"
        :src="productImage"
        style="height: 60px"
        alt=""
      >

      <div class="ml-4 flex-1">
        <div class="font-bold tracking-3 leading-ah-relaxed">
          {{ baseProduct.name }}
        </div>
        <div class="text-grey-70 text-sm tracking-4 mt-1 leading-snug">
          {{ baseProduct.description_preview }}, {{ availableSizes }}
        </div>
        <div class="text-blue-50 text-sm font-bold mt-1">
          {{ $t('global.price_from') }} {{ productPrice | currency }}
          <span class="font-normal text-xs text-gray-70">
            {{ $t('global.incl_one') }}
          </span>
        </div>
      </div>

      <div class="align-middle">
        <button
          class="ml-2 text-blue-50 text-xl p-2"
          @click.prevent="showProductInfo = true"
        >
          <i class="icon-info-outline" />
        </button>
      </div>
    </div>

    <modal
      v-if="showProductInfo"
      style="z-index: 1000"
      @close:modal="showProductInfo = false"
    >
      <product-info-popup :base-product-details="baseProduct" />
    </modal>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { PropOptions } from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import ProductInfoPopup from '@/components/config/ProductInfoPopup'
  import Modal from 'sb/elements/Modals/Modal'

  export default {

    props: {
      /** @type {PropOptions<BaseProduct>} */
      baseProduct: {
        type: Object,
        required: true
      },
      /** @type {PropOptions<BaseProduct>} */
      currentBaseProduct: {
        type: Object,
        required: true
      },
      availableColors: {
        type: Array
      },
    },

    data() {
      return {
        showProductInfo: false
      }
    },

    methods: {
      changeCurrentBaseProduct() {
        this.setCurrentBaseProductReference(this.baseProduct.reference)

        const baseUrl = `${window.location.protocol}//${window.location.host}`;

        let path = `${baseUrl}/designer/${this.baseProduct.slug}`
        if (this.cart && this.cart.reference) {
          path = `${path}/${this.cart.reference}`
        }

        if (this.currentManufacturingJobGroupId) {
          path = `${path}/${this.currentManufacturingJobGroupId}`
        }

        window.history.pushState({ path }, '', path)
      },
      ...mapActions([
        'setCurrentBaseProductReference'
      ])
    },

    computed: {
      productPrice() {
        return parseInt(this.baseProduct.baseprice_processing_default.data.amount)
      },
      productImage() {
        const image = this.baseProduct.default_image || this.defaultBaseProductVariant.images.data.front.find(
          ({ image_type }) => image_type === 'catalog-image'
        )
        return image.small || image.standard
      },
      productFit() {
        return this.baseProduct.attributes.data.find(({ type_key }) => type_key === 'product-fit')
      },
      /**
       * @return {string}
       */
      availableSizes() {
        if (!this.defaultBaseProductVariant) return ''

        const sizes = this.defaultBaseProductVariant.size_variants.data.map(({ size }) => size)
        if (sizes.length > 1) {
          return `${sizes[0]}-${sizes[sizes.length - 1]}`
        }

        return sizes[0]
      },
      defaultBaseProductVariant() {
        if (!this.baseProduct.variants) return null

        const defaultColorId = parseInt(this.baseProduct.default_variant_sku.split('.')[1])
        return this.baseProduct.variants.data.find(({ id }) => id === defaultColorId) || this.baseProduct.variants.data[0]
      },
      ...mapGetters([
        'cart',
        'currentManufacturingJobGroupId',
      ]),
    },

    components: {
      ProductInfoPopup,
      Modal,
    },

  }
</script>

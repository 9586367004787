<template>
  <form-group :has-danger="hasDanger">
    <label class="form-group__label">
      <slot></slot>
    </label>
    <sg-select
      :id="id"
      :name="name"
      :options="options"
      :class="[className, { 'has-danger': hasDanger }]"
      :value="value"
      :disabled="disabled"
      :required="required"
      :tabindex="tabindex"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
      :size="size"
      @input="$emit('input', $event)" />
  </form-group>
</template>

<script>
  import FormGroup from './FormGroup.vue'
  import SgSelect from './SgSelect.vue'

  export default {

    props: {
      id: String,
      name: String,
      className: String,
      hasDanger: Boolean,
      value: String,
      options: {
        type: Object,
        required: true,
      },
      disabled: Boolean,
      required: Boolean,
      tabindex: Number,
      autofocus: Boolean,
      autocomplete: String,
      size: String,
    },

    components: {
      FormGroup,
      SgSelect,
    },

  }
</script>

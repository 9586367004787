<template>
  <button
    :title="title"
    :class="[
      'border rounded-full flex transition-all duration-200 font-icomoon bg-white items-center justify-center',
      sizeClass,
      colorClass,
    ]"
    :disabled="disabled ? 'disabled' : false"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
  export default {

    props: {
      sizeClass: {
        type: String,
        default: 'h-11 w-11 text-2xl',
      },
      colorClass: {
        type: String,
        default: 'text-blue-50 border-blue-50-50 hover:border-blue-60'
      },
      title: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        defaultValue: false
      }
    },

  }
</script>

<template>
  <div class="design-preview">
    <div
      v-for="item in previewList"
      :key="item.printarea.key"
      class="w-24 relative preview-window cursor-pointer"
      @click="() => {
        if (item !== null) select(item)
      }"
    >
      <div
        class="opacity-100 inset-0 text-center text-blue-50 text-2xl absolute hover-icon flex justify-center items-center h-full"
      >
        <i class="icon-search-outline" />
      </div>

      <div
        :class="{'transition-opacity duration-200 hover:opacity-50 h-full': true}"
        v-html="item.preview.svg"
      />
    </div>

    <modal
      v-if="selectedItem"
      @close:modal="selectedItem = null"
    >
      <div
        class="w-360px max-w-full"
        v-html="selectedItem.preview.svg"
      />
    </modal>
  </div>
</template>

<script>
  import DesignPreview from '@/mixins/DesignPreview'
  import Modal from 'sb/elements/Modals/Modal'

  export default {

    mixins: [DesignPreview],

    components: {
      Modal,
    }

  }
</script>

<template>
  <nav class="sg-header-nav">
    <a
      ref="hamburger"
      class="sg-header-nav__hamburger"
      title="Menü öffnen/schließen"
      aria-hidden="true"
      tabindex="-1"
      @click="clicked"
      @blur="blurred">
      <icon fa="bars" />
    </a>
    <div class="sg-header-nav__wrapper">
      <slot></slot>
    </div>
  </nav>
</template>

<script>
  export default {

    data() {
      return {
        hamburgerClicked: false,
      }
    },

    methods: {
      clicked() {
        if (document.activeElement === this.$refs.hamburger) {
          const wasClicked = this.hamburgerClicked

          this.hamburgerClicked = !this.hamburgerClicked

          if (wasClicked) {
            document.activeElement.blur()
          }
        }
      },
      blurred() {
        if (document.activeElement !== this.$refs.hamburger) {
          this.hamburgerClicked = false
        }
      },
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .sg-header-nav {
    @include breakpoint(sm) {
      &.nav--left {
        margin-right: auto;
      }
    }

    &__hamburger {
      display: flex;
      color: $grey-70;
      font-size: 1.25rem;
      padding: 1rem;
      transition: .2s ease;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $grey-90;
      }

      &:focus {
        text-decoration: none;
        background: $grey-20;
        box-shadow: $focus-outline;
      }

      @include breakpoint(lg) {
        display: none;
      }
    }

    &__wrapper {
      display: flex;
      position: absolute;
      top: 5rem;
      left: 0;
      right: 0;
      background: $grey-20;
      flex-wrap: wrap;
      z-index: 10;

      opacity: 0;
      pointer-events: none;

      &:focus-within,
      .sg-header-nav__hamburger:focus + & {
        opacity: 1;
        pointer-events: auto;
      }

      @include breakpoint(lg) {
        position: static;
        background: transparent;
        flex-wrap: nowrap;
        opacity: 1;
        pointer-events: auto;
      }

      > a,
      > button {
        display: block;
        font-size: 1.125rem;
        color: $blue-dark;
        line-height: 1.75rem;
        font-family: $nunito;
        font-weight: 600;
        letter-spacing: 0.5px;
        width: 100%;
        padding: 1rem;

        &:hover,
        &:focus {
          text-decoration: none;

          background: $blue;
          color: $white;
        }

        &:focus-visible {
          box-shadow: $focus-outline;
        }

        @include breakpoint(lg) {
          width: auto;
          padding: 1.625rem 1.5rem;

          &:hover,
          &:focus {
            color: $blue;
            background: transparent;
          }
        }
      }
    }
  }
</style>

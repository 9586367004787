import Vue from 'vue'
import { parseISO } from 'date-fns/parseISO'
import { format } from 'date-fns/format'
import {
  getFirstSortPosition,
  makeArrayFromImageObject,
  getAllCartProcessingsForGroup,
  getAllCartProductsForGroup,
  getAllPreviewsForGroup,
  getAllCartProducts,
} from '@/utils/helpers'

/**
 * @typedef {Record<ApiFormattedManufacturingJobGroupId, Record<BaseProductId, ApiFormattedManufacturingJobUuid>>} ManufacturingJobProductsMapping
 */
/**
 * @typedef {{
 *  manufacturingJobProductsMapping: (function(State): ManufacturingJobProductsMapping),
 *  currentManufacturingJobUuid: (function(State): null),
 *  isLoadingPrices: (function(State): boolean),
 *  currentBaseProduct: (function(State): BaseProduct|null),
 *  cart: (function(State): ApiFormattedCart|null),
 *  designerFonts: (function(State): DesignerFontFamily[]),
 *  allCartProcessingsForCurrentGroup: (function(State): ApiFormattedProcessing[]),
 *  totalNumberOfItemsForCurrentGroup: (function(State, Getters): number),
 *  activePrintareasForCart: (function(State): string[]),
 *  currentGroupIsEmpty: (function(State, Getters): boolean),
 *  imageSideQuery: (function(State): string),
 *  currentBaseProductColor: (function(State, Getters): BaseProductColorVariant|null),
 *  isSavingBaseDesign: (function(State): boolean),
 *  isCheckedOut: (function(State): boolean),
 *  availablePrintareasForCurrentProduct: (function(State, Getters): BaseProductPrintarea[]),
 *  isLoadingCart: (function(State): boolean),
 *  cartIsEmpty: (function(State): boolean),
 *  isMpc: (function(State): boolean),
 *  cartReference: (function(State): string|null),
 *  manufacturingJobsForCurrentGroup: (function(State): ApiFormattedManufacturingJob[]),
 *  currentColorId: (function(State): number|null),
 *  alerts: (function(State): unknown[]),
 *  orderDetails: (function(State): unknown|null),
 *  allCartProductsForCurrentGroup: (function(State): ApiFormattedProduct[]),
 *  allCartProducts: (function(State): ApiFormattedProduct[]),
 *  currentImage: ((function(State, Getters): (BaseProductImage|null))|*),
 *  allPreviewsForCurrentGroup: (function(State): ManufacturingJobPreview[]),
 *  currentManufacturingJob: (function(State): (ApiFormattedManufacturingJob|null)),
 *  baseProducts: (function(State): BaseProduct[]),
 *  currentManufacturingJobGroupId: (function(State): ApiFormattedManufacturingJobGroupId|null)
 *  orderedCartCollections: (function(State): CartCollectionInterface[])
 * }} Getters
 *
 * @type {Getters}
 */
const getters = {
  currentBaseProduct: (state) => {
    if (!state.base_products) return null

    if (state.current_base_product_reference) {
      return state.base_products.find(({ reference }) => reference === state.current_base_product_reference)
    }

    return state.base_products[0]
  },
  currentBaseProductColor: (state, getters) => {
    if (!getters.currentBaseProduct) return null

    const foundColor = getters.currentBaseProduct.variants.data.find((color) => (
      color.id === state.current_color_id
    ))
    if (foundColor) return foundColor

    return getFirstSortPosition(getters.currentBaseProduct.variants.data)
  },
  currentColorId: (state) => {
    return state.current_color_id
  },
  currentImage: (state, getters) => {
    if (!getters.currentBaseProductColor) {
      return null
    }

    // TODO: Why is `image_side_query` null in store??
    const side = state.image_side_query || 'front'
    return getFirstSortPosition(makeArrayFromImageObject(getters.currentBaseProductColor.images.data)
      .filter(img => img.side === side)
    ) || null
  },
  imageSideQuery: (state) => {
    return state.image_side_query
  },
  baseProducts: (state) => {
    return state.base_products || []
  },
  designerFonts: (state) => {
    return state.designer_fonts
  },
  cart: (state) => {
    return state.cart
  },
  allCartProducts: (state) => {
    return getAllCartProducts(state.cart)
  },
  allCartProductsForCurrentGroup: (state) => {
    return getAllCartProductsForGroup(state.cart, state.current_manufacturing_job_group_id)
  },
  allCartProcessingsForCurrentGroup: (state) => {
    return getAllCartProcessingsForGroup(state.cart, state.current_manufacturing_job_group_id)
  },
  allPreviewsForCurrentGroup: (state) => {
    return getAllPreviewsForGroup(state.cart, state.current_manufacturing_job_group_id)
  },
  availablePrintareasForCurrentProduct: (state, getters) => {
    if (!getters.currentBaseProduct || !getters.activePrintareasForCurrentGroup) {
      return []
    }

    let printareas = {}

    let printableAreas = getters.currentBaseProduct.printareas.data.filter(a => a.is_printable)

    printableAreas.forEach((printarea) => {
      if (getters.activePrintareasForCurrentGroup.includes(printarea.key) && !printareas.hasOwnProperty(printarea.key)) {
        printareas[printarea.key] = printarea
      }
    })

    let areas = Object.values(printareas)

    if (!areas.length) {
      areas = printableAreas
    }

    return areas
  },
  isCheckedOut: (state) => {
    return state.is_checked_out || false
  },
  cartReference: (state) => {
    if (!state.cart) {
      return null
    }

    return state.cart.reference
  },
  currentManufacturingJobGroupId: (state) => {
    return state.current_manufacturing_job_group_id
  },
  currentManufacturingJobUuid: (state) => {
    return state.current_manufacturing_job_uuid
  },
  manufacturingJobsForCurrentGroup: (state) => {
    if (!state.cart || !state.cart.manufacturing_jobs.data || !state.current_manufacturing_job_group_id) {
      return []
    }

    return state.cart.manufacturing_jobs.data.filter(
      (job) => job.group_id === state.current_manufacturing_job_group_id
    ) || []
  },
  currentManufacturingJob: (state) => {
    if (!state.cart || !state.cart.manufacturing_jobs) {
      return null
    }

    if (state.current_manufacturing_job_group_id === null) {
      return state.cart.manufacturing_jobs ? state.cart.manufacturing_jobs.data[0] : null
    }

    if (state.current_manufacturing_job_uuid === null) {
      const jobsForGroup = state.cart.manufacturing_jobs.find((job) => {
        if (typeof job.group_id === 'undefined' || typeof state.current_manufacturing_job_group_id === 'undefined') {
          return false
        }

        return job.group_id.toString() === state.current_manufacturing_job_group_id.toString()
      })
      return jobsForGroup ? jobsForGroup[0] : null
    }

    return state.cart.manufacturing_jobs.data
      .find((/** ApiFormattedManufacturingJob */job) => {
        if (typeof job.group_id === 'undefined' || typeof state.current_manufacturing_job_group_id === 'undefined') {
          return false
        }

        if (typeof job.uuid === 'undefined' || typeof state.current_manufacturing_job_uuid === 'undefined') {
          return false
        }

        return job.group_id.toString() === state.current_manufacturing_job_group_id.toString() &&
          job.uuid.toString() === state.current_manufacturing_job_uuid.toString()
      }) || null
  },
  orderDetails: (state) => {
    return state.order_details
  },
  alerts: (state) => {
    return state.alerts
  },
  activePrintareasForCurrentGroup: (state) => {
    if (!state.active_cart_printareas || !Object.keys(state.active_cart_printareas).length || state.current_manufacturing_job_group_id === null) {
      return []
    }

    return state.active_cart_printareas[state.current_manufacturing_job_group_id.toString()] || []
  },
  activePrintareasForCart: (state) => state.active_cart_printareas,
  totalNumberOfItemsForCurrentGroup: (state, getters) => {
    if (!getters.allCartProductsForCurrentGroup || getters.allCartProductsForCurrentGroup.length === 0) return 0

    return getters.allCartProductsForCurrentGroup.reduce((productSum, /* ApiFormattedProduct */product) => {
      if (product.amount) return productSum + product.amount

      return product.variants.reduce(
        (sum, variant) => sum + variant.amount,
        productSum
      )
    }, 0)
  },
  manufacturingJobProductsMapping: (state) => {
    if (!state.cart || !state.cart.manufacturing_jobs.data) return {}

    const productsMapping = {}
    state.cart.manufacturing_jobs.data.forEach((job) => {
      if (!{}.hasOwnProperty.call(productsMapping, job.group_id || 0)) {
        productsMapping[job.group_id || 0] = {}
      }

      job.products.data.forEach((product) => {
        Vue.set(productsMapping[job.group_id || 0], product.base_product_id, job.uuid)
      })
    })

    return productsMapping
  },
  cartIsEmpty: (state) => {
    if (!state.cart || !state.cart.manufacturing_jobs.data) return true
    return state.cart.manufacturing_jobs.data.every((/** ApiFormattedManufacturingJob */job) => {
      if (job.products.data.length === 0) {
        return true
      }

      return job.products.data.every((product) => {
        return product.variants.length === 0
      })
    })
  },
  currentGroupIsEmpty: (state, getters) => {
    if (!getters.allCartProductsForCurrentGroup || getters.allCartProductsForCurrentGroup.length === 0) return true

    return getters.allCartProductsForCurrentGroup.every((/* ApiFormattedProduct */product) => {
      return product.variants.length === 0 || product.variants.every((variant) => variant.amount === 0)
    })
  },
  orderedCartCollections(state) {
    const collections = {}

    const getTimestamp = (element) => {
      if (element.created_at) {
        return parseISO(element.created_at).getTime();
      }

      if (element.createdAt) {
        return parseISO(element.createdAt).getTime();
      }

      return 0;
    }

    if (!state.cart || !state.cart.manufacturing_jobs) return []

    state.cart.manufacturing_jobs.data.forEach((job) => {
      let groupName = job.group_name || 'Kollektion'
      if (!job.group_name && job.created_at) {
        groupName = `Kollektion vom ${format(parseISO(job.created_at).getTime(), 'dd.MM.yy - HH:mm')} Uhr`
      }

      const collection = {
        createdAt: job.created_at,
        groupId: job.group_id,
        groupName: groupName,
        products: job.products.data,
        processings: job.processings.data,
        previews: job.previews ? job.previews.data : [],
      }

      if (Object.hasOwnProperty.call(collections, job.group_id)) {
        collections[job.group_id].products = collections[job.group_id].products.concat(collection.products)
        collections[job.group_id].processings = collections[job.group_id].processings.concat(collection.processings)
        collections[job.group_id].previews = collections[job.group_id].previews.concat(collection.previews)
        collections[job.group_id].group_name = job.group_name
      } else {
        collections[job.group_id] = collection
      }

      collections[job.group_id].products.sort((a, b) => getTimestamp(a) > getTimestamp(b) ? 1 : -1)
    })

    const collectionArray = Object.values(collections)
    collectionArray.sort((a, b) => getTimestamp(a) > getTimestamp(b) ? 1 : -1)

    return collectionArray
  },
  isMpc: (state) => state.is_mpc,
  isLoadingCart: (state) => state.is_loading_cart,
  isLoadingPrices: (state) => state.is_loading_prices,
  isSavingBaseDesign: (state) => state.is_saving_base_design,
}
export default getters

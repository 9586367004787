<template>
  <div class="flex">
    <div class="bg-grey-00 min-h-full flex-1">
      <div class="min-h-footer-padding">
        <shirtigo-header :show-meta-bar="false" />

        <main class="config-container">
          <h1 class="config-headline">
            {{ $t('views.calculator.headline') }}
          </h1>

          <div class="bg-white border px-4 pt-6 pb-8 lg:p-10 mt-6 rounded-lg">
            <div class="flex mb-8">
              <div class="number-icon-circle">
                1
              </div>

              <div>
                <h2 class="font-bold text-dark-blue text-xl lg:text-2xl mb-1">
                  {{ $t('views.calculator.products.headline') }}
                </h2>

                <p class="text-grey-70">
                  {{ $t('views.calculator.products.description') }}
                </p>
              </div>
            </div>

            <template v-if="!currentGroupIsEmpty">
              <calculator-product-group
                v-for="cartProduct in allCartProductsForCurrentGroup"
                :key="cartProduct.base_product_id"
                :product="cartProduct"
                :loading="isLoadingCart || !currentPriceObject"
                @update="updatePrice"
                @removeProduct="() => removeProduct(cartProduct.base_product_id)"
              />

              <div class="mt-8 flex items-center">
                <button
                  class="flex text-blue-50 items-center"
                  :disabled="isLoadingCart ? 'disabled' : false"
                  @click="openSelectProductsDialog"
                >
                <span
                  class="h-8 w-8 flex items-center justify-center border rounded-full border-blue-50 border-dashed mr-3"
                >
                  <i class="icon-plus-outline h-4 w-4" />
                </span>

                  {{ $t('views.calculator.products.add_product') }}
                </button>

                <span class="ml-auto">
                {{ $t('views.calculator.products.total_amount') }}:
                <strong class="font-bold">{{ totalNumberOfItemsForCurrentGroup }}</strong>
              </span>
              </div>
            </template>

            <template v-else>
              <div v-if="isLoadingCart">
                <loader
                  color="blue-50"
                  size="large"
                  class="flex-1 h-140px mt-6"
                />
              </div>

              <add-product-button
                v-else
                class="md:h-140px"
                @click="openSelectProductsDialog"
              />
            </template>
          </div>

          <div class="bg-white border px-4 pt-6 pb-8 lg:p-10 mt-6 rounded-lg">
            <div class="flex">
              <div
                class="number-icon-circle"
              >
                2
              </div>

              <div>
                <h2 class="font-bold text-dark-blue text-xl lg:text-2xl mb-1">
                  {{ $t('views.calculator.printing_process.headline') }}
                </h2>

                <p class="text-grey-70">
                  {{ $t('views.calculator.printing_process.description') }}

                  <a
                    href="https://grossauflagen.shirtigo.de/druckverfahrenberater"
                    target="_blank"
                    class="text-blue-50"
                  >
                    {{ $t('views.calculator.printing_process.desc_link') }}.
                  </a>
                </p>
              </div>
            </div>

            <div class="flex flex-wrap lg:flex-no-wrap mt-8 gap-4">
              <div
                class="w-full flex p-4 pb-6 border cursor-pointer rounded"
                :class="[printingProcess === 'digital' ? 'bg-blue-00 text-blue-50 border-blue-300' : 'bg-white']"
                @click="setPrintingProcess('digital')"
              >
                <div class="mr-4">
                  <sg-radio
                    name="printing_process"
                    value="digital"
                    :checked="printingProcess === 'digital'"
                  />
                </div>

                <div class="flex-1 text-center px-2">
                  <svg-vue
                    icon="digital-print"
                    :class="[printingProcess === 'digital' ? 'text-blue-50' : 'text-gray-50', 'h-18 w-full mb-4']"
                  />

                  <span class="text-lg text-dark-blue font-bold">
                    {{ $t('views.calculator.printing_process.digital.headline') }}
                  </span>

                  <ul class="list-disc text-left text-gray-70 mt-4">
                    <li>
                      {{ $t('views.calculator.printing_process.digital.item0') }}
                    </li>
                    <li>
                      {{ $t('views.calculator.printing_process.digital.item1') }}
                    </li>
                    <li>
                      {{ $t('views.calculator.printing_process.digital.item2') }}
                    </li>
                    <li>
                      {{ $t('views.calculator.printing_process.digital.item3') }}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="w-full flex p-5 border cursor-pointer rounded"
                :class="[printingProcess === 'screen' ? 'bg-blue-00 text-blue-50 border-blue-300' : 'bg-grey-00']"
              >
                <div class="mr-4">
                  <sg-radio
                    name="printing_process"
                    value="screen"
                    disabled="disabled"
                    :checked="printingProcess === 'screen'"
                  />
                </div>

                <div class="flex-1 text-center">
                  <svg-vue
                    icon="screen-print"
                    :class="[printingProcess === 'screen' ? 'text-blue-50' : 'text-gray-50', 'h-18 w-full mb-4']"
                  />

                  <span class="text-lg text-dark-blue font-bold">
                    {{ $t('views.calculator.printing_process.screen.headline') }}
                  </span>

                  <ul class="list-disc text-left text-gray-70 mt-4">
                    <li>
                      {{ $t('views.calculator.printing_process.screen.item0') }}
                    </li>
                    <li>
                      {{ $t('views.calculator.printing_process.screen.item1') }}
                    </li>
                    <li>
                      {{ $t('views.calculator.printing_process.screen.item2') }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="availablePrintareas.length"
            class="bg-white border px-4 pt-6 pb-8 lg:p-10 mt-6 rounded-lg"
          >
            <div class="flex">
              <div
                class="number-icon-circle"
              >
                3
              </div>

              <div>
                <h2 class="font-bold text-dark-blue text-xl lg:text-2xl mb-1">
                  {{ $t('views.calculator.printareas.headline') }}
                </h2>

                <p class="text-grey-70">
                  {{ $t('views.calculator.printareas.description') }}
                </p>
              </div>
            </div>

            <div class="flex flex-wrap lg:flex-no-wrap mt-8 gap-4">
              <div
                v-for="printarea in availablePrintareas"
                :key="printarea.key"
                class="w-full flex p-5 border cursor-pointer rounded"
                :class="[activePrintareasForCurrentGroup.includes(printarea.key) ? 'bg-blue-00 text-blue-50 border-blue-300' : 'bg-white']"
                @click="toggleActivePrintarea(printarea.key)"
              >
                <div class="mr-4 flex items-center">
                  <sg-checkbox
                    name="printing_printarea"
                    :value="printarea.key"
                    :checked="activePrintareasForCurrentGroup.includes(printarea.key)"
                  />
                </div>

                <span class="text-lg text-dark-blue font-bold">{{ printarea.name }}</span>
              </div>
            </div>
          </div>

          <!-- Ausgeblendet, weil erstmal nicht gebraucht - Translations daher auch noch nicht eingepflegt -->
          <div
            v-if="false && !currentGroupIsEmpty && activePrintareasForCurrentGroup.length"
            class="bg-white border px-4 pt-6 pb-8 lg:p-10 mt-6 rounded-lg"
          >
            <div class="flex">
              <div class="number-icon-circle">
                4
              </div>

              <div>
                <h2 class="font-bold text-dark-blue text-xl lg:text-2xl mb-1">
                  Produktions- & Lieferzeit
                </h2>

                <p class="text-grey-70">
                  Gib die gewünschte Liefer- und Produktionsdauer an.
                </p>
              </div>
            </div>

            <div class="mb-4 mt-4 text-green-70 tracking-tight p-4 bg-green-100 border border-green-200 rounded">
              <div class="flex items-center">
                <i class="icon-car-outline inline-block mr-2 text-xl" />

                Deine Lieferung erfolgt zwischen dem <strong class="mx-1 font-bold">24.10.2018</strong> und dem
                <strong class="mx-1 font-bold">30.10.2018</strong>. <span class="ml-1 underline">Mehr erfahren</span>
              </div>
            </div>

            <div class="flex flex-wrap lg:flex-no-wrap gap-4">
              <div
                class="w-full flex flex-col items-center p-4 pb-6 border cursor-pointer rounded"
                :class="[shippingMethod === 'default' ? 'bg-blue-00 text-blue-50 border-blue-300' : 'bg-white']"
                @click="setShippingMethod('default')"
              >
                <div class="mr-4 self-start absolute">
                  <sg-radio
                    name="shipping_method"
                    value="digital"
                    :checked="shippingMethod === 'default'"
                  />
                </div>

                <svg-vue
                  icon="truck"
                  :class="[shippingMethod === 'default' ? 'text-blue-50' : 'text-gray-50', 'h-15 w-full']"
                />

                <span class="text-lg text-dark-blue font-bold">Standard</span>

                <p class="text-gray-70 mt-4 text-sm">
                  10-13 Werktage Lieferzeit
                </p>

                <div
                  class="rounded-full text-xs px-2 py-1 font-semibold mt-2"
                  :class="[shippingMethod === 'default' ? 'text-blue-60 bg-blue-30' : 'bg-gray-00 text-gray-70']"
                >
                  0,00 &euro;
                </div>
              </div>

              <div
                class="w-full flex flex-col items-center p-4 pb-6 border cursor-pointer rounded"
                :class="[shippingMethod === 'appointment' ? 'bg-blue-00 text-blue-50 border-blue-300' : 'bg-white']"
                @click="setShippingMethod('appointment')"
              >
                <div class="mr-4 self-start absolute">
                  <sg-radio
                    name="shipping_method"
                    value="appointment"
                    :checked="shippingMethod === 'appointment'"
                  />
                </div>

                <svg-vue
                  icon="truck-clock"
                  :class="[shippingMethod === 'appointment' ? 'text-blue-50' : 'text-gray-50', 'h-15 w-full']"
                />

                <span class="text-lg text-dark-blue font-bold">Termin</span>

                <p class="text-gray-70 mt-4 text-sm">
                  10 Werktage Lieferzeit
                </p>

                <div
                  class="rounded-full text-xs px-2 py-1 font-semibold mt-2"
                  :class="[shippingMethod === 'appointment' ? 'text-blue-60 bg-blue-30' : 'bg-gray-00 text-gray-70']"
                >
                  10,00 &euro;
                </div>
              </div>

              <div
                class="w-full flex flex-col items-center p-4 pb-6 border cursor-pointer rounded"
                :class="[shippingMethod === 'express' ? 'bg-blue-00 text-blue-50 border-blue-300' : 'bg-white']"
                @click="setShippingMethod('express')"
              >
                <div class="mr-4 self-start absolute">
                  <sg-radio
                    name="shipping_method"
                    value="express"
                    :checked="shippingMethod === 'express'"
                  />
                </div>

                <svg-vue
                  icon="car-side"
                  :class="[shippingMethod === 'express' ? 'text-blue-50' : 'text-gray-50', 'h-15 w-full']"
                />

                <span class="text-lg text-dark-blue font-bold">Express</span>

                <p class="text-gray-70 mt-4 text-sm">
                  7 Werktage Lieferzeit
                </p>

                <div
                  class="rounded-full text-xs px-2 py-1 font-semibold mt-2"
                  :class="[shippingMethod === 'express' ? 'text-blue-60 bg-blue-30' : 'bg-gray-00 text-gray-70']"
                >
                  15,00 &euro;
                </div>
              </div>

              <div
                class="w-full flex flex-col items-center p-4 pb-6 border cursor-pointer rounded"
                :class="[shippingMethod === 'express-plus' ? 'bg-blue-00 text-blue-50 border-blue-300' : 'bg-white']"
                @click="setShippingMethod('express-plus')"
              >
                <div class="mr-4 self-start absolute">
                  <sg-radio
                    name="shipping_method"
                    value="express-plus"
                    :checked="shippingMethod === 'express-plus'"
                  />
                </div>

                <svg-vue
                  icon="car-side-clock"
                  :class="[shippingMethod === 'express-plus' ? 'text-blue-50' : 'text-gray-50', 'h-15 w-full']"
                />

                <span class="text-lg text-dark-blue font-bold">Express Plus</span>

                <p class="text-gray-70 mt-4">
                  5 Werktage Lieferzeit
                </p>

                <div
                  class="rounded-full text-xs px-2 py-1 font-semibold mt-2"
                  :class="[shippingMethod === 'express-plus' ? 'text-blue-60 bg-blue-30' : 'bg-gray-00 text-gray-70']"
                >
                  20,00 &euro;
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white border px-4 pt-6 pb-8 lg:p-10 mt-6 rounded-lg">
            <div class="flex flex-wrap gap-4 items-center">
              <p class="text-dark-blue">
                {{ $t('views.calculator.naming.headline') }}
              </p>

              <sg-input
                v-model="collectionName"
                :placeholder="collectionPlaceholderName"
                class="w-360px lg:w-128 lg:ml-auto"
                type="text"
              />
            </div>
          </div>

          <config-mobile-bottom
            v-if="cart"
            :disabled="isLoadingCart"
            :price-for-group="currentPriceObject"
            :cart="cart"
            :submit-button-text="$t('views.calculator.submit_button') || ''"
            :loading="isLoadingCart || !currentPriceObject"
            @update="updatePrice"
            @submit="loadNextStep"
          />

          <p class="text-grey-50 text-sm mt-10">
            {{ $t('views.calculator.caption') }}
          </p>
        </main>

        <modal
          v-if="showSelectProductsDialog"
          @close:modal="closeSelectProductsDialog"
        >
          <select-products-dialog
            @close="closeSelectProductsDialog"
            @select-product="selectProduct"
          />
        </modal>
      </div>

      <config-footer />
    </div>

    <config-desktop-sidebar
      :collection-name="collectionName || collectionPlaceholderName"
      :show-processings="false"
      :price-for-group="currentPriceObject"
      :submit-button-text="$t('views.calculator.submit_button') || ''"
      :loading="isLoadingCart || !currentPriceObject"
      @submit="loadNextStep"
      @update="updatePrice"
      @removeProduct="removeProduct($event)"
    />

    <alert-modal
      v-if="showNoProductInCartModal"
      @close="showNoProductInCartModal = false"
    >
      <strong>{{ $t('views.calculator.alert.no_product_in_cart') }}</strong>
    </alert-modal>

    <alert-modal
      v-if="showNoProcessingModal"
      @close="showNoProcessingModal = false"
    >
      <strong>{{ $t('views.calculator.alert.no_processingarea_selected') }}</strong>
    </alert-modal>

    <confirm-modal
      v-if="showStartNewCollectionModal"
      @decline="declineStartNewCollection"
      @confirm="confirmStartNewCollection"
      @close="showStartNewCollectionModal = false"
    >
      <strong>{{ $t('views.calculator.alert.start_new_collection') }}</strong>

      <template v-slot:confirm>
        {{ $t('views.calculator.alert.start_new_collection_confirm') }}
      </template>
      <template v-slot:deny>
        {{ $t('views.calculator.alert.start_new_collection_cancel') }}
      </template>
    </confirm-modal>

    <confirm-modal
      v-if="showProductWithMissingAreaModal"
      @decline="showProductWithMissingAreaModal = false"
      @confirm="removeProductsWithoutArea"
      @close="showProductWithMissingAreaModal = false"
    >
      <strong v-if="productsWithoutSelectedArea.length > 1">
        {{ $t('views.calculator.alert.no_printarea_for_products') }}
        <p
          v-for="product in productsWithoutSelectedArea"
          :key="product.id">
          - {{ product.name }}
        </p>
      </strong>
      <strong v-else>
        {{ $t('views.calculator.alert.no_printarea_for_product', { 'product': productsWithoutSelectedArea[0].name }) }}

      </strong>

      <template v-slot:confirm>
        Produkte entfernen
      </template>
      <template v-slot:deny>
        {{ $t('views.calculator.alert.start_new_collection_cancel') }}
      </template>
    </confirm-modal>

    <confirm-modal
      v-if="showRemoveProductAlert"
      @confirm="removeProductConfirmed()"
      @close="showRemoveProductAlert = false"
    >
      <strong>{{ $t('views.cart.product_group.remove_product_confirm') }}</strong>
    </confirm-modal>

    <loader-full-page v-if="!baseProductsLoaded">
      {{ $t('views.calculator.loading_products') }}
    </loader-full-page>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import CalculatorProductGroup from '@/components/config/calculator/CalculatorProductGroup'
  import SelectProductsDialog from '@/components/config/productSelect/SelectProductsDialog'
  import ConfigFooter from '@/components/config/ConfigFooter'
  import AlertModal from '@/components/layout/AlertModal'
  import Modal from 'sb/elements/Modals/Modal'
  import SgRadio from 'sb/elements/Form/SgRadio'
  import SgInput from 'sb/elements/Form/SgInput'
  import SgCheckbox from 'sb/elements/Form/SgCheckbox'
  import ShirtigoHeader from '@/components/layout/ShirtigoHeader.vue'
  import AddProductButton from '@/components/config/AddProductButton.vue'
  import ConfigDesktopSidebar from '@/components/config/sidebar/ConfigDesktopSidebar'
  import ConfigMobileBottom from '@/components/config/sidebar/ConfigMobileBottom'
  import Prices from '../../mixins/Prices'
  import Cart from '../../mixins/Cart'
  import ConfirmModal from '../../components/layout/ConfirmModal.vue'
  import LoaderFullPage from '../../components/layout/LoaderFullPage.vue'
  import Loader from '../../components/layout/Loader.vue'
  import { getDummyManufacturingJob, getDummyProcessing, getEmptyDummyCart } from '@/api/calculation'
  import { format } from 'date-fns/format'
  import Vue from 'vue'

  /**
   * @typedef {{key: string, name: string}} AvailablePrintarea
   */

  export default {

    mixins: [ Prices, Cart ],

    data() {
      return {
        showProductWithMissingAreaModal: false,
        showNoProductInCartModal: false,
        showNoProcessingModal: false,
        showSelectProductsDialog: false,
        showStartNewCollectionModal: false,
        showRemoveProductAlert: false,
        removeProductId: null,
        printingProcess: 'digital',
        shippingMethod: 'default',
        /** @type {AvailablePrintarea[]} */
        availablePrintareas: [],
        baseProductsLoaded: false,
        collectionName: '',
      }
    },

    async mounted() {
      if (this.isCheckedOut) {
        this.deleteCart()
        this.deleteOrderDetails()
        location.reload()
        return
      }

      this.setIsLoadingPrices(false)
      this.setIsLoadingCart(false)

      await this.loadAllBaseProducts()
      this.baseProductsLoaded = true

      if (!this.cart) {
        this.setCart(getEmptyDummyCart([
          getDummyManufacturingJob({
            amount: 0,
          })
        ]))
      } else if (!this.currentManufacturingJob) {
        this.showStartNewCollectionModal = true
      }

      if (this.currentManufacturingJob && this.currentManufacturingJob.group_name) {
        this.collectionName = this.currentManufacturingJob.group_name
      }

      this.determineAvailablePrintareas()
    },

    methods: {
      removeProduct(baseProductId) {
        this.removeProductId = baseProductId
        this.showRemoveProductAlert = true
      },
      removeProductConfirmed() {
        if (!this.removeProductId) return

        this.removeProductFromCart(this.removeProductId)

        Vue.nextTick(() => {
          this.determineAvailablePrintareas()
          this.updatePrice()
        })

        this.removeProductId = null
        this.showRemoveProductAlert = false
      },
      removeProductsWithoutArea() {
        this.productsWithoutSelectedArea.forEach(product => {
          this.removeProductFromCart(product.id)
        })

        this.loadNextStep()
      },
      async loadNextStep() {
        if (this.currentGroupIsEmpty || !this.cart) {
          this.showNoProductInCartModal = true
          return
        }
        if (!this.activePrintareasForCurrentGroup.length) {
          this.showNoProcessingModal = true
          return
        }

        if (this.productsWithoutSelectedArea.length) {
          this.showProductWithMissingAreaModal = true
          return
        }

        this.$Progress.start()
        this.setIsMpc(true)

        await this.saveCollectionName()

        await this.$router.push({
          name: 'mpc-designer',
          params: {
            reference: this.cart.reference,
            groupid: this.currentManufacturingJobGroupId,
            id: this.allCartProductsForCurrentGroup[0].base_product_id.toString()
          }
        })
      },
      openSelectProductsDialog() {
        this.showSelectProductsDialog = true
      },
      async selectProduct(baseProduct) {
        this.setIsMpc(true)

        if (!this.cart) {
          await this.initEmptyCart(baseProduct).then(() => {
          })
        } else {
          await this.addBaseProductToCart(baseProduct)
          await this.updatePrice().catch()
        }

        this.closeSelectProductsDialog()
        this.determineAvailablePrintareas()
      },
      closeSelectProductsDialog() {
        this.showSelectProductsDialog = false
      },
      async saveCollectionName() {
        await Promise.all(
          this.manufacturingJobsForCurrentGroup.map((job) => this.updateManufacturingJob({
            ...job,
            group_name: this.collectionName || this.collectionPlaceholderName,
          })),
        )

        return this.updateCart()
      },
      async confirmStartNewCollection() {
        this.addManufacturingJob(getDummyManufacturingJob({
          amount: 0,
        }))

        this.setCurrentManufacturingJobUuid(/** @type ApiFormattedManufacturingJobUuid */0)
        this.setCurrentManufacturingJobGroupId(/** @type ApiFormattedManufacturingJobGroupId */0)

        this.determineAvailablePrintareas()
      },
      declineStartNewCollection() {
        let currentManufacturingJob = null
        const allManufacturingJobs = this.cart.manufacturing_jobs.data
        if (this.manufacturingJobsForCurrentGroup.length) {
          currentManufacturingJob = this.manufacturingJobsForCurrentGroup[this.manufacturingJobsForCurrentGroup.length - 1]
        } else if (allManufacturingJobs.length > 0) {
          currentManufacturingJob = allManufacturingJobs[allManufacturingJobs.length - 1]
        }

        if (currentManufacturingJob) {
          this.setCurrentManufacturingJobUuid(currentManufacturingJob.uuid)
          this.setCurrentManufacturingJobGroupId(currentManufacturingJob.group_id)
        } else {
          this.$router.replace({ name: 'single-product-designer' })
        }
      },
      /**
       * @param {string} process
       */
      setPrintingProcess(process) {
        this.printingProcess = process
      },
      /**
       * @param {string} method
       */
      setShippingMethod(method) {
        this.shippingMethod = method
      },
      determineAvailablePrintareas() {
        if (!this.baseProductsInCart.length) {
          this.availablePrintareas = []
          return
        }

        const availablePrintareas = []
        this.baseProductsInCart
          .forEach((baseProduct) => {
            if (!baseProduct.printareas || !baseProduct.printareas.data) return
            baseProduct.printareas.data.forEach((printarea) => {
              if (!availablePrintareas.some(({ key }) => key === printarea.key) && printarea.is_printable) {
                availablePrintareas.push({ key: printarea.key, name: printarea.name })
              }
            })
          })

        if (availablePrintareas.length) {
          // Remove selected printareas that are no longer available
          const activePrintareas = this.activePrintareasForCurrentGroup
            .filter((key) => availablePrintareas.some((printarea) => key === printarea.key))

          if (!activePrintareas.length) {
            // Set default selected printarea
            const defaultSelectedPrintarea = availablePrintareas.find((printarea) => printarea.key === 'front') || availablePrintareas[0]
            this.updatePrintareasForCurrentGroup([defaultSelectedPrintarea.key])
          } else {
            this.updatePrintareasForCurrentGroup(activePrintareas)
          }
        }

        this.availablePrintareas = availablePrintareas
      },
      /**
       * @param {string} printareaKey
       */
      toggleActivePrintarea(printareaKey) {
        if (this.activePrintareasForCurrentGroup.includes(printareaKey)) {
          this.updatePrintareasForCurrentGroup(this.activePrintareasForCurrentGroup.filter((key) => key !== printareaKey))
        } else {
          this.updatePrintareasForCurrentGroup([ ...this.activePrintareasForCurrentGroup, printareaKey ])
        }
      },
      /**
       * @param {string[]} printareas
       */
      updatePrintareasForCurrentGroup(printareas) {
        this.setActiveCartPrintareas({
          ...this.activePrintareasForCart,
          [this.currentManufacturingJobGroupId.toString()]: printareas
        })

        // Add empty processings for printareas if necessary
        if (this.currentManufacturingJob) {
          const newProcessingSpecificationsArray = /** @type ApiFormattedProcessing[] */[]

          printareas.forEach((key) => {
            const newProcessingSpecification = this.currentManufacturingJob.processings.data
              .find(({ processingarea_type_key }) => processingarea_type_key === key)

            if (newProcessingSpecification) {
              newProcessingSpecificationsArray.push(newProcessingSpecification)
            } else {
              newProcessingSpecificationsArray.push(getDummyProcessing(key))
            }
          })

          this.updateManufacturingJob({
            ...this.currentManufacturingJob,
            processings: {
              data: newProcessingSpecificationsArray,
            }
          })

          this.updatePrice()
        }
      },
      ...mapActions([
        'loadAllBaseProducts',
        'setActiveCartPrintareas',
        'setIsMpc',
        'updateManufacturingJob',
        'setCart',
        'setCurrentManufacturingJobGroupId',
        'setCurrentManufacturingJobUuid',
        'addManufacturingJob',
        'setIsLoadingPrices',
        'setIsLoadingCart',
        'removeProductFromCart',
        'deleteCart',
        'deleteOrderDetails',
      ]),
    },

    computed: {
      /**
       * @return {BaseProduct[]}
       */
      baseProductsInCart() {
        if (!this.allCartProductsForCurrentGroup || !this.baseProducts) return []
        const baseProductIdsInCart = this.allCartProductsForCurrentGroup.map(({ base_product_id }) => base_product_id)
        return this.baseProducts.filter(({ id }) => baseProductIdsInCart.includes(id))
      },
      /**
       * @returns {BaseProduct[]}
       */
      productsWithoutSelectedArea() {
        return this.baseProductsInCart.filter((/** @type {BaseProduct} */baseProduct) => {
          return !baseProduct.printareas.data.some((printarea) => {
            return printarea.is_printable && this.activePrintareasForCurrentGroup.includes(printarea.key)
          })
        })
      },
      /**
       * @returns {ManufacturingJobPriceItem | null}
       */
      currentPriceObject() {
        if (!this.currentManufacturingJob) return null
        return this.priceForGroup(this.currentManufacturingJob.group_id)
      },
      collectionPlaceholderName() {
        return `Kollektion vom ${format(new Date(), 'dd.MM.yy - HH:mm')} Uhr`
      },
      ...mapGetters([
        'baseProducts',
        'currentGroupIsEmpty',
        'currentManufacturingJobGroupId',
        'allCartProductsForCurrentGroup',
        'activePrintareasForCart',
        'activePrintareasForCurrentGroup',
        'cart',
        'currentManufacturingJob',
        'isLoadingCart',
        'totalNumberOfItemsForCurrentGroup',
        'manufacturingJobsForCurrentGroup',
        'isCheckedOut',
      ])
    },

    components: {
      Loader,
      ConfirmModal,
      LoaderFullPage,
      AddProductButton,
      AlertModal,
      ConfigFooter,
      SelectProductsDialog,
      ShirtigoHeader,
      CalculatorProductGroup,
      ConfigDesktopSidebar,
      ConfigMobileBottom,
      Modal,
      SgRadio,
      SgCheckbox,
      SgInput,
    }
  }
</script>

<style lang="scss" scoped>
  body {
    background: #f8f9fa;
  }
</style>

import catalog from './modules/catalog'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

/*************************************************************************
 *                                NOTE:                                  *
 *   Increase this number whenever the structure of the store changes.   *
 * Clients may have an older version of the state stored in localStorage *
 *   Changing the version number can force the store to be re-written.   *
 *************************************************************************/
const CURRENT_VERSION = 1
/*************************************************************************/

require('../typedefs')

const STORAGE_KEY_VUEX = 'Shirtigo_store_vuex'
const STORAGE_KEY_VERSION = 'Shirtigo_store_version'

Vue.use(Vuex)

let plugins = []

if (typeof window !== 'undefined') {
  // Remove legacy store
  if (window.localStorage.getItem('vuex_v2')) {
    window.localStorage.removeItem('vuex_v2')
  }

  const oldVersion = window.localStorage.getItem(STORAGE_KEY_VERSION)
  if (oldVersion !== CURRENT_VERSION.toString()) {
    window.localStorage.removeItem(`${STORAGE_KEY_VUEX}_${oldVersion}`)
  }

  window.localStorage.setItem(STORAGE_KEY_VERSION, CURRENT_VERSION.toString())

  const vuexLocalStorage = new VuexPersist({
    key: `${STORAGE_KEY_VUEX}_${CURRENT_VERSION}`,
    storage: window.localStorage
  })

  plugins.push(vuexLocalStorage.plugin)
}

/**
 * @typedef State
 * @property {string|null} current_base_product_reference
 * @property {number|null} current_color_id
 * @property {string|null} current_image
 * @property {string|null} image_side_query
 * @property {BaseProduct[]} base_products
 * @property {DesignerFontFamily[]} designer_fonts
 * @property {ApiFormattedManufacturingJobUuid|null} current_manufacturing_job_uuid
 * @property {ApiFormattedManufacturingJobGroupId|null} current_manufacturing_job_group_id
 * @property {ApiFormattedCart|null} cart
 * @property {string[]} active_cart_printareas
 * @property {boolean} is_mpc
 * @property {boolean} is_checked_out
 * @property {*} order_details
 * @property {*} alerts
 * @property {ManufacturingJobPreview[]} previews
 */

/**
 * @type {State}
 */
const state = {
  current_base_product_reference: null,
  current_color_id: null,
  current_image: null,
  image_side_query: 'front',
  base_products: [],
  designer_fonts: [],
  current_manufacturing_job_uuid: null,
  current_manufacturing_job_group_id: null,
  cart: null,
  active_cart_printareas: {},
  is_mpc: false,
  is_checked_out: false,
  order_details: null,
  alerts: [],
  previews: [],
  is_loading_cart: false,
  is_loading_prices: false,
  is_saving_base_design: false,
}

export function createStore() {
  return new Vuex.Store({
    modules: {
      catalog
    },
    state,
    getters,
    actions,
    mutations,
    plugins
  })
}

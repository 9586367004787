<template>
  <section class="product-info__description">
    <h3>{{ baseProduct.name }}</h3>

    <p v-html="baseProduct.description"></p>
  </section>
</template>

<script>
  export default {

    props: {
      baseProduct: {
        type: Object,
        required: true,
      },
    },

  }
</script>

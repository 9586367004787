var render = function render(){var _vm=this,_c=_vm._self._c;return _c('i',{class:[
    'w-6 h-6 text-xl',
    _vm.active ? 'bg-blue-50' : `bg-gray-80 group-hover:bg-blue-50`,
  ],style:(`
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url('/images/category_icons/${_vm.category.key}.svg')
    mask-image: url('/images/category_icons/${_vm.category.key}.svg')
  `)})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div
      v-show="isInitialized"
      :ref="componentId"
      class="mockup-product-preview"
    />
    <div
      v-show="!isInitialized"
      class="mockup-placeholder"
    />
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import Vue, { PropOptions } from 'vue'
  import { SVG } from '@svgdotjs/svg.js'

  export default {

    props: {
      /** @type {PropOptions<BaseProductColorVariant|null>} */
      color: {
        type: Object
      },
      scale: {
        type: Number,
        default: 1,
        required: false
      },
      maxHeight: {
        type: Number,
        default: 9999,
        required: false
      },
      mockup: {
        type: Object,
        required: true
      },
    },

    data() {
      return {
        isInitialized: false,
        draw: false,
        backgroundLayer: null,
        heatherPattern: null,
      }
    },

    mounted() {
      this.initMockup()
    },

    methods: {
      initMockup() {
        if (!this.mockup) return

        // calculate the final dimensions of the svg

        if (this.draw && this.draw.node.parentNode) {
          this.isInitialized = false
          this.draw.remove()
        }

        this.draw = SVG().size(this.renderWidth, this.renderHeight)

        // Draw the background fill layer with the current color set
        this.backgroundLayer = this.draw.rect(this.renderWidth, this.renderHeight).attr({
          fill: `#${this.color.hexcode_designer}`,
          class: 'notarget'
        })

        // load the template image overlay
        let svgImage = this.draw
          .image(this.mockup.image_url)
          .width(this.renderWidth)
          .height(this.renderHeight)
          .attr({ class: 'notarget' })

        this.heatherPattern = this.draw
          .image('/images/seedDesigner/heather_pattern.png')
          .width(this.renderWidth)
          .height(this.renderHeight)
          .attr({ class: 'notarget' })

        // add background and overlay to our group
        let svgGroup = this.draw.group()
          .add(this.backgroundLayer)
          .add(this.heatherPattern)
          .add(svgImage)

        if (this.color.is_heathered) {
          this.heatherPattern.attr({ opacity: 1 })
        } else {
          this.heatherPattern.attr({ opacity: 0 })
        }

        // get the template shape as svg and set it as mask for the group
        // this.getTemplateSVG(path).then((result) => {
        //   // load the svg into a defs section
        //   var defs = this.draw.defs()
        //   let svgMask = defs.svg(result)
        //
        //   // apply the mask to the group
        //   svgGroup.maskWith(svgMask.children()[0])
        //
        //   // show the dom element
        //   Vue.nextTick(() => {
        //     this.isInitialized = true
        //     this.draw.addTo(this.$refs[this.componentId])
        //   })
        // })
        if (this.mockupSvg.endsWith('webp') || this.mockupSvg.endsWith('png')) {
          this.applyBitmapMask(svgGroup, this.renderWidth, this.renderHeight);
        } else {
          this.applySvgMask(svgGroup);
        }
        // if (this.mockupSvg.endsWith('png')) {
        //   Vue.nextTick(() => {
        //     this.isInitialized = true
        //     this.draw.addTo(this.$refs[this.componentId])
        //   })
        // } else {
        //   // get the template shape as svg and set it as mask for the group
        //   this.getTemplateSVG(this.mockupSvg).then((result) => {
        //     // load the svg into a defs section
        //     var defs = this.draw.defs()
        //     let svgMask = defs.svg(result)
        //
        //     // apply the mask to the group
        //     svgGroup.maskWith(svgMask.children()[0])
        //
        //     // show the dom element
        //     Vue.nextTick(() => {
        //       this.isInitialized = true
        //       this.draw.addTo(this.$refs[this.componentId])
        //     })
        //   })
        // }
      },
      applyBitmapMask(group, width, height) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        // Load the mask as an image
        const maskImage = new Image();
        maskImage.src = this.mockupSvg;
        maskImage.crossOrigin = "anonymous";

        maskImage.onload = () => {
          // Draw the mask onto the canvas
          ctx.drawImage(maskImage, 0, 0, width, height);

          // Convert the canvas to a data URL and use it as a mask
          const maskUrl = canvas.toDataURL();
          let maskSvgImage = this.draw.image(maskUrl, width, height);

          // Set the mask using `maskWith`
          group.maskWith(maskSvgImage);

          Vue.nextTick(() => {
            this.isInitialized = true
            this.draw.addTo(this.$refs[this.componentId])
          })
        };
      },

      applySvgMask(group) {
        this.getTemplateSVG(this.mockupSvg).then((result) => {
          const defs = this.draw.defs();
          let svgMask = defs.svg(result);

          group.maskWith(svgMask.children()[0]);

          Vue.nextTick(() => {
            this.isInitialized = true
            this.draw.addTo(this.$refs[this.componentId])
          })
        });
      },


      getTemplateSVG(path) {
        return new Promise((resolve) => {
          this.$http.get(path).then((response) => {
            resolve(response.body)
          })
        })
      }
    },

    computed: {
      /**
       * @return {string|null}
       */
      mockupSvg() {
        if (this.mockup) {
          return this.mockup.svg_url || this.mockup.cutout_mask_url
        }

        return null
      },
      /**
       * @return {number}
       */
      renderWidth() {
        if (!this.mockup) return 0
        return Math.min(Math.round(this.scale * this.mockup.width_px), this.maxHeight)
      },
      /**
       * @return {number}
       */
      renderHeight() {
        if (!this.mockup) return 0
        return Math.min(Math.round(this.scale * this.mockup.height_px), this.maxHeight)
      },
      /**
       * @return {string}
       */
      componentId() {
        return `mockup_${this._uid}`
      },
    },

    watch: {
      color() {
        if (!this.backgroundLayer) {
          return false
        }

        this.backgroundLayer.attr({
          fill: '#' + this.color.hexcode
        })

        if (this.heatherPattern) {
          this.heatherPattern.attr({ opacity: this.color.is_heathered ? 1 : 0 })
        }

        return true
      },
      mockup() {
        this.initMockup()
      },
      printarea() {
        this.initMockup()
      }
    },

  }
</script>

<style lang="scss" scoped>
  .mockup-product-preview {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mockup-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;

    .load8 {
      position: absolute;
      margin-top: -3.75em;
      z-index: 10;
    }
  }
</style>

<template>
  <div class="lg:grid lg:grid-cols-4 lg:gap-6">
    <div class="px-4 sm:px-6 lg:px-8 col-span-3">
      <div class="mb-6">
        <span class="relative z-0 inline-flex shadow-sm rounded-md text-gray-70">
          <button
            v-for="(printArea, index) in printAreas"
            :key="printArea.key"
            type="button"
            class="relative inline-flex items-center px-4 py-2 border border-gray-20 bg-white hover:text-blue-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            :class="{'rounded-l-md': index===0, 'rounded-r-md':index === (printAreas.length - 1), '-ml-px':index!==0, 'bg-blue-00 text-blue-50 border-light-blue':selectedArea.key === printArea.key}"
            @click="selectedArea = printArea">
            {{ printArea.name }}
          </button>
        </span>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden ring-1 ring-black ring-opacity-5">
              <table class="min-w-full divide-y divide-gray-300 border">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="p-3 text-sm font-bold text-gray-90 uppercase">
                      {{ $t('global.size') }}
                    </th>
                    <th
                      scope="col"
                      class="p-3 text-sm font-bold text-gray-90 uppercase tracking-1">
                      {{ $t('views.product_details.dimensions.max_width') }}
                    </th>
                    <th
                      scope="col"
                      class="p-3 text-sm font-bold text-gray-90 uppercase tracking-1">
                      {{ $t('views.product_details.dimensions.max_height') }}
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="size in catalogProduct.sizings.data"
                    :key="size.size">
                    <td class="text-sm font-bold text-gray-50 text-center p-3">
                      {{ size.size }}
                    </td>
                    <td class="text-center text-gray-80 p-3">
                      {{ size.print_dimensions.data[selectedArea.key] && size.print_dimensions.data[selectedArea.key][processingMethod.key] ? size.print_dimensions.data[selectedArea.key][processingMethod.key].max_processing_width_mm : '-' }} mm
                    </td>
                    <td class="text-center text-gray-80 p-3">
                      {{ size.print_dimensions.data[selectedArea.key] && size.print_dimensions.data[selectedArea.key][processingMethod.key] ? size.print_dimensions.data[selectedArea.key][processingMethod.key].max_processing_height_mm : '-' }} mm
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex lg:block">
      <div>
        <img
          src="/img/print-dimensions.svg"
          class="mx-auto mt-8 lg:mt-0"
          alt="Size Chart">
      </div>
      <div class="mt-8 text-blue-50 border-t pt-8">
        <a
          href="https://helpcenter.shirtigo.de/faq/allgemein/dtg-guideline/"
          target="_blank"
          class="mb-2 flex items-center hover:text-blue-60">
          {{ $t('views.product_details.dimensions.print_file_infos') }}
          <span class="icon-external-link-outline inline-block ml-1 text-lg group-hover:text-blue-50"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

    props: {
      catalogProduct: {
        type: Object,
      },
    },

    data() {
      return {
        selectedArea: {}
      }
    },

    created() {
      if (this.printAreas) {
        this.selectedArea = this.printAreas[0]
      }
    },

    computed: {
      defaultVariant() {
        let sku = this.catalogProduct.default_variant_sku.split('.')
        let productVariant = this.catalogProduct.variants.data.find(p => p.id === parseInt(sku[1]))
        if (!productVariant) {
          productVariant = this.catalogProduct.variants.data[0]
        }
        return productVariant
      },
      processingAreas() {
        let areas = this.defaultVariant.size_variants.data.map(s => s.processingareas.data)

        return areas
      },
      processingMethod() {
        let methods = []
        this.catalogProduct.processingareas.data.forEach(p => {
          p.processingmethods.data.forEach(pm => {
            if (!methods.find(m => m.key === pm.key)) {
              methods.push(pm)
            }
          })
        })
        return methods[0]
      },
      printAreas() {
        if (!this.catalogProduct) {
          return null
        }

        let areas = this.catalogProduct.processingareas.data.filter(a => {
          return a.processing_methods.find(m => m === this.processingMethod.key)
        })

        return areas.length ? areas : this.catalogProduct.processingareas.data
      }
    },
  }
</script>

<template>
<div class="flex flex-col-reverse lg:grid lg:grid-cols-half lg:gap-16">
    <div class="mt-6 lg:mt-0 prose">
      <h3 class="text-2xl font-bold text-dark-blue mb-6">
        {{ catalogProduct.name }}
      </h3>
      <div
        class="text-gray-80 content-text list-disc"
        v-html="catalogProduct.description"></div>

      <div
        v-if="hasAttributes"
        class="mt-16">
        <h3 class="text-xl font-bold text-dark-blue mb-6">
          {{ $t('views.catalog.attributes.title') }}
        </h3>

        <div>
          <chip
            v-if="catalogProduct.is_organic"
            kind="green"
            is-large
          >
            <svg
              id="Canvas"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="h5 w-5"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M21.27,2.04C14.15,0,7.33,2.34,5.08,7.61c-2.08,4.86-.05,8.71.04,8.87l.04-.02c-1.48,2.78-2.09,5.12-2.13,5.3l1.94.49c.02-.08,1.99-7.68,7.58-11.41l-1.11-1.66c-2.13,1.42-3.76,3.29-5.01,5.15-.34-1.27-.59-3.42.48-5.93,1.53-3.57,6.47-6.23,13.05-4.63-.12,2.16-.77,8.37-4.17,11.47-1.54,1.4-3.41,1.98-5.71,1.77l-.18,1.99c.36.03.72.05,1.06.05,2.4,0,4.48-.78,6.18-2.34,4.79-4.37,4.84-13.33,4.84-13.71,0-.45-.3-.84-.73-.96Z"></path>
            </svg>
            {{ $t('views.catalog.attributes.organic') }}
          </chip>
          <chip
            v-if="catalogProduct.is_24h_express_available"
            is-large>
            <svg
              id="Ebene_1"
              class="h5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path
                fill="currentcolor"
                d="m13.99,7c-.55,0-1,.45-.99,1.01l.02,3.6-2.73,2.69c-.39.39-.4,1.02-.01,1.41.2.2.45.3.71.3s.51-.1.7-.29l3.03-2.98c.19-.19.3-.45.3-.72l-.03-4.02c0-.55-.43-1.01-1.01-.99Z"></path>
              <rect
                fill="currentcolor"
                y="11"
                width="4"
                height="2"></rect>
              <rect
                fill="currentcolor"
                y="8"
                width="4"
                height="2"></rect>
              <rect
                fill="currentcolor"
                y="14"
                width="4"
                height="2"></rect>
              <path
                fill="currentcolor"
                d="m14,3c-4.96,0-9,4.04-9,9s4.04,9,9,9,9-4.04,9-9S18.96,3,14,3Zm0,16c-3.86,0-7-3.14-7-7s3.14-7,7-7,7,3.14,7,7-3.14,7-7,7Z"></path>
            </svg>
            {{ $t('views.catalog.attributes.express') }}
          </chip>
          <chip
            v-if="catalogProduct.can_be_warehoused"
            is-large>
            <svg
              id="Canvas"
              xmlns="http://www.w3.org/2000/svg"
              class="h5 w-5"
              viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M7.29,8.71c-.39-.39-.39-1.02,0-1.41s1.02-.39,1.41,0l2.29,2.29V3c0-.55.45-1,1-1s1,.45,1,1v6.59l2.29-2.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41l-4,4c-.09.09-.2.17-.33.22-.12.05-.25.08-.38.08s-.26-.03-.38-.08c-.12-.05-.23-.12-.33-.22l-4-4ZM20,9c-.55,0-1,.45-1,1v9c0,.54-.59,1-1.29,1H6.29c-.7,0-1.29-.46-1.29-1v-9c0-.55-.45-1-1-1s-1,.45-1,1v9c0,1.65,1.47,3,3.29,3h11.43c1.81,0,3.29-1.35,3.29-3v-9c0-.55-.45-1-1-1Z"></path>
            </svg>
            {{ $t('views.catalog.attributes.warehouse') }}
          </chip>
        </div>
      </div>

      <div
        v-if="hasBranding"
        class="mt-16">
        <h3 class="text-xl font-bold text-dark-blue mb-6">
          {{ $t('views.catalog.branding.title') }}
        </h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-16 text-gray-80">
          <div
            v-if="catalogProduct.can_use_hangtag"
            class="flex gap-5">
            <div class="h-16 w-16 text-gray-80">
              <svg
                id="Stroke"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128">
                <path
                  fill="currentColor"
                  d="M87.69,29.17c-5.51,0-10,4.49-10,10s4.49,10,10,10,10-4.49,10-10-4.49-10-10-10ZM87.69,45.17c-3.31,0-6-2.69-6-6s2.69-6,6-6,6,2.69,6,6-2.69,6-6,6ZM108.66,18.52c-1.9-1.91-4.41-2.96-7.09-2.96-.05,0-.1,0-.16,0l-28.62.44c-4.7.07-9.13,1.94-12.45,5.27L14.01,67.6c-3.9,3.9-3.9,10.24,0,14.14l31.11,31.11c1.95,1.95,4.51,2.92,7.07,2.92s5.12-.97,7.07-2.92l46.38-46.38c3.29-3.29,5.16-7.66,5.27-12.32l.65-28.37c.06-2.74-.97-5.33-2.9-7.27ZM106.91,54.07c-.08,3.62-1.54,7.02-4.1,9.58l-46.38,46.38c-2.34,2.34-6.15,2.34-8.49,0l-31.11-31.11c-2.34-2.34-2.34-6.15,0-8.49L63.17,24.1c2.59-2.59,6.03-4.04,9.69-4.1l28.62-.44s.06,0,.09,0c1.61,0,3.12.63,4.26,1.77,1.16,1.17,1.78,2.72,1.74,4.36l-.65,28.37ZM63.48,49.19l-28.28,28.28c-.39.39-.9.59-1.41.59s-1.02-.2-1.41-.59c-.78-.78-.78-2.05,0-2.83l28.28-28.28c.78-.78,2.05-.78,2.83,0,.78.78.78,2.05,0,2.83ZM67.73,61.92l-24.04,24.04c-.39.39-.9.59-1.41.59s-1.02-.2-1.41-.59c-.78-.78-.78-2.05,0-2.83l24.04-24.04c.78-.78,2.05-.78,2.83,0,.78.78.78,2.05,0,2.83ZM71.97,71.82c.78.78.78,2.05,0,2.83l-19.8,19.8c-.39.39-.9.59-1.41.59s-1.02-.2-1.41-.59c-.78-.78-.78-2.05,0-2.83l19.8-19.8c.78-.78,2.05-.78,2.83,0Z"></path>
              </svg>
            </div>
            <div class="flex-1">
              <span class="font-bold text-lg">
                {{ $t('views.catalog.branding.hangtag.title') }}
              </span>
              <p class="text-gray-70">
                {{ $t('views.catalog.branding.hangtag.description') }}
              </p>
            </div>
          </div>

          <div
            v-if="catalogProduct.can_use_sewing_label"
            class="flex gap-5">
            <div class="h-16 w-16 text-gray-80">
              <svg
                id="Stroke"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128">
                <path
                  fill="currentColor"
                  d="M108,29h-6c.52-2.2,1.08-4.38,1.7-6.5.31-1.06-.29-2.17-1.35-2.48-.53-.15-1.07-.08-1.52.17-.25-.12-.53-.18-.83-.18H26.25c-1.03-.25-2.08.34-2.39,1.37-3.84,13.04-5.87,27.79-5.87,42.63s2.03,29.58,5.87,42.63c.26.87,1.05,1.44,1.92,1.44.15,0,.3-.03.44-.06h73.77c.31,0,.59-.07.85-.2.29.15.6.26.93.26.04,0,.07-.01.11-.02.04,0,.07.02.11.02.86,0,1.66-.57,1.92-1.44,3.58-12.17,6.08-29.7,6.08-42.63V31c0-1.1-.9-2-2-2ZM22,64c0-13.9,1.84-27.69,5.3-40h71.85c-3.36,12.39-5.15,26.14-5.15,40s1.79,27.61,5.15,40H27.3c-3.46-12.31-5.3-26.1-5.3-40ZM106,64c0,10.09-1.65,23.54-4.12,34.48-2.53-10.85-3.88-22.62-3.88-34.48,0-10.6,1.07-21.13,3.1-31h4.9v31ZM30,33c0-1.1.9-2,2-2h3c1.1,0,2,.9,2,2s-.9,2-2,2h-3c-1.1,0-2-.9-2-2ZM61.89,33c0-1.1.9-2,2-2h5.78c1.1,0,2,.9,2,2s-.9,2-2,2h-5.78c-1.1,0-2-.9-2-2ZM73.44,33c0-1.1.9-2,2-2h5.78c1.1,0,2,.9,2,2s-.9,2-2,2h-5.78c-1.1,0-2-.9-2-2ZM50.33,33c0-1.1.9-2,2-2h5.78c1.1,0,2,.9,2,2s-.9,2-2,2h-5.78c-1.1,0-2-.9-2-2ZM38.78,33c0-1.1.9-2,2-2h5.78c1.1,0,2,.9,2,2s-.9,2-2,2h-5.78c-1.1,0-2-.9-2-2ZM85,33c0-1.1.9-2,2-2h3c1.1,0,2,.9,2,2s-.9,2-2,2h-3c-1.1,0-2-.9-2-2Z"></path>
              </svg>
            </div>
            <div class="flex-1">
              <span class="font-bold text-lg">
                {{ $t('views.catalog.branding.sewing_label.title') }}
              </span>
              <p class="text-gray-70">
                {{ $t('views.catalog.branding.sewing_label.description') }}
              </p>
            </div>
          </div>

          <div
            v-if="catalogProduct.can_use_imprint"
            class="flex gap-5">
            <div class="h-16 w-16 text-gray-80">
              <svg
                id="Stroke"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128">
                <path
                  fill="currentColor"
                  d="M125.04,50.29l-24.96-15.15c-.81-.49-1.75-.66-2.65-.53-.47-.47-1.16-.71-1.86-.56-17.7,3.93-45.43,3.93-63.13,0-.7-.15-1.39.08-1.86.56-.91-.13-1.85.04-2.65.53L2.96,50.29c-.94.57-1.25,1.8-.67,2.75.57.95,1.81,1.24,2.75.67l24.96-15.16v5.45c0,9.26,3.66,17.91,10.29,24.37,6.4,6.23,14.79,9.63,23.7,9.63.32,0,.64,0,.96-.01,18.22-.5,33.05-16.04,33.05-34.65v-4.78l24.96,15.16c.94.57,2.17.27,2.75-.67.57-.94.27-2.17-.67-2.75ZM64.84,73.99c-8.22.23-15.91-2.79-21.76-8.48-5.86-5.7-9.08-13.33-9.08-21.5v-5.45s0-.07,0-.1c8.7,1.69,19.35,2.53,30.01,2.53s21.31-.85,30.01-2.53c0,.03,0,.07,0,.1v4.78c0,16.46-13.08,30.21-29.16,30.65ZM110.06,57.4c-4.42,21.79-23.79,37.6-46.06,37.6s-41.64-15.81-46.06-37.6c-.22-1.08.48-2.14,1.56-2.36,1.08-.22,2.14.48,2.36,1.56,4.05,19.93,21.77,34.4,42.14,34.4s38.09-14.47,42.14-34.4c.22-1.08,1.28-1.78,2.36-1.56,1.08.22,1.78,1.28,1.56,2.36ZM45,50c0-1.1.9-2,2-2h34c1.1,0,2,.9,2,2s-.9,2-2,2h-34c-1.1,0-2-.9-2-2ZM79,58c0,1.1-.9,2-2,2h-26c-1.1,0-2-.9-2-2s.9-2,2-2h26c1.1,0,2,.9,2,2Z"></path>
              </svg>
            </div>
            <div class="flex-1">
              <span class="font-bold text-lg">
                {{ $t('views.catalog.branding.imprint.title') }}
              </span>
              <p class="text-gray-70">
                {{ $t('views.catalog.branding.imprint.description') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16">
        <h3 class="text-xl font-bold text-dark-blue mb-6">
          {{ $t('views.catalog.gpsr.title') }}
        </h3>
        <div class="flex flex-col gap-3">
          <div>
          {{ $t('views.catalog.gpsr.manufacturer') }} {{ catalogProduct.brand.data.company_name }}, {{ catalogProduct.brand.data.street }}, {{ catalogProduct.brand.data.zip }} {{ catalogProduct.brand.data.city }}, {{ catalogProduct.brand.data.country }}, <a :href="`mailto:${catalogProduct.brand.data.email}`" class="text-blue-50">{{ catalogProduct.brand.data.email }}</a>
          </div>
          <div v-if="catalogProduct.brand.data.representative_company_name">
            {{ $t('views.catalog.gpsr.responsible_person') }} {{ catalogProduct.brand.data.representative_company_name }}, {{ catalogProduct.brand.data.representative_street }}, {{ catalogProduct.brand.data.representative_zip }} {{ catalogProduct.brand.data.representative_city }}, {{ catalogProduct.brand.data.representative_country }}, <a :href="`mailto:${catalogProduct.brand.data.representative_email}`" class="text-blue-50">{{ catalogProduct.brand.data.representative_email }}</a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="border-b border-gray-10">
        <ordinal-attribute
          v-for="(attribute, index) in ordinalAttributes"
          :key="index"
          :attribute="attribute"></ordinal-attribute>
      </div>
      <div class="mt-8 text-blue-50">
        <a
          v-if="catalogProduct.brand.data.url"
          :href="catalogProduct.brand.data.url"
          target="_blank"
          class="mb-2 flex items-center hover:text-blue-60">
          {{ $t('views.product_details.description.brand_homepage') }}
          <span class="icon-external-link-outline inline-block ml-1 text-lg group-hover:text-blue-50"></span>
        </a>
        <a
          href="https://helpcenter.shirtigo.de/helpcenter-sg/wo-finde-ich-pflegehinweise/"
          target="_blank"
          class="flex items-center hover:text-blue-60">
          {{ $t('views.product_details.description.wash_notes') }}
          <span class="icon-external-link-outline inline-block ml-1 text-lg group-hover:text-blue-50"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import OrdinalAttribute from './OrdinalAttribute.vue'

  export default {

    props: {
      catalogProduct: {
        type: Object,
      },
    },

    computed: {
      hasAttributes() {
        return this.catalogProduct.is_organic || this.catalogProduct.is_24h_express_available || this.catalogProduct.can_be_warehoused
      },
      hasBranding() {
        return this.catalogProduct.can_use_hangtag || this.catalogProduct.can_use_imprint || this.catalogProduct.can_use_sewing_label
      },
      ordinalAttributes() {
        if (!this.catalogProduct) {
          return null
        }

        return this.catalogProduct.attributes.data.filter(a => a.type.data.is_primary_information === true && a.type.data.is_ordinal === true)
      }
    },

    components: {
      OrdinalAttribute,
    },
  }
</script>

<style lang="scss">
  .content-text {
    color: #000;

    ul {
      list-style: disc !important;
      margin-top: 0;
      margin-bottom: 24px;
      padding-left: 18px;

      li {
        margin-bottom: 8px;
      }
    }
  }
</style>

<template>
  <div
    v-if="baseProduct"
    class="bg-white border rounded flex flex-col"
  >
    <div class="product-card-image-wrapper">
      <design-preview
        :base-product="baseProduct"
        :current-color-id="currentColorId"
        :previews="allPreviewsForCurrentGroup"
        class="w-full h-full"
      />

      <button
        class="product-card-image-button"
        @click.prevent="openMpcDesigner"
      >
        <span class="sr-only">{{ $t('views.mpc_overview.product_group.edit_product') }}</span>
      </button>

      <button
        class="remove-product-button"
        :title="$t('views.mpc_overview.product_group.remove_product')"
        :disabled="isLoadingCart ? 'disabled' : false"
        @click.prevent="removeProduct"
      >
        <i class="font-bold text-lg icon-trash-2-outline" />
      </button>

      <div
        v-if="showColorSelector"
        class="absolute inset-0 p-4 bg-white z-30"
        @keydown.escape="showColorSelector = false"
      >
        <div class="flex justify-between">
          <h3 class="text-lg font-bold text-dark-blue mb-1 mt-1">
            {{ $t('views.mpc_overview.product_group.add_colors') }}
          </h3>

          <icon-button
            color-class="text-gray-50 border-grey-10 hover:text-blue-50 hover:border-grey-20"
            size-class="h-10 w-10 text-lg"
            :title="$t('global.close')"
            @click="showColorSelector = false"
          >
            <i class="fas fa-times m-auto leading-10" />
          </icon-button>
        </div>

        <div
          class="mt-3 flex flex-wrap"
        >
          <color-selector
            v-for="color in colorsAvailable"
            :key="color.id"
            :title="color.color_name"
            :color="color.hexcode"
            @click="addColor(color)"
          />
        </div>
      </div>

      <button
        class="edit-product-button-wrapper"
        :title="$t('views.mpc_overview.product_group.edit_product')"
        @click.prevent="openMpcDesigner"
      >
        <span class="edit-product-button">
          <i class="font-bold text-blue-50 text-3xl icon-edit-outline" />
        </span>
      </button>
    </div>

    <div class="p-4 pb-6">
      <div class="flex items-center justify-between">
        <span>
          {{ baseProduct.name }}
        </span>

        <button
          class="product-info-button"
          @click.prevent="showProductInfo = true"
        >
          <i class="font-bold text-lg icon-info-outline" />
        </button>
      </div>

      <div class="mt-3 flex flex-wrap">
        <div
          v-for="color in colorsInUse"
          :key="color.id"
          class="relative group"
        >
          <color-selector
            :title="color.color_name"
            :color="color.hexcode"
            :active="color.id === currentColorId"
            @click="currentColorId = color.id"
          />

          <button
            v-if="colorsInUse.length !== 1"
            class="delete-color-badge opacity-0 group-hover:opacity-100"
            @click="deleteColor(color)"
          >
            <i class="icon-close-outline text-sm font-icomoon cursor-pointer" />
          </button>
        </div>

        <button
          v-if="colorsAvailable.length > 0"
          class="color-swatch color-swatch--button mr-3 mb-3 bg-white"
          :title="$t('views.mpc_overview.product_group.add_colors')"
          @click.prevent="showColorSelector = true"
          @keydown.escape="showColorSelector = false"
        >
          <i class="icon-plus-outline font-icomoon text-lg cursor-pointer" />
        </button>
      </div>
    </div>

    <modal
      v-if="showProductInfo"
      @close:modal="showProductInfo = false"
    >
      <product-info-popup :base-product-details="baseProduct" />
    </modal>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { PropOptions } from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import ProductInfoPopup from '../ProductInfoPopup'
  import DesignPreview from './DesignPreview'
  import ColorSelector from '../colors/ColorSelector'
  import Modal from 'sb/elements/Modals/Modal'
  import IconButton from '../../buttons/IconButton'
  import { getBaseProductVariantId } from '../../../utils/helpers'
  import _debounce from 'lodash/debounce'

  export default {

    props: {
      /** @type {PropOptions<ApiFormattedProduct>} */
      product: {
        type: Object,
        required: true
      },
    },

    data() {
      return {
        showProductInfo: false,
        currentColorId: null,
        showColorSelector: false,
        colorsInUse: [],
      }
    },

    mounted() {
      this.determineCurrentColorId()
      this.colorsInUse = this.determineColorsInUse(this.product.variants)
    },

    methods: {
      determineCurrentColorId() {
        if (!this.product.variants) return

        let currentColorId = parseInt(this.product.variants[0].color_id)
        if (this.currentBaseProductColor !== null) {
          if (this.product.variants.some(({ color_id }) => color_id === this.currentBaseProductColor.id)) {
            currentColorId = this.currentBaseProductColor.id
          }
        }

        this.currentColorId = currentColorId
        this.setCurrentColorId(currentColorId)
      },
      removeProduct() {
        if (this.isLoadingCart) return

        this.removeProductFromCart(this.product.base_product_id)
        this.$emit('update')
      },
      openMpcDesigner() {
        this.$router.push({
          name: 'mpc-designer',
          query: {
            color: this.currentColorId,
          },
          params: {
            id: this.product.base_product_id,
            reference: this.cart.reference,
            groupid: this.currentManufacturingJobGroupId
          }
        })
      },
      /**
       * @param {BaseProductColorVariant} color
       */
      deleteColor(color) {
        const existingColorIndex = this.colorsInUse.indexOf(color)
        this.colorsInUse.splice(existingColorIndex, 1)

        const baseProductVariantId = getBaseProductVariantId(
          this.baseProduct.id,
          color.id.toString(),
          color.size_variants.data[0].size_id.toString()
        )

        const updatedVariants = this.product.variants.filter(({ baseproduct_variant_id }) => baseproduct_variant_id !== baseProductVariantId)

        // Make sure the product has at least an amount of 1 (for price calculation)
        if (updatedVariants.length && updatedVariants.every((variant) => variant.amount === 0)) {
          updatedVariants[0].amount = 1
        }

        this.setVariantsForProductInCurrentGroup({
          baseProductId: this.baseProduct.id,
          variants: updatedVariants
        })

        if (this.currentColorId === color.id) {
          this.determineCurrentColorId()
        }

        this.$emit('update')
      },
      /**
       * @param {BaseProductColorVariant} color
       */
      addColor(color) {
        this.colorsInUse.push(color)

        const baseproduct_variant_id = getBaseProductVariantId(
          this.baseProduct.id,
          color.id.toString(),
          color.size_variants.data[0].size_id.toString()
        )

        this.currentColorId = color.id
        this.setCurrentColorId(color.id)

        this.updateOrAddProductVariantInCurrentGroup({
          baseProductId: this.baseProduct.id,
          variant: {
            baseproduct_variant_id,
            amount: 0,
          },
        })

        this.saveColorToCart(color.id)
      },
      saveColorToCart: _debounce(function () {
        this.showColorSelector = false
        this.$emit('update')
      }, 1000),
      determineColorsInUse(productVariants) {
        if (!this.baseProduct) return []

        return this.colors.filter((baseProductVariant) => {
          return productVariants.some((variant) => {
            return variant.color_id && variant.color_id.toString() === baseProductVariant.id.toString()
          })
        })
      },
      ...mapActions([
        'removeProductFromCart',
        'setCurrentColorId',
        'removeProductVariantFromCart',
        'updateOrAddProductVariantInCurrentGroup',
        'setVariantsForProductInCurrentGroup',
      ]),
    },

    computed: {
      /**
       * @return {BaseProduct}
       */
      baseProduct() {
        return this.baseProducts.find(({ id }) => this.product.base_product_id === id)
      },
      /**
       * @return {BaseProductColorVariant[]}
       */
      colors() {
        return this.baseProduct.variants.data
      },
      /**
       * @return {BaseProductColorVariant[]}
       */
      colorsAvailable() {
        return this.colors.filter(({ id }) => !this.colorsInUse.some((color) => color.id === id))
      },
      ...mapGetters([
        'cart',
        'baseProducts',
        'currentBaseProductColor',
        'isLoadingCart',
        'currentManufacturingJobGroupId',
        'allPreviewsForCurrentGroup',
      ]),
    },

    components: {
      ProductInfoPopup,
      DesignPreview,
      Modal,
      ColorSelector,
      IconButton,
    },

  }
</script>

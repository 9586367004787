// TODO: Convert everything to typescript one day

/**
 * @typedef {number} BaseProductId
 * @typedef {`${string}.${string}.${string}`} BaseProductVariantId
 */

/**
 * @typedef BaseProductImage
 * @property {string} image_type
 * @property {string} reference
 * @property {string} standard
 */

/**
 * @typedef BaseProductSizeVariant
 * @property {BaseProductVariantId} sku
 * @property {number} size_id
 * @property {string} reference
 * @property {string} size_reference
 * @property {string} size
 * @property {number} sort_position
 * @property {number} baseprice_no_processing
 * @property {number} is_onlocalstock
 * @property {number} stock_amount
 */

/**
 * @typedef BaseProductColorVariant
 * @property {string} color_group_name
 * @property {string} color_group_reference
 * @property {string} hex_contrast
 * @property {string} hexcode
 * @property {string} hexcode_contrast_designer
 * @property {string} hexcode_designer
 * @property {number} id
 * @property {{data: Object.<string, BaseProductImage>}} images
 * @property {number} is_contrast
 * @property {number} is_heathered
 * @property {number} is_white
 * @property {string} reference
 * @property {{data: BaseProductSizeVariant[]}} size_variants
 * @property {number} sort_position
 */

/**
 * @typedef BaseProductPrintarea
 * @property {number} height
 * @property {number} height_px
 * @property {boolean} is_printable
 * @property {string} key
 * @property {number} margin_top
 * @property {string} name
 * @property {string} template
 * @property {number} width
 * @property {number} width_px
 */

/**
 * @typedef BaseProductAttribute
 * @property {string} description
 * @property {boolean} is_active
 * @property {string} key
 * @property {string} name
 * @property {number} sort_weight
 * @property {string} type
 * @property {string} type_key
 */

/**
 * @typedef BaseProductMockup
 * @property {number} id
 * @property {string} name
 * @property {number} is_creator_editable
 * @property {number} is_default
 * @property {boolean} is_legacy
 * @property {number} mockup_group_id
 * @property {string} processingarea_key
 * @property {number} width_px
 * @property {number} height_px
 * @property {number} offset_x_center_px
 * @property {number} offset_y_top_px
 * @property {number} px_to_mm
 * @property {string|null} thumbnail_url
 * @property {string|null} image_url
 * @property {string|null} cutout_mask_url
 * @property {string|null} svg_url
 * @property {{data: {
 *   id: number,
 *   key: string,
 *   name: string,
 *   image_url: string,
 *   description: string,
 * }}} productMockupType
 */

/**
 * @typedef BaseProductBrand
 * @property {*} brand_icon
 * @property {*} brand_logo_highres
 * @property {*} brand_logo_lowres
 * @property {string} name
 * @property {string} reference
 * @property {*} url
 */

/** @typedef BaseProductBasePrice
 * @property {number} amount
 * @property {string} currency_code
 * @property {number} currency_factor
 * @property {string} currency_symbol
 * @property {{data: {amount: number}}} vat_rate
 */

/**
 * @typedef BaseProduct
 * @property {BaseProductId} id
 * @property {string} slug
 * @property {number} amount
 * @property {{data: BaseProductBasePrice}} baseprice_processing_default
 * @property {{data: BaseProductBasePrice}} baseprice_no_processing_default
 * @property {number} available_for_crowdfunding
 * @property {number} sort_weight
 * @property {string} short_description
 * @property {string} reference
 * @property {string} platform
 * @property {string} name
 * @property {boolean} is_active
 * @property {*} legacy_product_id
 * @property {*} product_group
 * @property {string} description
 * @property {BaseProductVariantId} default_variant_sku
 * @property {string} description_preview
 * @property {{image_type: string, reference: string, standard: string}} default_image
 * @property {string} brand_product_name
 * @property {{data: BaseProductBrand[]}} brand
 * @property {{data: BaseProductColorVariant[]}} variants
 * @property {{data: BaseProductPrintarea[]}} printareas
 * @property {{data: BaseProductAttribute[]}} attributes
 * @property {{data: BaseProductMockup[]}} mockups
 * @property {*} sizings
 * @property {*} step_prices
 * @property {string} created_at
 * @property {string} updated_at
 */

/**
 * @typedef ManufacturingJobPriceItem
 * @property {number} total
 * @property {number} discount
 * @property {number} vat
 * @property {number} vat_rate
 */

/**
 * @typedef DesignerFontFamily
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string} reference
 * @property {boolean} active
 * @property {boolean} bold_enabled
 * @property {boolean} italic_enabled
 * @property {string} base_path
 * @property {string} name
 * @property {string} type
 * @property {number} sort_weight
 */

/**
 * @typedef ProcessingItemAsset
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string} mime_type
 * @property {string} reference
 * @property {string | null} name
 * @property {number | null} width
 * @property {number | null} height
 * @property {string | null} original_file
 * @property {string | null} preview
 * @property {{content: string} | null} settings
 */

/**
 * @typedef ApiFormattedAssetProcessingItem
 * @property {?string} _internal_id
 * @property {boolean} initialize
 * @property {?string} consolidated_id
 * @property {ProcessingItemAsset} asset
 * @property {string | null} preview
 * @property {number} height_mm
 * @property {number} width_mm
 * @property {number} position_x_mm
 * @property {number} position_y_mm
 * @property {number} rotation
 * @property {'asset'} type
 */

/**
 * @typedef ApiFormattedTextProcessingItem
 * @property {?string} _internal_id
 * @property {boolean} initialize
 * @property {?string} consolidated_id
 * @property {number} aspect_ratio
 * @property {ProcessingItemAsset} asset
 * @property {boolean} asset_creating
 * @property {string} content
 * @property {boolean} font_bold
 * @property {DesignerFontFamily} font_family
 * @property {boolean} font_italic
 * @property {*} input_name @deprecated
 * @property {boolean} is_input @deprecated
 * @property {number} font_size
 * @property {string} text_align
 * @property {string} text_color
 * @property {boolean} text_underline
 * @property {number} height_mm
 * @property {number} width_mm
 * @property {number} position_x_mm
 * @property {number} position_y_mm
 * @property {number} rotation
 * @property {string} svg_content
 * @property {'text'} type
 */

/**
 * @typedef ApiFormattedDesignProcessingItem
 * @property {?string} _internal_id
 * @property {boolean} initialize
 * @property {string} uuid
 * @property {*} design // we need to declare type of Design
 * @property {number} aspect_ratio
 * @property {?number} dpi
 * @property {number} height_mm
 * @property {number} width_mm
 * @property {number} position_x_mm
 * @property {number} position_y_mm
 * @property {number} rotation
 * @property {string} method
 * @property {string} position_type_key
 * @property {string | null} preview
 * @property {number} z_index
 * @property {'design'} type
 */

/**
 * @typedef {ApiFormattedAssetProcessingItem | ApiFormattedTextProcessingItem | ApiFormattedDesignProcessingItem} ApiFormattedProcessingItem
 */

/**
 * @typedef ApiFormattedProcessing
 * @property {string} processingarea_type_key
 * @property {?string} processingmethod_key
 * @property {{data: ApiFormattedProcessingItem[]}} processing_items
 */

/**
 * @typedef ApiFormattedVariantBase
 * @property {number} amount
 * @property {BaseProductVariantId} baseproduct_variant_id
 * @property {number|string} color_id
 * @property {number|string} size_id
 */

/**
 * @typedef ApiFormattedVariantServerSide
 * @property {?number} item_price_net
 * @property {?number} sum_item_price_net
 * @property {?number} item_price_gross
 * @property {?number} item_price_undiscounted_gross
 *
 * @typedef {ApiFormattedVariantBase & ApiFormattedVariantServerSide} ApiFormattedVariant
 */

/**
 * @typedef ApiFormattedProductBase
 * @property {BaseProductId} base_product_id
 * @property {(ApiFormattedVariantBase | ApiFormattedVariant)[]} variants
 */

/**
 * @typedef ApiFormattedProductServerSide
 * @property {?number} base_color_id
 * @property {?number} amount
 * @property {?string} category
 * @property {?number} avg_item_price_gross
 * @property {?number} avg_item_price_undiscounted_gross
 * @property {?number} sum_item_price_gross
 * @property {?number} sum_item_price_undiscounted_gross
 *
 * @typedef {ApiFormattedProductBase & ApiFormattedProductServerSide} ApiFormattedProduct
 */

/**
 * @typedef {string|0} ApiFormattedManufacturingJobUuid
 * @typedef {string|0} ApiFormattedManufacturingJobGroupId
 *
 * @typedef ApiFormattedManufacturingJob
 * @property {{data: ApiFormattedProcessing[]}} processings
 * @property {{data: ApiFormattedProduct[]}} products
 * @property {?number} amount
 * @property {string} created_at
 * @property {?ApiFormattedManufacturingJobUuid} uuid
 * @property {?ApiFormattedManufacturingJobGroupId} group_id
 * @property {?number} average_item_price_gross
 * @property {?number} sum_item_price_gross
 * @property {?number} average_item_price_undiscounted_gross
 * @property {?number} sum_item_price_undiscounted_gross
 * @property {?number} discount
 * @property {?{data: ManufacturingJobPreview[]}} previews
 * @property {string|null} group_name
 */

/**
 * @typedef ApiFormattedCart
 * @property {string|null} reference
 * @property {string} delivery_iso
 * @property {string} platform
 * @property {null} payment_method // Why is payment_method always null
 * @property {string} vat_calculation
 * @property {{data: ApiFormattedManufacturingJob[]}} manufacturing_jobs
 * @property {string|null} coupon_code
 * @property {?boolean} status
 * @property {?string} selected_fulfillment_mode
 * @property {?number} manufacturing_price_net
 * @property {?number} shipping_price_net
 * @property {?number} price_net
 * @property {?number} price_discount
 * @property {?number} price_gross
 * @property {?number} vat
 * @property {?number} vat_rate
 * @property {?boolean} has_free_shipping
 */

/** @typedef ManufacturingJobPreview
 * @property {number} base_product_id
 * @property {number} color_id
 * @property {boolean} customized
 * @property {string} processing_svg
 * @property {string} svg
 * @property {string} processingarea_type_key
 */

/** @typedef {[null, ApiFormattedManufacturingJobUuid|null, ApiFormattedManufacturingJobGroupId|null]} CartReturnArray */

/** @typedef CartCollectionInterface
 * @property {string} createdAt,
 * @property {ApiFormattedManufacturingJobGroupId} groupId,
 * @property {string} groupName,
 * @property {ApiFormattedProduct[]} products,
 * @property {ApiFormattedProcessing[]} processings,
 * @property {ManufacturingJobPreview[]} previews,
 */

exports.unused = {}

<template>
  <div>
    <form-group
      class="text-gray-70"
      :has-danger="!!(formErrors['company_name'] || false)"
    >
      <label class="leading-8">
        {{ $t('views.checkout.address.company') }} <span class="text-sm">(Optional)</span>
      </label>
      <sg-input
        v-model="address.company_name"
        :has-danger="!!(formErrors['company_name'] || false)"
      />
    </form-group>

    <form-group
      class="text-gray-70"
      :has-danger="!!(formErrors['firstname'] || false)"
    >
      <label class="leading-8">{{ $t('views.checkout.address.firstname') }}</label>
      <sg-input
        v-model="address.firstname"
        :has-danger="!!(formErrors['firstname'] || false)"
      />
    </form-group>

    <form-group
      class="text-gray-70"
      :has-danger="!!(formErrors['lastname'] || false)"
    >
      <label class="leading-8">{{ $t('views.checkout.address.lastname') }}</label>
      <sg-input
        v-model="address.lastname"
        :has-danger="!!(formErrors['lastname'] || false)"
      />
    </form-group>

    <div class="flex">
      <form-group
        class="text-gray-70 w-full"
        :has-danger="!!(formErrors['street'] || false)">
        <label class="leading-8">{{ $t('views.checkout.address.street') }}</label>
        <sg-input
          v-model="address.street"
          :has-danger="!!(formErrors['street'] || false)"
        />
      </form-group>

      <form-group
        class="text-gray-70 w-2/6 pl-4"
        style="max-width: 150px"
        :has-danger="!!(formErrors['streetnumber'] || false)">
        <label class="leading-8">{{ $t('views.checkout.address.streetnumber') }}</label>
        <sg-input
          v-model="address.streetnumber"
          :has-danger="!!(formErrors['streetnumber'] || false)"
        />
      </form-group>
    </div>

    <div class="flex">
      <form-group
        class="text-gray-70 w-2/6 pr-4"
        style="max-width: 150px"
        :has-danger="!!(formErrors['zip'] || false)">
        <label class="leading-8">{{ $t('views.checkout.address.zip') }}</label>
        <sg-input
          v-model="address.zip"
          :has-danger="!!(formErrors['zip'] || false)"
        />
      </form-group>

      <form-group
        class="text-gray-70 w-full"
        :has-danger="!!(formErrors['city'] || false)">
        <label class="leading-8">{{ $t('views.checkout.address.city') }}</label>
        <sg-input
          v-model="address.city"
          :has-danger="!!(formErrors['city'] || false)"
        />
      </form-group>
    </div>

    <form-group
      class="text-gray-70"
      :has-danger="!!(formErrors['country'] || false)">
      <label class="leading-8">
        {{ $t('views.checkout.address.country') }}
        <span
          v-if="countryReadOnly"
          class="text-sm">{{ $t('views.checkout.address.country_readonly') }}</span>
      </label>
      <select
        v-if="!countryReadOnly"
        v-model="address.country"
        class="p-2 rounded border-grey-20 border block w-full bg-white"
        name="address_country"
      >
        <option
          v-for="country in countries"
          :key="country.code"
          :value="country.code"
        >
          {{ country.country }}
        </option>
      </select>

      <sg-input
        v-else
        v-model="countryName"
        class="bg-gray-05"
        :disabled="true"
      />
    </form-group>
  </div>
</template>

<script>
  export default {

    name: 'AddressSelection',

    props: {
      countries: Array,
      address: Object,
      formErrors: Object,
      countryReadOnly: String
    },

    computed: {
      countryName() {
        return this.countries.filter(({ code }) => code === this.countryReadOnly)[0].country
      }
    }

  }
</script>

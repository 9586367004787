<template>
  <div class="flex w-full">
    <div class="mx-auto">
      <color-selector
        v-for="color in availableColors"
        :key="color.id"
        :title="color.color_name"
        :color="color.hexcode"
        :active="color.id === currentColor.id"
        @click="updateColor(color.id)" />
    </div>
  </div>
</template>

<script>
  import ColorSelector from './ColorSelector'

  export default {

    props: {
      availableColors: {
        type: Array
      },
      currentColor: {
        type: Object
      }
    },

    methods: {
      updateColor(color) {
        this.$emit('update:color', color)
      }
    },

    components: {
      ColorSelector
    }

  }
</script>

<style scoped>

</style>

<template>
  <input
    :id="id"
    :class="['sg-input', { 'has-icon': icon !== '', 'has-danger': hasDanger }]"
    :type="type"
    :value="value"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    :required="required"
    :tabindex="tabindex"
    :autofocus="autofocus"
    :autocomplete="autocomplete"
    :min="min"
    :max="max"
    :style="icon !== '' ? `background-image: url(${icon})` : ''"
    @blur="$emit('blur')"
    @input="$emit('input', $event.target.value)"
    @keyup="$emit('keyup', $event)"
    @keydown.enter="$emit('keydown.enter')" />
</template>

<script>
  export default {

    props: {
      id: String,
      type: {
        type: String,
        default: 'text'
      },
      value: {
        type: [String, Number],
        required: true
      },
      name: String,
      placeholder: String,
      disabled: Boolean,
      required: Boolean,
      tabindex: Number,
      autofocus: Boolean,
      autocomplete: {
        type: String,
        default: 'on'
      },
      icon: {
        type: String,
        default: ''
      },
      hasDanger: Boolean,
      min: [String, Number],
      max: [String, Number],
    },

  }
</script>

<style lang="scss" >
  @import "~sb_sass/variables";

  .sg-input {
    width: 100%;
    height: 2.625rem;
    border: 1px solid $grey-30;
    border-radius: 3px;
    box-shadow: inset 1px 0 3px 0 rgba(0, 0, 0, .05);
    font-size: 1rem;
    font-family: $nunito-sans;
    padding: .75rem;
    transition: .2s ease;

    &:focus {
      border-color: $blue-50;
      outline: none;
    }

    &::placeholder {
      color: $grey-40;
      opacity: 1;
    }

    // Internet Explorer 10-11
    &:-ms-input-placeholder {
      color: $grey-40;
    }

    // Microsoft Edge
    &::-ms-input-placeholder {
      color: $grey-40;
    }

    &.has-danger {
      border-color: $red !important;
    }

    &.input-lg {
      height: 3.25rem;
      padding: .875rem 1rem;
      border-radius: 5px;
    }

    &.has-icon {
      padding-left: 3rem;
      background-repeat: no-repeat;
      background-size: 1.25em;
      background-position: 1rem 50%;
    }

    &.input-icon-bg {
      padding-left: 3rem;
      background-repeat: no-repeat;
      background-size: 1.25rem;
      background-position: 1rem 50%;
    }
  }
</style>

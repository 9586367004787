<template>
  <div class="xl:hidden tracking-tight">
    <div class="bg-white border px-4 py-6 mt-6 rounded-lg">
      <div class="flex mb-1 text-sm text-gray-50">
        <div class="flex-1">
          {{ $t('views.cart.full_price_display.includes_vat', { vat_rate: priceForGroup ? priceForGroup.vat_rate : 19 }) }}
        </div>
        <div v-if="priceForGroup">
          {{ priceForGroup.vat | currency }}
        </div>
      </div>

      <div
        v-if="calculatedDiscountTotal"
        class="flex mb-1 text-sm text-green-50"
      >
        <div class="flex-1">
          {{ $t('views.cart.full_price_display.total_discount') }}
        </div>

        <div>
          {{ calculatedDiscountPercentage }} ({{ calculatedDiscountTotal | currency }})
        </div>
      </div>

      <div class="flex text-2xl">
        <div
          class="flex-1 items-stretch tracking-wide text-dark-blue"
          style="margin-left: -2px"
        >
          {{ $t('views.cart.full_price_display.total_price') }}
        </div>
        <div
          v-if="priceForGroup"
          class="text-blue-50 font-bold"
        >
          {{ priceForGroup.total | currency }}
        </div>
      </div>
    </div>

    <button
      :disabled="loading ? 'disabled' : false"
      class="submit-button"
      @click="$emit('submit')"
    >
      {{ submitButtonText }}

      <i class="icon-arrow-forward-outline ml-2 text-lg font-semibold text-white" />
    </button>
  </div>
</template>

<script>
  import SidebarCalculation from '@/mixins/SidebarCalculation'

  export default {

    name: 'ConfigMobileBottom',

    mixins: [ SidebarCalculation ],

    props: {
      priceForGroup: {
        type: Object,
        required: false,
      },
      submitButtonText: {
        type: String,
        defaultValue: 'Weiter',
      },
      loading: {
        type: Boolean,
        defaultValue: false,
      },
    },

  }
</script>

<style scoped lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer components {
    .submit-button {
      @apply mt-6 w-full px-4 py-2 h-11;
      @apply flex items-center justify-center;
      @apply font-nunito-sans font-semibold leading-7 text-white tracking-wide;
      @apply bg-dark-blue rounded-full shadow-big transition-all duration-200;

      &:hover {
        @apply bg-blue-50;
      }

      @media screen and (min-width: 480px) {
        @apply text-lg;
      }

      @media screen and (min-width: 768px) {
        @apply px-6;
      }
    }
  }
</style>

<template>
  <div class="product-amount-select">
    <range-slider
      :min="min"
      :max="max"
      :value="amount"
      @input="updateAmount" />
    <sg-input
      class="product-amount-select__input"
      type="number"
      :min="min"
      :max="max"
      :value="amount"
      @input="updateAmount" />
  </div>
</template>

<script>
  import RangeSlider from 'sb/elements/Product/RangeSlider.vue'
  import SgInput from 'sb/elements/Form/SgInput.vue'

  export default {

    props: {
      min: Number,
      max: Number,
      amount: {
        type: Number,
        required: true,
      },
    },

    methods: {
      updateAmount(val) {
        const amount = Math.max(this.min, Math.min(this.max, parseInt(val))) || 0
        this.$emit('update:amount', amount)
      },
    },

    components: {
      RangeSlider,
      SgInput,
    },

  }
</script>

<style lang="scss" scoped>
  // @import "~sb_sass/variables";

  .product-amount-select {
    display: flex;
    align-items: center;
    width: 100%;

    &__input {
      width: 6em;
      margin-left: 2em;
    }
  }
</style>

import { getDummyCart, saveCart } from '@/api/calculation'
import { mapActions, mapGetters } from 'vuex'
import { getBaseProductVariantId } from '@/utils/helpers'

export default {

  data() {
    return {
      currentCountry: 'DE',
    }
  },

  methods: {
    /**
     * @param {BaseProduct} baseProduct
     * @param {BaseProductColorVariant} variant
     * @param {string} deliveryIso
     * @param {ApiFormattedProcessing[]} processings
     * @returns {ApiFormattedCart}
     */
    getEmptyCart(baseProduct, variant, deliveryIso, processings) {
      return getDummyCart({
        amount: 1,
        productId: baseProduct.id,
        colorId: variant.id,
        sizeId: variant.size_variants.data[0].size_id,
        delivery_iso: deliveryIso,
        processings: processings
      })
    },
    /**
     * @param {ApiFormattedCart} cart
     * @param {boolean} generateThumbnail
     * @param {boolean} validateCoupon
     * @returns {Promise<ApiFormattedCart|null>}
     */
    async saveCart(cart, generateThumbnail = false, validateCoupon = false) {
      if (!cart) return null

      if (!this.isLoadingCart) {
        this.$Progress.start()
        this.setIsLoadingCart(true)

        try {
          /** @type ApiFormattedCart */
          const [
            updatedCart,
            currentManufacturingJobUuid,
            currentManufacturingJobGroupId
          ] = await saveCart(Object.freeze(cart), generateThumbnail, validateCoupon, this.currentManufacturingJobUuid)

          this.setCart(updatedCart)

          this.setCurrentManufacturingJobUuid(currentManufacturingJobUuid)
          this.setCurrentManufacturingJobGroupId(currentManufacturingJobGroupId)

          this.setIsLoadingCart(false)
          this.$Progress.finish()

          return updatedCart
        } catch (exception) {
          this.setIsLoadingCart(false)
          this.$Progress.fail()
        }
      }

      return null
    },
    removeEmptyProductsOrVariantsFromCart() {
      this.allCartProductsForCurrentGroup.forEach((product) => {
        if (!product.variants || product.variants.every(({ amount }) => amount === 0)) {
          this.removeProductFromCart(product.base_product_id)
        } else {
          this.removeEmptyVariantsFromProduct(product)
        }
      })
    },
    /**
     * @param {ApiFormattedProduct} product
     */
    removeEmptyVariantsFromProduct(product) {
      product.variants.forEach((/** @type ApiFormattedVariant */variant) => {
        if (variant.amount === 0) {
          this.removeProductVariantFromCart({
            baseProductId: product.base_product_id,
            baseProductVariantId: variant.baseproduct_variant_id,
          })
        }
      })
    },
    determineCountry() {
      if (this.cart) {
        this.currentCountry = this.cart.delivery_iso
        return
      }

      if (!window.__IP_COUNTRY__ || !window.__COUNTRIES__) return

      if (window.__COUNTRIES__.filter(c => c.code === window.__IP_COUNTRY__).length) {
        this.currentCountry = window.__IP_COUNTRY__
      }
    },
    /**
     * @param {BaseProduct} baseProduct
     * @return {Promise<void>}
     */
    async addBaseProductToCart(baseProduct, color = null) {
      let variant = baseProduct.variants.data[0]

      if (color) {
        variant = baseProduct.variants.data.find(v => v.id === color) || variant
      }

      const baseProductVariantId = getBaseProductVariantId(
        baseProduct.id,
        variant.id.toString(),
        variant.size_variants.data[0].size_id.toString()
      )

      this.addProductToJob({
        manufacturingJobUuid: this.currentManufacturingJob ? this.currentManufacturingJob.uuid : null,
        baseProductId: baseProduct.id,
        baseProductVariantId,
        amount: 1,
        append: true
      })
    },
    /**
     * @param {BaseProduct} baseProduct
     * @return {Promise<void>}
     */
    async initEmptyCart(baseProduct, color = null) {
      let colorVariant = baseProduct.variants.data[0]

      if (color) {
        colorVariant = baseProduct.variants.data.find(v => v.id === color)
          ?? baseProduct.variants.data[0]
      }

      await this.saveCart(this.getEmptyCart(
        baseProduct,
        colorVariant,
        this.currentCountry,
        [],
      ), false, false)
    },
    ...mapActions([
      'setCart',
      'addProductToJob',
      'removeProductFromCart',
      'removeProductVariantFromCart',
      'setCurrentManufacturingJobUuid',
      'setCurrentManufacturingJobGroupId',
    ])
  },

  computed: {
    ...mapGetters([
      'cart',
      'allCartProductsForCurrentGroup',
      'currentManufacturingJobUuid',
    ]),
  }

}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"z-10 flex justify-center items-center"},[_c('div',{class:{
      'spinning': true,
      [`text-${_vm.color}`]: true,
      'text-base leading-4 w-4 h-4': _vm.size === 'small',
      'text-2xl leading-6 w-6 h-6': _vm.size === 'medium',
      'text-4xl leading-9 w-9 h-9': _vm.size === 'large',
    }},[_c('i',{staticClass:"icon-loader-outline"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }
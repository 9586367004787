<template>
  <input-icon-group
    :title="showPassword ? 'Passwort verbergen' : 'Passwort anzeigen'"
    :is-visible="!hasDanger"
    :fa="showPassword ? 'eye-slash' : 'eye'"
    @click="showPassword = !showPassword">
    <float-label-input
      :type="showPassword ? 'text' : 'password'"
      :class-name="className"
      :title="title"
      :value="value"
      :has-danger="hasDanger"
      :required="required"
      :autocomplete="autocomplete"
      icon="/images/icons/input-lock-icon.svg"
      @keydown.enter="$emit('keydown.enter', $event)"
      @input="$emit('input', $event)">
    </float-label-input>
  </input-icon-group>
</template>

<script>
  import InputIconGroup from 'sb/elements/Form/InputIconGroup.vue'
  import FloatLabelInput from 'sb/elements/Form/FloatLabelInput.vue'

  export default {

    props: {
      className: String,
      title: String,
      value: {
        type: String,
        required: true
      },
      showPassword: Boolean,
      hasDanger: Boolean,
      required: Boolean,
      autocomplete: String,
    },

    components: {
      InputIconGroup,
      FloatLabelInput,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";
</style>

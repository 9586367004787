<template>
  <input
    :id="id"
    class="sg-checkbox"
    type="checkbox"
    :value="value"
    :name="name"
    :disabled="disabled"
    :required="required"
    :tabindex="tabindex"
    :autofocus="autofocus"
    :autocomplete="autocomplete"
    :checked="checked"
    @change="$emit('change', $event.target.value)" />
</template>

<script>
  export default {

    props: {
      id: String,
      value: String,
      name: String,
      disabled: Boolean,
      required: Boolean,
      tabindex: Number,
      autofocus: Boolean,
      autocomplete: String,
      checked: {
        type: Boolean,
        default: false,
        required: true,
      },
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .sg-checkbox {
    display: inline-block;
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    width: 1.125rem;
    height: 1.125rem;
    background: $white;
    border: 1px solid $grey-40;
    border-radius: $border-radius-checkbox;
    margin: 0;
    transition: border-color .2s ease;

    &:checked {
      color: $blue;
      @include fa;

      &::after {
        content: "\f00c";
        position: absolute;
        font-size: .75rem;
        top: .125rem;
        left: .125rem;
      }
    }

    &:not(:disabled):hover {
      border-color: $blue;
    }

    &:focus {
      box-shadow: $focus-outline;
      outline: none;
    }

    &:disabled {
      background: $grey-10;
      cursor: not-allowed;
      pointer-events: none;

      &::after {
        opacity: .75;
      }
    }
  }
</style>

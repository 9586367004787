<template>
  <div>
    <div
      v-for="(row, colorId) in colorMatrix"
      :key="colorId"
      class="mb-4"
    >
      <div class="flex bg-grey-10 py-3 px-4 items-center rounded justify-between">
        <div class="flex items-center text-grey-70 font-semibold">
          <span
            class="h-8 w-8 rounded-full border-white mr-3"
            style="box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); border-width: 3px"
            :style="{ backgroundColor: `#${row.color.hexcode}` }"
          />
          {{ row.color.color_name }}
        </div>

        <button
          v-if="!readOnly && Object.keys(colorMatrix).length > 1"
          class="text-blue-50 cursor-pointer"
          :title="$t('global.remove')"
          @click.prevent="removeColor(colorId)"
        >
          <i class="icon-trash-2-outline text-xl" />
        </button>
      </div>

      <div
        v-for="size in row.sizes"
        :key="size.size"
        class="flex bg-grey-00 py-2 px-4 mt-px items-center text-grey-90 text-sm"
      >
        <div class="w-1/3 uppercase">
          {{ size.size }}
        </div>

        <div class="w-1/3 text-center relative">
          <button
            v-if="!readOnly"
            class="absolute left-0 h-full pt-1 px-3 cursor-pointer"
            :class="getSizeAmountForVariantId(size.sku) <= 0 ? 'text-gray-40' : 'text-gray-90'"
            :disabled="getSizeAmountForVariantId(size.sku) <= 0"
            @click="decrementAmount(size.sku)"
          >
            <i class="icon-minus-outline" />
          </button>

          <button
            v-if="!readOnly"
            class="absolute right-0 h-full pt-1 px-3 text-gray-90 cursor-pointer"
            @click="incrementAmount(size.sku)"
          >
            <i class="icon-plus-outline" />
          </button>

          <input
            type="text"
            placeholder="0"
            :value="getSizeAmountForVariantId(size.sku)"
            :disabled="readOnly"
            class="h-9 border rounded-md text-center w-full py-5 text-black"
            @change="updateSizeAmountForVariantId(size.sku, $event.target.value)"
          />
        </div>

        <div class="w-1/3 text-right flex justify-end">
          <loader
            v-if="loading"
            size="small"
            color="grey-70"
            class="w-4 h-4 text-right"
          />
          <span
            v-else-if="product.avg_item_price_gross"
            class="leading-4"
          >
            {{ product.avg_item_price_gross | currency }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ConfigProductColorTable from '../../../mixins/ConfigProductColorTable'
  import Loader from '../../layout/Loader.vue'

  export default {

    mixins: [ ConfigProductColorTable ],

    methods: {
      /**
       * @param {string} baseProductVariantId
       */
      incrementAmount(baseProductVariantId) {
        const amount = this.getSizeAmountForVariantId(baseProductVariantId) + 1
        this.updateSizeAmountForVariantId(baseProductVariantId, amount)
      },
      /**
       * @param {string} baseProductVariantId
       */
      decrementAmount(baseProductVariantId) {
        const currentAmount = this.getSizeAmountForVariantId(baseProductVariantId)
        const amount = Math.max(currentAmount - 1, 0)
        this.updateSizeAmountForVariantId(baseProductVariantId, amount)
      },
    },

    components: { Loader },

  }
</script>

<template>
  <div class="popper">
    <slot></slot>
    <div
      ref="arrow"
      class="popper__arrow">
    </div>
  </div>
</template>

<script>
  /* eslint-disable vue/require-prop-types */

  import Popper from 'popper.js'

  export default {

    props: {
      target: {
        // HACK: HTMLElement not compatible with SSR
        // type: [HTMLElement, String],
        required: true,
      },
      placement: {
        type: String,
        default: 'bottom',
      },
    },

    data() {
      return {
        popper: null,
      }
    },

    beforeDestroy() {
      if (this.popper) {
        this.popper.destroy()
      }
    },

    mounted() {
      let element = null

      if (typeof this.target === 'string') {
        element = document.querySelector(this.target)
      } else {
        element = this.target
      }

      if (element) {
        this.popper = new Popper(element, this.$el, {
          placement: this.placement,
          modifiers: {
            arrow: {
              element: this.$refs.arrow,
            },
          },
        })
      } else {
        console.error('Reference element not found.')
      }
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  $arrow-width: $popper--arrow-width;

  .popper {
    background: $white;
    padding: 1.25rem;
    border-radius: $border-radius-modal;
    box-shadow: $floating-alert-shadow;
    margin-top: $arrow-width/2 + .5rem;
    z-index: 10;

    &__arrow {
      position: absolute;
      height: $arrow-width/2;
      width: $arrow-width;
      top: -$arrow-width/2;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: $white;
      border-width: 0 $arrow-width/2 $arrow-width/2 $arrow-width/2;
    }
  }
</style>

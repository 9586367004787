<template>
  <div>
    <div
      v-if="showSelect"
      class="absolute top-0 left-0 right-0 bottom-0"
      @click="showSelect = false"
    />
    <div class="relative">
      <div
        class="flex cursor-pointer"
        @click.prevent="showSelect = !showSelect"
      >
        <div
          class="w-6 h-6 bg-center bg-cover"
          :style="{backgroundImage: 'url(/images/country_flags/' + selected.toUpperCase() + '.svg)'}"
        />
        <i class="ml-1 text-xl leading-6 text-blue-30 opacity-50 icon-chevron-down-outline" />
      </div>

      <div
        v-if="showSelect"
        class="absolute bottom-0 right-0 border border-gray-00 max-h-40s overflow-auto bg-white rounded-sm shadow-xl mb-10 w-48"
      >
        <div
          v-for="country in countries"
          :key="country.code"
          class="px-2 py-2 text-gray-80 cursor-pointer border-b border-gray-00 last:border-b-0 hover:text-blue-50"
          @click.prevent="selectCountry(country)"
        >
          <div
            :ref="'c_' + country.code"
            class="table-row"
            :class="{'text-blue-50': focusedCountry && focusedCountry.code === country.code}"
          >
            <div class="table-cell align-middle">
              <img
                :alt="country.code"
                class="w-5 h-5 mr-2"
                :src="`/images/country_flags/${country.code.toUpperCase()}.svg`"
              />
            </div>

            <div class="table-cell">
              {{ country.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const SPECIAL_ORDER = [ 'DE', 'AT', 'CH' ]

  export default {

    name: 'CountryPicker',

    props: {
      selected: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        showSelect: false,
        focusedCountry: null,
        countries: (window.__COUNTRIES__ || []).sort((a, b) => {
          if (SPECIAL_ORDER.indexOf(a.code) > -1) {
            if (SPECIAL_ORDER.indexOf(b.code) > -1) {
              return SPECIAL_ORDER.indexOf(a.code) - SPECIAL_ORDER.indexOf(b.code)
            }
            return -1
          }
          if (a.country < b.country) {
            return -1
          }
          if (a.country > b.country) {
            return 1
          }
          return 0
        }),
        selectedCountry: this.selected
      }
    },

    mounted() {
      window.addEventListener('keyup', this.onKeyUp)
    },

    methods: {
      onKeyUp(e) {
        if (!this.showSelect) {
          return
        }

        if (e.code === 'Escape') {
          this.showSelect = false
          return
        }

        if (this.focusedCountry && e.code === 'Enter') {
          // Selected item
          this.selectCountry(this.focusedCountry)
          return
        }

        let countriesStartingWith = this.countries.filter(c => c.country.startsWith(e.key.toUpperCase()))

        // No country starting with letter
        if (!countriesStartingWith.length) {
          return
        }

        // Scroll to next item, when pressing a letter twice
        let index = countriesStartingWith.indexOf(this.focusedCountry) + 1
        if (index >= countriesStartingWith.length) {
          index = 0
        }
        this.focusedCountry = countriesStartingWith[index]
        const el = this.$refs['c_' + this.focusedCountry.code][0]

        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          el.focus()
        }
      },
      selectCountry(country) {
        this.showSelect = false
        this.$emit('change', country.code)
      }
    },

    watch: {
      showSelect() {
        this.focusedCountry = null
      }
    }

  }
</script>

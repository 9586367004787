import Vue from 'vue'
import { i18n } from './i18n.js'
import App from './App.vue'
import ShirtigoFrontendComponents from 'vendor/shirtigo/shirtigo-frontend-components'
import { createRouter } from './routes'
import { createStore } from './store'
import vueResource from 'vue-resource'
import Head from './mixins/Head.js'
import LoadScript from 'vue-plugin-load-script'
import SvgVue from 'svg-vue'
import currencyGross from 'vendor/shirtigo/shirtigo-frontend-components/src/common/filters/currencyGross'
import VueProgressBar from 'vue-progressbar'
import CatalogIndex from './views/catalog/CatalogIndex.vue'
require('./bootstrap')
require('./typedefs')

// cookieconsent will be included by webpack in webpack.mix.js directly, to avoid it executing on the server

const debug = process.env.NODE_ENV !== 'production'

// Configure Dev Tools
Vue.config.devtools = debug

Vue.use(ShirtigoFrontendComponents, { useRouter: true })
Vue.use(vueResource)
Vue.use(LoadScript)
Vue.use(SvgVue)
Vue.filter('currencyGross', currencyGross)
Vue.use(VueProgressBar, {
  color: 'rgb(255, 204, 0)',
  failedColor: 'red',
  height: '2px',
  autoFinish: false
})

export function createApp(config) {
  const router = createRouter(config)
  const store = createStore(config)
  const injectedRoutes = [
    'cart',
    'catalog-index',
    'catalog-product-show',
    'thank-you',
    '404'
  ]
  router.beforeEach((to, from, next) => {
    const toRequiresInjection = injectedRoutes.some(name => to.name === name)
    const fromRequiresInjection = from.name
      ? injectedRoutes.some(name => from.name === name)
      : false;
    if (from.name && toRequiresInjection !== fromRequiresInjection) {
      window.location.href = to.fullPath
    } else {
      next()
    }
  })
  router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    Vue.nextTick(() => {
      let title = window.__META__.title || null
      Object.keys(window.__META__).forEach((k) => {
        if (to.path.startsWith(k) && !title) {
          title = window.__META__[k].title
        }
      })
      document.title = title || 'Shirtigo'
    })
  })

  const app = new Vue({
    i18n,
    router,
    store,
    components: {
      CatalogIndex,
    },
    render: (h) => h(App),
  })

  Vue.mixin(Head)

  return { app, router, store }
}

<template>
  <div class="bg-white border p-6 lg:p-8 mt-6 rounded flex flex-col items-center">
    <img
      class="flex mb-6 lg:mb-8"
      src="/images/cart-add-product.svg"
      alt=""
      aria-hidden="true"
      role="presentation"
      width="128"
    />

    <h5 class="text-base text-grey-80 mb-2">
      Füge deinem Warenkorb eine weitere Kollektion hinzu.
    </h5>

    <button
      class="my-3 mx-auto w-auto items-center text-center px-4 md:px-6 py-2 border border-blue-50-50 text-blue-50 rounded-full font-semibold leading-6 tracking-wide min-h-10 xs:text-base bg-white transition-all duration-200 hover:border-blue-60 hover:text-blue-60 font-nunito-sans flex"
      :disabled="disabled ? 'disabled' : false"
      @click="$emit('click')"
    >
      <i class="icon-plus-outline text-large" />
      <span class="hidden md:block ml-2">{{ $t('views.cart.add_collection') }}</span>
    </button>
  </div>
</template>

<script>
  export default {

    props: {
      disabled: Boolean,
    }

  }
</script>

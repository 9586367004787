<template>
  <modal @close:modal="$emit('close:modal')">
    <div class="w-128 p-8">
      <div class="w-full flex items-center justify-center flex-col">
        <div class="text-ah2xl text-blue-50 flex items-center justify-center h-29 w-29 rounded-full bg-blue-00">
          <i class="icon-email-outline" />
        </div>

        <div class="text-h6 text-dark-blue font-bold mt-8">
          {{ $t('navigation.header.meta.contact_modal.headline') }}
        </div>

        <div class="mt-6 text-center text-lg opacity-75 leading-8">
          <p class="mb-8">
            {{ $t('navigation.header.meta.contact_modal.text') }}
          </p>

          <div class="mb-3 flex">
            <a
              href="tel:+4922337132282"
              class="text-blue-50 flex items-center mx-auto"
            >
              <i class="icon-phone-outline mr-1" />
              +49 (0)2233 / 71 32 282
            </a>
          </div>

          <div class="flex">
            <a
              href="mailto:info@shirtigo.de"
              class="text-blue-50 flex items-center mx-auto"
            >
              <i class="icon-email-outline mr-1" />
              info@shirtigo.de
            </a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from 'sb/elements/Modals/Modal'

  export default {

    components: { Modal }

  }
</script>

<template>
  <div :class="{ 'pointer-events-none select-none': isUploading }">
    <div class="text-gray-70 flex">
      <div
        class="flex-1 text-center cursor-pointer py-4 border border-gray-20 rounded-md mt-0 mr-2 md:my-4 md:ml-4 mb-4"
        :class="{ 'text-blue-50 border-blue-50': selectedType === 'text' }"
        @click="addText()"
      >
        <span class="icon-text-outline font-icomoon text-2xl block"></span>
        <span class="text-sm">Text hinzufügen</span>
      </div>

      <div
        class="flex-1 text-center cursor-pointer py-4 border border-gray-20 rounded-md mt-0 ml-2 md:my-4 md:mr-4 mb-4"
        :class="{ 'text-blue-50 border-blue-50': selectedType === 'upload' }"
        @click="addImage()"
      >
        <span class="icon-image-outline font-icomoon text-2xl block"></span>
        <span class="text-sm">Grafik hinzufügen</span>
      </div>
    </div>

    <div class="md:px-4">
      <div v-if="selectedType === 'text'">
        <text-control-elements
          :processing-items="processingItems"
          :selected-processing-item="activeProcessingItem"
          :designer-fonts="designerFonts"
          @processing-updated="onTextProcessingUpdated($event)"
        />
      </div>

      <div v-if="selectedType === 'upload'">
        <!-- TODO: Control elements for image -->
        <slot name="upload" />
      </div>
    </div>
  </div>
</template>

<script>
  import TextControlElements from 'sb/elements/Designer/Controls/TextControlElements'
  import generateUniqueId from '../../../common/utils/uniqueId'

  export default {

    props: {
      processingItems: {
        type: Array,
        required: true
      },
      color: {
        type: Object,
        required: true
      },
      designerFonts: {
        type: Array,
        required: true
      },
      disableFeatures: {
        type: Array,
        default: () => []
      },
      selectedProcessingItem: {
        type: Object,
        default: () => null,
        require: false
      },
      isUploading: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        selectedType: 'text',
        activeProcessingItem: null
      }
    },

    mounted() {
      this.activeProcessingItem = this.selectedProcessingItem || null
      this.determineSelectedType()
    },

    methods: {
      addText() {
        this.selectedType = 'text'

        this.activeProcessingItem = this.createTextElement()
        this.elementCreated(this.activeProcessingItem)
      },
      addImage() {
        this.selectedType = 'upload'
        this.activeProcessingItem = null
      },
      onTextProcessingUpdated(updatedTextProcessingItem) {
        if (!updatedTextProcessingItem || !this.processingItems) return

        const newProcessingItemsArray = this.processingItems.map((processingItem) => {
          if (typeof updatedTextProcessingItem.uuid !== 'undefined' && processingItem.uuid === updatedTextProcessingItem.uuid) {
            return updatedTextProcessingItem
          }

          if (typeof updatedTextProcessingItem._internal_id !== 'undefined' && processingItem._internal_id === updatedTextProcessingItem._internal_id) {
            return updatedTextProcessingItem
          }

          return processingItem
        })

        this.updateProcessingItems(newProcessingItemsArray)
        this.activeProcessingItem = updatedTextProcessingItem
      },
      /**
       * @param {Partial<ApiFormattedTextProcessingItem>|{}} additionalAttributes
       * @return {ApiFormattedTextProcessingItem}
       */
      getTextElementTemplate(additionalAttributes = {}) {
        return {
          _internal_id: generateUniqueId(),
          position_x_mm: 0,
          position_y_mm: 40,
          width_mm: 150,
          rotation: 0,
          type: 'text',
          content: '',
          text_color: this.color.is_white ? '#000000' : '#FFFFFF',
          font_size: 30, // Fixed value - we don't actually use this anymore
          font_family: this.designerFonts[0],
          font_bold: false,
          aspect_ratio: 1,
          font_italic: false,
          text_underline: false,
          text_align: 'left',
          is_input: false,
          initialize: true,
          input_name: null,
          svg_content: null,
          asset_creating: false,
          ...additionalAttributes
        }
      },
      /**
       * By default, creates a regular text element that is static and only customizable by the creator.
       *
       * @param element
       * @returns {*}
       */
      createTextElement(element = null) {
        if (!element) {
          element = this.getTextElementTemplate({ content: 'Dein Text' })
        }

        return element
      },
      determineSelectedType() {
        if (this.activeProcessingItem && this.activeProcessingItem.type === 'text') {
          this.selectedType = 'text'
        } else {
          this.selectedType = 'upload'
        }
      },
      updateProcessingItems(newProcessingItems) {
        this.$emit('processing-items-updated', newProcessingItems)
      },
      elementCreated(createdProcessingItem) {
        if (!createdProcessingItem) return

        this.updateProcessingItems([
          ...this.processingItems,
          createdProcessingItem,
        ])
      },
    },

    watch: {
      selectedProcessingItem() {
        this.activeProcessingItem = this.selectedProcessingItem
        this.determineSelectedType()
      },
      activeProcessingItem() {
        this.$emit('selected-processing-item-updated', this.activeProcessingItem)
      }
    },

    components: { TextControlElements }

  }
</script>

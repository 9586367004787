<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-25"
    style="z-index: 9999">
    <div class="h-full w-full flex items-center justify-center">
      <div class="load8">
        <div class="loader"></div>
        <div class="loader-text">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  }
</script>

<style scoped lang="scss">

.load8 {
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    min-height: 10em;
  }

  .loader {
    font-size: .5em;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(#90a0b0, 0.2);
    border-right: 1.1em solid rgba(#90a0b0, 0.2);
    border-bottom: 1.1em solid rgba(#90a0b0, 0.2);
    border-left: 1.1em solid rgba(#90a0b0, 1);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  .loader-text {
    text-align: center;
    color: #3e3e3e;
    font-weight: 400;
    font-size: 1rem;
    margin-top: -1em;
  }

  &.dark {
    .loader {
      border-color: rgba(#ccc, .5);
      border-left-color: #ccc;
    }
  }
}

@mixin load8-frames() {

  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

}

@keyframes load8 {
  @include load8-frames;
}
</style>

import ROOT from './utils/root'

ROOT.Vue = require('vue')

ROOT._ = require('lodash')
ROOT.Popper = require('popper.js').default

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

ROOT.axios = require('axios')

ROOT.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

if (process.client) {
  const token = document.head.querySelector('meta[name="csrf-token"]')

  if (token) {
    ROOT.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
  } else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
  }
}

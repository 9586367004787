<template>
  <div
    v-if="isVisible"
    :class="['alert', `alert-${type}`, { 'alert-dismissable': dismissable }]"
    :style="`opacity: ${alertOpacity}`">
    <span class="type-icon">
      <icon
        v-if="type === 'info'"
        class="fa-fw"
        fa="lightbulb" />
      <icon
        v-if="type === 'success'"
        class="fa-fw"
        fa="check-circle" />
      <icon
        v-if="type === 'warning'"
        class="fa-fw"
        fa="info-circle" />
      <icon
        v-if="type === 'danger'"
        class="fa-fw"
        fa="exclamation-triangle" />
    </span>

    <div class="content">
      <slot></slot>
    </div>

    <a
      v-if="dismissable"
      class="close"
      title="Schließen"
      href="#"
      @click.prevent="remove">
      <icon fa="times" />
    </a>
  </div>
</template>

<script>
  import Icon from 'sb/common/components/Icon.vue'

  // time in milliseconds
  const DURATION_NORMAL = 4000
  const DURATION_LONG = 8000
  const ANIMATION_TIME = 400

  export default {

    props: {
      type: {
        type: String,
        default: 'info',
      },
      dismissable: {
        default: true,
        type: Boolean
      },
      index: {
        type: Number,
        default: 0,
      },
      duration: [
        String,
        Number,
      ],
    },

    data() {
      return {
        isVisible: true,
        alertOpacity: 1,
      }
    },

    created() {
      if (this.duration === 'normal') {
        setTimeout(() => this.remove(), DURATION_NORMAL)
      } else if (this.duration === 'long') {
        setTimeout(() => this.remove(), DURATION_LONG)
      } else if (typeof this.duration === 'number') {
        setTimeout(() => this.remove(), parseInt(this.duration))
      }
    },

    methods: {
      remove() {
        if (!this.dismissable) {
          return false
        }

        this.alertOpacity = 0

        setTimeout(() => {
          this.isVisible = false

          if (this.index) {
            this.$emit('alert:remove', this.index)
          }
        }, ANIMATION_TIME)

        return true
      },
    },

    components: {
      Icon,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .alert {
    margin-bottom: 0;
    font-size: 1rem;
    padding: 1em 1.5rem;
    border: 1px solid transparent;
    opacity: 1;
    transition: .2s ease;
    display: flex;
    line-height: 1.75;
    border-radius: $border-radius-alert;

    .type-icon {
      display: block;
      font-size: 1.125rem;
      margin-right: .75rem;
      margin-left: -.25rem;
    }

    &.alert-dismissable {
      position: relative;
      padding-right: 3rem;

      .close {
        position: absolute;
        padding: 1em 1.5rem;
        top: 0;
        right: 0;
        text-decoration: none;

        &:focus {
          text-decoration: underline;
        }
      }
    }

    p {
      color: inherit;
      margin: 0 0 .5em;

      &:last-child {
        margin: 0;
      }
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: inherit;
        text-decoration: none;
      }
    }

    &,
    &.alert-info {
      color: $state-info-text;
      background-color: $state-info-bg;
      border-color: $state-info-border;
    }

    &.alert-success {
      color: $state-success-text;
      background-color: $state-success-bg;
      border-color: $state-success-border;
    }

    &.alert-warning {
      color: $state-warning-text;
      background-color: $state-warning-bg;
      border-color: $state-warning-border;
    }

    &.alert-danger {
      color: $state-danger-text;
      background-color: $state-danger-bg;
      border-color: $state-danger-border;
    }
  }
</style>

export default {
    "de": {
        "auth": {
            "failed": "Diese Kombination aus Zugangsdaten wurde nicht in unserer Datenbank gefunden.",
            "throttle": "Zu viele Loginversuche. Versuchen Sie es bitte in {seconds} Sekunden nochmal."
        },
        "pagination": {
            "previous": "&laquo; Zurück",
            "next": "Weiter &raquo;"
        },
        "cookieconsent": {
            "consent_modal": {
                "title": "Diese Webseite verwendet Cookies.",
                "description": "Cookies erleichtern die Bereitstellung unserer Dienste und werden für Marketing-, Analyse- und Statistikzwecke verwendet. Durch Klick auf „Alle akzeptieren“ stimmst du der Nutzung dieser Cookies zu (mehr Infos: <a href=\"/privacy\" target=\"_blank\">Datenschutzerklärung</a>). Mit der Auswahl „Nur essentielle Cookies akzeptieren“ beschränken wir uns auf die wesentlichen Cookies, die für den Betrieb dieser Seite erforderlich sind.",
                "primary_btn": "Alle akzeptieren",
                "secondary_btn": "Einstellungen",
                "third_btn": "Nur notwendige"
            },
            "settings_modal": {
                "title": "Cookie-Einstellungen",
                "description": "Cookies erleichtern die Bereitstellung unserer Dienste und werden für Marketing-, Analyse- und Statistikzwecke verwendet. Durch Klick auf „Alle akzeptieren“ stimmst du der Nutzung dieser Cookies zu (mehr Infos: <a href=\"/privacy\" target=\"_blank\">Datenschutzerklärung</a>). Mit der Auswahl „Nur essentielle Cookies akzeptieren“ beschränken wir uns auf die wesentlichen Cookies, die für den Betrieb dieser Seite erforderlich sind.",
                "save_settings_btn": "Aktuelle Einstellungen speichern",
                "accept_all_btn": "Alle Cookies akzeptieren",
                "close_btn_label": "Schließen",
                "table": {
                    "header": "Name",
                    "necessary": {
                        "title": "Essenziell",
                        "description": "Diese Cookies sind technisch notwendig, um die Kernfunktionalität der Website aktivieren zu können."
                    },
                    "statistics": {
                        "title": "Statistik",
                        "description": "Mit diesen Cookies können wir die Nutzung der Website analysieren, um die Leistung messen und verbessern zu können."
                    },
                    "marketing": {
                        "title": "Marketing",
                        "description": "Marketing-Cookies werden anonymisiert gesammelt und helfen dabei, Nutzerinteressen besser zu verstehen und Services darauf abzustimmen."
                    }
                }
            }
        },
        "meta": {
            "title": {
                "designer": "Gestalte dein Produkt im Designer",
                "calculator": "Gestalte dein Produkt im Designer",
                "mpc_overview": "Gestalte dein Produkt im Designer",
                "sizes": "Größen auswählen & Design überprüfen",
                "cart": "Warenkorb: Checke deine Daten",
                "checkout_confirm": "Bestellung überprüfen & aufgeben",
                "checkout": "Checkout: Gib deine Anschrift & Zahlungsart an",
                "thank_you": "Vielen Dank für deine Bestellung!"
            },
            "description": {
                "designer": "Wähle im Designer dein ▶ Wunschprodukt + ▶ Farbe aus, lade deine ▶ Druckdatei hoch und bestimme die ▶ Druckposition & -größe. Jetzt gestalten!",
                "calculator": "Gestalte dein Produkt im Designer",
                "mpc_overview": "Gestalte dein Produkt im Designer",
                "sizes": "Bestimme für dein T-Shirt, Hoodie o. Ä. deine gewünschten Größen und überprüfe dein Design. Lege abschließend dein Produkte in den Warenkorb.",
                "cart": "Überprüfe im Warenkorb deine Produktdaten, dein Design und die anstehenden Kosten. Nach erfolgreichem Gegencheck gehst du weiter zur Kasse.",
                "checkout_confirm": "Überprüfe im letzten Schritt alle Bestellangaben, wie z. B. Rechnungs-/Lieferadresse, Zahlungs- und Produktionsart. Gib abschließend deine Bestellung auf!",
                "checkout": "Gib im Checkout deine ▶ Anschrift, ▶ Produktionsart sowie ▶ Zahlungsart und ▶ weitere Angaben an. Im nächsten Schritt bestätigst du den Checkout.",
                "thank_you": "Deine Bestellung ist nun bei uns eingegangen. Nach Zahlung des vollständigen Betrags geht sie in Produktion und wird anschließend versendet."
            }
        },
        "views": {
            "calculator": {
                "headline": "Druckpreis kalkulieren",
                "products": {
                    "headline": "Produkte",
                    "description": "Wähle Produkte, die du deiner Kalkulation hinzufügen möchtest. Die genauen Farben und Größen legst du in den nächsten Schritten fest.",
                    "add_product": "Produkt hinzufügen",
                    "total_amount": "Gesamtmenge"
                },
                "printing_process": {
                    "headline": "Druckverfahren",
                    "description": "Wähle das gewünschte Druckverfahren. Mehr Details zu den einzelnen Druckverfahren findest du in unserem",
                    "desc_link": "Druckverfahrenberater",
                    "digital": {
                        "headline": "Digitaldruck",
                        "item0": "Motivgröße kann flexibel für jedes Textil angepasst werden",
                        "item1": "Anzahl der Druckfarben hat keinen Einfluss auf den Preis",
                        "item2": "Geeignet für Farbverläufe und Fotos",
                        "item3": "Kurze Produktionsdauer"
                    },
                    "screen": {
                        "headline": "Siebdruck",
                        "item0": "Preisgünstiges Verfahren für Auflagen ab 100 Stück",
                        "item1": "Druck nach Pantone®-Standard möglich",
                        "item2": "Kleinste Textilgröße bestimmt max. Druckgröße für alle Textilien"
                    }
                },
                "printareas": {
                    "headline": "Druckflächen",
                    "description": "Gib an welche Flächen auf deinem Produkt du bedrucken möchtest. Die Druckflächen gelten für alle Produkte, bei denen diese Fläche bedruckt werden kann."
                },
                "naming": {
                    "headline": "Gib deiner Kollektion optional einen Namen als Referenz:"
                },
                "select_products_dialog": {
                    "headline": "Weiteres Produkt hinzufügen",
                    "category": "Kategorie",
                    "sort_by": "Sortieren nach",
                    "popularity": "Beliebtheit",
                    "no_products_for_category": "In dieser Kategorie sind keine weiteren Produkte mehr verfügbar.",
                    "caption": "Preise gelten ab 1 Stück mit einseitigem Digitaldruck.",
                    "product": "Produkt"
                },
                "caption": "* Stückpreise inkl. Veredelung",
                "submit_button": "Produkte gestalten",
                "loading_products": "Produkte werden geladen. Bitte warten...",
                "alert": {
                    "no_product_in_cart": "Bitte füge mindestens ein Produkt hinzu um mit der Bestellung fortzufahren.",
                    "no_processingarea_selected": "Bitte wähle mindestens eine Druckfläche aus die bedruckt werden soll",
                    "start_new_collection": "Möchtest du eine weitere Kollektion anlegen?",
                    "start_new_collection_confirm": "Neue Kollektion anlegen",
                    "start_new_collection_cancel": "Abbrechen",
                    "no_printarea_for_product": "Für das Product {product} wurde keine passende Printarea ausgewählt",
                    "no_printarea_for_products": "Für folgende Produkte wurde keine passende Printarea ausgewählt"
                },
                "product_group": {
                    "badge_base_product": "Basisprodukt",
                    "more_infos": "Mehr Infos",
                    "remove_product": "Produkt enfernen"
                }
            },
            "product_details": {
                "design_now_button": "Jetzt gestalten",
                "calculate_button": "Preis kalkulieren",
                "standard_delivery": " Lieferzeit ",
                "standard_delivery_days": "2-5 Werktage",
                "delivery_country": "Lieferland",
                "piece_price": "Stückpreis",
                "free_shipping": "Kostenloser Versand (EU)",
                "processing_method": "Veredlungsart",
                "one_side_print": "inkl. 1-seitigem Druck",
                "express_available": "<strong>24h-Express</strong> verfügbar",
                "production": "Produktion (Standard)",
                "price_info_gross": "*Preisangaben inkl. 19 % Mehrwertsteuer zzgl. <a href=\"/preise\" class=\"text-blue-50\" target=\"_blank\">Versandkosten</a>. Für Übergrößen und Sonderfarben können Aufpreise anfallen.",
                "material": "Material",
                "tabs": {
                    "description": "Beschreibung",
                    "data_sheet": "Datenblatt",
                    "size_chart": "Größentabelle",
                    "prices": "Preise",
                    "printinfo": "Druckinfo"
                },
                "description": {
                    "brand_homepage": "Herstellerwebsite",
                    "wash_notes": "Allgemeine Waschhinweise"
                },
                "data_sheet": {
                    "product_name": "Produktname",
                    "brand": "Hersteller",
                    "brand_product_name": "Hersteller Produktname"
                },
                "size_chart": {
                    "width": "Breite (B)",
                    "height": "Höhe (A)",
                    "sleeve_length": "Ärmellänge (C)"
                },
                "prices": {
                    "amount": "Menge",
                    "price_net": "Stückpreis (Netto)",
                    "price_gross": "Stückpreis (Brutto)",
                    "prices_note": "Alle Preise sind Stückpreise, inkl. 1-seitigem Digitaldruck und verstehen sich inkl. 19% Mehrwertsteuer.",
                    "prices": "ab {amount} Stück"
                },
                "gpsr": {
                    "title": "Herstellerangaben gemäß GPSR",
                    "manufacturer": "Hersteller:",
                    "responsible_person": "Verantwortliche Person:"
                },
                "dimensions": {
                    "print_file_infos": "Infos zum Anlegen der Druckdatei",
                    "download_info": "Datenblatt herunterladen",
                    "max_width": "Max. DruckBreite (B)",
                    "max_height": "Max. DruckHöhe (H)"
                }
            },
            "checkout": {
                "headline": "Deine Bestellung",
                "address": {
                    "headline": "Adressinformation",
                    "email": "E-Mail-Adresse",
                    "company": "Firma",
                    "firstname": "Vorname",
                    "lastname": "Nachname",
                    "street": "Straße",
                    "streetnumber": "Hausnummer",
                    "zip": "PLZ",
                    "city": "Ort",
                    "country": "Land",
                    "country_readonly": "(übernommen aus Rechnungsadresse)",
                    "shipping_equals_primary": "Die Bestellung soll an diese Adresse gesendet werden",
                    "shipping_not_equals_primary": "Ich möchte die Bestellung an eine andere Lieferadresse geschickt bekommen.",
                    "delivery_address": "Lieferadresse"
                },
                "production_time": {
                    "headline": "Produktionsdauer",
                    "default_shipping_conditions_title": "Standard",
                    "default_shipping_conditions": "Zustellung in 2-5 Werktagen",
                    "default_shipping_conditions_message": "Gute Wahl, denn der Standardversand ist für dich kostenlos!"
                },
                "payment": {
                    "headline": "Zahlungsart",
                    "total_price": "Gesamtsumme"
                },
                "redirect_loading": "Deine Bestellung wird gespeichert. Dies kann ein paar Sekunden dauern...",
                "error_fields_required": "Bitte fülle alle markierten Felder aus.",
                "error_product_failed": "Eines der Produkte konnte nicht bestellt werden. Bitte versuche es später erneut.",
                "error_invalid_email": "E-Mail Adresse ungültig.",
                "error_unknown": "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.",
                "ppp": {
                    "method": "Vorkasse (SEPA)",
                    "description": "Bitte beachte, dass deine Bestellung erst produziert wird, sobald der Kaufbetrag auf unserem Bankkonto eingegangen ist. Die Bankverbindung erhältst du nochmals per Mail.",
                    "caption": "In diesem Schritt wird die Zahlung autorisiert. Die tatsächliche Zahlung erfolgt erst, nachdem du noch einmal die Möglichkeit hattest, alles zu überprüfen.",
                    "authorize_payment": "Zahlung autorisieren",
                    "check_order": "Bestellung überprüfen",
                    "loading": "Bitte warten..."
                },
                "benefits": {
                    "headline_1": "Printed in Germany",
                    "text_1": "Wir produzieren in Deutschland",
                    "headline_2": "Kostenloser Standardversand",
                    "text_2": "Innerhalb der Europäischen Union",
                    "headline_3": "Hast du noch Fragen?",
                    "text_3": "Ruf uns an unter +49 (0)2233 / 71 32 282"
                },
                "confirm": {
                    "headline": "Bestellung überprüfen",
                    "accept_all_terms_html": "Ich habe die\n                    <a\n                      href='/terms'\n                      class='text-blue-50'\n                      target='_blank'>AGB</a>, die\n                    <a\n                      href='/privacy'\n                      class='text-blue-50'\n                      target='_blank'>Datenschutzhinweise</a> und das\n                    <a\n                      href='/withdrawal'\n                      class='text-blue-50'\n                      target='_blank'>Widerrufsrecht</a> gelesen und akzeptiert.</span>",
                    "accept_text": "Ich akzeptiere, dass die Bestellung vollautomatisiert abgewickelt wird und entsprechend keine manuelle Prüfung des Auftrags erfolgt. Nachträgliche Änderungswünsche per Mail können daher nicht berücksichtigt werden.",
                    "submit_button": "Jetzt kaufen",
                    "confirm_terms_alert": "Bitte akzeptiere die Allgemeinen Geschäftsbedingungen und den Hinweis bezüglich Änderungswünsche, um mit der Bestellung fortzufahren."
                }
            },
            "mpc_overview": {
                "headline": "Gestalte die Produkte deiner Kollektion",
                "alert": "In einer Kollektion haben alle Produkte dasselbe Motiv – Größe und Position kannst du je Produkt anpassen. Für ein neues Motiv einfach die aktuelle Kollektion in den Warenkorb legen und eine neue starten.",
                "submit_button": "Größen wählen",
                "product_group": {
                    "badge_base_product": "Basisprodukt",
                    "edit_product": "Produkt bearbeiten",
                    "remove_product": "Produkt enfernen",
                    "add_colors": "Farben hinzufügen"
                },
                "add_products": {
                    "headline": "Füge deiner Bestellung weitere Produkte hinzu"
                }
            },
            "catalog": {
                "categories": {
                    "all_products": "Alle Produkte",
                    "all_products_description": "Verewige dein Design auf dem passenden Print-Produkt – ob T-Shirt, Hoodie, Sweatshirt oder Tasche. Bei uns findest du eine breite Auswahl an hochwertigen Produkten in vielfältigen Farben, Formen und Größen. Unser Sortiment wird stetig erweitert.",
                    "gender": "Geschlecht",
                    "methods": "Veredlungsart"
                },
                "order_by": {
                    "label": "Sortieren",
                    "popularity": "Beliebtheit",
                    "price_asc": "Preis aufsteigend",
                    "price_desc": "Preis absteigend"
                },
                "all_products": "Alle Produkte",
                "filter_products": "Produkte filtern",
                "product_card": {
                    "product_details": "Produktdetails",
                    "bestseller": "Bestseller",
                    "sustainable": "Nachhalting"
                },
                "page_number": "Seite",
                "product_price_caption": "* Stückpreis für ein bestelltes Produkt mit 1-seitigem Druck inkl. 19 % MwSt.",
                "product_price_caption_gross": "*Stückpreis bei einseitiger Veredlung, inkl. MwSt., zzgl. <a href=\"/pricing\" class=\"text-blue-50\" target=\"_blank\">Versand</a>",
                "products": "Products",
                "show_product_count": "Zeige {amount} Produkte",
                "branding": {
                    "title": "Branding-Optionen",
                    "hangtag": {
                        "title": "Hangtag",
                        "description": "Individuell bedrucktes Hänge-Etikett, das am Größenetikett angebracht wird."
                    },
                    "sewing_label": {
                        "title": "Web-Etikett",
                        "description": "Gewebtes Etikett aus Stoff, wahlweise am Saum oder Ärmel des Produktes."
                    },
                    "imprint": {
                        "title": "Nackendruck",
                        "description": "Gedrucktes Nacken-Emblem, wahlweise innen oder außen platziert."
                    }
                },
                "attributes": {
                    "title": "Produkteigenschaften",
                    "organic": "Nachhaltig",
                    "express": "Express-Produktion",
                    "warehouse": "Einlagerungsfähig"
                },
                "gpsr": {
                    "title": "Herstellerangaben gemäß GPSR",
                    "manufacturer": "Hersteller:",
                    "responsible_person": "Verantwortliche Person:"
                }
            },
            "designer": {
                "upload": {
                    "headline": "Design erstellen",
                    "add_text": "Text hinzufügen",
                    "add_text_caption": "Erstelle ein Design aus Text mit unserem Texteditor.",
                    "upload_asset": "Datei hochladen",
                    "upload_asset_text_part_1": "Beachte unsere",
                    "upload_asset_text_part_2": "Druckdatei-Anforderungen",
                    "upload_asset_hint": "Ziehe deine Druckdatei hierher",
                    "loader_text_wait": "Bild wird verarbeitet...",
                    "loader_text_percent": "Lade hoch ({uploadPercent}%)...",
                    "uploading": " Datei wird hochgeladen",
                    "error": {
                        "headline": "Fehler bei Dateiupload!",
                        "file_too_large": "Die Datei darf maximal 32MB groß sein.",
                        "file_type_invalid": "Ungültiger Dateityp.",
                        "unknown": "Unbekannter Fehler bei Dateiupload."
                    }
                },
                "sidebar": {
                    "includes_tax_shipping": "inkl. MwSt. & Versand EU",
                    "submit_base_design": "Basisdesign speichern",
                    "submit_sizes": "Größen wählen",
                    "base_product": "Dein Basisprodukt",
                    "submit_save_product": "Produkt speichern",
                    "cancel": "Abbrechen",
                    "tooltip_add_products_later": "Du kannst im nächsten Schritt weitere Produkte zu deiner Bestellung hinzufügen.",
                    "color": "Produktfarbe",
                    "width": "Breite",
                    "height": "Höhe",
                    "print_width": "Motivbreite",
                    "print_quality": "Druckqualität",
                    "print_quality_bad": "Schlecht",
                    "print_quality_good": "Ausreichend",
                    "print_quality_excellent": "Exzellent",
                    "edit_base_design": "Basisdesign bearbeiten",
                    "edit_base_design_info": "Änderungen am Basisdesign werden für alle Produkte in deiner Konfiguration übernommen.",
                    "color_selection": "Wähle eine Produktfarbe",
                    "standard_delivery": "Werktage Lieferzeit ",
                    "standard_delivery_days": "2-5",
                    "tab_product": "Produkt",
                    "tab_design": "Design",
                    "product_details": "Produktdetails ansehen",
                    "change_base_product": "Anderes Produkt auswählen"
                },
                "discard_order": {
                    "headline": "Bestellung verwerfen",
                    "confirm": "Möchtest du wirklich deine Bestellung löschen?"
                },
                "discard_collection": {
                    "headline": "Kollektion löschen",
                    "confirm": "Möchtest du wirklich deine Kollektion löschen?"
                },
                "validation": {
                    "error_title_fallback": "Achtung!",
                    "error_missing_design": "Bitte erstelle ein Design, um fortzufahren.",
                    "error_design_too_big_for_product": "Das Design ist zu groß für das gewählte Produkt. Bitte passe es an, um fortzufahren.",
                    "error_consolidate": "Beim Speichern des Designs ist ein Fehler aufgetreten.",
                    "error_product_not_found": "Das angefragte Basisprodukt konnte nicht gefunden werden."
                },
                "save_temporary_processings_modal": {
                    "text_html": "Du hast das Basisdesign überarbearbeitet. Möchtest du das Design auf alle Produkte deiner Bestellung übernehmen?\n        <br><br> Wichtig: Motiv und Druckgröße des aktuellen Produktes werden für alle Produkte übernommen.\n        Du kannst Druckgröße und Position später für jedes Produkte individuell anpassen.",
                    "confirm": "Basisdesign übernehmen",
                    "deny": "Zurücksetzen"
                }
            },
            "sizes_config": {
                "headline": "Wähle deine Größen",
                "submit_button": "In den Warenkorb",
                "alert": {
                    "no_product_added": "Bitte wähle eine Größe aus, um mit der Bestellung fortzufahren.",
                    "no_product_in_cart": "Bitte füge mindestens ein Produkt hinzu um mit der Bestellung fortzufahren."
                },
                "product_group": {
                    "badge_base_product": "Basisprodukt",
                    "product_information": "Produktinformation",
                    "add_color": "Farbe hinzufügen"
                },
                "color_table": {
                    "price": "Stückpreis (€)"
                },
                "return_to_homepage": "Zur Startseite zurückkehren"
            },
            "thank_you": {
                "headline": "Vielen Dank für deine Bestellung",
                "your_ordernumber": "Deine Bestellnummer lautet",
                "prepayment_notice": "Nach Zahlungseingang beginnen wir mit der Produktion.",
                "order": "Bestellung",
                "payment": "Zahlung",
                "production": "Produktion",
                "delivery": "Versand",
                "arrival": "Zustellung",
                "prepayment": {
                    "text": "Die Produktion und der Versand deiner Bestellung erfolgt erst nach Bezahlung des vollständigen Betrages. Bitte gib als Verwendungszweck unbedingt deine Bestellnummer an und überweise den Betrag auf folgendes Konto:",
                    "recipient": "Empfänger",
                    "institute": "Institut",
                    "amount": "Betrag",
                    "subject": "Betreff"
                },
                "cart_overview": {
                    "headline": "Details deiner Bestellung",
                    "ordernumber": "Bestellnummer",
                    "delivery_address": "Lieferadresse",
                    "billing_address": "Rechnungsadresse",
                    "payment_method": "Zahlungsart",
                    "production": "Produktion",
                    "payment_method_prepayment": "Vorkasse",
                    "payment_method_paypal": "Durch PayPal"
                },
                "faq": {
                    "headline": "Hast du noch Fragen?",
                    "text_html": "Nimm Kontakt zu unserem <a\n                  href='mailto:info@shirtigo.de'\n                  class='text-blue-50'>Support</a> auf! Unser erfahrenes Team steht dir beratend zur Seite.\n                  Daneben findest du in unserem <a\n                  href='https://helpcenter.shirtigo.de/#shirtigo'\n                  class='text-blue-50'\n                  target='_blank'>Helpcenter</a> hilfreiche Informationen zu Druckverfahren, Bestellprozessen, Produkten & Co."
                },
                "newsletter": {
                    "headline": "Halte dich auf dem Laufenden!",
                    "text": "Abonniere unseren Newsletter um spannende Neuigkeiten, Insights und Sonderaktionen zu erhalten.*",
                    "privacy_notice_html": "*Mit deiner Anmeldung erhältst du regelmäßig Informationen zu uns, unseren Leistungen und Angeboten. Du\n            kannst den Newsletter jederzeit abbestellen. Mehr Infos in unserer\n            <a\n              href='/privacy'\n              target='_blank'\n              class='text-blue-50'>Datenschutzerklärung</a>.",
                    "email": "Deine E-Mail-Adresse",
                    "submit_button": "Jetzt anmelden"
                }
            },
            "cart": {
                "headline": "Dein Warenkorb",
                "empty_cart": {
                    "headline": "Warenkorb leeren",
                    "confirm": "Bist du sicher, dass du den Warenkorb leeren und neu anfangen möchtest?"
                },
                "collection": "Kollektion",
                "add_collection": "Neue Kollektion",
                "product_group": {
                    "product": "Produkt",
                    "price_per_item": "Stückpreis",
                    "total_price": "Gesamtpreis",
                    "printing_process": {
                        "headline": "Druck",
                        "digital": "Digitaldruck"
                    },
                    "colors_and_sizes": "Farben & Größen",
                    "remove_product_confirm": "Bist du sicher, dass du dieses Produkt entfernen möchtest?",
                    "edit_product": "Produkt gestalten",
                    "remove_product": "Produkt entfernen",
                    "product_title_incl_print": "{amount}x {name}"
                },
                "empty_state": {
                    "headline": "Dein Warenkorb ist leer.",
                    "go_back": "Zur Startseite"
                },
                "paypal_pay_with": "Zahlen mit",
                "submit_button": "Weiter zur Kasse",
                "full_price_display": {
                    "coupon": {
                        "headline": "Gutscheincode",
                        "open_button": "Ich möchte einen Gutschein einlösen",
                        "input_placeholder": "Dein Gutscheincode",
                        "apply_coupon": "Gutscheincode anwenden",
                        "applied_successfully": "Der Gutscheincode wurde erfolgreich angewendet.",
                        "removed_successfully": "Der Gutscheincode wurde erfolgreich entfernt.",
                        "remove_coupon": "Gutscheincode entfernen",
                        "error_enter_valid_code": "Bitte gib einen gültigen Gutscheincode ein.",
                        "error_invalid_coupon": "Der eingegebene Gutscheincode ist ungültig.",
                        "error_depleted_coupon": "Der eingegebene Gutscheincode wurde bereits zu oft verwendet.",
                        "error_rate_limiting": "Der eingegebene Gutscheincode wurde in der letzten Stunde zu oft verwendet, versuche es später erneut.",
                        "error_unknown": "Ein unbekannter Fehler ist aufgetreten."
                    },
                    "delivery_time": "Die Lieferung erfolgt in <strong class='font-bold'>{days} Werktagen.</strong>",
                    "delivery_to": "Versand nach {countryName}",
                    "cost_textiles_and_print": "Textilien & Druck",
                    "cost_products": "Produkte",
                    "shipping": "Versand",
                    "total_price": "Gesamtpreis",
                    "total_discount": "Gutschein",
                    "net_price": "Nettopreis",
                    "vat": "{vat_rate} % MwSt.",
                    "includes_vat": "inkl. {vat_rate} % MwSt.",
                    "includes_shipping": "inkl. Versand",
                    "free_shipping": "Kostenloser"
                },
                "navigation": {
                    "product_designer": "Produkte gestalten",
                    "config_sizes": "Größen wählen",
                    "cart": "Warenkorb"
                },
                "sidebar": {
                    "configuration": {
                        "headline": "Deine Kollektion",
                        "empty_state_no_size": "Bitte wähle mindestens eine Größe für dein Produkt.",
                        "empty_state_no_product": "Bitte wähle mindestens ein Produkt aus."
                    },
                    "printing_process": {
                        "headline": "Dein Druck",
                        "digital": "Digitaldruck",
                        "empty_state_no_processings": "Kein Design ausgewählt."
                    }
                },
                "add_product_button": {
                    "headline": "Füge ein Produkt hinzu",
                    "text": "Dein Design wird auf alle Produkte übertragen"
                }
            }
        },
        "passwords": {
            "reset": "Das Passwort wurde zurückgesetzt!",
            "sent": "Passworterinnerung wurde gesendet!",
            "throttled": "Bitte warten Sie, bevor Sie es erneut versuchen.",
            "token": "Der Passwort-Wiederherstellungs-Schlüssel ist ungültig oder abgelaufen.",
            "user": "Es konnte leider kein Nutzer mit dieser E-Mail-Adresse gefunden werden."
        },
        "validation": {
            "accepted": "{attribute} muss akzeptiert werden.",
            "active_url": "{attribute} ist keine gültige Internet-Adresse.",
            "after": "{attribute} muss ein Datum nach dem {date} sein.",
            "after_or_equal": "{attribute} muss ein Datum nach dem {date} oder gleich dem {date} sein.",
            "alpha": "{attribute} darf nur aus Buchstaben bestehen.",
            "alpha_dash": "{attribute} darf nur aus Buchstaben, Zahlen, Binde- und Unterstrichen bestehen.",
            "alpha_num": "{attribute} darf nur aus Buchstaben und Zahlen bestehen.",
            "array": "{attribute} muss ein Array sein.",
            "before": "{attribute} muss ein Datum vor dem {date} sein.",
            "before_or_equal": "{attribute} muss ein Datum vor dem {date} oder gleich dem {date} sein.",
            "between": {
                "numeric": "{attribute} muss zwischen {min} & {max} liegen.",
                "file": "{attribute} muss zwischen {min} & {max} Kilobytes groß sein.",
                "string": "{attribute} muss zwischen {min} & {max} Zeichen lang sein.",
                "array": "{attribute} muss zwischen {min} & {max} Elemente haben."
            },
            "boolean": "{attribute} muss entweder 'true' oder 'false' sein.",
            "confirmed": "{attribute} stimmt nicht mit der Bestätigung überein.",
            "date": "{attribute} muss ein gültiges Datum sein.",
            "date_equals": "{attribute} muss ein Datum gleich {date} sein.",
            "date_format": "{attribute} entspricht nicht dem gültigen Format für {format}.",
            "different": "{attribute} und {other} müssen sich unterscheiden.",
            "digits": "{attribute} muss {digits} Stellen haben.",
            "digits_between": "{attribute} muss zwischen {min} und {max} Stellen haben.",
            "dimensions": "{attribute} hat ungültige Bildabmessungen.",
            "distinct": "{attribute} beinhaltet einen bereits vorhandenen Wert.",
            "email": "{attribute} muss eine gültige E-Mail-Adresse sein.",
            "ends_with": "{attribute} muss eine der folgenden Endungen aufweisen: {values}",
            "exists": "Der gewählte Wert für {attribute} ist ungültig.",
            "file": "{attribute} muss eine Datei sein.",
            "filled": "{attribute} muss ausgefüllt sein.",
            "gt": {
                "numeric": "{attribute} muss größer als {value} sein.",
                "file": "{attribute} muss größer als {value} Kilobytes sein.",
                "string": "{attribute} muss länger als {value} Zeichen sein.",
                "array": "{attribute} muss mehr als {value} Elemente haben."
            },
            "gte": {
                "numeric": "{attribute} muss größer oder gleich {value} sein.",
                "file": "{attribute} muss größer oder gleich {value} Kilobytes sein.",
                "string": "{attribute} muss mindestens {value} Zeichen lang sein.",
                "array": "{attribute} muss mindestens {value} Elemente haben."
            },
            "image": "{attribute} muss ein Bild sein.",
            "in": "Der gewählte Wert für {attribute} ist ungültig.",
            "in_array": "Der gewählte Wert für {attribute} kommt nicht in {other} vor.",
            "integer": "{attribute} muss eine ganze Zahl sein.",
            "ip": "{attribute} muss eine gültige IP-Adresse sein.",
            "ipv4": "{attribute} muss eine gültige IPv4-Adresse sein.",
            "ipv6": "{attribute} muss eine gültige IPv6-Adresse sein.",
            "json": "{attribute} muss ein gültiger JSON-String sein.",
            "lt": {
                "numeric": "{attribute} muss kleiner als {value} sein.",
                "file": "{attribute} muss kleiner als {value} Kilobytes sein.",
                "string": "{attribute} muss kürzer als {value} Zeichen sein.",
                "array": "{attribute} muss weniger als {value} Elemente haben."
            },
            "lte": {
                "numeric": "{attribute} muss kleiner oder gleich {value} sein.",
                "file": "{attribute} muss kleiner oder gleich {value} Kilobytes sein.",
                "string": "{attribute} darf maximal {value} Zeichen lang sein.",
                "array": "{attribute} darf maximal {value} Elemente haben."
            },
            "max": {
                "numeric": "{attribute} darf maximal {max} sein.",
                "file": "{attribute} darf maximal {max} Kilobytes groß sein.",
                "string": "{attribute} darf maximal {max} Zeichen haben.",
                "array": "{attribute} darf maximal {max} Elemente haben."
            },
            "mimes": "{attribute} muss den Dateityp {values} haben.",
            "mimetypes": "{attribute} muss den Dateityp {values} haben.",
            "min": {
                "numeric": "{attribute} muss mindestens {min} sein.",
                "file": "{attribute} muss mindestens {min} Kilobytes groß sein.",
                "string": "{attribute} muss mindestens {min} Zeichen lang sein.",
                "array": "{attribute} muss mindestens {min} Elemente haben."
            },
            "not_in": "Der gewählte Wert für {attribute} ist ungültig.",
            "not_regex": "{attribute} hat ein ungültiges Format.",
            "numeric": "{attribute} muss eine Zahl sein.",
            "password": "Das Passwort ist falsch.",
            "present": "{attribute} muss vorhanden sein.",
            "regex": "{attribute} Format ist ungültig.",
            "required": "{attribute} muss ausgefüllt werden.",
            "required_if": "{attribute} muss ausgefüllt werden, wenn {other} den Wert {value} hat.",
            "required_unless": "{attribute} muss ausgefüllt werden, wenn {other} nicht den Wert {values} hat.",
            "required_with": "{attribute} muss ausgefüllt werden, wenn {values} ausgefüllt wurde.",
            "required_with_all": "{attribute} muss ausgefüllt werden, wenn {values} ausgefüllt wurde.",
            "required_without": "{attribute} muss ausgefüllt werden, wenn {values} nicht ausgefüllt wurde.",
            "required_without_all": "{attribute} muss ausgefüllt werden, wenn keines der Felder {values} ausgefüllt wurde.",
            "same": "{attribute} und {other} müssen übereinstimmen.",
            "size": {
                "numeric": "{attribute} muss gleich {size} sein.",
                "file": "{attribute} muss {size} Kilobyte groß sein.",
                "string": "{attribute} muss {size} Zeichen lang sein.",
                "array": "{attribute} muss genau {size} Elemente haben."
            },
            "starts_with": "{attribute} muss mit einem der folgenden Anfänge aufweisen: {values}",
            "string": "{attribute} muss ein String sein.",
            "timezone": "{attribute} muss eine gültige Zeitzone sein.",
            "unique": "{attribute} ist bereits vergeben.",
            "uploaded": "{attribute} konnte nicht hochgeladen werden.",
            "url": "{attribute} muss eine URL sein.",
            "uuid": "{attribute} muss ein UUID sein.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "username": "Benutzername",
                "email": "E-Mail Adresse",
                "first_name": "Vorname",
                "last_name": "Nachname",
                "password": "Passwort",
                "password_confirmation": "Passwort Bestätigung",
                "city": "Stadt",
                "country": "Land",
                "address": "Adresse",
                "phone": "Telefonnummer",
                "mobile": "Handynummer",
                "age": "Alter",
                "sex": "Geschlecht",
                "gender": "Geschlecht",
                "day": "Tag",
                "month": "Monat",
                "year": "Jahr",
                "hour": "Stunde",
                "minute": "Minute",
                "second": "Sekunde",
                "title": "Titel",
                "content": "Inhalt",
                "description": "Beschreibung",
                "excerpt": "Auszug",
                "date": "Datum",
                "time": "Uhrzeit",
                "available": "verfügbar",
                "size": "Größe"
            }
        },
        "global": {
            "breadcrumb_home": "Home",
            "color": "Farbe",
            "colors": "Farben",
            "sizes": "Größen",
            "size": "Größe",
            "size_chart": "Größentabelle",
            "incl_one": "bei 1 Stück",
            "price_from": "ab",
            "remove": "Entfernen",
            "description": "Beschreibung",
            "edit": "Bearbeiten",
            "filter": "Filters",
            "manufacturer": "Hersteller",
            "piece": "Stück",
            "print": "Druck",
            "close": "Schließen",
            "loading": "Bitte warten…",
            "gross": "Brutto",
            "back": "Zurück"
        },
        "navigation": {
            "footer": {
                "terms_and_conditions": "AGB",
                "imprint": "Impressum",
                "privacy": "Datenschutz",
                "withdrawal": "Widerruf",
                "price_info": "Preise inkl. MwSt. und Versand (EU)",
                "legal": "Rechtliches",
                "service": "Service",
                "seedshirt": "T-Shirts verkaufen",
                "mpc": "Großbestellung",
                "cockpit": "Print-Fulfillment",
                "ressources": "Ressourcen",
                "press": "Presse",
                "jobs": "Jobs",
                "contact": "Kontakt"
            },
            "modal": {
                "alert": {
                    "headline_fallback": "Achtung",
                    "confirm_fallback": "Ok"
                },
                "confirm": {
                    "headline_fallback": "Achtung",
                    "confirm_fallback": "Ja",
                    "deny_fallback": "Nein"
                }
            },
            "header": {
                "catalog_index": "Produkte",
                "product_designer": "Gestalten",
                "mpc_calculator": "Großbestellung",
                "cockpit": "Print-Fulfillment",
                "cart": "Warenkorb",
                "menu": "Menü",
                "meta": {
                    "helpcenter": "Hilfe",
                    "contact": "Kontakt",
                    "benefits": {
                        "more_infos": "Mehr Infos",
                        "free_shipping": {
                            "button_title": "Kostenloser Versand",
                            "modal_headline": "Gratis Versand",
                            "modal_text": "Ob Einzeldrucke, Kleinauflagen oder Großbestellungen: Alle unsere Produkte liefern wir grundsätzlich ohne Versandkosten!"
                        },
                        "coupons": {
                            "button_title": "Geniale Rabatte",
                            "modal_headline": "Geniale Rabatte",
                            "modal_text": "Profitiere von attraktiven Mengenrabatten schon ab +1 Stück! Mit jedem weiteren Stück sinkt der Stückpreis."
                        },
                        "no_hidden_fees": {
                            "button_title": "Keine versteckten Kosten",
                            "modal_headline": "Keine versteckten Kosten",
                            "modal_text": "Alle angezeigten Preisen decken sämtliche Kosten für Rohmaterial, Druck und Versand + Mehrwertsteuerz"
                        }
                    },
                    "contact_modal": {
                        "headline": "Kontakt",
                        "text": "Wenn du Fragen hast, ruf uns gerne an oder schreib uns einfach eine Mail. Wir stehen dir hilfreich zur Seite!"
                    }
                }
            }
        }
    },
    "en": {
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "cookieconsent": {
            "consent_modal": {
                "title": "This website uses cookies.",
                "description": "Cookies make it easier for us to provide our services and are used for marketing, analysis and statistical purposes. By clicking on \"Accept all\" you agree to the use of these cookies (more information: <a href=\"/privacy\" target=\"_blank\">data protection declaration</a>). With the selection \" Save current settings\" under “Settings” we limit ourselves to the essential cookies that are required for the operation of this website.",
                "primary_btn": "Accept all",
                "secondary_btn": "Settings",
                "third_btn": "Only necessary"
            },
            "settings_modal": {
                "title": "Cookie-Settings",
                "description": "Cookies make it easier for us to provide our services and are used for marketing, analysis and statistical purposes. By clicking on \"Accept all\" you agree to the use of these cookies (more information: <a href=\"/privacy\" target=\"_blank\">data protection declaration</a>). With the selection \" Save current settings\" under “Settings” we limit ourselves to the essential cookies that are required for the operation of this website.",
                "save_settings_btn": "Save current settings",
                "accept_all_btn": "Accept all cookies",
                "close_btn_label": "Close",
                "table": {
                    "header": "Name",
                    "necessary": {
                        "title": "Essential",
                        "description": "These cookies are technically necessary to enable the core functionality of the website."
                    },
                    "statistics": {
                        "title": "Statistics",
                        "description": "These cookies allow us to analyze how the website is used in order to measure and improve performance."
                    },
                    "marketing": {
                        "title": "Marketing",
                        "description": "Marketing cookies are collected anonymously and help us to better understand user interests and tailor services accordingly."
                    }
                }
            }
        },
        "meta": {
            "title": {
                "designer": "Gestalte dein Produkt im Designer",
                "calculator": "Gestalte dein Produkt im Designer",
                "mpc_overview": "Gestalte dein Produkt im Designer",
                "sizes": "Größen auswählen & Design überprüfen",
                "cart": "Warenkorb: Checke deine Daten",
                "checkout_confirm": "Bestellung überprüfen & aufgeben",
                "checkout": "Checkout: Gib deine Anschrift & Zahlungsart an",
                "thank_you": "Vielen Dank für deine Bestellung!"
            },
            "description": {
                "designer": "Wähle im Designer dein ▶ Wunschprodukt + ▶ Farbe aus, lade deine ▶ Druckdatei hoch und bestimme die ▶ Druckposition & -größe. Jetzt gestalten!",
                "calculator": "Gestalte dein Produkt im Designer",
                "mpc_overview": "Gestalte dein Produkt im Designer",
                "sizes": "Bestimme für dein T-Shirt, Hoodie o. Ä. deine gewünschten Größen und überprüfe dein Design. Lege abschließend dein Produkte in den Warenkorb.",
                "cart": "Überprüfe im Warenkorb deine Produktdaten, dein Design und die anstehenden Kosten. Nach erfolgreichem Gegencheck gehst du weiter zur Kasse.",
                "checkout_confirm": "Überprüfe im letzten Schritt alle Bestellangaben, wie z. B. Rechnungs-/Lieferadresse, Zahlungs- und Produktionsart. Gib abschließend deine Bestellung auf!",
                "checkout": "Gib im Checkout deine ▶ Anschrift, ▶ Produktionsart sowie ▶ Zahlungsart und ▶ weitere Angaben an. Im nächsten Schritt bestätigst du den Checkout.",
                "thank_you": "Deine Bestellung ist nun bei uns eingegangen. Nach Zahlung des vollständigen Betrags geht sie in Produktion und wird anschließend versendet."
            }
        },
        "views": {
            "calculator": {
                "headline": "Druckpreis kalkulieren",
                "products": {
                    "headline": "Produkte",
                    "description": "Wähle Produkte, die du deiner Kalkulation hinzufügen möchtest. Die genauen Farben und Größen legst du in den nächsten Schritten fest.",
                    "add_product": "Produkt hinzufügen",
                    "total_amount": "Gesamtmenge"
                },
                "printing_process": {
                    "headline": "Druckverfahren",
                    "description": "Wähle das gewünschte Druckverfahren. Mehr Details zu den einzelnen Druckverfahren findest du in unserem",
                    "desc_link": "Druckverfahrenberater",
                    "digital": {
                        "headline": "Digitaldruck",
                        "item0": "Motivgröße kann flexibel für jedes Textil angepasst werden",
                        "item1": "Anzahl der Druckfarben hat keinen Einfluss auf den Preis",
                        "item2": "Geeignet für Farbverläufe und Fotos",
                        "item3": "Kurze Produktionsdauer"
                    },
                    "screen": {
                        "headline": "Siebdruck",
                        "item0": "Preisgünstiges Verfahren für Auflagen ab 100 Stück",
                        "item1": "Druck nach Pantone®-Standard möglich",
                        "item2": "Kleinste Textilgröße bestimmt max. Druckgröße für alle Textilien"
                    }
                },
                "printareas": {
                    "headline": "Druckflächen",
                    "description": "Gib an welche Flächen auf deinem Produkt du bedrucken möchtest. Die Druckflächen gelten für alle Produkte, bei denen diese Fläche bedruckt werden kann."
                },
                "select_products_dialog": {
                    "headline": "Weiteres Produkt hinzufügen",
                    "category": "Kategorie",
                    "sort_by": "Sortieren nach",
                    "popularity": "Beliebtheit",
                    "no_products_for_category": "In dieser Kategorie sind keine weiteren Produkte mehr verfügbar.",
                    "caption": "Preise gelten ab 1 Stück mit einseitigem Digitaldruck.",
                    "product": "Produkt"
                },
                "caption": "* Stückpreise inkl. Veredelung",
                "submit_button": "Produkte gestalten",
                "alert": {
                    "no_product_in_cart": "Bitte füge mindestens ein Produkt hinzu um mit der Bestellung fortzufahren.",
                    "no_processingarea_selected": "Bitte wähle mindestens eine Druckfläche aus die bedruckt werden soll"
                },
                "product_group": {
                    "badge_base_product": "Basisprodukt",
                    "more_infos": "Mehr Infos",
                    "remove_product": "Produkt enfernen"
                }
            },
            "product_details": {
                "design_now_button": "Jetzt gestalten",
                "standard_delivery": "Werktage Lieferzeit ",
                "standard_delivery_days": "2-5",
                "delivery_country": "Lieferland",
                "piece_price": "inkl. Druck bei 1 Stück",
                "free_shipping": "Kostenloser Versand (EU)",
                "tabs": {
                    "description": "Beschreibung",
                    "data_sheet": "Datenblatt",
                    "size_chart": "Größentabelle",
                    "prices": "Preise",
                    "printinfo": "Druckinfo"
                },
                "description": {
                    "brand_homepage": "Herstellerwebsite",
                    "wash_notes": "Allgemeine Waschhinweise"
                },
                "data_sheet": {
                    "product_name": "Produktname",
                    "brand": "Hersteller",
                    "brand_product_name": "Hersteller Produktname"
                },
                "size_chart": {
                    "width": "Breite (B)",
                    "height": "Höhe (A)",
                    "sleeve_length": "Ärmellänge (C)"
                },
                "prices": {
                    "amount": "Menge",
                    "price_net": "Stückpreis (Netto)",
                    "price_gross": "Stückpreis (Brutto)",
                    "prices_note": "Alle Preise sind Stückpreise, inkl. 1-seitigem Digitaldruck und verstehen sich inkl. 19% Mehrwertsteuer.",
                    "prices": "ab {amount} Stück"
                },
                "dimensions": {
                    "print_file_infos": "Infos zum Anlegen der Druckdatei",
                    "download_info": "Datenblatt herunterladen",
                    "max_width": "Max. DruckBreite (B)",
                    "max_height": "Max. DruckHöhe (H)"
                }
            },
            "checkout": {
                "headline": "Deine Bestellung",
                "address": {
                    "headline": "Adressinformation",
                    "email": "E-Mail-Adresse",
                    "company": "Firma",
                    "firstname": "Vorname",
                    "lastname": "Nachname",
                    "street": "Straße",
                    "streetnumber": "Hausnummer",
                    "zip": "PLZ",
                    "city": "Ort",
                    "country": "Land",
                    "country_readonly": "(übernommen aus Rechnungsadresse)",
                    "shipping_equals_primary": "Die Bestellung soll an diese Adresse gesendet werden",
                    "shipping_not_equals_primary": "Ich möchte die Bestellung an eine andere Lieferadresse geschickt bekommen.",
                    "delivery_address": "Lieferadresse"
                },
                "production_time": {
                    "headline": "Produktionsdauer",
                    "default_shipping_conditions_title": "Standard",
                    "default_shipping_conditions": "Zustellung in 2-5 Werktagen",
                    "default_shipping_conditions_message": "Gute Wahl, denn der Standardversand ist für dich kostenlos!"
                },
                "payment": {
                    "headline": "Zahlungsart",
                    "total_price": "Gesamtsumme"
                },
                "redirect_loading": "Deine Bestellung wird übermittelt. Dies kann ein paar Sekunden dauern...",
                "error_fields_required": "Bitte fülle alle markierten Felder aus.",
                "error_product_failed": "Eines der Produkte konnte nicht bestellt werden. Bitte versuche es später erneut.",
                "error_invalid_email": "E-Mail Adresse ungültig.",
                "error_unknown": "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.",
                "ppp": {
                    "method": "Vorkasse (SEPA)",
                    "description": "Bitte beachte, dass deine Bestellung erst produziert wird, sobald der Kaufbetrag auf unserem Bankkonto eingegangen ist. Die Bankverbindung erhältst du nochmals per Mail.",
                    "caption": "In diesem Schritt wird die Zahlung autorisiert. Die tatsächliche Zahlung erfolgt erst, nachdem du noch einmal die Möglichkeit hattest, alles zu überprüfen.",
                    "authorize_payment": "Zahlung autorisieren",
                    "check_order": "Bestellung überprüfen",
                    "loading": "Bitte warten..."
                },
                "benefits": {
                    "headline_1": "Printed in Germany",
                    "text_1": "Wir produzieren in Deutschland",
                    "headline_2": "Kostenloser Standardversand",
                    "text_2": "Innerhalb der Europäischen Union",
                    "headline_3": "Hast du noch Fragen?",
                    "text_3": "Ruf uns an unter +49 (0)2233 / 71 32 282"
                },
                "confirm": {
                    "headline": "Bestellung überprüfen",
                    "accept_all_terms_html": "Ich habe die\n                    <a\n                      href='/terms'\n                      class='text-blue-50'\n                      target='_blank'>AGB</a>, die\n                    <a\n                      href='/privacy'\n                      class='text-blue-50'\n                      target='_blank'>Datenschutzhinweise</a> und das\n                    <a\n                      href='/withdrawal'\n                      class='text-blue-50'\n                      target='_blank'>Widerrufsrecht</a> gelesen und akzeptiert.</span>",
                    "accept_text": "Ich akzeptiere, dass die Bestellung vollautomatisiert abgewickelt wird und entsprechend keine manuelle Prüfung des Auftrags erfolgt. Nachträgliche Änderungswünsche per Mail können daher nicht berücksichtigt werden.",
                    "submit_button": "Jetzt kaufen",
                    "confirm_terms_alert": "Bitte akzeptiere die Allgemeinen Geschäftsbedingungen und den Hinweis bezüglich Änderungswünsche, um mit der Bestellung fortzufahren."
                }
            },
            "mpc_overview": {
                "headline": "Gestalte die Produkte deiner Kollektion",
                "alert": "Beachte dass alle Produkte deiner Kollektion <strong>dasselbe Basismotiv</strong> besitzen müssen. Wenn du ein weiteres Motiv kalkulieren möchtest, erstelle eine neue Kollektion und füge Sie deinem Warenkorb hinzu.",
                "submit_button": "Größen wählen",
                "product_group": {
                    "badge_base_product": "Basisprodukt",
                    "edit_product": "Produkt bearbeiten",
                    "remove_product": "Produkt enfernen",
                    "add_colors": "Farben hinzufügen"
                },
                "add_products": {
                    "headline": "Füge deiner Bestellung weitere Produkte hinzu"
                }
            },
            "catalog": {
                "categories": {
                    "all_products": "Alle Produkte",
                    "all_products_description": "Verewige dein Design auf dem passenden Print-Produkt – ob T-Shirt, Hoodie, Sweatshirt oder Tasche. Bei uns findest du eine breite Auswahl an hochwertigen Produkten in vielfältigen Farben, Formen und Größen. Unser Sortiment wird stetig erweitert."
                },
                "order_by": {
                    "popularity": "Beliebtheit",
                    "price_asc": "Preis aufsteigend",
                    "price_desc": "Preis absteigend"
                },
                "page_number": "Seite",
                "product_price_caption": "* Stückpreis für ein bestelltes Produkt mit 1-seitigem Druck inkl. 19 % MwSt.",
                "products": "Products"
            },
            "designer": {
                "headline": "Dein Warenkorb",
                "upload": {
                    "headline": "Design erstellen",
                    "add_text": "Text hinzufügen",
                    "add_text_caption": "Erstelle ein Design aus Text mit unserem Texteditor.",
                    "upload_asset": "Datei hochladen",
                    "upload_asset_text_part_1": "Beachte unsere",
                    "upload_asset_text_part_2": "Druckdatei-Anforderungen",
                    "upload_asset_hint": "Ziehe deine Druckdatei hierher",
                    "loader_text_wait": "Bild wird verarbeitet...",
                    "loader_text_percent": "Lade hoch ({uploadPercent}%)..."
                },
                "sidebar": {
                    "includes_tax_shipping": "inkl. MwSt. & Versand EU",
                    "submit_base_design": "Basisdesign speichern",
                    "submit_sizes": "Größen wählen",
                    "base_product": "Dein Basisprodukt",
                    "tooltip_add_products_later": "Du kannst im nächsten Schritt weitere Produkte zu deiner Bestellung hinzufügen.",
                    "color": "Produktfarbe",
                    "width": "Breite",
                    "height": "Höhe",
                    "print_quality": "Druckqualität",
                    "edit_base_design": "Basisdesign bearbeiten"
                }
            },
            "sizes_config": {
                "headline": "Wähle deine Größen",
                "submit_button": "In den Warenkorb",
                "alert": {
                    "no_product_added": "Bitte wähle eine Größe aus, um mit der Bestellung fortzufahren.",
                    "no_product_in_cart": "Bitte füge mindestens ein Produkt hinzu um mit der Bestellung fortzufahren."
                },
                "product_group": {
                    "badge_base_product": "Basisprodukt",
                    "product_information": "Produktinformation",
                    "add_color": "Farbe hinzufügen"
                },
                "color_table": {
                    "price": "Stückpreis (€)"
                },
                "return_to_homepage": "Zur Startseite zurückkehren"
            },
            "thank_you": {
                "headline": "Vielen Dank für deine Bestellung",
                "your_ordernumber": "Deine Bestellnummer lautet",
                "prepayment_notice": "Nach Zahlungseingang beginnen wir mit der Produktion.",
                "order": "Bestellung",
                "payment": "Zahlung",
                "production": "Produktion",
                "delivery": "Versand",
                "arrival": "Zustellung",
                "prepayment": {
                    "text": "Die Produktion und der Versand deiner Bestellung erfolgt erst nach Bezahlung des vollständigen Betrages. Bitte gib als Verwendungszweck unbedingt deine Bestellnummer an und überweise den Betrag auf folgendes Konto:",
                    "recipient": "Empfänger",
                    "institute": "Institut",
                    "amount": "Betrag",
                    "subject": "Betreff"
                },
                "cart_overview": {
                    "headline": "Details deiner Bestellung",
                    "ordernumber": "Bestellnummer",
                    "delivery_address": "Lieferadresse",
                    "billing_address": "Rechnungsadresse",
                    "payment_method": "Zahlungsart",
                    "production": "Produktion",
                    "payment_method_prepayment": "Vorkasse",
                    "payment_method_paypal": "Durch PayPal"
                },
                "faq": {
                    "headline": "Hast du noch Fragen?",
                    "text_html": "Nimm Kontakt zu unserem <a\n                  href='mailto:info@shirtigo.de'\n                  class='text-blue-50'>Support</a> auf! Unser erfahrenes Team steht dir beratend zur Seite.\n                  Daneben findest du in unserem <a\n                  href='https://helpcenter.shirtigo.de/#shirtigo'\n                  class='text-blue-50'\n                  target='_blank'>Helpcenter</a> hilfreiche Informationen zu Druckverfahren, Bestellprozessen, Produkten & Co."
                },
                "newsletter": {
                    "headline": "Halte dich auf dem Laufenden!",
                    "text": "Abonniere unseren Newsletter um spannende Neuigkeiten, Insights und Sonderaktionen zu erhalten.*",
                    "privacy_notice_html": "*Mit deiner Anmeldung erhältst du regelmäßig Informationen zu uns, unseren Leistungen und Angeboten. Du\n            kannst den Newsletter jederzeit abbestellen. Mehr Infos in unserer\n            <a\n              href='/privacy'\n              target='_blank'\n              class='text-blue-50'>Datenschutzerklärung</a>.",
                    "email": "Deine E-Mail-Adresse",
                    "submit_button": "Jetzt anmelden"
                }
            },
            "cart": {
                "headline": "Dein Warenkorb",
                "empty_cart": {
                    "headline": "Warenkorb leeren",
                    "confirm": "Bist du sicher, dass du den Warenkorb leeren und neu anfangen möchtest?"
                },
                "product_group": {
                    "product": "Produkt",
                    "price_per_item": "Stückpreis",
                    "total_price": "Gesamtpreis",
                    "printing_process": {
                        "headline": "Druck",
                        "digital": "Digitaldruck"
                    },
                    "colors_and_sizes": "Farben & Größen",
                    "remove_product_confirm": "Bist du sicher, dass du dieses Produkt entfernen möchtest?",
                    "edit_product": "Produkt gestalten",
                    "remove_product": "Produkt entfernen",
                    "product_title_incl_print": "{amount}x {name}"
                },
                "empty_state": {
                    "headline": "Dein Warenkorb ist leer.",
                    "go_back": "Zur Startseite"
                },
                "paypal_pay_with": "Zahlen mit",
                "submit_button": "Weiter zur Kasse",
                "full_price_display": {
                    "coupon": {
                        "headline": "Gutschein",
                        "open_button": "Ich möchte einen Gutschein einlösen",
                        "input_placeholder": "Dein Gutscheincode",
                        "apply_coupon": "Gutscheincode anwenden",
                        "applied_successfully": "Der Gutscheincode wurde erfolgreich angewendet.",
                        "removed_successfully": "Der Gutscheincode wurde erfolgreich entfernt.",
                        "remove_coupon": "Gutscheincode entfernen",
                        "error_enter_valid_code": "Bitte gib einen gültigen Gutscheincode ein.",
                        "error_invalid_coupon": "Der eingegebene Gutscheincode ist ungültig.",
                        "error_depleted_coupon": "Der eingegebene Gutscheincode wurde bereits zu oft verwendet.",
                        "error_rate_limiting": "Der eingegebene Gutscheincode wurde in der letzten Stunde zu oft verwendet, versuche es später erneut.",
                        "error_unknown": "Ein unbekannter Fehler ist aufgetreten."
                    },
                    "delivery_time": "Die Lieferung erfolgt in <strong class='font-bold'>{days} Werktagen.</strong>",
                    "delivery_to": "Versand nach {countryName}",
                    "cost_textiles_and_print": "Textilien & Druck",
                    "cost_products": "Produkte",
                    "shipping": "Versand",
                    "total_price": "Gesamtpreis",
                    "total_discount": "Gutschein",
                    "net_price": "Nettopreis",
                    "includes_vat": "inkl. {vat_rate} % MwSt.",
                    "includes_shipping": "inkl. Versand",
                    "delivery_country": "Lieferland",
                    "free_shipping": "Kostenloser"
                },
                "sidebar": {
                    "configuration": {
                        "headline": "Deine Konfiguration",
                        "empty_state_no_size": "Bitte wähle mindestens eine Größe für dein Produkt.",
                        "empty_state_no_product": "Bitte wähle mindestens ein Produkt aus."
                    },
                    "printing_process": {
                        "headline": "Dein Druck",
                        "digital": "Digitaldruck",
                        "empty_state_no_processings": "Kein Design ausgewählt."
                    }
                },
                "add_product_button": {
                    "headline": "Füge ein Produkt hinzu",
                    "text": "Dein Design wird auf alle Produkte übertragen"
                }
            }
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "global": {
            "breadcrumb_home": "Home",
            "color": "Farbe",
            "colors": "Farben",
            "sizes": "Größen",
            "size": "Größe",
            "size_chart": "Größentabelle",
            "incl_one": "bei 1 Stück",
            "price_from": "ab",
            "remove": "Entfernen",
            "description": "Beschreibung",
            "edit": "Bearbeiten",
            "filter": "Filters",
            "manufacturer": "Hersteller",
            "piece": "Stück",
            "print": "Druck",
            "close": "Close"
        },
        "navigation": {
            "footer": {
                "terms_and_conditions": "AGB",
                "imprint": "Impressum",
                "privacy": "Datenschutz",
                "withdrawal": "Widerruf",
                "price_info": "Preise inkl. MwSt. und Versand (EU)"
            }
        }
    }
}

<template>
  <aside class="catalog-detail-thumbnails">
    <a
      v-for="image in images"
      :key="image.reference"
      href="#"
      :class="['catalog-detail-thumbnails__item', { active: image.reference === currentImage.reference }]"
      aria-hidden="true"
      tabindex="-1"
      @click.prevent="$emit('set:image', image.reference)">
      <img
        :src="image.low_res"
        :alt="image.reference">
    </a>
  </aside>
</template>

<script>
  export default {

    props: {
      images: {
        type: Array,
        required: true,
      },
      currentImage: Object,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";

  .catalog-detail-thumbnails {
    display: flex;
    flex-direction: column;
    width: auto;

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5em;
      height: 5em;
      padding: .375em;
      border-radius: $border-radius-checkbox;
      margin-bottom: .5em;
      box-shadow: 0 0 0 1px $grey-20;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;

      &.active {
        border-bottom-color: $blue;
      }

      img {
        position: absolute;
        width: 4.5em;
        height: 4.5em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>

<template>
  <div class="z-10 flex justify-center items-center">
    <div
      :class="{
        'spinning': true,
        [`text-${color}`]: true,
        'text-base leading-4 w-4 h-4': size === 'small',
        'text-2xl leading-6 w-6 h-6': size === 'medium',
        'text-4xl leading-9 w-9 h-9': size === 'large',
      }"
    >
      <i class="icon-loader-outline" />
    </div>
  </div>
</template>

<script>
  export default {

    props: {
      color: {
        type: String,
        defaultValue: 'blue-50',
      },
      size: {
        type: String,
        defaultValue: 'medium',
      },
    },

  }
</script>

<style lang="scss" scoped>
  .spinning {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
</style>

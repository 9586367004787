<template>
  <div class="w-full md:py-8">
    <div class="w-0 m-auto relative">
      <div
        class="transition transform hover:scale-110 duration-200 ease-in-out flex flex-col opacity-90 align-middle justify-center cursor-pointer items-center text-center bg-white px-3 md:py-4 absolute mt-4 h-32 w-32 -left-16 rounded-full border-blue-30"
        @click="$emit('click')"
      >
        <img
          class="w-16"
          src="/images/add-design.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<template>
  <div
    class="hidden lg:shadow-sm lg:flex bg-white border-l border-grey-10 fixed top-0 right-0 bottom-0 pb-1 h-full flex-col justify-between"
    style="width: 480px"
  >
    <sidebar-tabs
      :selected-tab="selectedTab"
      @change-tab="selectTab"
    />

    <div
      v-if="showAllColors"
      class="flex-1 p-6"
    >
      <div class="flex mb-4 items-center">
        <div class="flex-1 text-xl tracking-3 leading-8 font-bold">
          {{ $t('views.designer.sidebar.color_selection') }}
        </div>

        <icon-button
          color-class="text-grey-70 border-grey-10 hover:border-grey-20"
          size-class="h-10 w-10 text-xl"
          @click="showAllColors = false"
        >
          <i class="fas fa-times m-auto leading-10" />
        </icon-button>
      </div>

      <color-select-dropdown
        :available-colors="availableColors"
        :current-color="currentBaseProductColor"
        @update:color="onColorUpdate"
      />
    </div>

    <div
      v-else-if="selectedTab === 'product'"
      class="flex flex-col flex-1"
    >
      <div class="pb-8 px-6 border-b border-gray-200 flex items-center">
        <img
          v-if="currentProductImage"
          :src="currentProductImage"
          alt=""
          class="w-18 h-18 object-contain flex-shrink-0"
        >

        <div class="flex flex-col">
          <p class="font-semibold leading-tight">
            {{ currentBaseProduct.name }}
          </p>
          <p class="text-gray-70 text-sm my-1">
            {{ currentBaseProduct.brand.data.name }}
          </p>

          <button
            class="text-blue-50 text-sm flex items-center"
            @click.prevent="showProductInfo = true"
          >
            <span>{{ $t('views.designer.sidebar.product_details') }}</span>

            <span class="text-lg ml-2">
              <i class="icon-info-outline flex" />
            </span>
          </button>
        </div>

        <icon-button
          size-class="h-11 w-11 text-xl ml-auto"
          :title="$t('views.designer.sidebar.change_base_product') || ''"
          @click="openSelectProductsDialog()"
        >
          <i class="icon icon-edit-outline" />
        </icon-button>
      </div>

      <div class="px-6 mt-4">
        <color-selection
          :available-colors="availableColorsSpliced"
          :current-color="currentBaseProductColor"
          :has-more="availableColors.length > availableColorsSpliced.length"
          @more="showAllColors = true"
          @select="setCurrentColorId($event.id)"
        />
      </div>
    </div>

    <div
      v-else-if="selectedTab === 'design'"
      class="flex-1"
    >
      <create-control-element
        v-if="designerRef"
        ref="creator"
        :color="currentBaseProductColor"
        :designer-fonts="designerFonts"
        :processing-items="designerRef.processingItems"
        :disable-features="[]"
        :is-uploading="$refs.assetUploader && $refs.assetUploader.isLoading"
        :selected-processing-item="designerRef.selectedProcessingItem"
        @selected-processing-item-updated="designerRef.selectProcessingItem($event)"
        @processing-items-updated="(updatedProcessingItems) => $parent.$parent.updateVisibleProcessingItems(updatedProcessingItems)"
        @select-graphic="$emit('select-graphic')"
        @upload-graphic="e => $emit('upload-graphic', e)"
      >
        <template v-slot:upload>
          <asset-uploader
            ref="assetUploader"
            v-if="designerRef.printarea"
            :area="designerRef.printarea.key"
            @asset-uploaded="(d) => $parent.$parent.addCampaignAsset(d)"
            @upload:error="(e) => $parent.$parent.showError(e)"
          />
        </template>
      </create-control-element>
    </div>

    <div class="w-full text-sm">
      <div class="my-4 px-6 text-green-70 font-light tracking-tight">
        <div class="flex items-center">
          <strong class="font-bold flex items-center mr-1">
            <i class="icon-clock-outline inline-block mr-1 text-lg" />
            {{ $t('views.designer.sidebar.standard_delivery_days') }}
          </strong>
          {{ $t('views.designer.sidebar.standard_delivery') }}
        </div>

        <div class="mt-1 flex">
          <div class="flex-1 flex items-center">
            <strong class="font-bold flex items-center mr-1">
              <i class="icon-car-outline inline-block mr-1 text-lg" />

              <template v-if="shippingPrice <= 0">{{ $t('views.cart.full_price_display.free_shipping') }}</template>
              <template v-else>{{ shippingPrice | currency }}</template>
            </strong>

            {{ $t('views.cart.full_price_display.delivery_to', { countryName: currentCountry }) }}
          </div>

          <country-picker
            :selected="currentCountry"
            @change="$emit('countryChange', $event)"
          />
        </div>
      </div>

      <div class="flex justify-between items-center border-t border-gray-10 p-6">
        <div>
          <div class="text-h6 text-blue-50 font-semibold">
            {{ currentPrice | currency }}
          </div>
          <div class="text-xs text-grey-50 tracking-4">
            {{ $t('views.designer.sidebar.includes_tax_shipping') }}
          </div>
        </div>

        <button
          class="submit-button"
          :disabled="loadingNextStep"
          @click.prevent="$emit('nextStep')"
        >
          {{ loadingNextStep ? $t('global.loading') : $t('views.designer.sidebar.submit_sizes') }}
          <i class="fas fa-arrow-right text-sm ml-2 opacity-50" />
        </button>
      </div>
    </div>

    <modal
      v-if="showSelectProductsDialog"
      @close:modal="closeSelectProductsDialog()"
    >
      <select-products-dialog
        @close="closeSelectProductsDialog()"
        @select-product="selectProduct"
      />
    </modal>

    <modal
      v-if="showProductInfo"
      style="z-index: 1000"
      @close:modal="showProductInfo = false"
    >
      <product-info-popup :base-product-details="currentBaseProduct" />
    </modal>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import SelectProducts from '../productSelect/SelectProducts'
  import CountryPicker from '../CountryPicker'
  import AssetUploader from '../designer/AssetUploader'
  import IconButton from '../../buttons/IconButton'
  import SidebarTabs from './SidebarTabs.vue'
  import ColorSelectDropdown from '../colors/ColorSelectDropdown.vue'
  import ColorSelection from 'sb/elements/Designer/Controls/ColorSelection.vue'
  import CreateControlElement from 'sb/elements/Designer/Controls/CreateControlElement.vue'
  import SelectProductsDialog from '@/components/config/productSelect/SelectProductsDialog.vue'
  import Cart from '@/mixins/Cart'
  import Modal from 'sb/elements/Modals/Modal'
  import ProductInfoPopup from '@/components/config/ProductInfoPopup.vue'
  import ColorSelector from '@/components/config/colors/ColorSelector.vue'

  export default {

    mixins: [ Cart ],

    props: {
      availableColors: {
        type: Array
      },
      shippingPrice: {
        type: Number,
        required: true
      },
      currentPrice: {
        type: Number,
        required: true
      },
      loadingNextStep: {
        type: Boolean,
        required: true
      },
      designerRef: {
        type: Object,
      },
    },

    data() {
      return {
        showTooltip: false,
        selectedTab: 'product',
        showAllColors: false,
        showProductInfo: false,
        showSelectProductsDialog: false,
      }
    },

    mounted() {
      this.$emit('openBaseDesignEditor')
    },

    methods: {
      selectTab(tab) {
        this.selectedTab = tab
        this.$emit('closeColorSelector')
      },
      onColorUpdate(colorId) {
        this.$emit('closeColorSelector')
        this.setCurrentColorId(colorId)
      },
      closeSelectProductsDialog() {
        this.showSelectProductsDialog = false
      },
      openSelectProductsDialog() {
        this.showSelectProductsDialog = true
      },
      selectProduct(baseProduct) {
        this.$emit('selectProduct', baseProduct)

        Vue.nextTick(() => {
          this.closeSelectProductsDialog()
        })
      },
      ...mapActions([
        'setCurrentBaseProductReference',
        'setCurrentColorId'
      ])
    },

    computed: {
      currentProductImage() {
        if (!this.currentBaseProduct) return null
        const image = this.currentBaseProduct.default_image || this.defaultBaseProductVariant.images.data.front.find(
          ({ image_type }) => image_type === 'catalog-image'
        )
        return image.small || image.standard
      },
      defaultBaseProductVariant() {
        if (!this.availableColors) return null

        const defaultColorId = parseInt(this.currentBaseProduct.default_variant_sku.split('.')[1])
        return this.availableColors.find(({ id }) => id === defaultColorId) || this.availableColors[0]
      },
      availableColorsSpliced() {
        return this.availableColors.slice(0, 8)
      },
      ...mapGetters([
        'currentBaseProduct',
        'currentBaseProductColor',
        'currentManufacturingJobGroupId',
        'designerFonts',
        'cart',
      ])
    },

    components: {
      ColorSelector,
      ProductInfoPopup,
      SelectProductsDialog,
      ColorSelectDropdown,
      SidebarTabs,
      IconButton,
      AssetUploader,
      CountryPicker,
      SelectProducts,
      ColorSelection,
      CreateControlElement,
      Modal,
    }

  }
</script>

<style lang="scss" scoped>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer components {
    .submit-button {
      @apply mt-0 w-auto px-4 py-2 h-11;
      @apply flex items-center justify-center;
      @apply font-nunito-sans font-semibold leading-7 text-white tracking-wide;
      @apply bg-dark-blue rounded-full shadow-big transition-all duration-200;

      &:hover {
        @apply bg-blue-60;
      }

      &:disabled {
        @apply bg-gray-500;
      }

      @media screen and (min-width: 480px) {
        @apply text-lg;
      }

      @media screen and (min-width: 768px) {
        @apply px-6;
      }
    }
  }
</style>

<template>
  <!-- We need to wrap the Foreign Object around the Text SVG because otherwise Firefox can't handle nested SVGs -->
  <foreignObject
    :width="width"
    :height="height"
    :x="x"
    :y="y"
  >
    <svg
      :ref="nodeId"
      :fill="processingItem.text_color"
      :width="width"
      :height="height"
      :x="x"
      :y="y"
      :viewBox="svgViewBox"
      preserveAspectRatio="none"
    >
      <path
        v-if="svgLineData"
        :fill="processingItem.text_color"
        :d="`M0,0 H0 V${svgHeight} Z ${svgLineData.join(' ')}`"
      />
    </svg>
  </foreignObject>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import Vue, { PropOptions } from 'vue'
  import TextToSVG from 'text-to-svg'
  import objectContainsChanges from '../../../common/utils/objectContainsChanges'

  export const PLACEHOLDER = 'Dein Text'

  export default {

    props: {
      /** @type {PropOptions<ApiFormattedProcessingItem>} */
      processingItem: {
        type: Object,
        required: true
      },
      width: {
        type: Number,
        required: true
      },
      height: {
        type: [ String, Number ],
        required: true
      },
      x: {
        type: Number,
        required: true
      },
      y: {
        type: Number,
        required: true
      },
      designerFonts: {
        type: Array,
        required: true
      },
    },

    data() {
      return {
        svgLineData: null,
        svgViewBox: null,
        svgWidth: null,
        svgHeight: null,
        svgAspectRatio: 1
      }
    },

    mounted() {
      if (this.processingItem.content) {
        this.convertTextToSvg()
      }
    },

    methods: {
      convertTextToSvg() {
        if (!this.fontFilePath || !this.processingItem.content) return

        TextToSVG.load(this.fontFilePath, (error, textToSVG) => {
          if (error) {
            throw error
          }

          // TODO: Underline können wir hiermit leider nicht abdecken... das müsste man als separate Font anbieten
          const options = {
            fontSize: 72,
            anchor: 'top',
          }

          let svgWidth = 0
          let svgHeight = 0
          const lines = this.processingItem.content.split('\n')
          this.svgLineData = lines.map((line) => {
            const metrics = textToSVG.getMetrics(line, options)
            const offsetY = svgHeight

            if (metrics.width > svgWidth) {
              svgWidth = metrics.width
            }
            svgHeight += metrics.height

            return textToSVG.getD(line, { ...options, y: offsetY })
          })

          this.svgViewBox = `0 0 ${svgWidth} ${svgHeight}`
          this.svgWidth = svgWidth
          this.svgHeight = svgHeight
          this.svgAspectRatio = svgHeight / svgWidth

          this.recalculate()
        })
      },
      onResize(newWidthMM) {
        if (typeof newWidthMM !== 'undefined') {
          this.updateProcessingItem({ width_mm: newWidthMM })
        }
      },
      async recalculate() {
        await Vue.nextTick()
        this.updateProcessingItem({
          aspect_ratio: this.svgAspectRatio,
          height_mm: this.processingItem.width_mm * this.svgAspectRatio,
          svg_content: this.$refs[this.nodeId] ? this.$refs[this.nodeId].outerHTML : null
        })
      },
      /**
       * @param {Partial<ApiFormattedProcessingItem>} updatedValues
       */
      updateProcessingItem(updatedValues) {
        if (objectContainsChanges(updatedValues, this.processingItem)) {
          this.$emit('element-updated', {
            ...this.processingItem,
            ...updatedValues,
            aspect_ratio: this.svgAspectRatio,
            height_mm: this.processingItem.width_mm * this.svgAspectRatio,
          })
        }
      },
    },

    computed: {
      fontFileName() {
        switch (true) {
          case this.processingItem.font_bold:
            return 'font-bold.ttf'
          case this.processingItem.font_italic:
            return 'font-italic.ttf'
          default:
            return 'font.ttf'
        }
      },
      fontFilePath() {
        const font = this.designerFonts.find(({ name }) => name === this.processingItem.font_family.name)
        if (!font) return null

        return `${font.base_path}/${this.fontFileName}`
      },
      nodeId() {
        return `processing_item_text_path_${this._uid}`
      },
    },

    watch: {
      'processingItem.content'(val) {
        this.convertTextToSvg()

        if (!val) {
          this.updateProcessingItem({
            content: PLACEHOLDER
          })
        }
      },
      'processingItem.width_mm'() {
        this.recalculate()
      },
      'processingItem.font_bold'() {
        this.convertTextToSvg()
      },
      'processingItem.font_italic'() {
        this.convertTextToSvg()
      },
      'processingItem.font_family': {
        deep: true,
        handler(value, oldValue) {
          if (value.base_path !== oldValue.base_path) {
            this.convertTextToSvg()
          }
        }
      },
      'processingItem.text_color'() {
        this.recalculate()
      },
    }

  }
</script>

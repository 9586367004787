<template>
  <div class="bg-grey-00 px-1 md:px-4 lg:px-6">
    <catalog-show
      v-if="currentProduct"
      create-route="single-product-designer"
      :product-category="productCategory"
      :catalog-product="currentProduct"
      @calculation="goToCalculation"></catalog-show>
    <loader-full-page v-if="loading" />
  </div>
</template>

<script>
  import ShirtigoFooter from '@/components/layout/ShirtigoFooter'
  import ShirtigoHeader from '@/components/layout/ShirtigoHeader'
  import { mapActions, mapGetters } from 'vuex'
  import LoaderFullPage from '../../components/layout/LoaderFullPage'
  import Cart from '@/mixins/Cart'
  import { getDummyManufacturingJob, getDummyProductsArray, getEmptyDummyCart } from '@/api/calculation'
  import Prices from '../../mixins/Prices'

  export default {
    mixins: [ Cart, Prices ],
    data() {
      return {
        loading: false
      }
    },

    created() {
      if (!this.catalogProduct || this.catalogProduct.slug !== this.$route.params.key) {
        this.loading = true
        this.loadCatalogProduct(this.$route.params.key).then(() => {
          this.loading = false
        })
      }
    },

    methods: {
      async goToCalculation(color) {
        if (!this.currentManufacturingJob || !this.currentManufacturingJob.processings.data.find(p => p.processing_items.data.length)) {
          if (!this.cart) {
            this.setCart(getEmptyDummyCart([
              getDummyManufacturingJob({
                amount: 0,
                products: getDummyProductsArray({
                  amount: 1,
                  productId: this.currentProduct.id,
                  colorId: color.id,
                  sizeId: color.sizes.data[0].size_id,
                  productPrice: 0
                })
              })
            ]))
          } else if (this.currentManufacturingJob) {
           this.addBaseProductToCart(this.currentProduct, color.id)
          } else {
            this.addManufacturingJob(getDummyManufacturingJob({
              amount: 0,
              products: getDummyProductsArray({
                amount: 1,
                productId: this.currentProduct.id,
                colorId: color.id,
                sizeId: color.sizes.data[0].size_id,
                productPrice: 0
              })
            }))
          }
          await this.updatePrice().catch()
          this.$router.push({
            name: 'mpc-calculator',
          })
        } else {
          this.addBaseProductToCart(this.currentProduct, color.id).then(() => {
            this.$router.push({
              name: 'mpc-collection',
            })
          })
        }
      },
      ...mapActions([
        'addManufacturingJob',
        'loadCatalogProduct',
        'setCurrentManufacturingJobGroupId',
        'setCurrentManufacturingJobUuid',
      ])
    },

    computed: {
      currentProduct() {
        if (!this.catalogProduct || this.catalogProduct.slug !== this.$route.params.key) {
          return null
        }

        return this.catalogProduct
      },
      productCategory() {
        if (!this.currentProduct) {
          return {}
        }

        return this.currentProduct.attributes.data.find(a => a.type_key === 'category')
      },
      ...mapGetters([
        'catalogProduct',
        'currentManufacturingJob'
      ])
    },

    components: {
      ShirtigoFooter,
      ShirtigoHeader,
      LoaderFullPage
    }
  }
</script>

<style scoped>

</style>

<template>
  <div :class="['float-label-input', { 'has-icon': icon !== '' }]">
    <sg-input
      :id="id"
      :name="name"
      :type="type"
      :class="[className, { 'has-danger': hasDanger, 'input-lg': size === 'lg' }]"
      :placeholder="title"
      :value="value"
      :disabled="disabled"
      :required="required"
      :tabindex="tabindex"
      :icon="icon"
      :has-danger="hasDanger"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
      :min="min"
      :max="max"
      @keydown.enter="$emit('keydown.enter', $event)"
      @input="$emit('input', $event)" />
    <label>{{ title }}</label>
  </div>
</template>

<script>
  import SgInput from './SgInput.vue'

  export default {

    props: {
      id: String,
      name: String,
      type: {
        type: String,
        default: 'text'
      },
      className: String,
      hasDanger: Boolean,
      title: String,
      value: {
        type: String,
        required: true
      },
      disabled: Boolean,
      required: Boolean,
      tabindex: Number,
      icon: {
        type: String,
        default: ''
      },
      autofocus: Boolean,
      autocomplete: String,
      size: String,
      min: [String, Number],
      max: [String, Number],
    },

    components: {
      SgInput,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .float-label-input {
    position: relative;
    display: block;

    input {
      transition: .2s ease;

      &::-webkit-input-placeholder {
        opacity: 0;
      }

      &::-moz-placeholder {
        opacity: 0;
      }

      // show as placeholder
      &:placeholder-shown ~ label {
        position: absolute;
        top: 50%;
        left: .75rem;
        color: $grey-40;
        font-size: 1em;
        pointer-events: none;
        cursor: text;
        transition: .2s ease;
        margin: 0;
        transform: translateY(-50%);
      }

      // show as label
      &:not(:placeholder-shown) ~ label,
      &:focus ~ label {
        position: absolute;
        top: 0;
        left: .75rem;
        color: $grey-60;
        font-size: .625em;
        transition: .2s ease;
        margin: 0;
        margin-left: 1px;
        transform: translateY(50%);
      }

      &:not(:placeholder-shown),
      &:focus {
        padding-top: 1.25rem;
        padding-bottom: .5rem;
      }

      &.has-icon ~ label {
        left: 3rem;
      }

      &.has-danger ~ label {
        color: $red;
      }
    }
  }
</style>

<template>
  <div>
    <div>
      <div class="pt-4 text-sm">
        Farbe: <span class="text-blue-50">{{ currentColor.color_name }}</span>
      </div>
      <div
        class="pt-2">
        <div
          v-for="color in availableColors"
          :key="color.id"
          @click.prevent="selectColor(color)">
          <color-swatch-designer
            :color="color"
            :current-color="currentColor">
          </color-swatch-designer>
        </div>
      </div>
      <div
        v-if="hasMore"
        class="flex text-center items-center">
        <div
          class="color-swatch mr-3 mb-3 bg-white border border-1 border-dashed border-gray-30 hover:text-blue-50"
          @click="allColors">
          <span class="icon-plus-outline font-icomoon text-sm"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ColorSwatchDesigner from 'sb/elements/Designer/Controls/ColorSwatchDesigner'
  export default {
    props: {
      availableColors: {
        type: Array,
        required: true
      },
      currentColor: {
        type: Object,
        required: true
      },
      hasMore: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        colorWidth: 110
      }
    },

    methods: {
      selectColor(color) {
        this.$emit('select', color)
      },
      allColors() {
        this.$emit('more')
      }
    },

    components: { ColorSwatchDesigner },
  }
</script>

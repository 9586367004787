<template>
  <div class="catalog-product-info">
    <container class="catalog-product-info__container">
      <tab-nav
        class="catalog-product-info__tabs"
        :tabs="tabs"
        :active="section"
        @set:tab="section = $event" />

      <transition
        name="fade"
        mode="out-in">
        <component
          :is="`product-info-${section}`"
          :base-product="baseProduct" />
      </transition>
    </container>
  </div>
</template>

<script>
  import Container from 'sb/elements/Layout/Container.vue'
  import TabNav from 'sb/elements/Layout/TabNav.vue'
  import ProductInfoDescription from './ProductInfoDescription.vue'
  import ProductInfoDataSheet from './ProductInfoDataSheet.vue'
  import ProductInfoSizesTable from './ProductInfoSizesTable.vue'
  import ProductInfoPrintDetails from './ProductInfoPrintDetails.vue'

  export default {

    props: {
      baseProduct: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        section: 'description',
        tabs: {
          'description': 'Beschreibung',
          'data-sheet': 'Datenblatt',
          'sizes-table': 'Größentabelle',
          'print-details': 'Druckinfo',
        },
      }
    },

    components: {
      Container,
      TabNav,
      ProductInfoDescription,
      ProductInfoDataSheet,
      ProductInfoSizesTable,
      ProductInfoPrintDetails,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .catalog-product-info {
    background: $white;
    border-top: 1px solid $grey-20;
    padding: 2em 0;

    @include breakpoint(lg) {
      padding: 4em 0;
    }

    &__container {
      flex-wrap: wrap;
    }

    &__tabs {
      margin-bottom: 3em;
    }
  }
</style>

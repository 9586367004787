<template>
  <section class="catalog-detail-preview">
    <img
      class="catalog-detail-preview__image"
      :src="currentImage.high_res"
      :alt="currentImage.reference"
      @click.prevent="showZoom = true">

    <catalog-detail-zoom
      v-if="showZoom"
      :images="images"
      :current-image="currentImage"
      @set:image="$emit('set:image', $event)"
      @close:modal="showZoom = false" />
  </section>
</template>

<script>
  import CatalogDetailZoom from './CatalogDetailZoom.vue'

  export default {

    props: {
      images: {
        type: Array,
        required: true,
      },
      currentImage: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        showZoom: false,
      }
    },

    components: {
      CatalogDetailZoom,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";

  .catalog-detail-preview {
    width: 100%;
    max-width: 576px;
    margin: 0 auto;
    padding: 0 1em;

    &__image {
      max-width: 100%;
      max-height: 100%;

      &:hover {
        cursor: zoom-in;
      }
    }
  }
</style>

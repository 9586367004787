<template>
  <div class="tracking-4">
    <div class="flex-1">
      <div class="bg-gray-00 px-4">
        <div class="mx-auto py-4 sm:py-6 lg:py-10 lg:max-w-container">
          <div class="lg:grid lg:grid-cols-3 lg:items-start">
            <div class="flex lg:hidden items-center leasing-1 text-blue-50 mb-4">
              <span class="icon-arrow-ios-back-outline mx-1"></span>
              <a
                :href="`/catalog/${categoryKey}`"
                class="hover:text-gray-80">
                {{ categoryName }}
              </a>
            </div>
            <breadcrumb
              :pages="[{href: `/catalog/${categoryKey}`, title: categoryName }]"
              :current-page-name="catalogProduct.name"
              :back-url="`/catalog/${categoryKey}`"
              is-product-page
            />
            <div class="flex flex-col flex-col-reverse lg:flex-row lg:col-span-2">
              <div class="hidden mt-6 mx-auto lg:block lg:max-w-none">
                <div
                  class="flex gap-1 lg:gap-0 lg:flex-col">
                  <button
                    v-for="(image, index) in images"
                    :key="index"
                    class="relative lg:h-24 lg:w-24 lg:bg-white mb-6 rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                    type="button"
                    @click="currentImage = image">
                    <img
                      :src="image.small || image.standard"
                      :alt="catalogProduct.name"
                      class="hidden lg:block w-full h-full object-center object-cover rounded-box-sm border"
                      :style="currentImage && currentImage.reference === image.reference
                        ? 'border-bottom-width: 3px; border-bottom-color: #039be5;'
                        : ''">
                    <span
                      class="lg:hidden h-2 w-2 rounded-full bg-gray-70 "
                      :class="currentImage && currentImage.reference === image.reference ? 'opacity-100' : 'opacity-50'"></span>
                  </button>
                </div>
              </div>

              <div class="w-full justify-between relative hidden lg:flex items-center">
                <div class="h-full flex items-center lg:hidden">
                  <button @click="prevImage">
                    <span class="icon-chevron-left-outline text-gray-30 text-5xl"></span>
                  </button>
                </div>
                <div
                  id="tabs-1-panel-1"
                  aria-labelledby="tabs-1-tab-1"
                  role="tabpanel"
                  class="lg:mx-auto w-7/10"
                  tabindex="0">
                  <div>
                    <div>
                      <img
                        :src="currentImage ? currentImage.large || currentImage.standard : ''"
                        :alt="catalogProduct.name"
                        class="w-full h-full object-center object-cover sm:rounded-lg">
                    </div>
                  </div>
                </div>
                <div class="h-full flex items-center lg:hidden">
                  <button @click="nextImage">
                    <span class="icon-chevron-right-outline text-gray-30 text-5xl"></span>
                  </button>
                </div>
              </div>
              <div class="w-full justify-between relative flex lg:hidden items-center">
                <div
                  id="tabs-1-panel-1"
                  aria-labelledby="tabs-1-tab-1"
                  role="tabpanel"
                  class="lg:mx-auto w-full xs:w-8/10 md:w-6/10 mx-auto"
                  tabindex="0">
                  <div class="glide">
                    <div
                      class="glide__track"
                      data-glide-el="track">
                      <ul class="glide__slides flex">
                        <li
                          v-for="(image, index) in images"
                          :key="index"
                          class="glide__slide">
                          <img
                            :src="image.large || image.standard"
                            :alt="catalogProduct.name"
                            class="w-full h-full object-center object-cover sm:rounded-lg">
                        </li>
                      </ul>
                    </div>
                    <div
                      class="glide__bullets"
                      data-glide-el="controls[nav]">
                      <button
                        v-for="(image, index) in images"
                        :key="index"
                        class="glide__bullet"
                        :data-glide-dir="`=${index}`"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:mt-0">
              <div>
                <div class="flex items-center gap-1">
                  <h1 class="text-2xl font-bold text-dark-blue mr-1">
                    {{ catalogProduct.name }}
                  </h1>
                  <div
                    v-if="catalogProduct.is_24h_express_available"
                    class="text-blue-50 h-6 w-6">
                    <svg
                      id="Ebene_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentcolor"
                        d="m13.99,7c-.55,0-1,.45-.99,1.01l.02,3.6-2.73,2.69c-.39.39-.4,1.02-.01,1.41.2.2.45.3.71.3s.51-.1.7-.29l3.03-2.98c.19-.19.3-.45.3-.72l-.03-4.02c0-.55-.43-1.01-1.01-.99Z"></path>
                      <rect
                        fill="currentcolor"
                        y="11"
                        width="4"
                        height="2"></rect>
                      <rect
                        fill="currentcolor"
                        y="8"
                        width="4"
                        height="2"></rect>
                      <rect
                        fill="currentcolor"
                        y="14"
                        width="4"
                        height="2"></rect>
                      <path
                        fill="currentcolor"
                        d="m14,3c-4.96,0-9,4.04-9,9s4.04,9,9,9,9-4.04,9-9S18.96,3,14,3Zm0,16c-3.86,0-7-3.14-7-7s3.14-7,7-7,7,3.14,7,7-3.14,7-7,7Z"></path>
                    </svg>
                  </div>
                  <div
                    v-if="catalogProduct.is_organic"
                    class="text-green-70 h-6 w-6">
                    <svg
                      id="Canvas"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M21.27,2.04C14.15,0,7.33,2.34,5.08,7.61c-2.08,4.86-.05,8.71.04,8.87l.04-.02c-1.48,2.78-2.09,5.12-2.13,5.3l1.94.49c.02-.08,1.99-7.68,7.58-11.41l-1.11-1.66c-2.13,1.42-3.76,3.29-5.01,5.15-.34-1.27-.59-3.42.48-5.93,1.53-3.57,6.47-6.23,13.05-4.63-.12,2.16-.77,8.37-4.17,11.47-1.54,1.4-3.41,1.98-5.71,1.77l-.18,1.99c.36.03.72.05,1.06.05,2.4,0,4.48-.78,6.18-2.34,4.79-4.37,4.84-13.33,4.84-13.71,0-.45-.3-.84-.73-.96Z"></path>
                    </svg>
                  </div>
                  <chip
                    v-if="productTag"
                    :class="productTag.key">
                    {{ productTag.name }}
                  </chip>
                </div>
                <div
                  class="text-gray-80 tracking-3 ">
                  {{ catalogProduct.brand.data.name }}, {{ catalogProduct.brand_product_name }} -
                  {{ catalogProduct.brand_code }}
                </div>

                <div class="mt-4 pt-4 border-t border-gray-20">
                  <span class="text-gray-80 font-normal">
                    {{ $t('views.product_details.processing_method') }}
                  </span>
                  <div class="mt-2">
                    <chip
                      v-for="method in processingMethods"
                      :key="method.key"
                      is-large
                      class="mr-2"
                    >
                      <processing-method-icons :method-key="method.key"></processing-method-icons>
                      <span class="text-sm">
                        {{ method.name }}
                      </span>
                    </chip>
                  </div>
                </div>
              </div>
              <div class="mt-6">
                <span class="text-gray-80 font-normal">
                  {{ $t('global.color') }}: <strong>{{ currentColor.color_name }} <span
                    v-if="currentColor.discontinued">({{ $t('views.products.discontinued_color') }})</span></strong>
                </span>
                <div class="mt-3 flex flex-wrap">
                  <div class="flex flex-wrap gap-3">
                    <color-selector
                      v-for="color in availableColors"
                      :key="color.id"
                      :title="color.color_name"
                      :color="color.hexcode"
                      :active="color.id === currentColor.id"
                      :discontinued="color.discontinued"
                      :heather="!!color.is_heathered"
                      @click="selectColor(color)" />
                  </div>
                </div>
              </div>
              <div class="flex justify-between mt-6">
                <div class="text-gray-80">
                  {{ $t('global.sizes') }}: <strong>{{ availableSizes }}</strong>
                </div>
                <a
                  v-if="catalogProduct.show_size_chart"
                  href="#details"
                  class="text-blue-50 tracking-4 flex items-center"
                  @click.prevent="scrollToSizeChart('details')">
                  <span class="icon-ruler inline-block text-lg mr-1"></span>
                  {{ $t('global.size_chart') }}
                </a>
              </div>
              <div class="mt-6">
                <div class="grid grid-cols-1 xs:grid-cols-2 gap-3">
                  <div class="bg-white border border-gray-20 p-4">
                    <div class="text-sm text-gray-80">
                      {{ $t('views.product_details.piece_price') }} (<a
                        href="#"
                        class="lowercase text-blue-50"
                        @click.prevent="showVatChange = true">{{
                          vatMethod === 'net' ? $t('global.netto') : $t('global.gross')
                        }}</a>)
                    </div>
                    <div class="text-lg font-bold leading-7">
                      {{ productPrice | currencyGross(vatMethod, catalogProduct.platform === 'SG') }}*
                    </div>
                    <div class="text-sm text-gray-50 leading-6">
                      {{ $t('views.product_details.one_side_print') }}
                    </div>
                  </div>
                  <div class="bg-white border border-gray-20 p-4">
                    <div class="text-sm text-gray-80">
                      {{ $t('views.product_details.production') }}
                    </div>
                    <div class="text-lg font-bold leading-7">
                      {{ $t('views.product_details.standard_delivery_days') }}
                    </div>
                    <div
                      v-if="catalogProduct.is_24h_express_available && catalogProduct.platform !== 'SG'"
                      class="text-sm text-green-70 leading-6 flex items-center">
                      <svg
                        id="Ebene_1"
                        class="h-5 w-5 mr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path
                          fill="currentcolor"
                          d="m13.99,7c-.55,0-1,.45-.99,1.01l.02,3.6-2.73,2.69c-.39.39-.4,1.02-.01,1.41.2.2.45.3.71.3s.51-.1.7-.29l3.03-2.98c.19-.19.3-.45.3-.72l-.03-4.02c0-.55-.43-1.01-1.01-.99Z"></path>
                        <rect
                          fill="currentcolor"
                          y="11"
                          width="4"
                          height="2"></rect>
                        <rect
                          fill="currentcolor"
                          y="8"
                          width="4"
                          height="2"></rect>
                        <rect
                          fill="currentcolor"
                          y="14"
                          width="4"
                          height="2"></rect>
                        <path
                          fill="currentcolor"
                          d="m14,3c-4.96,0-9,4.04-9,9s4.04,9,9,9,9-4.04,9-9S18.96,3,14,3Zm0,16c-3.86,0-7-3.14-7-7s3.14-7,7-7,7,3.14,7,7-3.14,7-7,7Z"></path>
                      </svg>
                      <span v-html="$t('views.product_details.express_available')"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-6">
                <a
                  :href="designerLink"
                  target="_blank"
                  class="btn-platform-primary">
                  {{ $t('views.product_details.design_now_button') }}
                </a>
              </div>

              <div class="mt-4" v-if="catalogProduct.platform === 'SG'">
                <a
                  href="#"
                  @click="$emit('calculation', currentColor)"
                  target="_blank"
                  class="btn-platform-secondary">
                  {{ $t('views.product_details.calculate_button') }}
                </a>
              </div>
              <div class="text-gray-80 mt-4 pt-4 leading-7">
                <div
                  v-if="catalogProduct.material"
                  class="flex items-center mb-2"
                >
                  <i
                    :class="`icon-material`"
                    class="icon-embroidery inline-block mr-2 text-lg"
                  />
                  {{ $t('views.product_details.material') }}: {{ catalogProduct.material }}
                </div>

                <div
                  v-for="attribute in primaryProductAttributes"
                  :key="attribute.key"
                  class="flex items-center mb-2"
                >
                  <span
                    :class="attribute.type_key !== 'category'
                      ? `icon-${attribute.type_key}`
                      : `icon-${attribute.key}`"
                    class="inline-block mr-2 text-lg"></span>
                  <div>
                    <span v-if="attribute.type_key !== 'category'">
                      {{ attribute.type.data.name }}:
                    </span>
                    {{ attribute.name }}
                  </div>
                </div>
                <div
                  v-for="(attribute, index) in primaryBrandProductAttributes"
                  :key="index"
                  class="flex items-center mb-2">
                  <span
                    :class="`icon-${attribute.type.data.productAttributeType.data.key}`"
                    class="icon-embroidery inline-block mr-2 text-lg"></span>
                  <div>
                    {{ attribute.type.data.productAttributeType.data.name }}: {{ attribute.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <product-details
        id="details"
        :catalog-product="catalogProduct"
        :select-tab="selectedTab"
        :vat-method="vatMethod"
        @tab:changed="selectedTab = null"></product-details>
    </div>
    <loader-full-page v-if="loading"></loader-full-page>
    <modal
      v-if="showVatChange"
      new-modal
      @submit="changeVatMethod"
      @close:modal="showVatChange = false">
      <div>
        <p>Bitte wähle aus, wie die Preise angezeigt werden sollen:</p>
        <form>
          <div class="flex flex-col">
            <label
              class="inline-flex items-center mt-3 p-4 border border-solid border-gray-10"
              :class="{'border-light-blue bg-blue-00':selectedVatMethod === 'net'}">
              <input
                v-model="selectedVatMethod"
                type="radio"
                class="form-radio h-5 w-5 text-blue-600"
                name="tax_setting"
                value="net"><span
                  class="ml-2 text-gray-700">Exklusive Mehrwertsteuer (Netto)</span>
            </label>
            <label
              class="inline-flex items-center mt-3 p-4 border border-solid border-gray-10"
              :class="{'border-light-blue bg-blue-00':selectedVatMethod === 'gross'}">
              <input
                v-model="selectedVatMethod"
                type="radio"
                class="form-radio h-5 w-5 text-blue-600"
                name="tax_setting"
                value="gross"><span
                  class="ml-2 text-gray-700">Inklusive Mehrwertsteuer (Brutto)</span>
            </label>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
  import LoaderFullPage from 'sb/elements/Layout/LoaderFullPage.vue'
  import ColorSelector from 'sb/common/components/config/ColorSelector'
  import ProductDetails from 'sb/common/components/catalog/ProductDetails'
  import Breadcrumb from 'sb/common/components/catalog/Breadcrumb'
  import Glide from '@glidejs/glide'
  import ProcessingMethodIcons from 'sb/elements/Icons/ProcessingMethodIcons.vue'
  import Modal from 'sb/elements/Modals/Modal.vue'

  export default {

    props: {
      catalogProduct: {
        type: Object,
      },
      createRoute: {
        type: String
      },
      productCategory: {
        type: Object
      },
      vatMethod: {
        type: String,
        default: 'gross'
      }
    },

    data() {
      return {
        loading: false,
        currentColor: null,
        currentImage: null,
        selectedTab: null,
        selectedVatMethod: null,
        showVatChange: false,
      }
    },

    created() {
      if (this.availableColors) {
        this.currentColor = this.availableColors[0]
      }

      if (this.images) {
        this.currentImage = this.images[0]
      }

      this.selectedVatMethod = this.vatMethod
    },

    mounted() {
      new Glide('.glide').mount()
    },

    methods: {
      changeVatMethod() {
        this.$emit('update:vat', this.selectedVatMethod)
        this.loading = true
        this.showVatChange = false
      },
      nextImage() {
        let index = this.images.indexOf(this.currentImage)

        if (this.images.length > index + 1) {
          this.currentImage = this.images[index + 1]
        } else {
          this.currentImage = this.images[0]
        }
      },
      prevImage() {
        let index = this.images.indexOf(this.currentImage)

        if (index > 0) {
          this.currentImage = this.images[index - 1]
        } else {
          this.currentImage = this.images[this.images.length - 1]
        }
      },
      selectColor(color) {
        this.currentColor = color

        let images = []

        if (color.brandImages && Object.keys(this.currentColor.brandImages.data).length) {
          Object.values(color.brandImages.data).forEach(c => c.forEach(i => images.push(i)))
        } else {
          images.push(this.catalogProduct.default_image)
        }

        this.currentImage = images[0]
      },
      scrollToSizeChart(destination) {
        this.selectedTab = 'sizechart'

        if (typeof window !== 'undefined') {
          document.getElementById(destination).scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        }
      }
    },

    computed: {
      productTag() {
        return this.catalogProduct.attributes.data.find(a => a.type_key === 'product-tag')
      },
      designerLink() {
        let link = this.$router.resolve({
          name: this.createRoute,
          params: {
            id: this.catalogProduct.slug
          },
          query: {
            color: this.currentColor.id
          }
        })

        return link.href
      },
      processingMethods() {
        let methods = []
        this.catalogProduct.processingareas.data.forEach(p => {
          if (p.processingmethods) {
            p.processingmethods.data.forEach(pm => {
              if (!methods.find(m => m.key === pm.key)) {
                methods.push(pm)
              }
            })
          }
        })
        return methods
      },
      primaryProductAttributes() {
        if (!this.catalogProduct) {
          return null
        }

        return this.catalogProduct.attributes.data.filter(a => {
          return a.type.data.is_primary_information === true &&
            a.type.data.is_ordinal === false &&
            a.type.data.listed_information === true
        })
      },
      primaryBrandProductAttributes() {
        if (!this.catalogProduct) {
          return null
        }

        return this.catalogProduct.brand_attributes.data.filter(a => {
          return a.type.data.productAttributeType &&
            a.type.data.productAttributeType.data.is_primary_information === true &&
            a.type.data.productAttributeType.data.is_ordinal === false &&
            a.type.data.productAttributeType.data.listed_information === true
        })
      },
      categoryKey() {
        if (!this.productCategory || !this.productCategory.key) {
          return null
        }

        if (this.productCategory.key.startsWith('creator')) {
          return this.productCategory.key.slice(8)
        }

        return this.productCategory.key.slice(9)
      },
      categoryName() {
        if (!this.productCategory || !this.productCategory.name) {
          return null
        }

        return this.productCategory.name
      },
      productPrice() {
        return this.catalogProduct.baseprice_processing_default
      },
      availableColors() {
        if (!this.catalogProduct) {
          return null
        }

        let colors = this.catalogProduct.variants.data.map(color => ({
          id: color.id,
          color_name: color.color_name,
          hexcode: color.hexcode,
          images: color.images,
          brandImages: color.brand_images,
          sort_position: color.sort_position,
          discontinued: color.is_discontinued,
          sizes: color.size_variants,
          is_heathered: color.is_heathered
        }))

        return colors.sort((a, b) => {
          if (a.sort_position > b.sort_position) {
            return 1
          } else if (a.sort_position < b.sort_position) {
            return -1
          }

          return 0
        })
      },
      defaultProductVariant() {
        if (!this.catalogProduct) {
          return null
        }
        let sku = this.catalogProduct.default_variant_sku.split('.')
        let productVariant = this.catalogProduct.variants.data.find(p => {
          return p.id === parseInt(sku[1])
        })
        if (!productVariant) {
          productVariant = this.catalogProduct.variants.data[0]
        }
        return productVariant
      },
      availableSizes() {
        if (!this.currentColor) {
          return null
        }
        let sizes = this.currentColor.sizes.data.map(s => s.size)

        if (sizes.length > 1) {
          return sizes[0] + '-' + sizes[sizes.length - 1]
        }

        return sizes[0]
      },
      images() {
        if (!this.currentColor) {
          return []
        }

        let images = []

        if (!this.currentColor.brandImages || !Object.keys(this.currentColor.brandImages.data).length) {
          images.push(this.catalogProduct.default_image)
          return images
        }

        const imageTypes = [ 'studio', 'packshot', 'flat', 'hollowmen' ]

        Object.values(this.currentColor.brandImages.data).forEach(c => {
          let filtered = c.filter(f => imageTypes.includes(f.image_type))
          filtered.forEach(i => {
            if (!images.find(p => p.reference === i.reference)) {
              images.push(i)
            }
          })
        })

        return images.slice(0, 5)
      },
    },

    watch: {
      vatMethod: function () {
        this.selectedVatMethod = this.vatMethod
      },
    },

    components: {
      Modal,
      ProcessingMethodIcons,
      LoaderFullPage,
      ColorSelector,
      ProductDetails,
      Breadcrumb
    }

  }
</script>

<style scoped lang="scss">
  @import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
  @import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

  .product-tag-topseller {
    background-color: #b6e6fe;
    border: 1px solid #b6e6fe;
    color: #15255a;
  }

  .product-tag-recommendation {
    background-color: #fce8ba;
    border: 1px solid #fce8ba;
    color: #332500;
  }

  .product-tag-new {
    background-color: #bcf2cc;
    border: 1px solid #bcf2cc;
    color: #093014;
  }

  .product-tag-discount {
    background-color: #f9b8bc;
    border: 1px solid #f9b8bc;
    color: #360508;
  }
</style>

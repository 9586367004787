<template>
  <section class="catalog-detail-context">
    <p class="context__brand-name">
      {{ brandName }}
    </p>
    <h1 class="h3 context__product-name">
      {{ productName }}
    </h1>
    <div class="product-price">
      <span class="product-price__value">{{ price | currency }}</span>
      <span class="product-price__details">inkl. 1-seitigem Druck</span>
      <a
        href="#"
        class="product-price__tooltip">
        <icon fa="question-circle" />
      </a>
    </div>

    <div class="context__divider"></div>

    <div class="color-name">
      <label class="color-name__label">Farbe: </label>
      <span class="color-name__value">{{ currentColor.color_name }}</span>
    </div>
    <div class="context__colors">
      <color-swatch
        v-for="color in colors"
        :key="color.reference"
        :color="color.hexcode"
        :active="color.reference === currentColor.reference"
        :title="color.color_name"
        :dark="color.is_dark"
        @click="$emit('set:color', color.reference)" />
    </div>

    <div class="context__sizes">
      <div class="context__available_sizes">
        Verfügbare Größen: {{ availableSizesLabel }}
      </div>
      <a
        href="#"
        class="context__sizes-table"
        @click.prevent="$emit('open:sizes-table')">
        Größentabelle
      </a>
    </div>

    <div class="context__actions">
      <sg-btn
        kind="primary"
        class="btn-lg btn-fullwidth context__submit"
        @click="$emit('open:pod-designer')">
        Produkt gestalten
      </sg-btn>
      <sg-btn
        kind="white"
        class="btn-lg btn-fullwidth context__submit"
        @click="$emit('open:configurator')">
        Produkt kalkulieren
      </sg-btn>
    </div>

    <div class="context__delivery-details">
      <icon fa="truck" />
      <span>Standard Lieferung <strong>10-13 Werktage</strong></span>
    </div>
    <div class="context__delivery-details">
      <icon fa="forward" />
      <span>Express-Option verfügbar</span>
    </div>
  </section>
</template>

<script>
  import Icon from 'sb/common/components/Icon.vue'
  import ColorSwatch from 'sb/elements/Product/ColorSwatch.vue'

  export default {

    props: {
      brandName: String,
      productName: String,
      colors: Array,
      currentColor: Object,
      price: Number,
    },

    computed: {
      availableSizesLabel() {
        const sizes = this.currentColor.size_variants
        const sortedArray = sizes.sort((a, b) => a.sort_position - b.sort_position)
        const min = sortedArray[0]
        const max = sortedArray[sortedArray.length - 1]

        return `${min.size} - ${max.size}`
      },
    },

    components: {
      Icon,
      ColorSwatch,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";

  .catalog-detail-context {
    width: 100%;

    .context {
      &__brand-name {
        color: $grey-70;
        text-transform: uppercase;
        font-size: .75em;
        font-weight: bold;
        letter-spacing: 1px;
        margin: 0;
      }

      &__product-name {
        margin-bottom: .75rem;
      }

      &__divider {
        margin: 1.25rem 0;
        height: 1px;
        width: 100%;
        background: $grey-20;
      }

      &__colors {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .25rem;

        .color-swatch {
          display: block;
          margin-right: .75rem;
          margin-bottom: .75rem;
        }
      }

      &__sizes {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.25rem;
        font-size: .875rem;
        line-height: 1.75rem;
        letter-spacing: 0.5px;
        color: $grey-60;
      }

      &__actions {
        margin-bottom: 1.5em;
      }

      &__submit:not(:last-child) {
        margin-bottom: .625rem;
      }

      &__delivery-details {
        color: $green-70;
        font-size: .875rem;
        line-height: 1.75rem;
        letter-spacing: 0.5px;
        margin-bottom: .25rem;

        .icon {
          margin-right: .5rem;
        }
      }
    }

    .product-price {
      display: flex;
      align-items: flex-end;
      color: $blue;

      &__value {
        font-size: 1.375rem;
        line-height: 1.875rem;
        font-weight: bold;
      }

      &__details,
      &__tooltip {
        display: inline;
        font-size: .875rem;
        line-height: 1.625rem;
        margin-left: .5rem;
      }
    }

    .color-name {
      font-size: .75em;
      line-height: 1.5rem;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: .5rem;

      &__label {
        color: $grey-80;
      }

      &__value {
        color: $grey-70;
      }
    }
  }
</style>

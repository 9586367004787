<template>
  <dynamic-modal
    v-if="showPopup"
    class="visible z-30"
    @close:modal="showPopup = false"
  >
    <h2 class="text-2xl font-bold text-dark-blue mb-7">
      {{ $t('views.designer.upload.headline') }}
    </h2>

    <div class="md:flex md:space-x-6">
      <asset-uploader
        class="flex-1 mb-6 md:mb-0"
        :accept="accept"
        :hint="false"
        :area="area"
        @close:modal="showPopup = false"
        @asset-uploaded="assetUploaded"
        @status-change="setLoading"
        @upload:error="onUploadError"
      />

      <div
        v-if="!isLoading"
        class="cursor-pointer rounded-md md:py-4 border-2 border-dashed border-gray-30 flex-1 pb-4"
        @click="openTextInput"
      >
        <div class="flex flex-col align-middle justify-center items-center text-center mt-6 pt-1">
          <span class="block">
            <img
              src="/images/add-text.svg"
              class="w-16 mb-5"
            />
          </span>

          <span
            class="flex cursor-pointer items-center px-4 md:px-6 py-2 bg-dark-blue text-white rounded-full font-semibold leading-7 tracking-wide shadow-big h-11 xs:text-lg transition-all duration-200 hover:bg-blue-60 font-nunito-sans mb-5 mt-5"
          >
            {{ $t('views.designer.upload.add_text') }}
          </span>
        </div>

        <span class="block text-center text-gray-50 text-sm mt-2">
          {{ $t('views.designer.upload.add_text_caption') }}
        </span>
      </div>
    </div>
  </dynamic-modal>
</template>

<script>
  import DynamicModal from '@/components/layout/DynamicModal'
  import AssetUploader from '@/components/config/designer/AssetUploader'

  export default {
    props: {
      accept: String,
      area: String,
    },

    data() {
      return {
        showPopup: false,
        isLoading: false
      }
    },

    methods: {
      setLoading(isLoading) {
        this.isLoading = isLoading
      },
      addDesignPopup() {
        this.showPopup = true
      },
      openTextInput() {
        this.showPopup = false
        this.$emit('add-text')
      },
      assetUploaded(e) {
        this.isLoading = false
        this.$emit('asset-uploaded', e)
      },
      onUploadError(a, b) {
        this.isLoading = false
        this.$emit('upload:error', a, b)
      }
    },

    components: { AssetUploader, DynamicModal },

  }
</script>

<template>
  <button
    :title="title"
    :class="{'cursor-pointer': !disabled}"
    class="flex h-full items-center px-6 md:px-10 xl:px-4 xl:w-1/3 justify-center"
    @click="$emit('click')"
  >
    <span
      :class="active ? 'bg-blue-50 text-white' : 'bg-grey-10 text-grey-50'"
      class="h-10 w-10 xl:h-8 xl:w-8 rounded-full flex items-center text-center text-lg font-semibold align-center"
    >
      <span class="mx-auto">{{ step }}</span>
    </span>

    <span
      :class="active ? 'text-blue-50' : 'text-grey-50 hover:text-blue-50'"
      class="text-lg font-semibold sr-only xl:not-sr-only xl:ml-3"
    >
      {{ title }}
    </span>
  </button>
</template>

<script>
  export default {

    name: 'NavStep',

    props: {
      step: String,
      title: String,
      disabled: Boolean,
      active: Boolean,
    },

  }
</script>

<template>
  <!-- Das <g> brauchen wir damit die richtige Größe & Position des Elements in ProcessingItemHandles.vue gemessen werden kann -->
  <g>
    <image
      :width="width"
      :height="height"
      :x="x"
      :y="y"
      :href="processingItem.asset.preview || processingItem.preview"
      draggable="false"
    />
  </g>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import Vue, { PropOptions } from 'vue'
  import objectContainsChanges from '../../../common/utils/objectContainsChanges'

  export default {

    props: {
      /** @type {PropOptions<ApiFormattedProcessingItem>} */
      processingItem: {
        type: Object,
        required: true
      },
      width: {
        type: Number,
        required: true
      },
      height: {
        type: [String, Number],
        required: true
      },
      x: {
        type: Number,
        required: true
      },
      y: {
        type: Number,
        required: true
      },
    },

    mounted() {
      this.recalculate()
    },

    methods: {
      async recalculate() {
        await Vue.nextTick()

        const dpi = this.processingItem.asset.width * 2.54 / this.processingItem.width_mm * 0.1

        this.updateProcessingItem({
          asset: {
            ...this.processingItem.asset,
            dpi,
          },
          height_mm: this.processingItem.width_mm / (this.processingItem.asset.width / this.processingItem.asset.height)
        })
      },
      onResize(newWidthMM) {
        if (typeof newWidthMM !== 'undefined') {
          this.updateProcessingItem({ width_mm: newWidthMM })
        }
      },
      /**
       * @param {Partial<ApiFormattedProcessingItem>} updatedValues
       */
      updateProcessingItem(updatedValues) {
        if (objectContainsChanges(updatedValues, this.processingItem)) {
          this.$emit('element-updated', {
            ...this.processingItem,
            ...updatedValues,
          })
        }
      },
    },

    watch: {
      'processingItem.width_mm'() {
        this.recalculate()
      }
    }

  }
</script>

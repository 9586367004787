import client from './client'

const transformOrderDetails = (reference, orderDetails) => {
  let deliveryAddress = null
  let primaryAddress = null
  let couponCode = null

  if (orderDetails) {
    primaryAddress = orderDetails.primary
    couponCode = orderDetails.coupon_code

    deliveryAddress = orderDetails.shipping_equals_primary ? orderDetails.primary : orderDetails.shipping

    // Country must always equal to primary country
    deliveryAddress.country = orderDetails.primary.country
  }

  return {
    cart_reference: reference,
    is_user_sale: null,
    coupon_code: couponCode,
    primaryAddress: primaryAddress,
    deliveryAddress: deliveryAddress,
    agb_checkbox: null,
    age_checkbox: null,
    validPayment: null
  }
}

export const submitCheckout = (state, formData) => {
  return client.post(`/api/checkout`, formData).then(response => {
    sessionStorage.setItem('order', response)
  })
}

export const submitOrderDetails = async (state) => {
  let data = transformOrderDetails(state.cart.reference, state.order_details || null)
  data.agb_checkbox = '1'
  data.age_checkbox = '1'
  data.validPayment = '1'
  return client.post(`/api/checkout/validate`, data)
}

export const submitPayment = (paymentData) => {
  return client.post('/api/checkout/pay', paymentData)
}

import { mapGetters } from 'vuex'

require('../typedefs')

export default {

  methods: {
    /**
     * @param {BaseProductId} baseProductId
     * @returns {BaseProduct | null}
     */
    findBaseProduct(baseProductId) {
      return this.baseProducts.find((baseProduct) => baseProduct.id === baseProductId) || null
    }
  },

  computed: {
    /** @returns {number} */
    calculatedOriginalPriceTotal() {
      if (!this.allCartProductsForCurrentGroup) return 0

      return this.allCartProductsForCurrentGroup.reduce((sum, product) => {
        const baseProductDetails = this.findBaseProduct(product.base_product_id)
        const pricePerItem = parseFloat(baseProductDetails.baseprice_processing_default)

        return sum + (pricePerItem * product.amount)
      }, 0)
    },
    calculatedDiscountTotal() {
      if (!this.cart || !this.cart.price_gross) return 0

      const discount = this.calculatedOriginalPriceTotal - this.cart.price_gross

      if (discount <= 0) return 0
      return discount
    },
    calculatedDiscountPercentage() {
      if (!this.cart || !this.cart.price_gross) return ''

      const discount = 1 - (this.cart.price_gross / this.calculatedOriginalPriceTotal).toFixed(2)
      if (discount <= 0 || this.cart.price_gross === 0) {
        return ''
      }

      return `-${Math.round(discount * 100)} %`
    },
    ...mapGetters([
      'allCartProductsForCurrentGroup',
      'baseProducts',
      'cart',
    ]),
  }

}

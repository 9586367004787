<template>
  <div>
    <button
      class="mr-6 cursor-pointer hover:text-black"
      @click="showModal = true"
    >
      <i :class="`${buttonIcon} mr-1`" />
      {{ buttonTitle }}
    </button>

    <modal
      v-if="showModal"
      @close:modal="showModal = false"
    >
      <div class="w-128 p-8">
        <div class="w-full flex items-center justify-center flex-col">
          <div class="text-ah2xl text-blue-50 flex items-center justify-center h-29 w-29 rounded-full bg-blue-00">
            <i :class="buttonIcon" />
          </div>

          <div class="text-h6 text-dark-blue font-bold mt-8">
            {{ modalHeadline }}
          </div>

          <div class="mt-6 text-center text-lg opacity-75 leading-8">
            <p class="mb-6">
              {{ modalText }}
            </p>

            <a
              :href="ctaLink"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue-50"
            >
              {{ $t('navigation.header.meta.benefits.more_infos') }}
            </a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  export default {

    props: {
      buttonIcon: String,
      buttonTitle: String,
      modalHeadline: String,
      modalText: String,
      ctaLink: String,
    },

    data() {
      return {
        showModal: false,
      }
    }

  }
</script>

<template>
  <div
    class="modal__wrapper"
    :class="{'flex items-center justify-center': newModal}"
  >
    <div
      class="modal__overlay"
      @click.prevent="$emit('close:modal')"
    />

    <div
      v-if="!newModal"
      :class="['modal', { 'modal--dismissable': dismissable, 'modal--large': large }]">
      <div class="modal__body">
        <div class="modal__content">
          <slot></slot>
        </div>
      </div>

      <a
        v-if="dismissable"
        href="#"
        class="close"
        title="Schließen"
        @click.prevent="$emit('close:modal')"
      >
        <icon fa="times" />
      </a>
    </div>

    <div
      v-else
      class="bg-white max-w-2xl mx-4 w-full rounded-lg shadow-lg z-50">
      <div class="relative flex justify-center items-center px-6 py-8 bg-gray-00 rounded-t-lg border-b border-gray-10">
        <p class="text-2xl font-bold text-blue-80">
          Umsatzsteuer-Einstellungen
        </p>
        <div
          class="absolute flex items-center h-full top-0 right-0 cursor-pointer z-50 p-6"
          @click="$emit('close:modal')">
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512">
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
        </div>
      </div>
      <div class="mb-4 px-6 py-8">
        <slot></slot>
      </div>
      <div class="flex justify-between p-6 bg-gray-00 rounded-b-lg border-t border-gray-10 flex-col lg:flex-row gap-4">
        <button
          class="py-1.5 px-4 bg-gray-00 border-solid rounded-lg text-blue-80 font-bold border border-gray-20 hover:bg-black"
          @click="$emit('close:modal')">
          Abbrechen
        </button>
        <button
          class="btn-platform-primary w-auto py-1.5 px-4 text-base leading-7"
          @click="$emit('submit')">
          Anwenden
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from 'sb/common/components/Icon.vue'

  export default {

    props: {
      dismissable: {
        type: Boolean,
        default: true,
      },
      large: {
        type: Boolean,
        default: false,
      },
      newModal: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      document.body.style.overflowY = 'hidden'

      let productsList = document.getElementById('products-list')

      if (productsList) {
        productsList.style.overflowY = 'hidden'
      }
    },

    beforeDestroy() {
      if (typeof document !== 'undefined') {
        document.body.style.overflowY = 'auto'

        let productsList = document.getElementById('products-list')

        if (productsList) {
          productsList.style.overflowY = 'scroll'
        }
      }
    },

    components: {
      Icon,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .modal {
    position: relative;
    max-width: 960px;
    max-height: 96vh;
    padding: 1rem;

    @media (max-width: 500px) {
      padding: 0;
      max-height: 100vh;
      overflow: hidden;
    }

    &__wrapper {
      position: relative;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;
      z-index: 20;

      @media (max-width: 500px) {
        padding: 0;
        align-items: end;

        &.items-center {
          align-items: center !important;
        }
      }
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .7);
    }

    &__body {
      background: $white;
      padding: 2rem;
      border-radius: $border-radius-modal;
      box-shadow: 0 5px 60px 0 rgba(0, 0, 0, .5);
      overflow-y: auto;

      @media (max-width: 500px) {
        height: 100vh;
      }
    }

    &--dismissable .close {
      position: absolute;
      top: 0;
      right: 0;
      text-decoration: none;
      background: $blue;
      border-radius: 50%;
      font-size: 1.25rem;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, .3);
      transition: background-color .2s ease;

      @media (max-width: 500px) {
        margin-right: 15px;
        margin-top: 15px;
      }

      &:hover {
        background-color: $blue-60;
      }

      &:focus {
        box-shadow: $focus-outline, 0 1px 20px 0 rgba(0, 0, 0, .3);
      }
    }

    &--large {
      width: 100%;
      height: 100%;

      .modal__body {
        position: absolute;
        top: 1rem;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
      }
    }
  }
</style>

<template>
  <modal
    class="catalog-detail-zoom"
    :large="true"
    @close:modal="$emit('close:modal')">
    <catalog-detail-thumbnails
      :images="images"
      :current-image="currentImage"
      @set:image="$emit('set:image', $event)" />

    <figure
      class="catalog-detail-zoom__zoomer"
      :style="`background-image: url(${currentImage.high_res})`"
      @mousemove="zoom"
      @click="$emit('close:modal')">
      <img
        :src="currentImage.high_res"
        :alt="currentImage.reference">
    </figure>
  </modal>
</template>

<script>
  import Modal from 'sb/elements/Modals/Modal.vue'
  import CatalogDetailThumbnails from 'sb/modules/Catalog/CatalogDetailThumbnails.vue'

  export default {

    props: {
      images: {
        type: Array,
        required: true,
      },
      currentImage: {
        type: Object,
        required: true,
      },
    },

    methods: {
      zoom(e) {
        const zoomer = e.currentTarget

        const offsetX = e.offsetX ? e.offsetX : (e.touches ? e.touches[0].pageX : 0)
        const offsetY = e.offsetY ? e.offsetY : (e.touches ? e.touches[0].pageY : 0)
        const x = offsetX / zoomer.offsetWidth * 100
        const y = offsetY / zoomer.offsetHeight * 100

        zoomer.style.backgroundPosition = `${x}% ${y}%`
      },
    },

    components: {
      Modal,
      CatalogDetailThumbnails,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .catalog-detail-zoom {
    .modal__body {
      padding: 1em;
    }

    .modal__content {
      display: flex;
      flex-wrap: wrap;

      @include breakpoint(sm) {
        flex-wrap: nowrap;
      }
    }

    &__zoomer {
      position: relative;
      width: 100%;
      background-position: center;
      overflow: hidden;
      cursor: zoom-in;
      margin: 0;

      @include breakpoint(sm) {
        margin-left: 1em;
      }

      & img:hover {
        opacity: 0;
      }

      img {
        display: block;
        width: 100%;
        transition: opacity .2s;
        background: $white;
      }
    }

    .catalog-detail-thumbnails {
      flex-direction: row;
      width: 100%;
      order: 1;
      padding-top: 1em;

      @include breakpoint(sm) {
        flex-direction: column;
        width: auto;
        order: 0;
        padding: 0;
      }

      &__item {
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: .5em;
        }

        @include breakpoint(sm) {
          margin-right: 0 !important;
          margin-bottom: .5em;
        }
      }
    }
  }
</style>

<template>
  <mobile-modal
    v-if="isMobileViewport"
    @close="$emit('close:modal', $event)"
  >
    <slot></slot>
  </mobile-modal>

  <modal
    v-else
    @close:modal="$emit('close:modal', $event)"
  >
    <slot></slot>
  </modal>
</template>

<script>
  import Modal from 'sb/elements/Modals/Modal'
  import MobileModal from './MobileModal'

  export default {

    computed: {
      /**
       * Depending on the viewport, either the normal modal will be used, or the mobile modal
       */
      isMobileViewport() {
        return screen.width < 1024
      }
    },

    components: { Modal, MobileModal }

  }
</script>

import { parseISO } from 'date-fns/parseISO'

export const getNthSortPosition = (arr, n = 1) => {
  if (!Array.isArray(arr)) {
    throw new Error(`First parameter must be of type Array, ${typeof arr} given.`)
  }

  const sortedArray = arr.sort((a, b) => a.sort_position - b.sort_position)
  return sortedArray[n - 1]
}

/**
 * @param {ApiFormattedProcessing[]|null} processingSpecifications
 * @return {ApiFormattedProcessing[]}
 */
export const getProcessingsWithItems = (processingSpecifications) => {
  if (!processingSpecifications) return []

  return processingSpecifications.filter(specification => specification.processing_items.data.length)
}

const getTimestamp = (product) => {
  return product.created_at ? parseISO(product.created_at).getTime() : 0
}

/**
 * @param {ApiFormattedCart|null} cart
 * @param {ApiFormattedManufacturingJobGroupId|null} groupId
 * @return {ApiFormattedProduct[]}
 */
export const getAllCartProductsForGroup = (cart, groupId) => {
  if (!cart || groupId === null || !cart.manufacturing_jobs || !cart.manufacturing_jobs.data) return []

  const productsForGroup = cart.manufacturing_jobs.data.reduce((productsArray, job) => {
    if (job.group_id === groupId && job.products && job.products.data) {
      return [ ...productsArray, ...job.products.data ]
    }

    return productsArray
  }, [])

  productsForGroup.sort((a, b) => getTimestamp(a) > getTimestamp(b) ? 1 : -1)

  return productsForGroup
}

/**
 * @param {ApiFormattedCart|null} cart
 * @return {ApiFormattedProduct[]}
 */
export const getAllCartProducts = (cart) => {
  if (!cart || !cart.manufacturing_jobs || !cart.manufacturing_jobs.data) return []

  const cartProducts = cart.manufacturing_jobs.data.reduce((productsArray, job) => {
    if (job.products && job.products.data) {
      return [ ...productsArray, ...job.products.data ]
    }

    return productsArray
  }, [])
  cartProducts.sort((a, b) => getTimestamp(a) > getTimestamp(b) ? 1 : -1)

  return cartProducts
}


/**
 * @param {ApiFormattedCart|null} cart
 * @param {ApiFormattedManufacturingJobGroupId} groupId
 * @param {BaseProductId} baseProductId
 * @return {ApiFormattedManufacturingJob|null}
 */
export const findManufacturingJobForProductInGroup = (cart, groupId, baseProductId) => {
  if (!cart) return null

  /** @type ApiFormattedManufacturingJob */
  const manufacturingJobForProduct = cart.manufacturing_jobs.data.find((job) => {
    if (job.group_id === groupId) {
      return job.products.data.some(({ base_product_id }) => base_product_id === baseProductId)
    }

    return false
  })

  if (manufacturingJobForProduct) {
    return manufacturingJobForProduct
  }

  return null
}

/**
 * @param {ApiFormattedCart|null} cart
 * @param {ApiFormattedManufacturingJobGroupId|null} groupId
 * @return {ApiFormattedProcessing[]}
 */
export const getAllCartProcessingsForGroup = (cart, groupId) => {
  if (!cart || groupId === null || !cart.manufacturing_jobs.data) return []

  return cart.manufacturing_jobs.data.reduce((processingsArray, job) => {
    if (job.group_id === groupId) {
      return [ ...processingsArray, ...job.processings.data ]
    }

    return processingsArray
  }, [])
}

/**
 * @param {ApiFormattedCart|null} cart
 * @param {ApiFormattedManufacturingJobGroupId|null} groupId
 * @return {ManufacturingJobPreview[]}
 */
export const getAllPreviewsForGroup = (cart, groupId) => {
  if (!cart || groupId === null || !cart.manufacturing_jobs.data) return []

  return cart.manufacturing_jobs.data.reduce((previewsArray, job) => {
    if (job.group_id === groupId && job.previews) {
      return [ ...previewsArray, ...job.previews.data ]
    }

    return previewsArray
  }, [])
}

export const getFirstSortPosition = (arr) => getNthSortPosition(arr, 1)

export const makeArrayFromImageObject = (images) => {
  const arr = []

  Object.values(images).forEach((s, i) => {
    const side = Object.keys(images)[i]

    s.forEach((image) => arr.push({ ...image, side }))
  })

  return arr
}

/**
 * checks a file for mime type and file size. if it is not valid for the tested
 * constraints, then it returns false. otherwise it returns true.
 * @param  {File} file
 * @return {Boolean}
 */
export const fileIsValidImage = (file) => {
  // define valid file types which can be processed by the server
  const VALID_FILE_TYPES = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    // 'application/pdf',
    // 'image/svg+xml',
    // 'application/postscript',
  ]

  // define the max file size in MB
  const MAX_FILE_SIZE = 32 * 1024 * 1024

  if (file.type.length === 0) {
    return false
  }

  if (VALID_FILE_TYPES.indexOf(file.type) === -1) {
    return 'file_type'
  }

  if (file.size > MAX_FILE_SIZE) {
    return 'too_large'
  }

  return 'valid'
}

/**
 * @param {BaseProductId} baseProductId
 * @param {string} colorId
 * @param {string} sizeId
 * @return {string}
 */
export const getBaseProductVariantId = (baseProductId, colorId, sizeId) => {
  return `${baseProductId}.${colorId}.${sizeId}`
}

/**
 * @param {BaseProductVariantId} baseProductVariantId
 * @return {{base_product_id: number, color_id: number, size_id: number}}
 */
export const splitBaseProductVariantId = (baseProductVariantId) => {
  const [ base_product_id, color_id, size_id ] = baseProductVariantId.split('.')

  return {
    base_product_id: parseInt(base_product_id),
    color_id: parseInt(color_id),
    size_id: parseInt(size_id),
  }
}

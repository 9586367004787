import catalogApi from '../../../api/catalog.js'

export default {
  loadCatalogProducts({ commit }, {
    category = null,
    color = [],
    currentPage = null,
    orderBy = null,
    brands = [],
    genders = [],
    locale = 'de'
  }) {
    return catalogApi.indexCatalog(
      category,
      color,
      currentPage,
      orderBy,
      brands,
      genders,
      locale
    ).then((base_products) => {
      return commit('SET_CATALOG_PRODUCTS', base_products)
    })
  },

  loadCatalogProduct({ commit }, slug) {
    return catalogApi.getSingleProduct(slug).then((base_product) => {
      return commit('SET_CATALOG_PRODUCT', base_product)
    })
  },

  loadAllProductCategories({ commit }) {
    return catalogApi.getProductAttributes('category').then((product_categories) => {
      return commit('SET_PRODUCT_CATEGORIES', product_categories)
    })
  },

  loadBrands({ commit }, { category = null, colors = [] }) {
    return catalogApi.getBrands(category, colors).then((brands) => {
      return commit('SET_BRANDS', brands)
    })
  },

  loadGenders({ commit }) {
    return catalogApi.getProductAttributes('gender').then((genders) => {
      return commit('SET_GENDERS', genders)
    })
  }
}

<template>
  <a
    href="#"
    :title="title"
    :class="['color-swatch', { active, dark }]"
    :style="`background-color: #${color}`"
    @click.prevent="$emit('click', $event)">
  </a>
</template>

<script>
  export default {

    props: {
      title: String,
      color: String,
      active: Boolean,
      dark: Boolean,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .color-swatch {
    display: inline-block;
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 3px solid $white;
    box-shadow: $color-swatch-shadow;

    &:hover {
      box-shadow: $color-swatch-shadow, 0 0 0 1px $blue;
    }

    &:focus {
      box-shadow: $color-swatch-shadow, $focus-outline;
    }

    &.active {
      box-shadow: 0 0 0 2px $blue;

      &:focus {
        box-shadow: 0 0 0 2px $blue, $focus-outline;
      }
    }
  }
</style>

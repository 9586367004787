<template>
  <header :class="className">
    <nav
      class="flex justify-between h-18 lg:h-20 items-center px-4"
      style="margin-bottom: -1px /* Align active state border*/"
    >
      <a
        style="flex-shrink: 0"
        class="w-12 h-12"
        href="/"
      >
        <img
          src="/img/Logo_Shirtigo_Bildmarke_RGB.svg"
          alt="Shirtigo"
          :aria-label="$t('views.sizes_config.return_to_homepage')"
        />
      </a>

      <slot />

      <nav-design-options @discard="$emit('discard')" />
    </nav>
  </header>
</template>

<script>
  import NavDesignOptions from '@/components/config/header/NavDesignOptions'
  import HeaderNavSteps from '@/components/config/header/HeaderNavSteps'

  export default {

    props: {
      className: {
        type: String,
        default: 'z-50 lg:bg-white lg:border-b',
      }
    },

    components: {
      NavDesignOptions,
      HeaderNavSteps,
    },

  }
</script>

import SizesConfig from '@/views/config/SizesConfig'
import MpcOverview from '@/views/config/MpcOverview'
import ConfigCalculator from '@/views/config/Calculator'
import ConfigCart from '@/views/config/Cart'
import Checkout from '@/views/config/Checkout'
import CheckoutConfirm from '@/views/config/CheckoutConfirm'
import ThankYou from '@/views/config/ThankYou'
import NotFound from '@/views/NotFound'
import CatalogIndex from '@/views/catalog/CatalogIndex'
import CatalogDetail from '@/views/catalog/CatalogDetail'
import MpcProductDesigner from '@/views/designer/MpcProductDesigner.vue'
import SingleProductDesigner from '@/views/designer/SingleProductDesigner'
export default [
  { path: '/designer/:id?/:reference?/:groupid?', name: 'single-product-designer', component: SingleProductDesigner },
  { path: '/calculate/:reference?/:groupid?', name: 'mpc-calculator', component: ConfigCalculator },
  { path: '/mpc/designer/:id?/:reference?/:groupid?', name: 'mpc-designer', component: MpcProductDesigner },
  { path: '/mpc/collection/:reference?/:groupid?', name: 'mpc-collection', component: MpcOverview },
  { path: '/mpc/size-select/:reference?/:groupid?', name: 'mpc-size-select', component: SizesConfig },
  { path: '/size-select/:reference?/:groupid?', name: 'config-sizes', component: SizesConfig },
  { path: '/cart/:reference?', name: 'cart', component: ConfigCart },
  { path: '/checkout-confirm/:reference?', name: 'checkout-confirm', component: CheckoutConfirm },
  { path: '/checkout/:reference?', name: 'checkout', component: Checkout },
  { path: '/thank-you', name: 'thank-you', component: ThankYou },
  { path: '/:lang/catalog/:key?', name: 'catalog-index', component: CatalogIndex },
  { path: '/:lang/catalog/product/:key?', name: 'catalog-product-show', component: CatalogDetail },
  { path: '/product/:key', name: 'catalog-show', component: CatalogDetail },
  { path: '/404', name: '404', component: NotFound },
  { path: '*', redirect: '/404' },
]

<template>
  <nav class="tab-nav">
    <a
      v-for="(val, key) in tabs"
      :key="key"
      href="#"
      :class="['tab', { active: key === active }]"
      @click.prevent="$emit('set:tab', key)">
      {{ val }}
    </a>
  </nav>
</template>

<script>
  export default {

    props: {
      tabs: {
        type: Object,
        required: true,
      },
      active: String,
    },

  }
</script>

<style lang="scss" scoped>
  @import "~sb_sass/variables";
  @import "~sb_sass/mixins";

  .tab-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-x: auto;

    @include breakpoint(sm) {
      flex-wrap: nowrap;
    }

    .tab {
      display: flex;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px;
      line-height: 1.75rem;
      font-size: .875rem;
      font-weight: 600;
      color: $grey-70;
      padding: .75rem 1rem;
      margin: 0;
      transition: .2s ease;
      border-bottom: 2px solid transparent;
      width: 100%;

      @include breakpoint(sm) {
        padding: 0 1rem .75rem;
        width: auto;
      }

      @include breakpoint(md) {
        margin: 0 1rem;
      }

      &:hover,
      &:focus {
        color: $blue;
      }

      &.active {
        color: $blue;
        border-bottom-color: $blue;
      }
    }
  }
</style>

<template>
  <footer class="bg-gray-05">
    <div class="text-gray-50 border-t border-gray-10 pt-3 md:pt-6 px-4">
      <div class="m-auto px-3 max-w-container">
        <div class="lg:flex mt-10 mb-10">
          <div class="sm:flex w-full">
            <div class="w-full text-center lg:text-left">
              <h4 class="block mb-2 text-xl font-bold text-gray-70">
                {{ $t('navigation.footer.legal') }}
              </h4>
              <ul class="leading-9">
                <li>
                  <a
                    href="/imprint"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.imprint') }}
                  </a>
                </li>
                <li>
                  <a
                    href="/terms"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.terms_and_conditions') }}
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.privacy') }}
                  </a>
                </li>
                <li>
                  <a
                    href="/withdrawal"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.withdrawal') }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="w-full text-center lg:text-left mt-4 sm:mt-0">
              <h4 class="block mb-2 text-xl font-bold text-gray-70">
                {{ $t('navigation.footer.service') }}
              </h4>
              <ul class="leading-9">
                <li>
                  <a
                    href="https://www.seedshirt.de/"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.seedshirt') }}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.shirtigo.de/"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.mpc') }}
                  </a>
                </li>
                <li>
                  <a
                    href="https://cockpit.shirtigo.de/"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.cockpit') }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="w-full text-center lg:text-left mt-4 sm:mt-0">
              <h4 class="block mb-2 text-xl font-bold text-gray-70">
                {{ $t('navigation.footer.ressources') }}
              </h4>
              <ul class="leading-9">
                <li>
                  <a
                    href="https://www.shirtigo.de/presse"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.press') }}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.shirtigo.de/jobs"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.jobs') }}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.shirtigo.de/imprint"
                    class="hover:text-gray-70"
                  >
                    {{ $t('navigation.footer.contact') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="mt-10 lg:mt-0 flex flex-col items-center lg:items-end">
            <div class="flex justify-center lg:justify-end">
              <a
                class="block"
                href="https://www.shirtigo.de/stanley-stella-nachhaltig-fair-produziert-shirtigo?&cat=collection"
              >
                <img
                  alt=""
                  src="/img/ss-official-dealer.svg"
                  style="height: 64px;"
                />
              </a>
              <div class="pl-10">
                <img
                  alt="Printed in Germany"
                  src="/img/printed_in_germany_footer.svg"
                  style="height: 64px;"
                />
              </div>
            </div>

            <div class="flex justify-center lg:justify-end mt-16">
              <a
                href="https://www.youtube.com/user/shirtigo"
                target="_blank"
                class="mr-8 block"
              >
                <img
                  alt="Youtube"
                  src="/img/youtube.svg"
                  style="height: 32px"
                />
              </a>
              <a
                href="https://de-de.facebook.com/shirtigo/"
                target="_blank"
                class="block"
              >
                <img
                  alt="Facebook"
                  src="/img/facebook.svg"
                  style="height: 32px"
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-dark-blue text-gray-10 px-2 py-6">
      <div class="m-auto px-3 max-w-container">
        *{{ $t('navigation.footer.price_info') }}
      </div>
    </div>
  </footer>
</template>

<script>
  export default {}
</script>

<style scoped>

</style>

<template>
  <div class="absolute h-screen w-full bg-grey-00 top-0 py-5 px-4 z-40 overflow-hidden">
    <div class="flex justify-between items-center mb-4">
      <p class="text-h5 font-bold tracking-2 flex items-center">
        <slot name="title"></slot>
      </p>

      <icon-button @click="$emit('close')">
        <i class="fas fa-times m-auto text-xl leading-10" />
      </icon-button>
    </div>

    <slot></slot>
  </div>
</template>

<script>
  import IconButton from '../buttons/IconButton'

  export default {

    components: { IconButton }

  }
</script>

import client from './client'

export default {
  indexCatalog(
    category = null,
    colors = [],
    currentPage = null,
    orderBy = null,
    brands = [],
    genders = [],
    locale = 'de'
  ) {
    let formData = {
      locale
    }

    if (category) {
      formData.category = category
    }

    if (genders.length) {
      formData.genders = genders
    }

    if (colors.length) {
      formData.colors = colors
    }

    if (currentPage) {
      formData.currentPage = currentPage
    }

    if (orderBy) {
      formData.orderBy = orderBy
    }

    if (brands) {
      formData.brands = brands
    }

    return client.get('/api/catalog', formData)
  },

  getSingleProduct(slug) {
    return client.get(`/api/catalog/${slug}`)
  },

  getProductAttributes(
    attribute = null,
    locale = 'de'
  ) {
    let formData = {
      locale
    }

    if (attribute) {
      formData.type = attribute
    }

    return client.get('/api/catalog/attributes', formData)
  },

  getBrands(category = null, colors = []) {
    let formData = {}

    if (category) {
      formData.category = category
    }

    if (colors.length) {
      formData.colors = colors
    }

    return client.get('/api/catalog/brands', formData)
  }
}
